import { useContext, useEffect, useState } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { loadStripe } from "@stripe/stripe-js";
import { BrokerContext } from "../../../contexts/BrokerContext";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getApps, initializeApp } from "firebase/app";
import { useNotification } from "../../../contexts/NotificationContext";

// Check if any Firebase apps already exist
const apps = getApps();
let app;
if (!apps.length) {
  app = initializeApp({
    /* your firebase config */
  });
} else {
  app = apps[0];
}

const functions = getFunctions(app);

const stripePromise = loadStripe(
  "pk_live_51MdD1XKAIrac8U37nW7QSqW60kDIkqrL5R0x89gXlAMUtjIgCbCNqvpCQAfLZ9el5WFuSXl4O3xLId3M61252y4M002vAXTbsr"
);

const includedFeatures = [
  "Effortless User Registration",
  "Seamless Transactions",
  "Advanced KYC Solutions",
  "Secure Data Encryption",
];

export default function BillingSettings() {
  const { broker } = useContext(BrokerContext);
  const [isLoading, setIsLoading] = useState(false); // Add this line
  const { showNotification } = useNotification();

  useEffect(() => {
    showNotification(
      "success",
      "Welcome aboard! Please activate the free trial below to start using XOH Sync."
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs once on component mount

  useEffect(() => {
    stripePromise.catch((error) => {
      console.error("Error initializing Stripe: ", error);
    });
  }, []);

  const handleSubscription = async () => {
    setIsLoading(true);

    const stripe = await stripePromise;

    const createSubscription = httpsCallable(functions, "createSubscription");

    try {
      // Get the current domain
      const currentDomain = window.location.origin;

      // Call the createSubscription function and pass the email from BrokerContext and the current domain
      const result = await createSubscription({
        email: broker.userData.email,
        priceId: "price_1O7vqYKAIrac8U37Z9zcksPY",
        domain: currentDomain,
      });

      // When the customer clicks on the button, redirect them to Checkout.
      const stripeResult = await stripe.redirectToCheckout({
        sessionId: result.data.id,
      });

      if (stripeResult.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer.
        alert(stripeResult.error.message);
      }
    } catch (error) {
      console.error("Error creating subscription: ", error);
    } finally {
      setIsLoading(false); // Set loading to false after Stripe redirection or in case of any error
    }
  };
  // Define the keyframes and animation styles
  const animationStyles = `
    @keyframes gradientX {
      0% { background-position: 0% 50%; }
      50% { background-position: 100% 50%; }
      100% { background-position: 0% 50%; }
    }
    .animate-gradient-x {
      background-size: 200% 200%;
      animation: gradientX 8s ease infinite;
      position: relative;
    }
    
    .animate-gradient-x::before {
      top: 0;
    }
    .animate-gradient-x::after {
      bottom: 0;
    }
    `;

  return (
    <>
      <style>{animationStyles}</style>
      <div
        style={{ height: "calc(100vh - 96px)", overflow: "hidden" }}
        className="bg-gray-50 dark:bg-gray-900 py-24 sm:py-32"
      >
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl rounded-3xl shadow-xl shadow-blue-600/20 dark:shadow-blue-600/10 ring-1 ring-gray-200 dark:ring-white/10 lg:mx-0 lg:flex lg:max-w-none animate-gradient-x bg-gradient-to-r  from-blue-700 via-purple-700 to-pink-700">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3 className="text-2xl font-bold tracking-tight text-white">
                XOH Sync Subscription
              </h3>
              <p className="mt-6 text-base leading-7 text-white/80">
                Dive into the full suite of XOH Sync, your all-encompassing CRM
                and Trader's Room for the X Open Hub platform. Experience
                firsthand how our integrated features can streamline your
                brokerage operations. During your trial, you’ll gain access to:
              </p>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-primary-500">
                  What’s included
                </h4>
                <div className="h-px flex-auto bg-white/10" />
              </div>
              <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-white sm:grid-cols-2 sm:gap-6">
                {includedFeatures.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-primary-500"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-white py-10 text-center ring-1 ring-inset ring-gray-900/5 dark:ring-white/10 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div className="mx-auto max-w-xs px-8">
                  <p className="text-base font-semibold text-gray-600">
                    Start with a 1-month free trial, then just $1000/month
                  </p>
                  <p className="mt-6 flex items-baseline justify-center gap-x-2">
                    <span className="text-5xl font-bold tracking-tight text-gray-900">
                      $1000
                    </span>
                    <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                      monthly
                    </span>
                  </p>
                  <button
                    onClick={handleSubscription}
                    className="mt-10 block w-full rounded-md bg-primary-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center">
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Redirecting to payment...
                      </div>
                    ) : (
                      "Start your free trial"
                    )}
                  </button>
                  <p className="mt-6 text-xs leading-5 text-gray-600">
                    Enjoy the flexibility of our service - you can cancel
                    anytime. No cancellation fee.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
