import React, { useContext, useState } from "react";
import { BrokerContext } from "../../../contexts/BrokerContext.js";
import { useNotification } from "../../../contexts/NotificationContext.js";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase";

export default function DomainSettings({ currentView, setCurrentView }) {
  const broker = useContext(BrokerContext)?.broker || {};
  const { showNotification } = useNotification();
  const [isSaving, setIsSaving] = useState(false);
  const [domain, setDomain] = useState("");
  const [txtRecord, setTxtRecord] = useState(null);

  function extractHost(domain) {
    const match = domain.match(/^_(vercel)\./);
    return match ? match[1] + "_" : domain; // Append an underscore to 'vercel' if matched
  }

  const handleDomainVerification = async () => {
    const brokerUID = broker.brokerData?.uid;

    const payload = {
      brokerUID: brokerUID,
      domain: domain,
    };

    const verifyVercelTxtFunction = httpsCallable(functions, "verifyVercelTxt");

    setIsSaving(true); // Set isSaving to true at the start

    // Check if the domain is in the format of a subdomain
    if (!/^platform\.[a-z0-9.-]+\.[a-z]{2,}$/.test(domain)) {
      showNotification(
        "error",
        "Please provide a valid subdomain in the format 'platform.domain.com'"
      );
      return;
    }

    try {
      console.log("Attempting to call verifyVercelTxt function...");
      const response = await verifyVercelTxtFunction(payload);
      console.log("Full response from verifyVercelTxtFunction:", response);
      if (response.data.success) {
        showNotification("success", response.data.message);
        setTxtRecord(null);
        setCurrentView("domainPage");
      } else {
        let txtData;
        if (response.data.txtRecord) {
          txtData = response.data.txtRecord;
        } else {
          const messageRegex =
            /Please set the following TXT record for domain verification: (.*)/;
          const match = response.data.message.match(messageRegex);
          if (match && match[1]) {
            txtData = JSON.parse(match[1]);
          }
        }

        if (txtData) {
          txtData.domain = extractHost(txtData.domain) || txtData.domain; // Extract the desired host from domain
          showNotification(
            "error",
            `${response.data.message}\nPlease set the following TXT record:\nName: ${txtData.domain}\nValue: ${txtData.value}`
          );
          setTxtRecord(txtData);
        } else {
          showNotification("error", response.data.message);
          setTxtRecord(null);
        }
      }
    } catch (error) {
      console.error("Domain verification error:", error);
      showNotification(
        "error",
        "An unexpected error occurred while verifying domain ownership."
      );
      setTxtRecord(null); // Clear the txtRecord state if there's an error
    } finally {
      setIsSaving(false); // Reset isSaving to false at the end
    }
  };

  const handleBreadcrumbClick = (view) => {
    setCurrentView(view);
  };

  const pages = [
    {
      name: "Custom domain",
      onClick: () => setCurrentView("domainPage"),
      view: "domainPage",
      current: currentView === "domainPage",
    },
    {
      name: "Domain configuration",
      onClick: () => setCurrentView("domainSettings"),
      view: "domainSettings",
      current: currentView === "domainSettings",
    },
  ];

  // Check if user object or userData is null or undefined
  if (!broker || !broker.userData) {
    return (
      <div className="fixed inset-0 flex items-center justify-center dark:bg-gray-900">
        <svg
          className="animate-spin h-10 w-10 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-2xl space-y-16 lg:mx-0 lg:max-w-none">
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          <li>
            <div>
              <button
                onClick={() => handleBreadcrumbClick("domainPage")} // replace 'yourDefaultView' with the desired view name
                className="text-gray-400 hover:text-primary-600 focus:outline-none cursor-pointer"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </button>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <button
                  onClick={() => {
                    handleBreadcrumbClick(page.view);
                  }}
                  className={`ml-4 text-sm font-medium ${
                    page.current ? "text-primary-600" : "text-gray-500"
                  } hover:text-primary-600 focus:outline-none cursor-pointer`}
                  aria-current={page.current ? "page" : undefined}
                >
                  {page.name}
                </button>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <div>
        <dl className="mt-6 space-y-6 divide-y divide-gray-100 dark:divide-gray-700 border-t border-gray-200 dark:border-gray-700 text-sm leading-6">
          <div className="w-full">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white text-left mt-6">
              Connect Your Subdomain to Our Platform
            </h2>
            <dd className="mt-2 text-gray-500">
              To integrate your domain with our platform, follow these steps:
              <ol className="list-decimal pl-5 mt-2">
                <li>
                  Log in to your domain registrar's website and navigate to the
                  DNS management or DNS settings section.
                </li>
                <li>
                  Set up a CNAME record for the "platform" subdomain:
                  <ul className="list-disc pl-5 mt-2 dark:text-gray-400">
                    <li>
                      <strong>Name/Host/Alias:</strong> Use `platform`.
                    </li>
                    <li>
                      <strong>Value/Answer/Destination:</strong> Set it to
                      `xohsync.vercel.app` (the address provided by our
                      platform).
                    </li>
                  </ul>
                </li>
                <li>Save your DNS settings.</li>
                <li>
                  Return to our platform and initiate the verification process.
                </li>
              </ol>
            </dd>

            {txtRecord && (
              <div className="mt-6 text-gray-500">
                <strong>Additional TXT Record Requirements:</strong>
                <ul className="list-disc pl-5 mt-2 dark:text-gray-400">
                  <li>
                    <strong>Name/Host/Alias:</strong> {txtRecord.domain}
                  </li>
                  <li>
                    <strong>Value/Answer/Destination:</strong> {txtRecord.value}
                  </li>
                </ul>
              </div>
            )}

            <div className="pt-6">
              <div>
                <label
                  htmlFor="customDomain"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Verify domain ownership
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset dark:bg-gray-800 ring-gray-300 dark:ring-gray-700 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-600 sm:max-w-md">
                    <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                      https://
                    </span>
                    <input
                      type="text"
                      name="customDomain"
                      id="customDomain"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 dark:text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="platform.domain.com" // Updated placeholder
                      value={domain}
                      onChange={(e) => setDomain(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <button
                type="button"
                className="rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                disabled={isSaving}
                onClick={handleDomainVerification}
              >
                {isSaving ? (
                  <div className="flex items-center">
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Verifying...
                  </div>
                ) : (
                  "Verify domain ownership"
                )}
              </button>
            </div>
          </div>
        </dl>
      </div>
    </div>
  );
}
