import React, { useContext } from 'react';
import { BrokerContext } from '../../../contexts/BrokerContext.js';
import GroupSelection from './GroupSelection.js';

export default function AccountGroupSettings({ openProfileUpdateModalWithField }) {
    const broker = useContext(BrokerContext)?.broker || {};
  
    // Check if user object or userData is null or undefined
    if (!broker || !broker.userData) {
        return (
        <div className="fixed inset-0 flex items-center justify-center dark:bg-gray-900">
            <svg className="animate-spin h-10 w-10 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </div>
        );
    }

  return (
    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div>
              <dl className="w-full space-y-6 divide-y divide-gray-100 dark:divide-gray-700 border-t border-gray-200 dark:border-gray-700 text-sm leading-6">
                <div className="pt-6 sm:flex">
                <GroupSelection openProfileUpdateModalWithField={openProfileUpdateModalWithField} />
                </div>
              </dl>
            </div>
          </div>
  );
}
