import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import logoDark from "../../logoDark.svg";
import {
  getFirestore,
  getDocs,
  collection,
  where,
  doc,
  getDoc,
  query,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { Tooltip } from "flowbite-react";
import getSymbolFromCurrency from "currency-symbol-map";
import { RadioGroup } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../Shared/LanguageSelector";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function OnboardingAccountSelection({ onAccountChange }) {
  const [logoUrls, setLogoUrls] = useState(null);
  const [logoLoading, setLogoLoading] = useState(true);
  const [accounts, setaccounts] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const notifiedDefault = useRef(false);
  const navigate = useNavigate();
  const useQueryParams = useQuery();
  const { user } = useContext(UserContext);
  const userBrokerUID = user?.userData?.brokerUID;
  const queryBrokerUID = useQueryParams.get("brokerUID");
  const brokerUID = userBrokerUID || queryBrokerUID;
  const { t } = useTranslation();

  useEffect(() => {
    // If the hostname doesn't start with "platform", exit early
    if (!window.location.hostname.startsWith("platform.")) {
      setLogoLoading(false); // Ensure loading is set to false
      return;
    }

    const fetchLogoUrls = async () => {
      try {
        setLogoLoading(true);
        const db = getFirestore();
        const domain = window.location.hostname.split(".").slice(-2).join("."); // Get the domain

        // Query the "whitelabel" collection where the domain field matches the domain
        const whitelabelQuery = query(
          collection(db, "whitelabel"),
          where("domain", "==", domain)
        );
        const whitelabelSnapshot = await getDocs(whitelabelQuery);

        // Check if any documents match the query
        if (!whitelabelSnapshot.empty) {
          // Get the first document from the query results
          const whitelabelDoc = whitelabelSnapshot.docs[0];

          // Update the logo URLs
          setLogoUrls({
            dark: whitelabelDoc.data().logoUrlDark,
            light: whitelabelDoc.data().logoUrlLight,
          });
        }
      } catch (error) {
        console.error("An error occurred while fetching logo URLs:", error);
      } finally {
        setLogoLoading(false); // Ensure this gets called even if an error occurs
      }
    };

    // Fetch the logo URLs
    fetchLogoUrls();
  }, []);

  useEffect(() => {
    const fetchAccounts = async () => {
      const db = getFirestore();
      const docRef = doc(db, "whitelabel", brokerUID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const groups = docSnap.data().groups || [];
        const fetchedAccounts = groups.map((group) => ({
          displayName: group.displayName || group.name,
          name: group.name,
          currency: group.currency,
          type: group.type,
        }));
        console.log("Fetched accounts:", fetchedAccounts);

        setaccounts(fetchedAccounts);
      }
      setLoading(false);
    };

    fetchAccounts();
  }, [brokerUID, navigate, notifiedDefault, onAccountChange]);

  return (
    <section className="py-8 bg-white dark:bg-gray-900 lg:py-0">
      <div className="lg:flex">
        <div className="hidden w-full max-w-md p-12 lg:block lg:h-screen bg-primary-600">
          <div className="flex items-center mb-8 space-x-4">
            {logoLoading ? (
              <div className="animate-pulse rounded-full bg-gray-200 dark:bg-white/10 h-10 w-10 mx-auto"></div>
            ) : (
              <img
                className="h-10 w-auto"
                src={logoUrls ? logoUrls.dark : logoDark}
                alt="Logo"
              />
            )}
          </div>
          <LanguageSelector />
        </div>
        <div className="flex items-center mx-auto md:w-[42rem] px-4 md:px-8 xl:px-0">
          <div className="w-full">
            <div className="flex items-center justify-center mb-8 space-x-4 lg:hidden">
              {logoLoading ? (
                <div className="animate-pulse rounded-full bg-gray-200 dark:bg-white/10 h-10 w-10 mx-auto"></div>
              ) : (
                <img
                  className="h-10 w-auto"
                  src={logoUrls ? logoUrls.dark : logoDark}
                  alt="Logo"
                />
              )}
            </div>
            <ol className="flex items-center mb-6 text-sm font-medium text-center text-gray-500 dark:text-gray-400 lg:mb-12 sm:text-base justify-center">
              <li className="flex items-center after:content-[''] after:w-12 after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                <div className="flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-gray-200 dark:after:text-gray-500">
                  <div className="mr-2 sm:mb-2 sm:mx-auto">1</div>
                  {t("onboarding.selection")}
                </div>
              </li>
              <li className="flex items-center after:content-[''] after:w-12 after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                <div className="flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-gray-200 dark:after:text-gray-500">
                  <div className="mr-2 sm:mb-2 sm:mx-auto">2</div>
                  {t("onboarding.info")}
                </div>
              </li>
              <li className="flex items-center sm:block">
                <div className="mr-2 sm:mb-2 sm:mx-auto">3</div>
                {t("onboarding.finished")}
              </li>
            </ol>
            <h1 className="mb-4 text-2xl font-extrabold leading-tight tracking-tight text-gray-900 sm:mb-6 dark:text-white">
              {t("onboarding.chooseAccountTitle")}
              <span className="ml-3 inline-flex items-center justify-center w-6 h-6 me-2 text-sm font-semibold text-blue-800 bg-blue-100 rounded-full dark:bg-gray-700 dark:text-blue-400">
                <Tooltip content={t("onboarding.accountTooltip")}>
                  <svg
                    className="w-4 h-4"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Tooltip>
              </span>
            </h1>

            <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
              {t("onboarding.accountDescription")}
            </p>
            {loading ? (
              <div className="mb-6 space-y-4 sm:space-y-6">
                <input
                  type="radio"
                  id="skeleton"
                  name="profession"
                  className="hidden peer"
                  required=""
                />
                <label
                  htmlFor="skeleton"
                  className="inline-flex items-center justify-center w-full p-5 text-gray-500 border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-primary-500 peer-checked:border-primary-600 peer-checked:text-primary-600 bg-gray-50 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mr-2 animate-pulse"></div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full animate-pulse"></div>
                  <svg
                    className="w-6 h-6 ml-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </label>
              </div>
            ) : (
              <RadioGroup
                value={selected}
                onChange={(newSelectedAccount) => {
                  console.log("Selected account:", newSelectedAccount);
                  setSelected(newSelectedAccount);
                  if (onAccountChange) {
                    onAccountChange({
                      name: newSelectedAccount.name,
                      type: newSelectedAccount.type,
                    });
                  }
                }}
              >
                {accounts.map((account, index) => (
                  <RadioGroup.Option key={index} value={account}>
                    {() => (
                      <>
                        <div className="mb-6 space-y-4 sm:space-y-6">
                          <input
                            type="radio"
                            id={account.name}
                            name="profession"
                            value={account.name}
                            className="hidden peer"
                            required=""
                          />
                          <label
                            htmlFor={account.name}
                            className="inline-flex items-center justify-center w-full p-5 text-gray-500 border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-primary-500 peer-checked:border-primary-600 peer-checked:text-primary-600 bg-gray-50 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                          >
                            <span className="px-2.5 py-0.5 space-x-1 inline-flex items-center justify-center ms-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                              <span>
                                {getSymbolFromCurrency(account.currency)}
                              </span>
                            </span>
                            <span className="px-2.5 py-0.5 space-x-1 mr-2 inline-flex items-center justify-center ms-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                              <span>{account.type}</span>
                            </span>

                            <span className="w-full">
                              {account.displayName || account.name}
                            </span>
                            <svg
                              className="w-6 h-6 ml-3"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </label>
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            )}
            <button
              onClick={() => {
                if (accounts.length > 0) {
                  const firstAccount = accounts[0];
                  setSelected(firstAccount); // Update the selected state to the first account
                  // Mimic the onChange behavior of RadioGroup
                  if (onAccountChange) {
                    onAccountChange({
                      name: firstAccount.name,
                      type: firstAccount.type,
                    });
                  }
                }
              }}
              className="w-full px-5 py-2.5 sm:py-3.5 text-sm font-medium text-center text-white rounded-lg bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              {t("onboarding.nextButtonAccountInfo")}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
