import {
  Fragment,
  useRef,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { UserContext } from "../../contexts/UserContext.js";
import { useNotification } from "../../contexts/NotificationContext.js";
import { functions, auth } from "../../firebase.js";
import { httpsCallable } from "firebase/functions";
import { useLanguage } from "../../contexts/LanguageContext.js";
import KYCInfoModal from "./KYCModals/KYCInfoModal.js";
import { CustomCashier } from "./PSPModals/CustomCashier/index.js";
import CryptoDepositInfo from "./PSPModals/CustomCashier/CryptoDepositInfo.js";
import { useNavigate } from "react-router-dom";

export default function DepositModal({ open, setOpen, onCloseModal }) {
  const cancelButtonRef = useRef(null);
  const { showNotification } = useNotification();
  const { user } = useContext(UserContext);
  const { language } = useLanguage();
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [pspType, setPspType] = useState(null);
  const [isCashierLoaded, setIsCashierLoaded] = useState(false);
  const [showKYCInfo, setShowKYCInfo] = useState(false);
  const [amount, setAmount] = useState("");
  const [selectedMethod, setSelectedMethod] = useState("card");
  const [cryptoData, setCryptoData] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState("EUR");
  const [brightcartSrc, setBrightcartSrc] = useState(null);
  const navigate = useNavigate();
  const [initializationToken, setInitializationToken] = useState(null);
  const [mifinityScriptLoaded, setMifinityScriptLoaded] = useState(false);
  const mifinityWidgetRef = useRef(null);

  const handleDeposit = useCallback(async () => {
    if (
      user.userData.kycStatus !== "approved_kyc" &&
      !user.userData.allowDepositBeforeKYC
    ) {
      setShowKYCInfo(true);
      return;
    }
    try {
      setIsLoading(true);
      const routePSPRequest = httpsCallable(functions, "routePSPRequest");
      const requestData = {
        uid: auth.currentUser.uid,
        brokerUID: user.userData.brokerUID,
        userLogin: selectedAccountId,
        language: language,
        selectedMethod: selectedMethod,
        currency:
          selectedCurrency === "USDT (ERC20)" ? "USDT" : selectedCurrency,
      };
      if (amount) {
        requestData.amount = parseFloat(amount);
      }
      const response = await routePSPRequest(requestData);
      console.log("Firebase function response:", response.data);

      if (response.data && response.data.result === "success") {
        if (response.data.address && response.data.qr_code) {
          setCryptoData(response.data);
        } else if (response.data.redirectUrl && selectedMethod === "card") {
          setBrightcartSrc(response.data.redirectUrl);
        } else if (
          response.data.initializationToken &&
          selectedMethod === "mifinity"
        ) {
          const token =
            response.data.initializationToken.payload[0].initializationToken;
          console.log("Setting initializationToken:", token);
          setInitializationToken(token);
        } else {
          setRedirectUrl(response.data.redirect_url);
          setAuthToken(response.data.auth_token);
          setPspType(response.data.type);
        }
      }
    } catch (error) {
      console.error("Error initiating deposit:", error);
      showNotification("error", "Failed to initiate deposit.");
    } finally {
      setIsLoading(false);
    }
  }, [
    user,
    selectedAccountId,
    showNotification,
    language,
    amount,
    selectedMethod,
    selectedCurrency,
    navigate,
  ]);

  const closeAllModals = useCallback(() => {
    setOpen(false);
    setShowKYCInfo(false);
    // ... any other cleanup
  }, [setOpen]);

  useEffect(() => {
    const handleUrlChange = () => {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("cancel") === "true") {
        closeAllModals();
        navigate("/dashboard", { replace: true });
      }
    };

    window.addEventListener("popstate", handleUrlChange);
    return () => {
      window.removeEventListener("popstate", handleUrlChange);
    };
  }, [closeAllModals, navigate]);

  useEffect(() => {
    // Set the selectedAccountId if modal is opened.
    // If there's only one trading account, it gets selected.
    // If there are multiple trading accounts, the first one gets preselected.
    if (
      open &&
      user &&
      user.accountData &&
      user.accountData.trading_accounts &&
      user.accountData.trading_accounts.length > 0
    ) {
      setSelectedAccountId(user.accountData.trading_accounts[0].id_account);
      console.log("Selected account:", user.accountData.trading_accounts[0]);
    }
  }, [open, user]);

  const accounts =
    user && user.accountData && user.accountData.trading_accounts
      ? user.accountData.trading_accounts
      : [];

  useEffect(() => {
    if (!open) {
      setShowKYCInfo(false);
      setCryptoData(null);
      setRedirectUrl(null);
      setAuthToken(null);
      setPspType(null);
      setIsCashierLoaded(false);
      setAmount("");
      setSelectedMethod("card");
      setSelectedCurrency("EUR");
      setBrightcartSrc(null);
      setInitializationToken(null); // Add this line to reset the initializationToken
    }
  }, [open]);

  useEffect(() => {}, [open]);

  const handleTransactionApproved = (transactionData) => {
    const { amount, currency } = transactionData;
    showNotification(
      "success",
      `Success! Your transaction of ${amount} ${currency} has been approved!`
    );

    setOpen(false);
  };

  const handleTransactionAlert = (transactionData) => {
    const { status, error_message } = transactionData;
    const alertMessage = error_message || "Alert! Transaction not approved.";

    showNotification("warning", `Transaction ${status}: ${alertMessage}`);

    setOpen(false);
  };

  const loadMifinityScript = useCallback(() => {
    if (!mifinityScriptLoaded) {
      const script = document.createElement("script");
      script.src = "https://secure.mifinity.com/widgets/sgpg.js?58190a411dc3";
      script.async = true;
      script.onload = () => {
        console.log("MiFinity script loaded.");
        setMifinityScriptLoaded(true);
      };
      script.onerror = () => {
        console.error("Failed to load MiFinity script.");
        showNotification("error", "Failed to load payment gateway.");
      };
      document.body.appendChild(script);
    }
  }, [mifinityScriptLoaded, showNotification]);

  useEffect(() => {
    if (initializationToken) {
      loadMifinityScript();
    }
  }, [initializationToken, loadMifinityScript]);

  const initializeMifinityIframe = useCallback(() => {
    if (initializationToken && mifinityScriptLoaded) {
      console.log(
        "Initializing MiFinity iframe with token:",
        initializationToken
      );
      if (mifinityWidgetRef.current && mifinityWidgetRef.current.close) {
        mifinityWidgetRef.current.close();
      }
      mifinityWidgetRef.current = window.showPaymentIframe(
        "mifinity-widget-container",
        {
          token: initializationToken,
          complete: function () {
            setTimeout(() => {
              if (
                mifinityWidgetRef.current &&
                mifinityWidgetRef.current.close
              ) {
                mifinityWidgetRef.current.close();
              }
              setOpen(false);
              setInitializationToken(null);
            }, 5000);
          },
        }
      );
    }
  }, [initializationToken, mifinityScriptLoaded, setOpen]);

  useEffect(() => {
    if (initializationToken && mifinityScriptLoaded) {
      initializeMifinityIframe();
    }
    return () => {
      if (mifinityWidgetRef.current && mifinityWidgetRef.current.close) {
        mifinityWidgetRef.current.close();
      }
      mifinityWidgetRef.current = null;
    };
  }, [initializationToken, mifinityScriptLoaded, initializeMifinityIframe]);

  return showKYCInfo ? (
    <KYCInfoModal
      open={showKYCInfo}
      setOpen={setShowKYCInfo}
      onCloseModal={closeAllModals}
    />
  ) : (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg w-[95%] h-[95%] max-h-[95vh] sm:h-auto sm:max-h-none"
                style={
                  initializationToken
                    ? {
                        minWidth: "310px",
                        maxWidth: "375px",
                        minHeight: "680px",
                        width: "100%",
                        height: "100%",
                      }
                    : {}
                }
              >
                {cryptoData ? (
                  <CryptoDepositInfo
                    cryptoData={cryptoData}
                    onClose={() => setOpen(false)}
                  />
                ) : brightcartSrc ? (
                  <div className="h-full">
                    <iframe
                      src={brightcartSrc}
                      height="788px"
                      width="100%"
                      className="border-0"
                      allow="payment *; storage-access *; fullscreen *"
                    />
                  </div>
                ) : initializationToken ? (
                  <div
                    key={initializationToken}
                    id="mifinity-widget-container"
                    className="w-full h-full"
                  />
                ) : (
                  <CustomCashier
                    redirectUrl={redirectUrl}
                    authToken={authToken}
                    onLoaded={() => setIsCashierLoaded(true)}
                    isHidden={!isCashierLoaded}
                    onTransactionApproved={handleTransactionApproved}
                    onTransactionAlert={handleTransactionAlert}
                    isLoading={isLoading}
                    accounts={accounts.filter(
                      (account) => account.accountType !== "Demo"
                    )}
                    selectedAccountId={selectedAccountId}
                    setSelectedAccountId={setSelectedAccountId}
                    handleDeposit={handleDeposit}
                    onClose={() => setOpen(false)}
                    amount={amount}
                    setAmount={setAmount}
                    selectedMethod={selectedMethod}
                    setSelectedMethod={setSelectedMethod}
                    selectedCurrency={selectedCurrency}
                    setSelectedCurrency={setSelectedCurrency}
                    className="h-full"
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
