// Define a map of Firebase error codes to user-friendly messages
const firebaseErrorMessages = {
    "auth/admin-restricted-operation": "Admin only operation",
    "auth/argument-error": "Argument error",
    "auth/app-not-authorized": "App not authorized",
    "auth/app-not-installed": "App not installed",
    "auth/captcha-check-failed": "Captcha check failed",
    "auth/code-expired": "Code expired",
    "auth/cordova-not-ready": "Cordova not ready",
    "auth/cors-unsupported": "CORS unsupported",
    "auth/credential-already-in-use": "Credential already in use",
    "auth/custom-token-mismatch": "Credential mismatch",
    "auth/requires-recent-login": "Credential too old, login again",
    "auth/dependent-sdk-initialized-before-auth": "Dependent SDK initialized before Auth",
    "auth/dynamic-link-not-activated": "Dynamic link not activated",
    "auth/email-change-needs-verification": "Email change needs verification",
    "auth/email-already-in-use": "Email already in use",
    "auth/emulator-config-failed": "Emulator config failed",
    "auth/expired-action-code": "Expired action code",
    "auth/cancelled-popup-request": "Expired popup request",
    "auth/internal-error": "Internal error",
    "auth/invalid-api-key": "Invalid API key",
    "auth/invalid-app-credential": "Invalid app credential",
    "auth/invalid-app-id": "Invalid app ID",
    "auth/invalid-user-token": "Invalid Auth",
    "auth/invalid-auth-event": "Invalid auth event",
    "auth/invalid-cert-hash": "Invalid cert hash",
    "auth/invalid-verification-code": "Invalid code",
    "auth/invalid-continue-uri": "Invalid continue URI",
    "auth/invalid-cordova-configuration": "Invalid Cordova configuration",
    "auth/invalid-custom-token": "Invalid custom token",
    "auth/invalid-dynamic-link-domain": "Invalid dynamic link domain",
    "auth/invalid-email": "Invalid email",
    "auth/invalid-emulator-scheme": "Invalid emulator scheme",
    "auth/invalid-credential": "Invalid IDP response",
    "auth/invalid-message-payload": "Invalid message payload",
    "auth/invalid-multi-factor-session": "Invalid MFA session",
    "auth/invalid-oauth-client-id": "Invalid OAuth client ID",
    "auth/invalid-oauth-provider": "Invalid OAuth provider",
    "auth/invalid-action-code": "Invalid OOB code",
    "auth/unauthorized-domain": "Invalid origin",
    "auth/wrong-password": "Invalid password",
    "auth/invalid-persistence-type": "Invalid persistence",
    "auth/invalid-phone-number": "Invalid phone number",
    "auth/invalid-provider-id": "Invalid provider ID",
    "auth/invalid-recipient-email": "Invalid recipient email",
    "auth/invalid-sender": "Invalid sender",
    "auth/invalid-verification-id": "Invalid session info",
    "auth/invalid-tenant-id": "Invalid tenant ID",
    "auth/multi-factor-info-not-found": "MFA info not found",
    "auth/multi-factor-auth-required": "MFA required",
    "auth/missing-android-pkg-name": "Missing Android package name",
    "auth/missing-app-credential": "Missing app credential",
    "auth/auth-domain-config-required": "Missing Auth domain",
    "auth/missing-verification-code": "Missing code",
    "auth/missing-continue-uri": "Missing continue URI",
    "auth/missing-iframe-start": "Missing iframe start",
    "auth/missing-ios-bundle-id": "Missing iOS bundle ID",
    "auth/missing-or-invalid-nonce": "Missing or invalid nonce",
    "auth/missing-multi-factor-info": "Missing MFA info",
    "auth/missing-multi-factor-session": "Missing MFA session",
    "auth/missing-phone-number": "Missing phone number",
    "auth/missing-verification-id": "Missing session info",
    "auth/app-deleted": "Module destroyed",
    "auth/account-exists-with-different-credential": "Need confirmation",
    "auth/network-request-failed": "Network request failed",
    "auth/null-user": "Null user",
    "auth/no-auth-event": "No auth event",
    "auth/no-such-provider": "No such provider",
    "auth/operation-not-allowed": "Operation not allowed",
    "auth/operation-not-supported-in-this-environment": "Operation not supported",
    "auth/popup-blocked": "Popup blocked",
    "auth/popup-closed-by-user": "Popup closed by user",
    "auth/provider-already-linked": "Provider already linked",
    "auth/quota-exceeded": "Quota exceeded",
    "auth/redirect-cancelled-by-user": "Redirect cancelled by user",
    "auth/redirect-operation-pending": "Redirect operation pending",
    "auth/rejected-credential": "Rejected credential",
    "auth/second-factor-already-in-use": "Second factor already enrolled",
    "auth/maximum-second-factor-count-exceeded": "Second factor limit exceeded",
    "auth/tenant-id-mismatch": "Tenant ID mismatch",
    "auth/timeout": "Timeout",
    "auth/user-token-expired": "Token expired",
    "auth/too-many-requests": "Too many attempts, try later",
    "auth/unauthorized-continue-uri": "Unauthorized domain",
    "auth/unsupported-first-factor": "Unsupported first factor",
    "auth/unsupported-persistence-type": "Unsupported persistence",
    "auth/unsupported-tenant-operation": "Unsupported tenant operation",
    "auth/unverified-email": "Unverified email",
    "auth/user-cancelled": "User cancelled",
    "auth/user-not-found": "User not found",
    "auth/user-disabled": "User disabled",
    "auth/user-mismatch": "User mismatch",
    "auth/user-signed-out": "User signed out",
    "auth/weak-password": "Weak password",
    "auth/web-storage-unsupported": "Web storage unsupported",
    "auth/already-initialized": "Already initialized",
    "auth/recaptcha-not-enabled": "Recaptcha not enabled",
    "auth/missing-recaptcha-token": "Missing Recaptcha token",
    "auth/invalid-recaptcha-token": "Invalid Recaptcha token",
    "auth/invalid-recaptcha-action": "Invalid Recaptcha action",
    "auth/missing-client-type": "Missing client type",
    "auth/missing-recaptcha-version": "Missing Recaptcha version",
    "auth/invalid-recaptcha-version": "Invalid Recaptcha version",
    "auth/invalid-req-type": "Invalid request type",
    "auth/admin-restricted-operation": "This operation is restricted to administrators.",
    "auth/argument-error": "Invalid arguments provided.",
    "auth/app-not-authorized": "This app is not authorized to use Firebase.",
    "auth/app-not-installed": "This app is not installed on your device.",
    "auth/captcha-check-failed": "Google reCAPTCHA check failed.",
    "auth/code-expired": "The provided code has expired.",
    "auth/cordova-not-ready": "Cordova is not ready.",
    "auth/cors-unsupported": "CORS is not supported by your browser.",
    "auth/credential-already-in-use": "This credential is already associated with another account.",
    "auth/custom-token-mismatch": "The custom token and API key do not match.",
    "auth/requires-recent-login": "Please login again, as your last login was too long ago.",
    "auth/dependent-sdk-initialized-before-auth": "Please initialize the Firebase Auth SDK before other dependent SDKs.",
    "auth/dynamic-link-not-activated": "Dynamic links are not activated.",
    "auth/email-change-needs-verification": "Please verify your new email address.",
    "auth/email-already-in-use": "The email address is already in use.",
    "auth/emulator-config-failed": "Emulator configuration failed.",
    "auth/expired-action-code": "The action code has expired.",
    "auth/cancelled-popup-request": "The popup request has been cancelled.",
    "auth/internal-error": "An internal error has occurred.",
    "auth/invalid-api-key": "The provided API key is invalid.",
    "auth/invalid-app-credential": "The app credential is invalid.",
    "auth/invalid-app-id": "The app ID is invalid.",
    "auth/invalid-user-token": "Invalid user token.",
    "auth/invalid-auth-event": "Invalid authentication event.",
    "auth/invalid-cert-hash": "Invalid certificate hash.",
    "auth/invalid-verification-code": "The provided verification code is invalid.",
    "auth/invalid-continue-uri": "The continue URL is invalid.",
    "auth/invalid-cordova-configuration": "Invalid Cordova configuration.",
    "auth/invalid-custom-token": "The custom token is invalid.",
    "auth/invalid-dynamic-link-domain": "The dynamic link domain is invalid.",
    "auth/invalid-email": "Invalid email address.",
    "auth/invalid-emulator-scheme": "Invalid emulator scheme.",
    "auth/invalid-credential": "Invalid Identity Provider response.",
    "auth/invalid-message-payload": "Invalid message payload.",
    "auth/invalid-multi-factor-session": "Invalid multi-factor authentication session.",
    "auth/invalid-oauth-client-id": "Invalid OAuth client ID.",
    "auth/invalid-oauth-provider": "Invalid OAuth provider.",
    "auth/invalid-action-code": "The reset password action code is invalid.",
    "auth/unauthorized-domain": "Unauthorized domain.",
    "auth/wrong-password": "Incorrect password.",
    "auth/invalid-persistence-type": "Invalid persistence type.",
    "auth/invalid-phone-number": "Invalid phone number.",
    "auth/invalid-provider-id": "Invalid provider ID.",
    "auth/invalid-recipient-email": "Invalid recipient email.",
    "auth/invalid-sender": "Invalid sender.",
    "auth/invalid-verification-id": "Invalid verification ID.",
    "auth/invalid-tenant-id": "Invalid tenant ID.",
    "auth/multi-factor-info-not-found": "Multi-factor authentication information not found.",
    "auth/multi-factor-auth-required": "Multi-factor authentication is required.",
    "auth/missing-android-pkg-name": "Missing Android package name.",
    "auth/missing-app-credential": "Missing app credential.",
    "auth/auth-domain-config-required": "Missing authentication domain configuration.",
    "auth/missing-verification-code": "Missing verification code.",
    "auth/missing-continue-uri": "Missing continue URL.",
    "auth/missing-iframe-start": "Missing iframe start.",
    "auth/missing-ios-bundle-id": "Missing iOS bundle ID.",
    "auth/missing-or-invalid-nonce": "Missing or invalid nonce.",
    "auth/missing-multi-factor-info": "Missing multi-factor authentication information.",
    "auth/missing-multi-factor-session": "Missing multi-factor authentication session.",
    "auth/missing-phone-number": "Missing phone number.",
    "auth/missing-verification-id": "Missing verification ID.",
    "auth/app-deleted": "The authentication module has been deleted.",
    "auth/account-exists-with-different-credential": "An account with this email already exists with a different credential.",
    "auth/network-request-failed": "A network request has failed.",
    "auth/null-user": "No user is currently signed in.",
    "auth/no-auth-event": "No authentication event.",
    "auth/no-such-provider": "No such Identity Provider.",
    "auth/operation-not-allowed": "This operation is not allowed.",
    "auth/operation-not-supported-in-this-environment": "This operation is not supported in your current environment.",
    "auth/popup-blocked": "Popup blocked by the browser.",
    "auth/popup-closed-by-user": "Popup closed by user.",
    "auth/provider-already-linked": "This provider is already linked to your account.",
    "auth/quota-exceeded": "Quota exceeded.",
    "auth/redirect-cancelled-by-user": "Redirect cancelled by user.",
    "auth/redirect-operation-pending": "Redirect operation is pending.",
    "auth/rejected-credential": "Rejected credential.",
    "auth/second-factor-already-in-use": "Second factor authentication method already in use.",
    "auth/maximum-second-factor-count-exceeded": "Maximum number of second factor methods exceeded.",
    "auth/tenant-id-mismatch": "Tenant ID mismatch.",
    "auth/timeout": "A timeout has occurred.",
    "auth/user-token-expired": "User token has expired.",
    "auth/too-many-requests": "Too many attempts. Please try again later.",
    "auth/unauthorized-continue-uri": "Unauthorized continue URL.",
    "auth/unsupported-first-factor": "Unsupported first factor authentication method.",
    "auth/unsupported-persistence-type": "Unsupported persistence type.",
    "auth/unsupported-tenant-operation": "Unsupported tenant operation.",
    "auth/unverified-email": "Unverified email address.",
    "auth/user-cancelled": "User cancelled authentication.",
    "auth/user-not-found": "User not found.",
    "auth/user-disabled": "User account is disabled.",
    "auth/user-mismatch": "User mismatch.",
    "auth/user-signed-out": "User has signed out.",
    "auth/weak-password": "The password is weak.",
    "auth/web-storage-unsupported": "Web storage is unsupported by your browser.",
    "auth/already-initialized": "Firebase Auth has already been initialized.",
    "auth/recaptcha-not-enabled": "Google reCAPTCHA is not enabled.",
    "auth/missing-recaptcha-token": "Missing Google reCAPTCHA token.",
    "auth/invalid-recaptcha-token": "Invalid Google reCAPTCHA token.",
    "auth/invalid-recaptcha-action": "Invalid Google reCAPTCHA action.",
    "auth/missing-client-type": "Missing client type.",
    "auth/missing-recaptcha-version": "Missing Google reCAPTCHA version.",
    "auth/invalid-recaptcha-version": "Invalid Google reCAPTCHA version.",
    "auth/invalid-req-type": "Invalid request type."
  }
  
  export const handleFirebaseError = (errorCode) => {
    // Return the user-friendly message if it exists, otherwise a generic error message
    return firebaseErrorMessages[errorCode] || 'An unknown error occurred.';
  }
  