import React, { useEffect, useState } from "react";
import { Table } from "flowbite-react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useNotification } from "../../../../../contexts/NotificationContext";
import ReactCountryFlag from "react-country-flag";
import countries from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";
import Pagination from "../../../../Shared/Pagination";
import { useTranslation } from "react-i18next";

countries.registerLocale(en);

const UsersReportIB = () => {
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { showNotification } = useNotification();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const { t } = useTranslation();

  const fetchUserData = async (page = 1) => {
    setIsLoading(true);
    const pageSize = 10; // Or any other size you prefer
    const functions = getFunctions();
    const queryBigQueryIB = httpsCallable(functions, "queryBigQueryIB");
    try {
      const result = await queryBigQueryIB({
        type: "fetchUserDetails",
        pageSize: pageSize,
        page: page - 1, // Adjust if your backend expects 0-indexed pages
        timePeriod: "1",
      });
      console.log(result);
      if (result.data && result.data.userData) {
        setUserData(result.data.userData);
        const totalRecordsValue = result.data.totalRecords || 0; // Ensure totalRecords is not undefined
        setTotalRecords(totalRecordsValue); // Set totalRecords in state
        setTotalPages(Math.max(Math.ceil(totalRecordsValue / pageSize), 1)); // Ensure totalPages is at least 1
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      showNotification(
        "error",
        "Unable to load user data. Please try refreshing the page or check back later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchUserData(newPage);
  };

  useEffect(() => {
    fetchUserData(); // This will fetch the first page by default
  }, []);

  // Before the return statement in your component
  console.log("Current Page:", currentPage, "Total Pages:", totalPages);

  // Ensure totalPages is a non-negative number
  const safeTotalPages = totalPages >= 0 ? totalPages : 1;

  const downloadReport = async () => {
    setIsDownloading(true);

    const functions = getFunctions();
    const queryBigQueryIB = httpsCallable(functions, "queryBigQueryIB");

    try {
      // Call the function with fetchAll set to true
      const result = await queryBigQueryIB({
        type: "fetchUserDetails",
        fetchAll: true,
        timePeriod: "1", // Assuming timePeriod is still relevant in this context
      });

      if (result.data && result.data.userData) {
        // Convert userData to CSV
        const csvRows = [
          ["Name", "Email", "Country", "Registration", "User ID"], // CSV Header
          ...result.data.userData.map((user) =>
            [
              user.name,
              user.email,
              user.country,
              user.timestamp.value,
              user.uid,
            ].join(",")
          ), // Map each user to a CSV row
        ].join("\n");

        // Create a Blob with the CSV content
        const blob = new Blob([csvRows], { type: "text/csv" });
        const url = URL.createObjectURL(blob);

        // Create a temporary anchor to trigger download
        const a = document.createElement("a");
        a.href = url;
        a.download = "users-report.csv";
        document.body.appendChild(a);
        a.click();

        // Clean up
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Failed to fetch all user data:", error);
      showNotification(
        "error",
        "Unable to download user data. Please try again later."
      );
    } finally {
      setIsDownloading(false); // End loading
    }
  };

  return (
    <div className="p-6 bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full">
      <div className="flex justify-between">
        <div>
          <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-2">
            {t("ibUsersReport.title")}
          </h3>
          <p className="mb-2 max-w-xl">{t("ibUsersReport.description")}</p>
        </div>
        <div>
          <button
            type="button"
            onClick={downloadReport}
            disabled={isDownloading || isLoading}
            className="px-5 py-2.5 text-sm font-medium text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            <svg
              className="w-3.5 h-3.5 text-white me-2"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.7587 1H15.2413C16.0463 0.999988 16.7106 0.999979 17.2518 1.0442C17.8139 1.09012 18.3306 1.18868 18.816 1.43598C19.5686 1.81947 20.1805 2.43139 20.564 3.18404C20.8113 3.66938 20.9099 4.18608 20.9558 4.74818C21 5.28937 21 5.95372 21 6.75868V11.9742C21 12.5239 21 12.7987 20.9362 12.9126C20.8598 13.0488 20.83 13.0749 20.6849 13.1327C20.5636 13.1809 20.1753 13.1294 19.3987 13.0263C19.2682 13.0089 19.1352 13 19 13C17.3431 13 16 14.3431 16 16C15.2322 16 14.4645 16.2929 13.8787 16.8787C12.7071 18.0503 12.7071 19.9497 13.8787 21.1213L14.3968 21.6394C14.8232 22.0659 15.0364 22.2791 15.0509 22.4629C15.0635 22.6223 14.9988 22.7785 14.8773 22.8823C14.7371 23.0021 14.4359 23.0023 13.8334 23.0026C12.9003 23.003 11.9672 23.0022 11.0341 23.0013C10.2756 23.0007 9.51716 23 8.75868 23C7.95372 23 7.28937 23 6.74818 22.9558C6.18608 22.9099 5.66938 22.8113 5.18404 22.564C4.43139 22.1805 3.81947 21.5686 3.43598 20.816C3.18868 20.3306 3.09012 19.8139 3.0442 19.2518C2.99998 18.7106 2.99999 18.0463 3 17.2413V6.75869C2.99999 5.95373 2.99998 5.28936 3.0442 4.74818C3.09012 4.18608 3.18868 3.66938 3.43598 3.18404C3.81947 2.43139 4.43139 1.81947 5.18404 1.43598C5.66938 1.18868 6.18608 1.09012 6.74818 1.0442C7.28936 0.999979 7.95375 0.999988 8.7587 1ZM7 5C6.44772 5 6 5.44772 6 6C6 6.55229 6.44772 7 7 7H16C16.5523 7 17 6.55229 17 6C17 5.44772 16.5523 5 16 5H7ZM6 10C6 9.44771 6.44772 9 7 9H13C13.5523 9 14 9.44771 14 10C14 10.5523 13.5523 11 13 11H7C6.44772 11 6 10.5523 6 10ZM6 14C6 13.4477 6.44772 13 7 13H9C9.55229 13 10 13.4477 10 14C10 14.5523 9.55229 15 9 15H7C6.44772 15 6 14.5523 6 14Z"
                fill="currentColor"
              />
              <path
                d="M19 15C19.5523 15 20 15.4477 20 16V19.5858L21.2929 18.2929C21.6834 17.9024 22.3166 17.9024 22.7071 18.2929C23.0976 18.6834 23.0976 19.3166 22.7071 19.7071L19.7071 22.7071C19.5196 22.8946 19.2652 23 19 23C18.7348 23 18.4804 22.8946 18.2929 22.7071L15.2929 19.7071C14.9024 19.3166 14.9024 18.6834 15.2929 18.2929C15.6834 17.9024 16.3166 17.9024 16.7071 18.2929L18 19.5858V16C18 15.4477 18.4477 15 19 15Z"
                fill="currentColor"
              />
            </svg>
            {isDownloading
              ? t("ibCommissionReport.downloadButtonLoading")
              : t("ibCommissionReport.downloadButton")}
          </button>
        </div>
      </div>
      <div className="mt-4 overflow-x-auto">
        <Table hoverable>
          <Table.Head>
            <Table.HeadCell>{t("ibUsersReport.name")}</Table.HeadCell>
            <Table.HeadCell>{t("ibUsersReport.email")}</Table.HeadCell>
            <Table.HeadCell>{t("ibUsersReport.country")}</Table.HeadCell>
            <Table.HeadCell>
              {t("ibUsersReport.registrationDate")}
            </Table.HeadCell>
            <Table.HeadCell>{t("ibCommissionReport.userId")}</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {isLoading
              ? Array.from({ length: 10 }).map(
                  (
                    _,
                    index // Assuming you want to render 5 skeleton rows
                  ) => (
                    <Table.Row
                      key={index}
                      className="bg-white dark:border-gray-700 dark:bg-gray-700"
                    >
                      {Array.from({ length: 5 }).map(
                        (
                          _,
                          cellIndex // Assuming 4 cells per row
                        ) => (
                          <Table.Cell
                            key={cellIndex}
                            className="whitespace-nowrap font-medium text-gray-900 dark:text-white"
                          >
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-800 max-w-[150px] animate-pulse"></div>
                          </Table.Cell>
                        )
                      )}
                    </Table.Row>
                  )
                )
              : userData.map((user, index) => (
                  <Table.Row
                    key={index}
                    className="bg-white dark:border-gray-700 dark:bg-gray-700"
                  >
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {user.name}
                    </Table.Cell>
                    <Table.Cell>{user.email}</Table.Cell>
                    <Table.Cell className="flex">
                      <div className="mr-2">
                        <ReactCountryFlag
                          countryCode={countries.getAlpha2Code(
                            user.country,
                            "en"
                          )}
                          svg
                          style={{
                            width: "1em",
                            height: "1em",
                          }}
                          title={user.country}
                        />
                      </div>
                      {user.country}
                    </Table.Cell>
                    <Table.Cell>
                      {new Date(user.timestamp.value).toLocaleString("en-GB", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      })}
                    </Table.Cell>
                    <Table.Cell>{user.uid}</Table.Cell>
                  </Table.Row>
                ))}
          </Table.Body>
        </Table>
        <p className="text-sm mt-2">
          <span className="font-normal text-gray-500 dark:text-gray-400">
            {t("ibUsersReport.totalUsers")}{" "}
          </span>
          <span className="font-semibold text-gray-900 dark:text-white">
            {totalRecords}
          </span>
        </p>
        <div className="">
          <Pagination
            currentPage={currentPage}
            totalPages={safeTotalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default UsersReportIB;
