import React, { useState, useEffect, useRef, memo, useContext } from "react";
import { RadioGroup } from "@headlessui/react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AccountSelection({ onAccountChange }) {
  const [plans, setPlans] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const notifiedDefault = useRef(false);
  const navigate = useNavigate();
  const query = useQuery();
  const { user } = useContext(UserContext);
  const userBrokerUID = user?.userData?.brokerUID;
  const queryBrokerUID = query.get("brokerUID");
  const brokerUID = userBrokerUID || queryBrokerUID;

  useEffect(() => {
    const fetchPlans = async () => {
      const db = getFirestore();
      const docRef = doc(db, "whitelabel", brokerUID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const groups = docSnap.data().groups || [];
        const fetchedPlans = groups.map((group) => ({
          displayName: group.displayName || group.name,
          name: group.name,
          currency: group.currency,
          type: group.type,
        }));
        console.log("Fetched plans:", fetchedPlans);
        console.log("Default selected plan:", fetchedPlans[0]);
        setPlans(fetchedPlans);

        if (fetchedPlans.length === 1) {
          setSelected(fetchedPlans[0]); // Set the plan as default only if it's the sole plan available
        }

        if (
          fetchedPlans.length === 1 &&
          !notifiedDefault.current &&
          onAccountChange
        ) {
          onAccountChange({
            name: fetchedPlans[0].name,
            type: fetchedPlans[0].type,
          });
          notifiedDefault.current = true;
        }
      }
      setLoading(false);
    };

    fetchPlans();
  }, [brokerUID, navigate, notifiedDefault, onAccountChange]); // Removed onAccountChange from the dependency list

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <RadioGroup
      value={selected}
      onChange={(newSelectedPlan) => {
        console.log("Selected plan:", newSelectedPlan);
        setSelected(newSelectedPlan);
        if (onAccountChange) {
          onAccountChange({
            name: newSelectedPlan.name,
            type: newSelectedPlan.type, // Pass the "type" attribute as well
          });
        }
      }}
    >
      <RadioGroup.Label className="sr-only">Accounts</RadioGroup.Label>
      <div className="space-y-4">
        {plans.map((plan) => (
          <RadioGroup.Option
            key={plan.name + plan.type}
            value={plan}
            className={({ active }) =>
              classNames(
                active
                  ? "border-primary-600 ring-2 ring-primary-600"
                  : "border-gray-300 dark:border-white/10",
                "relative block cursor-pointer rounded-lg border bg-white dark:bg-white/5 px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between"
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span className="flex items-center">
                  <span className="flex flex-col text-sm">
                    <RadioGroup.Label
                      as="span"
                      className="font-medium text-gray-900 dark:text-white"
                    >
                      {plan.displayName}
                    </RadioGroup.Label>
                  </span>
                </span>
                <RadioGroup.Description
                  as="span"
                  className="mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right"
                >
                  <span className="font-medium text-gray-900 dark:text-white">
                    {plan.currency}
                  </span>
                  <span className="ml-1 text-gray-500 dark:text-gray-400 sm:ml-0">
                    Account
                  </span>
                </RadioGroup.Description>
                <span
                  className={classNames(
                    active ? "border" : "border-2",
                    checked ? "border-primary-600" : "border-transparent",
                    "pointer-events-none absolute -inset-px rounded-lg"
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}

export default memo(AccountSelection);
