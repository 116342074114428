import { useContext, useEffect, useState } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { UserContext } from "../../../../contexts/UserContext";
import ClientIBOverview from "./ClientIBOverview";
import ClientIBReports from "./ClientIBReports";
import ClientIBBalance from "./ClientIBBalance";
import { Tooltip } from "flowbite-react";
import getSymbolFromCurrency from "currency-symbol-map";
import { useTranslation } from "react-i18next";

export default function ClientIBDashboard() {
  const [currentView, setCurrentView] = useState("Overview");
  const { user } = useContext(UserContext);
  const [tierData, setTierData] = useState(null);
  const [copySuccess, setCopySuccess] = useState("");
  const [totalCommission, setTotalCommission] = useState(0);
  const [currency, setCurrency] = useState("USD"); // Assuming USD as default
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      let tier = "tier1"; // default tier

      // Check if user.userData.ibTier exists and is either "tier2" or "tier3"
      if (
        user.userData.ibTier &&
        ["tier2", "tier3"].includes(user.userData.ibTier)
      ) {
        tier = user.userData.ibTier;
      }

      const docRef = doc(
        db,
        "whitelabel",
        user.userData.brokerUID,
        "ibCommission",
        tier
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let data = docSnap.data();
        let url = new URL(data.url);
        let params = new URLSearchParams(url.search);
        params.append("ib", user.userData.uid);
        url.search = params.toString();
        data.url = url.toString();
        setTierData(data);
      } else {
        console.log("No such document!");
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    // New useEffect to fetch totalCommission and currency
    const fetchTotalCommission = async () => {
      if (user && user.userData.uid) {
        const db = getFirestore();
        const totalCommissionRef = doc(
          db,
          "ib",
          user.userData.uid,
          "commissionData",
          "totalCommission"
        );
        const totalCommissionSnap = await getDoc(totalCommissionRef);
        if (totalCommissionSnap.exists()) {
          const data = totalCommissionSnap.data();
          setTotalCommission(data.total);
          setCurrency(data.currency); // Set the currency from the document
        } else {
          console.log("No total commission data found.");
        }
      }
    };
    fetchTotalCommission();
  }, [user]); // Dependency on user to re-fetch if user data changes

  const tabs = [
    {
      name: t("ibDashboard.overview"),
      current: currentView === "Overview",
      onClick: () => setCurrentView("Overview"),
      icon: (
        <svg
          aria-hidden="true"
          className="w-4 h-4 mr-2"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: t("ibDashboard.balance"),
      current: currentView === "Balance",
      onClick: () => setCurrentView("Balance"),
      icon: (
        <svg
          aria-hidden="true"
          className="w-4 h-4 mr-2"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
            clipRule="evenodd"
          ></path>
        </svg>
      ),
    },
    {
      name: t("ibDashboard.reports"),
      current: currentView === "Reports",
      onClick: () => setCurrentView("Reports"),
      icon: (
        <svg
          aria-hidden="true"
          className="w-4 h-4 mr-2"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
          />
        </svg>
      ),
    },
  ];

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(tierData?.url || "");
      setCopySuccess(t("ibDashboard.copySuccess"));
      setTimeout(() => setCopySuccess(""), 3000); // Reset the message after 3 seconds
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <div>
      <div>
        <section>
          <div className="w-full mx-auto">
            {/* Start coding here */}
            <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800">
              <div className="md:ml-20 ml-6 mr-6 divide-y dark:divide-gray-700">
                <div className="flex flex-col py-3 space-y-3 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-4">
                  <div>
                    <div className="flex items-center flex-1 space-x-2">
                      <h5>
                        <span className="font-semibold dark:text-white text-xl">
                          {t("ibDashboard.title")}
                        </span>
                      </h5>
                      <Tooltip content={t("ibDashboard.linkTooltip")}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span className="sr-only">More info</span>
                      </Tooltip>
                      <label className="block text-sm font-medium text-gray-500 dark:text-gray-400">
                        {t("ibDashboard.titleDescription")}
                      </label>
                      <div>
                        <div className="w-full">
                          <div className="relative">
                            <label className="sr-only">
                              (tierData?.url || "")
                            </label>
                            <input
                              type="text"
                              className="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full px-2.5 py-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                              defaultValue={tierData?.url || ""}
                              disabled=""
                              readOnly=""
                            />
                            <button
                              onClick={copyToClipboard}
                              className="absolute end-2.5 top-1/2 -translate-y-1/2 text-gray-900 dark:text-gray-400 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-600 dark:hover:bg-gray-700 rounded-lg py-2 px-2.5 inline-flex items-center justify-center bg-white border-gray-200 border"
                            >
                              {copySuccess ? (
                                <span
                                  id="success-message"
                                  className="inline-flex items-center"
                                >
                                  <svg
                                    className="w-3 h-3 text-primary-700 dark:text-primary-500 me-1.5"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 16 12"
                                  >
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M1 5.917 5.724 10.5 15 1.5"
                                    />
                                  </svg>
                                  <span className="text-xs font-semibold text-primary-700 dark:text-primary-500">
                                    {t("ibDashboard.copySuccessAlt")}
                                  </span>
                                </span>
                              ) : (
                                <span
                                  id="default-message"
                                  className="inline-flex items-center"
                                >
                                  <svg
                                    className="w-3 h-3 me-1.5"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 18 20"
                                  >
                                    <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                                  </svg>
                                  <span className="text-xs font-semibold">
                                    {t("ibDashboard.copyButton")}
                                  </span>
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center space-x-6">
                    <div>
                      <h5 className="mb-1 text-gray-500 dark:text-gray-400">
                        {t("ibDashboard.pendingBalanceText")}
                      </h5>
                      <span className="bg-gray-100 text-gray-800 text-md font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                        {getSymbolFromCurrency(currency)}
                        {totalCommission}
                      </span>
                      <span className="inline-flex items-center justify-center w-6 h-6 me-2 text-sm font-semibold text-primary-800 bg-primary-100 rounded-full dark:bg-gray-700 dark:text-primary-400">
                        <Tooltip content={t("ibDashboard.balanceTooltip")}>
                          <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                          </svg>
                          <span className="sr-only">Icon description</span>
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col py-4 space-y-3 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-4 md:py-0">
                  <ul className="flex-wrap flex text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                    <li className="mr-2">
                      {tabs.map((tab) => (
                        <button
                          key={tab.name}
                          onClick={tab.onClick}
                          className={`inline-flex items-center py-4 pr-4 border-b border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${
                            tab.current
                              ? "text-primary-700 border-gray-700 active dark:text-white dark:border-white"
                              : "text-gray-500 dark:text-gray-400"
                          }`}
                        >
                          {tab.icon}
                          {tab.name}
                        </button>
                      ))}
                    </li>
                  </ul>

                  <div className="flex flex-shrink-0 items-center">
                    <Tooltip content="Use this link for new users.">
                      <button
                        onClick={copyToClipboard}
                        className="mr-3 inline-flex items-center justify-center rounded-lg border border-gray-200 bg-white px-3 py-2 text-xs font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
                      >
                        <svg
                          className="me-2 h-4 w-4"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14 3C14 2.44772 14.4477 2 15 2H21C21.5523 2 22 2.44771 22 3L22 9C22 9.55228 21.5523 10 21 10C20.4477 10 20 9.55229 20 9L20 5.41422L13.7071 11.7071C13.3166 12.0976 12.6834 12.0976 12.2929 11.7071C11.9024 11.3166 11.9024 10.6834 12.2929 10.2929L18.5858 4H15C14.4477 4 14 3.55228 14 3Z"
                            fill="currentColor"
                          />
                          <path
                            d="M11.9544 7.80292C12.3505 7.4069 12.5485 7.20889 12.6227 6.98056C12.6879 6.77972 12.6879 6.56337 12.6227 6.36253C12.5485 6.1342 12.3505 5.93619 11.9544 5.54017L11.1757 4.76147L11.1397 4.72532C11.0058 4.59067 10.8371 4.42112 10.6308 4.29467C10.4518 4.18501 10.2568 4.1042 10.0527 4.05521C9.81735 3.99871 9.57821 3.99935 9.3883 3.99985L9.33726 3.99995L7.7587 3.99995C6.95373 3.99993 6.28937 3.99992 5.74818 4.04414C5.18608 4.09007 4.66938 4.18863 4.18404 4.43592C3.43139 4.81941 2.81947 5.43134 2.43598 6.18398C2.18868 6.66932 2.09012 7.18602 2.0442 7.74812C1.99998 8.28931 1.99999 8.95366 2 9.75862V16.2412C1.99999 17.0462 1.99998 17.7106 2.0442 18.2518C2.09012 18.8139 2.18868 19.3306 2.43598 19.8159C2.81947 20.5686 3.43139 21.1805 4.18404 21.564C4.66938 21.8113 5.18608 21.9098 5.74818 21.9558C6.28937 22 6.95372 22 7.75868 21.9999H14.2413C15.0463 22 15.7106 22 16.2518 21.9558C16.8139 21.9098 17.3306 21.8113 17.816 21.564C18.5686 21.1805 19.1805 20.5686 19.564 19.8159C19.8113 19.3306 19.9099 18.8139 19.9558 18.2518C20 17.7106 20 17.0462 20 16.2413V14.6627L20.0001 14.6117C20.0006 14.4217 20.0012 14.1826 19.9447 13.9473C19.8957 13.7432 19.8149 13.5481 19.7053 13.3692C19.5788 13.1628 19.4093 12.9942 19.2746 12.8602L19.2385 12.8242L18.4598 12.0456C18.0638 11.6495 17.8658 11.4515 17.6375 11.3773C17.4366 11.3121 17.2203 11.3121 17.0194 11.3773C16.7911 11.4515 16.5931 11.6495 16.1971 12.0456L15.1213 13.1213C13.9497 14.2929 12.0503 14.2929 10.8787 13.1213C9.70711 11.9497 9.70711 10.0503 10.8787 8.87868L11.9544 7.80292Z"
                            fill="currentColor"
                          />
                        </svg>
                        {copySuccess || t("ibDashboard.referralLink")}
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="md:ml-20 ml-4 mr-4 mx-auto">
          {currentView === "Overview" && (
            <ClientIBOverview tierData={tierData} onNavigate={setCurrentView} />
          )}
          {currentView === "Reports" && <ClientIBReports />}
          {currentView === "Balance" && (
            <ClientIBBalance
              totalCommission={totalCommission}
              currency={currency}
            />
          )}
        </div>
      </div>
    </div>
  );
}
