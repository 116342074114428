import React, { useState, useEffect } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom"; // import useNavigate
import logo from "../../logo.svg";
import logoDark from "../../logoDark.svg";
import useDarkMode from "../../utils/useDarkMode";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { useNotification } from "../../contexts/NotificationContext";
import { handleFirebaseError } from "../../utils/handleFirebaseError";
import {
  getFirestore,
  getDocs,
  collection,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import TagManager from "react-gtm-module";
import PixelScript from "../Shared/PixelScript";
import GoogleSignIn from "../Shared/GoogleSignIn";

function validateStrongPassword(password) {
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password);

  return (
    password.length >= 8 &&
    hasUpperCase &&
    hasLowerCase &&
    hasNumber &&
    hasSpecialChar
  );
}

function validateEmail(email) {
  // Regular expression for email validation
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return pattern.test(email);
}

export default function CampaignRegistration() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const { showNotification } = useNotification(); // from NotificationContext
  const [logoUrls, setLogoUrls] = useState(null);
  const [logoLoading, setLogoLoading] = useState(true);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const getQuery = useQuery();
  const brokerUID = getQuery.get("brokerUID");
  const campaignId = getQuery.get("campaignId");
  localStorage.setItem("campaignId", campaignId);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const darkModeEnabled = useDarkMode();
  const [pixelId, setPixelId] = useState(null);
  const [googleTagManagerId, setGoogleTagManagerId] = useState(null);
  const db = getFirestore();
  const [imageUrl, setImageUrl] = useState(null);
  const subId = getQuery.get("SUBID");
  localStorage.setItem("subId", subId);

  useEffect(() => {
    const fetchPixelId = async () => {
      if (brokerUID) {
        const docRef = doc(db, "campaigns", brokerUID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();

          if (data.metaPixelEnabled) {
            const pixelId = data.metaPixelId;
            console.log("Pixel ID fetched: ", pixelId);
            setPixelId(pixelId);
            localStorage.setItem("pixelId", pixelId); // Store in local storage
          } else {
            console.log("Meta Pixel is not enabled!");
          }

          if (data.googleTagManagerEnabled) {
            const googleTagManagerId = data.googleTagManagerId.trim();
            console.log("Google Tag Manager ID fetched: ", googleTagManagerId);
            setGoogleTagManagerId(googleTagManagerId);
            localStorage.setItem("googleTagManagerId", googleTagManagerId); // Store in local storage

            // Initialize GTM
            TagManager.initialize({ gtmId: googleTagManagerId });

            // Log that GTM has been initialized
            console.log(
              `Google Tag Manager initialized with ID: ${googleTagManagerId}`
            );
          } else {
            console.log("Google Tag Manager is not enabled!");
          }

          if (data.propellerAdsEnabled) {
            const propellerAdsUrl = data.propellerAdsUrl;
            console.log("PropellerAds URL fetched: ", propellerAdsUrl);
            localStorage.setItem("propellerAdsUrl", propellerAdsUrl); // Store in local storage
          } else {
            console.log("PropellerAds tracking is not enabled!");
          }
        } else {
          console.log("No such document!");
        }
      }
    };

    fetchPixelId();
  }, [brokerUID, db, googleTagManagerId]);

  useEffect(() => {
    const fetchCampaignData = async () => {
      if (brokerUID && campaignId) {
        const docRef = doc(db, "campaigns", brokerUID, "campaigns", campaignId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();

          // Extract imageUrl from the document
          const imageUrl = data.coverPhoto; // Assuming the field is named 'coverPhoto'
          setImageUrl(imageUrl);
        } else {
          console.log("No such document!");
        }
      }
    };

    fetchCampaignData();
  }, [brokerUID, campaignId, db]);

  useEffect(() => {
    if (!brokerUID && !window.location.hostname.startsWith("platform")) {
      navigate("/incorrectlink");
      return; // Make sure to return so that the rest of the code in the effect doesn't run.
    }
  }, [brokerUID, navigate]);

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Form Submission Attempt
    if (googleTagManagerId) {
      window.dataLayer.push({
        event: "registration_attempt",
        email: email,
      });
    }

    // Validate email
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      setIsLoading(false);

      // Form Submission Error
      if (googleTagManagerId) {
        window.dataLayer.push({
          event: "form_submission_error",
          email: email,
          error: "Invalid email",
        });
      }

      return;
    }

    // Validate strong password
    if (!validateStrongPassword(password)) {
      setPasswordError(
        "Password must be at least 8 characters long and include an uppercase letter, lowercase letter, number, and special character."
      );
      setIsLoading(false);

      // Form Submission Error
      if (googleTagManagerId) {
        window.dataLayer.push({
          event: "form_submission_error",
          email: email,
          error: "Weak password",
        });
      }

      return;
    }

    // Check if password and confirmPassword match
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      setIsLoading(false);

      if (googleTagManagerId) {
        window.dataLayer.push({
          event: "form_submission_error",
          email: email,
          error: "Passwords do not match",
        });
      }

      return;
    }

    // Reset the confirm password error if passwords match
    setConfirmPasswordError("");

    // Continue with the rest of the form processing...
    const auth = getAuth();

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      console.log(user); // Log the user object to the console.

      // Form Submission Success
      if (googleTagManagerId) {
        window.dataLayer.push({
          event: "form_submission_success",
          email: email,
          brokerUID: brokerUID,
          campaignId: campaignId,
        });
      }

      // Conditionally include brokerUID in the redirect URL
      const redirectUrl =
        brokerUID && brokerUID !== "null" && brokerUID !== ""
          ? `/onboarding?brokerUID=${brokerUID}&campaignId=${campaignId}`
          : `/onboarding?campaignId=${campaignId}`;

      // Redirect to Onboarding
      if (googleTagManagerId) {
        window.dataLayer.push({
          event: "redirect_to_onboarding",
          email: email,
        });
      }

      navigate(redirectUrl);
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = handleFirebaseError(errorCode); // translate error
      showNotification("error", errorMessage);
      console.log(`Error code: ${errorCode}, Error message: ${errorMessage}`);
    } finally {
      setIsLoading(false); // set loading to false after registration is finished
    }
  };

  const handleAuthentication = () => {
    // Conditionally include brokerUID in the redirect URL
    const redirectUrl =
      brokerUID && brokerUID !== "null" && brokerUID !== ""
        ? `/onboarding?brokerUID=${brokerUID}&campaignId=${campaignId}`
        : `/onboarding?campaignId=${campaignId}`;

    navigate(redirectUrl);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    // If the hostname doesn't start with "platform", exit early
    if (!window.location.hostname.startsWith("platform.")) {
      setLogoLoading(false); // Ensure loading is set to false
      return;
    }

    const fetchLogoUrls = async () => {
      try {
        setLogoLoading(true);
        const db = getFirestore();
        const domain = window.location.hostname.split(".").slice(-2).join("."); // Get the domain

        // Query the "whitelabel" collection where the domain field matches the domain
        const whitelabelQuery = query(
          collection(db, "whitelabel"),
          where("domain", "==", domain)
        );
        const whitelabelSnapshot = await getDocs(whitelabelQuery);

        // Check if any documents match the query
        if (!whitelabelSnapshot.empty) {
          // Get the first document from the query results
          const whitelabelDoc = whitelabelSnapshot.docs[0];

          // Update the logo URLs
          setLogoUrls({
            dark: whitelabelDoc.data().logoUrlDark,
            light: whitelabelDoc.data().logoUrlLight,
          });
        }
      } catch (error) {
        console.error("An error occurred while fetching logo URLs:", error);
      } finally {
        setLogoLoading(false); // Ensure this gets called even if an error occurs
      }
    };

    // Fetch the logo URLs
    fetchLogoUrls();
  }, []);

  return (
    <>
      <PixelScript pixelId={pixelId} />
      <div className="flex min-h-screen flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              {logoLoading ? (
                <div className="animate-pulse rounded-full bg-gray-200 dark:bg-white/10 h-10 w-10"></div>
              ) : (
                <img
                  className="h-10 w-auto"
                  src={
                    logoUrls
                      ? darkModeEnabled
                        ? logoUrls.dark
                        : logoUrls.light
                      : darkModeEnabled
                      ? logoDark
                      : logo
                  }
                  alt="Logo"
                />
              )}
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
                Create a new account
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500 dark:text-gray-400">
                Sign up for free and instantly access our trading platform.
                Simple, quick, no cost.
              </p>
            </div>

            <div className="mt-10">
              <div>
                <form className="space-y-6" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 text-left"
                    >
                      Email address
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm ${
                          emailError && emailError.length > 0
                            ? "text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500"
                            : ""
                        } sm:text-sm sm:leading-6`}
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="Enter your email address"
                      />
                      {emailError && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {emailError && (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {emailError}
                      </p>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 text-left"
                    >
                      Password
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm ${
                          passwordError && passwordError.length > 0
                            ? "text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500"
                            : ""
                        } sm:text-sm sm:leading-6`}
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Create a password"
                      />
                      {passwordError && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {passwordError && (
                      <p
                        className="mt-2 text-sm text-red-600"
                        id="password-error"
                      >
                        {passwordError}
                      </p>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="confirmPassword"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 text-left"
                    >
                      Confirm password
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <input
                        id="confirmPassword"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm ${
                          confirmPasswordError &&
                          confirmPasswordError.length > 0
                            ? "text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500"
                            : ""
                        } sm:text-sm sm:leading-6`}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        placeholder="Confirm password"
                      />
                      {confirmPasswordError && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {confirmPasswordError && (
                      <p
                        className="mt-2 text-sm text-red-600"
                        id="confirm-password-error"
                      >
                        {confirmPasswordError}
                      </p>
                    )}
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center items-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                      disabled={isLoading} // disable the button while loading
                    >
                      {isLoading ? (
                        <svg
                          className="animate-spin h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        "Create account"
                      )}
                    </button>
                  </div>
                </form>
                <div className="relative mt-10">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-200 dark:border-white/10" />
                  </div>
                  <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-white dark:bg-gray-900 px-6 text-gray-900 dark:text-white">
                      Or continue with
                    </span>
                  </div>
                </div>
                <GoogleSignIn
                  parentIsLoading={isLoading}
                  onAuthentication={handleAuthentication}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          {imageUrl ? (
            <img
              className="absolute inset-0 h-full w-full object-cover object-center"
              src={imageUrl}
              alt=""
            />
          ) : (
            <div className="absolute inset-0 bg-gray-200 dark:bg-gray-900 animate-pulse"></div>
          )}
        </div>
      </div>
    </>
  );
}
