import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { useNotification } from "../../contexts/NotificationContext.js";
import { functions } from "../../firebase.js";
import { httpsCallable } from "firebase/functions";
import getSymbolFromCurrency from "currency-symbol-map";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

export default function CashOperationModal({
  open,
  setOpen,
  accounts,
  uidFromUrl,
  brokerUID,
}) {
  const cancelButtonRef = useRef(null);
  const { showNotification } = useNotification(); // Use the notification hook
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Set initial selected account ID if there's at least one trading account
  useEffect(() => {
    if (accounts && accounts.length > 0) {
      setSelectedAccountId(accounts[0].id_account);
    }
  }, [accounts]);

  // Find the selected account object
  const selectedAccount = accounts?.find(
    (account) => account.id_account === selectedAccountId
  );

  // Extract the currency from the selected account
  const selectedCurrency = selectedAccount?.currency || "USD";

  // Get the currency symbol
  const currencySymbol = getSymbolFromCurrency(selectedCurrency);

  // State for the amount and amount validation error
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState("");

  // Handle amount change and perform validation
  const handleAmountChange = (e) => {
    const value = e.target.value;

    // Check for the presence of a comma
    if (value.includes(",")) {
      setAmountError("Please use a dot (.) as a decimal separator");
    }
    // Check if the input is a valid number format
    else if (/^\d*\.?\d*$/.test(value) || value === "") {
      // Update the amount state
      setAmount(value);

      // Validate the amount
      if (value !== "" && (isNaN(value) || parseFloat(value) <= 0)) {
        setAmountError("Please enter a valid amount");
      } else {
        // Clear the error if the amount is valid
        setAmountError("");
      }
    }
  };

  // Helper function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Handle the cash operation
  const performCashOperation = async () => {
    setIsLoading(true);
    try {
      // Extract necessary data
      const userLogin = selectedAccountId;
      const uid = uidFromUrl;
      // Directly use brokerUID, no need to re-declare it
      const currency = selectedCurrency;
      const ledgerType = selectedLedgerType;
      const comment = ledgerType;

      // Prepare the data to be sent to the cloud function
      const data = {
        userLogin,
        uid,
        brokerUID, // Directly use the prop
        currency,
        ledgerType,
        comment,
        amount,
      };

      // Call the cloud function
      const cashOperationXManager = httpsCallable(
        functions,
        "cashOperationXManager"
      );
      const result = await cashOperationXManager(data);

      // Handle the result
      console.log("Cloud function result:", result);
      console.log("Cloud function result properties:", Object.keys(result));

      if (result.data && result.data.success) {
        const capitalizedMessage = capitalizeFirstLetter(result.data.message);
        showNotification("Success", capitalizedMessage); // Pass type and message as separate arguments
      } else {
        // If the success property is false or not present, show a generic success message
        showNotification("Success", "Cash operation performed successfully!"); // Pass type and message as separate arguments
      }

      // Close the modal
      setOpen(false);
    } catch (error) {
      console.error("Error performing cash operation:", error);
      let errorMessage = "An unexpected error occurred. Please try again.";
      if (error.code) {
        errorMessage = error.message; // Display the error message from the cloud function if available
      }
      const capitalizedErrorMessage = capitalizeFirstLetter(errorMessage);
      showNotification("Error", capitalizedErrorMessage); // Pass type and message as separate arguments
    } finally {
      setIsLoading(false);
    }
  };

  // Mapping from user-friendly names to required format
  const ledgerTypeMapping = {
    Deposit: "deposit",
    Withdrawal: "withdrawal",
    "Credit in": "credit in",
    "Credit out": "credit out",
  };

  // State to hold the selected ledger type
  const [selectedLedgerType, setSelectedLedgerType] = useState("deposit");

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 dark:bg-gray-900 dark:bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white dark:bg-gray-800 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 dark:bg-primary-500/10 sm:mx-0 sm:h-10 sm:w-10">
                      <ArrowPathIcon
                        className="h-6 w-6 text-primary-600 dark:text-primary-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
                      >
                        Cash operation
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Select a trading account below and perform a cash
                          operation.
                        </p>
                      </div>
                      <div>
                        <label
                          htmlFor="account"
                          className="block text-sm font-medium mt-4 leading-6 text-gray-900 dark:text-white"
                        >
                          Trading account
                        </label>
                        <select
                          id="account"
                          name="account"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus:ring-2 focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-white/5"
                          onChange={(e) => {
                            console.log("Selected Account ID:", e.target.value);
                            setSelectedAccountId(e.target.value);
                          }}
                        >
                          {Array.isArray(accounts) &&
                            accounts.map((account, index) => (
                              <option key={index} value={account.id_account}>
                                {account.id_account} - {account.currency}
                                {/* Adjust this according to the structure of the account object */}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div>
                        <label
                          htmlFor="cash-operation"
                          className="block text-sm font-medium mt-4 leading-6 text-gray-900 dark:text-white"
                        >
                          Cash operation
                        </label>
                        <select
                          id="cash-operation"
                          name="cash-operation"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus:ring-2 focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-white/5"
                          defaultValue="Deposit"
                          onChange={(e) =>
                            setSelectedLedgerType(
                              ledgerTypeMapping[e.target.value]
                            )
                          }
                        >
                          <option>Deposit</option>
                          <option>Withdrawal</option>
                          <option>Credit in</option>
                          <option>Credit out</option>
                        </select>
                      </div>
                      <div>
                        <label
                          htmlFor="amount"
                          className="block text-sm font-medium mt-4 leading-6 text-gray-900 dark:text-white"
                        >
                          Amount
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">
                              {currencySymbol}
                            </span>
                          </div>
                          <input
                            type="text"
                            name="amount"
                            id="amount"
                            value={amount}
                            onChange={handleAmountChange}
                            className={`block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-white/5 ${
                              amountError && amountError.length > 0
                                ? "text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500"
                                : ""
                            }`}
                            placeholder="0.00"
                            aria-describedby="price-currency"
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            {!amountError && (
                              <span
                                className="text-gray-500 sm:text-sm"
                                id="price-currency"
                              >
                                {selectedCurrency}
                              </span>
                            )}
                            {amountError && (
                              <ExclamationCircleIcon
                                className="h-5 w-5 text-red-500"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        </div>
                        {amountError && (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="amount-error"
                          >
                            {amountError}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 dark:bg-white/5 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-3 sm:w-auto"
                    disabled={isLoading} // Disable the button while loading
                    onClick={performCashOperation} // Call the performCashOperation function when clicked
                  >
                    {isLoading ? (
                      <div className="flex items-center">
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Performing operation...
                      </div>
                    ) : (
                      "Perform operation"
                    )}
                  </button>
                  <button
                    type="button"
                    className="rounded-md bg-white dark:bg-white/10 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:bg-gray-50 dark:hover:bg-white/20"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
