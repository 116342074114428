import { useState } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { app } from "../../../../firebase";

export default function StartMigration({
  onStartMigration,
  onFileUploaded,
  isLoading,
}) {
  const [uploading, setUploading] = useState(false);
  const storage = getStorage(app);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const storageRef = ref(
      storage,
      `csvprocess/${Math.random().toString(36).substring(2, 15)}`
    );

    try {
      setUploading(true);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Wait for the upload to complete
      await new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          null,
          (error) => reject(error),
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          }
        );
      });

      const fileUrl = await getDownloadURL(storageRef);
      onFileUploaded(fileUrl);
      setUploading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploading(false);
    }
  };

  const loading = uploading || isLoading; // Combined loading state

  return (
    <div className="text-center">
      <svg
        className="mt-20 mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-gray-900 dark:text-white">
        xManager migration tool
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        Import users and trading accounts from xManager. Only live accounts will
        be imported, your configured live credentials will be used and all
        accounts will be imported. Export the "Account list" from the live
        environment.
      </p>
      <div className="mt-6">
        <input
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          className="hidden"
          id="file-upload"
          disabled={uploading || isLoading}
        />
        <label
          htmlFor="file-upload"
          className="cursor-pointer inline-flex items-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500"
          disabled={uploading || isLoading}
        >
          {loading ? (
            <div className="flex items-center">
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Importing... This might take a while
            </div>
          ) : (
            <>
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              Upload CSV file
            </>
          )}
        </label>
      </div>
    </div>
  );
}
