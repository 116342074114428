import UsersChart from "./Charts/UsersChart";
import TotalCommissionChart from "./Charts/TotalCommissionChart";
import VolumeDonut from "./Charts/VolumeDonut";
import TimelineCard from "./Charts/TimelineCard";

export default function ClientIBOverview({ tierData, onNavigate }) {
  return (
    <div className="mx-auto bg-white pt-4 dark:bg-gray-900">
      <div className="mb-2 grid md:grid-cols-4 grid-cols-1 gap-2">
        <TimelineCard tierData={tierData} />
        <UsersChart onNavigate={onNavigate} />
        <TotalCommissionChart onNavigate={onNavigate} />
        <VolumeDonut onNavigate={onNavigate} />
      </div>
    </div>
  );
}
