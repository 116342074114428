import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useBrokerCommandPalette } from "../contexts/BrokerCommandPaletteProvider";
import BrokerCommandPalette from "../components/Modals/BrokerCommandPalette";

function BrokerCommandPaletteWrapper() {
  const location = useLocation();
  const isBrokerRoute = location.pathname.startsWith("/broker");

  // Use the custom hook to get the state and toggle function from context
  const { isPaletteVisible, togglePaletteVisibility } =
    useBrokerCommandPalette();

  useEffect(() => {
    function handleKeyDown(event) {
      if ((event.ctrlKey || event.metaKey) && event.key === "k") {
        event.preventDefault();
        console.log("Shortcut triggered");
        togglePaletteVisibility(); // Use the toggle function from context
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [togglePaletteVisibility]); // Add togglePaletteVisibility as a dependency

  return isBrokerRoute && isPaletteVisible ? (
    <BrokerCommandPalette
      isPaletteVisible={isPaletteVisible}
      setIsPaletteVisible={togglePaletteVisibility} // Pass the toggle function as prop
    />
  ) : null;
}

export default BrokerCommandPaletteWrapper;
