import { Fragment, useRef, useContext, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { UserContext } from "../../contexts/UserContext.js";
import { useNotification } from "../../contexts/NotificationContext.js";
import { functions } from "../../firebase.js";
import { httpsCallable } from "firebase/functions";
import { useTranslation } from "react-i18next";

export default function ChangeXManagerPassword({ open, setOpen }) {
  const cancelButtonRef = useRef(null);
  const { showNotification } = useNotification(); // Use the notification hook
  const { user } = useContext(UserContext);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  // Set initial selected account ID if there's at least one trading account
  useEffect(() => {
    if (
      user &&
      user.accountData &&
      user.accountData.trading_accounts.length > 0
    ) {
      setSelectedAccountId(user.accountData.trading_accounts[0].id_account);
    }
  }, [user]);

  // State to manage the password input
  const [password, setPassword] = useState("");

  const handleChangePassword = async () => {
    setIsLoading(true);
    const requestData = {
      userLogin: selectedAccountId,
      brokerUID: user.userData.brokerUID,
      password: password,
    };

    console.log("Calling Firebase function with data:", requestData);

    try {
      const changePasswordFunction = httpsCallable(functions, "changePassword");
      const response = await changePasswordFunction(requestData);

      if (response.data.result === "Password change successful") {
        showNotification(
          "success",
          "Your password has been successfully updated!"
        ); // Display success notification
        setOpen(false); // Close the modal
      } else {
        showNotification("error", "Failed to change password"); // Display error notification
      }
    } catch (error) {
      console.error(error);
      showNotification(
        "error",
        "An error occurred while changing the password"
      ); // Display error notification for exceptions
    }
    setIsLoading(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 dark:bg-gray-800 dark:bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white dark:bg-gray-800 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 dark:bg-primary-500/10 sm:mx-0 sm:h-10 sm:w-10">
                      <ArrowPathIcon
                        className="h-6 w-6 text-primary-600 dark:text-primary-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-semibold text-gray-900 dark:text-white"
                      >
                        {t("changePasswordModal.title")}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                          {t("changePasswordModal.description")}
                        </p>
                      </div>
                      <div>
                        <label
                          htmlFor="account"
                          className="mt-4 block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          {t("changePasswordModal.selectAccount")}
                        </label>
                        <select
                          id="account"
                          name="account"
                          className="mt-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          onChange={(e) => {
                            console.log("Selected Account ID:", e.target.value);
                            setSelectedAccountId(e.target.value);
                          }}
                        >
                          {Array.isArray(user?.accountData?.trading_accounts) &&
                            user.accountData.trading_accounts.map(
                              (account, index) => (
                                <option key={index} value={account.id_account}>
                                  {account.login}{" "}
                                  {/* Adjust this according to the structure of the account object */}
                                </option>
                              )
                            )}
                        </select>
                      </div>
                      <div>
                        <label
                          htmlFor="password"
                          className="mt-4 block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          {t("changePasswordModal.newPassword")}
                        </label>
                        <div className="mt-2">
                          <input
                            type="password"
                            name="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="•••••••••"
                            aria-describedby="password-description"
                          />
                        </div>
                        <p
                          className="mt-2 text-sm text-gray-500 dark:text-gray-400"
                          id="password-description"
                        >
                          {t("changePasswordModal.minChars")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 dark:bg-white/5 px-4 py-3 sm:grid-cols-2 sm:gap-3 sm:flex-row-reverse sm:grid sm:px-6">
                  <button
                    type="button"
                    className="w-full py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    onClick={() => setOpen(false)}
                  >
                    {t("changePasswordModal.cancel")}
                  </button>
                  <button
                    type="button"
                    className="w-full flex justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                    onClick={handleChangePassword} // Call the function on click
                    disabled={isLoading} // Disable the button while loading
                  >
                    {isLoading ? (
                      <div className="flex items-center">
                        <svg
                          aria-hidden="true"
                          className="w-4 h-4 -ml-1 mr-3 text-gray-200 animate-spin dark:text-gray-500 fill-primary-600 dark:fill-white"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        {t("changePasswordModal.changePasswordLoading")}
                      </div>
                    ) : (
                      t("changePasswordModal.changePassword")
                    )}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
