import React, { createContext, useState, useContext } from "react";

// Create a context with default values
export const BrokerCommandPaletteContext = createContext({
  isPaletteVisible: false,
  togglePaletteVisibility: () => {},
});

export function BrokerCommandPaletteProvider({ children }) {
  const [isPaletteVisible, setIsPaletteVisible] = useState(false);

  const togglePaletteVisibility = () => {
    setIsPaletteVisible((prev) => !prev);
  };

  return (
    <BrokerCommandPaletteContext.Provider
      value={{ isPaletteVisible, togglePaletteVisibility }}
    >
      {children}
    </BrokerCommandPaletteContext.Provider>
  );
}

// Custom hook for convenience
export function useBrokerCommandPalette() {
  return useContext(BrokerCommandPaletteContext);
}
