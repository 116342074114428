import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignIn from "./components/SignIn/SignIn";
import SignInBroker from "./components/SignIn/SignInBroker";
import Dashboard from "./components/Dashboard/Dashboard";
import Registration from "./components/Registration/Registration";
import RegistrationBroker from "./components/Registration/RegistrationBroker";
import Onboarding from "./components/Registration/Onboarding";
import OnboardingBroker from "./components/Registration/OnboardingBroker";
import NotificationAlert from "./components/Alerts/NotificationAlert";
import MissingParameterError from "./components/ErrorPages/MissingParameterError";
import { UserProvider } from "./contexts/UserContext";
import { BrokerProvider } from "./contexts/BrokerContext";
import { NotificationProvider } from "./contexts/NotificationContext";
import { ProtectedRoute } from "./components/Routes/ProtectedRoute";
import BrokerCommandPaletteWrapper from "./utils/BrokerCommandPaletteWrapper";
import { BrokerCommandPaletteProvider } from "./contexts/BrokerCommandPaletteProvider";
import ForgotPasswordPage from "./components/SignIn/PasswordReset/ForgotPasswordPage";
import ResetPasswordPage from "./components/SignIn/PasswordReset/ResetPasswordPage";
import CampaignRegistration from "./components/Registration/CampaignRegistration";
import ApplicationShellBroker from "./components/Dashboard/BrokerDashboard/ApplicationShellBroker";
import LanguageProvider from "./contexts/LanguageContext";
import StandalonePraxisIframe from "./components/Modals/PSPModals/StandalonePraxisIframe";

function App() {
  return (
    <NotificationProvider>
      <NotificationAlert />
      <Router>
        <div className="App">
          <LanguageProvider>
            <UserProvider>
              <BrokerProvider>
                <BrokerCommandPaletteProvider>
                  <BrokerCommandPaletteWrapper />
                  <Routes>
                    <Route path="/" element={<SignIn />} />

                    <Route
                      path="/praxis-iframe"
                      element={<StandalonePraxisIframe />}
                    />

                    <Route
                      path="/incorrectlink"
                      element={<MissingParameterError />}
                    />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/sign-in" element={<SignIn />} />
                    <Route path="/registration" element={<Registration />} />
                    <Route
                      path="/campaign-registration"
                      element={<CampaignRegistration />}
                    />
                    <Route
                      path="/forgotpassword"
                      element={<ForgotPasswordPage />}
                    />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPasswordPage />}
                    />
                    <Route
                      path="/resetpassword"
                      element={<ResetPasswordPage />}
                    />

                    {/* User specific routes */}

                    <Route
                      path="/onboarding"
                      element={
                        <ProtectedRoute userType="user">
                          <Onboarding />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/dashboard/*"
                      element={
                        <ProtectedRoute userType="user">
                          <Dashboard />
                        </ProtectedRoute>
                      }
                    />

                    {/* Broker specific routes */}
                    <Route path="/broker/signin" element={<SignInBroker />} />
                    <Route
                      path="/broker/registration"
                      element={<RegistrationBroker />}
                    />
                    <Route
                      path="/broker/onboarding"
                      element={<OnboardingBroker />}
                    />
                    <Route
                      path="/broker/dashboard/*"
                      element={
                        <ProtectedRoute userType="broker">
                          <ApplicationShellBroker />
                        </ProtectedRoute>
                      }
                    />
                    {/* You can add other routes here */}
                  </Routes>
                </BrokerCommandPaletteProvider>
              </BrokerProvider>
            </UserProvider>
          </LanguageProvider>
        </div>
      </Router>
    </NotificationProvider>
  );
}

export default App;
