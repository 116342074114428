import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Tabs } from "flowbite-react";
import UserProfileSettings from "../../Settings/UserComponents/UserProfileSettings";
import UserChangePassword from "../../Settings/UserComponents/UserChangePassword";
import UserManualKYC from "../../Settings/UserComponents/UserManualKYC";
import UserIcon from "../../Icons/UserIcon";
import PasswordIcon from "../../Icons/PasswordIcon";
import KYCIcon from "../../Icons/KYCIcon";
import CampaignsIcon from "../../Icons/CampaignsIcon";
import { useTranslation } from "react-i18next";
import { ExportK4 } from "./ExportK4";

export default function UserProfileModal({ isOpen, setIsOpen, user }) {
  const { t } = useTranslation();

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <Tabs
                  aria-label="User profile tabs"
                  style={{ width: "100%" }}
                  variant="underline"
                  className="text-gray-500 dark:text-gray-400"
                >
                  <Tabs.Item
                    active
                    title={t("profile.profile")}
                    icon={UserIcon}
                    className="text-cyan-600 dark:text-cyan-500 hover:text-cyan-600 dark:hover:text-cyan-500"
                  >
                    <UserProfileSettings />
                  </Tabs.Item>
                  <Tabs.Item
                    title={t("profile.changePassword")}
                    icon={PasswordIcon}
                    className="hover:text-gray-600 dark:hover:text-gray-300"
                  >
                    <UserChangePassword />
                  </Tabs.Item>
                  {user &&
                    user.userData &&
                    user.userData.kycStatus !== "approved_kyc" && (
                      <Tabs.Item
                        title={t("profile.accountVerification")}
                        icon={KYCIcon}
                      >
                        <UserManualKYC />
                      </Tabs.Item>
                    )}
                  {user &&
                    user.userData &&
                    user.userData.country === "SWEDEN" && (
                      <Tabs.Item title={t("exportK4.tab")} icon={CampaignsIcon}>
                        <ExportK4 />
                      </Tabs.Item>
                    )}
                </Tabs>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
