import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../contexts/UserContext.js";
import styles from "./TradingPlatform.module.css";
import { ArrowDownOnSquareIcon } from "@heroicons/react/20/solid";

export default function TradingPlatform() {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true); // Initialize loading state
  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
  ); // Initialize dark mode state

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  // Add a listener to update the dark mode state when the user's preferred color scheme changes
  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const changeHandler = () => setIsDarkMode(mediaQuery.matches);

    mediaQuery.addEventListener("change", changeHandler);
    return () => mediaQuery.removeEventListener("change", changeHandler);
  }, []);

  // Use the appropriate iframe URL based on the user's preferred theme
  const fallback_url = "https://xs5.xopenhub.pro/";
  const iframe_url =
    (isDarkMode
      ? user?.userData?.iframeUrlDark
      : user?.userData?.iframeUrlLight) || fallback_url;

  // Function to handle iframe onLoad event
  const handleIframeLoad = () => {
    setIsLoading(false); // Set loading state to false when iframe has loaded
  };

  useEffect(() => {
    if (iframe_url !== fallback_url) {
      setIsLoading(false); // Set loading state to false if iframe_url is not the fallback URL
    }
  }, [iframe_url]);

  // Function to detect the user's operating system
  const getOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

  const operatingSystem = getOperatingSystem();

  return (
    <div className={styles.iframeContainer}>
      {isLoading ? (
        // Loader
        <div className="fixed inset-0 flex items-center justify-center dark:bg-gray-900">
          <svg
            aria-hidden="true"
            className="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-primary-600 dark:fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </div>
      ) : operatingSystem === "iOS" ? (
        <div className="-ml-20 h-screen flex items-center justify-center">
          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12"
              width="50"
              height="50"
              viewBox="0 0 220 228"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M53.5 9.5C47.5 -1.3 37 1.33334 32.5 4C8.9 12 1.33333 35.6667 0.500001 46.5V159.5C-1.9 212.7 27.5 227.333 42.5 228L120.5 116C124.1 112 122 107 120.5 105L53.5 9.5Z"
                fill="#0DA7DE"
              />
              <path
                d="M189.5 64L147 0H179C198.2 0 211 10.6667 215 16L189.5 64Z"
                fill="#0DA7DE"
              />
              <path
                d="M150 228L189.5 159.5L220 206.5C214.4 223.3 190.333 227.833 179 228H150Z"
                fill="#0DA7DE"
              />
            </svg>
            <h3 className="mt-6 text-m font-semibold text-gray-900 dark:text-white">
              Access the Trading Platform on Mobile
            </h3>
            <p className="mt-1 text-m text-gray-500 max-w-sm">
              To access the trading platform via mobile, please download the
              "XOH Trader" app and log in using your credentials.
            </p>
            <div className="mt-6">
              <a
                href="https://apps.apple.com/us/app/xoh-trader/id978015340"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                <ArrowDownOnSquareIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                  aria-hidden="true"
                />
                Download XOH Trader
              </a>
            </div>
          </div>
        </div>
      ) : operatingSystem === "Android" ? (
        <div className="-ml-20 h-screen flex items-center justify-center">
          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12"
              width="50"
              height="50"
              viewBox="0 0 220 228"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M53.5 9.5C47.5 -1.3 37 1.33334 32.5 4C8.9 12 1.33333 35.6667 0.500001 46.5V159.5C-1.9 212.7 27.5 227.333 42.5 228L120.5 116C124.1 112 122 107 120.5 105L53.5 9.5Z"
                fill="#0DA7DE"
              />
              <path
                d="M189.5 64L147 0H179C198.2 0 211 10.6667 215 16L189.5 64Z"
                fill="#0DA7DE"
              />
              <path
                d="M150 228L189.5 159.5L220 206.5C214.4 223.3 190.333 227.833 179 228H150Z"
                fill="#0DA7DE"
              />
            </svg>
            <h3 className="mt-6 text-m font-semibold text-gray-900 dark:text-white">
              Access the Trading Platform on Mobile
            </h3>
            <p className="mt-1 text-m text-gray-500 max-w-sm">
              To access the trading platform via mobile, please download the
              "XOH Trader" app and log in using your credentials.
            </p>
            <div className="mt-6">
              <a
                href="https://play.google.com/store/apps/details?id=com.xoh.xohtrader"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                <ArrowDownOnSquareIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                  aria-hidden="true"
                />
                Download XOH Trader
              </a>
            </div>
          </div>
        </div>
      ) : (
        <iframe
          src={iframe_url}
          title="Trading Platform"
          onLoad={handleIframeLoad} // Attach onLoad event handler
        />
      )}
    </div>
  );
}
