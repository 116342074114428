import React, { useState, useEffect, useContext } from "react";
import CountryCombobox from "../Shared/CountryCombobox";
import { BrokerContext } from "../../contexts/BrokerContext";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useNotification } from "../../contexts/NotificationContext";

function TestPraxisModal({ isVisible, onClose }) {
  // Initialize showModal state with isVisible prop
  const [showModal, setShowModal] = useState(isVisible);
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    dob: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    account: "",
    uid: "",
    return_url: "",
  });

  // Access broker data from context
  const { broker } = useContext(BrokerContext);
  const brokerUID = broker.brokerData.uid; // Assuming brokerData has a uid property

  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
  const maxDate = eighteenYearsAgo.toISOString().split("T")[0];

  // Update showModal if isVisible prop changes
  useEffect(() => {
    setShowModal(isVisible);
  }, [isVisible]);

  const handleClose = () => {
    // Close the modal internally
    setShowModal(false);

    // Also inform the parent component to update its state
    if (onClose) onClose();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCountrySelect = (selectedCountry) => {
    // Update formData with the selected country
    setFormData((prevState) => ({
      ...prevState,
      country: selectedCountry,
    }));
  };

  const handleSubmit = (e) => {
    // Check if the form is valid
    if (!e.target.form.checkValidity()) {
      // If the form is not valid, let the browser show the validation errors
      return;
    }

    // Prevent the default form submission if the form is valid
    e.preventDefault();

    setIsLoading(true); // Start loading
    const userLogin = formData.account; // Trading account as userLogin
    const language = "EN"; // Hardcoded language
    const userData = {
      ...formData,
      country: formData.country,
    };

    // Initialize Firebase Functions
    const functions = getFunctions();

    // Reference to your callable function
    const sendPraxisPaymentRequest = httpsCallable(
      functions,
      "sendPraxisPaymentRequest"
    );

    // Call the function with your data
    sendPraxisPaymentRequest({ brokerUID, userLogin, language, userData })
      .then((result) => {
        console.log(result.data);
        const redirectUrlPart = result.data.redirect_url.split("/login/")[1];
        const authToken = result.data.auth_token;

        // Construct the test link
        const testLink = `/praxis-iframe?authToken=${encodeURIComponent(
          authToken
        )}&redirectUrl=${encodeURIComponent(redirectUrlPart)}`;

        // Here you can either navigate to the test link, open it in a new tab, or copy it to the clipboard
        console.log("Generated test link:", testLink); // For demonstration, logging the test link

        // Copy redirect_url to clipboard
        if (result.data && result.data.redirect_url) {
          // Copy test link to clipboard
          navigator.clipboard
            .writeText(testLink) // Changed from result.data.redirect_url to testLink
            .then(() => {
              // Notification message updated to include clipboard information
              showNotification(
                "success",
                "Test link generated and copied to clipboard."
              );
            })
            .catch((err) => {
              // Handle potential errors (e.g., clipboard permissions)
              console.error("Could not copy test link to clipboard", err);
              showNotification(
                "warning",
                "Test link generated, but could not be copied to clipboard."
              );
            });
        } else {
          // Handle case where redirect_url is missing
          showNotification(
            "error",
            "Payment link generated, but missing redirect URL."
          );
        }

        setIsLoading(false); // Stop loading on success
      })
      .catch((error) => {
        // Getting the Error details.
        const message = error.message;

        // Handle the error
        console.error("Error calling sendPraxisPaymentRequest:", message);
        showNotification("error", "Failed to initiate deposit.");
        setIsLoading(false); // Stop loading on success
      });
  };

  if (!showModal) {
    return null;
  }

  return (
    <>
      {/* Main modal */}
      <div className="flex items-center justify-center overflow-y-auto overflow-x-hidden fixed inset-0 z-50 bg-gray-500 bg-opacity-75 dark:bg-gray-900 dark:bg-opacity-75">
        <div className="relative p-4 w-full max-w-2xl">
          {/* Modal content */}
          <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
            {/* Modal header */}
            <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Generate Praxis payment link
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={handleClose}
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <form action="#">
              <div className="grid gap-4 mb-4 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="First name"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="surname"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Last name
                  </label>
                  <input
                    type="text"
                    name="surname"
                    id="surname"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Last name"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="date-of-birth"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Date of birth
                  </label>
                  <input
                    id="dob"
                    name="dob"
                    required
                    type="date"
                    autoComplete="bday"
                    max={maxDate}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="name@company.com"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Phone
                  </label>
                  <input
                    type="phone"
                    name="phone"
                    id="phone"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="+1 123 321 123"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="address"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Street name
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Street name"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="city"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="City"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="state"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    State
                  </label>
                  <input
                    type="text"
                    name="state"
                    id="state"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="State"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="zip_code"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Zip code
                  </label>
                  <input
                    type="text"
                    name="zip_code"
                    id="zip_code"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Zip code"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="country"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Country
                  </label>
                  <CountryCombobox onCountrySelect={handleCountrySelect} />
                </div>
                <div>
                  <label
                    htmlFor="account"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Trading account
                  </label>
                  <input
                    type="number"
                    name="account"
                    id="account"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="3123132"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="uid"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    User ID
                  </label>
                  <input
                    type="text"
                    name="uid"
                    id="uid"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="9zoj26q2lBhS19U20i19fUvsTbM2"
                    required
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="return_url"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Return URL
                  </label>
                  <input
                    type="text"
                    name="return_url"
                    id="return_url"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="https://platform.broker.com"
                    required
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex mt-8">
                <div>
                  <button
                    type="submit"
                    className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                    onClick={handleSubmit}
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center text-center">
                        <svg
                          aria-hidden="true"
                          className="w-4 h-4 -ml-1 mr-3 text-gray-200 animate-spin dark:text-gray-500 fill-primary-600 dark:fill-white"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        Generating link...
                      </div>
                    ) : (
                      "Generate payment link"
                    )}
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default TestPraxisModal;
