export default function BillingActionPanel() {
  return (
    <div className="bg-white dark:bg-white/10 shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
          Manage your subscription
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500 dark:text-gray-400">
          <p>
            Click the button below to manage your subscription details. You will
            be redirected to our secure payment partner, Stripe, where you can
            update your card details, view your billing history, or cancel your
            subscription.
          </p>
        </div>
        <div className="mt-5">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
            onClick={() =>
              window.open(
                "https://billing.stripe.com/p/login/9AQ9C0gfu0KW4ko8ww",
                "_blank"
              )
            }
          >
            Manage subscription
          </button>
        </div>
      </div>
    </div>
  );
}
