import { useState, useContext } from "react";
import { BrokerContext } from "../../contexts/BrokerContext.js"; // import BrokerContext
import {
  CreditCardIcon,
  SwatchIcon,
  UserCircleIcon,
  RectangleStackIcon,
  ServerStackIcon,
  DocumentTextIcon,
  GlobeAltIcon,
  UsersIcon,
  SparklesIcon,
  AtSymbolIcon,
  LockClosedIcon,
  CloudArrowDownIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import ProfileUpdateFormBroker from "../Modals/ProfileUpdateFormBroker";
import ProfileSettings from "./BrokerComponents/ProfileSettings";
import WhiteLabelSettings from "./BrokerComponents/WhiteLabelSettings";
import AccountGroupSettings from "./BrokerComponents/AccountGroupSettings.js";
import ConnectionSettingsXmanager from "./BrokerComponents/ConnectionSettingsXmanager.js";
import KYCSettings from "./BrokerComponents/KYCSettings.js";
import PSPSettings from "./BrokerComponents/PSPSettings.js";
import DomainPage from "./BrokerComponents/DomainPage.js";
import DomainSettings from "./BrokerComponents/DomainSettings.js";
import SignInSignUpSettings from "./BrokerComponents/SignInSignUpSettings.js";
import BonusSettings from "./BrokerComponents/BonusSettings.js";
import EmailConfiguration from "./BrokerComponents/EmailConfiguration.js";
import UserChangePassword from "./UserComponents/UserChangePassword.js";
import MigrationDashboard from "./BrokerComponents/Migration/MigrationDashboard.js";
import BillingActionPanel from "./BrokerComponents/BillingActionPanel.js";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SettingsBroker() {
  const broker = useContext(BrokerContext)?.broker || {};
  const [isProfileUpdateOpen, setProfileUpdateOpen] = useState(false);
  const [currentFieldName, setCurrentFieldName] = useState("");
  const [currentFieldValue, setCurrentFieldValue] = useState("");
  const [currentCollectionName, setCurrentCollectionName] = useState("users");
  const [currentView, setCurrentView] = useState(
    localStorage.getItem("currentView") || "profile"
  );

  const secondaryNavigation = [
    {
      name: "Profile information",
      view: "profile",
      icon: UserCircleIcon,
      current: currentView === "profile",
    },
    {
      name: "White label settings",
      view: "whitelabel",
      icon: SwatchIcon,
      current: currentView === "whitelabel",
    },
    {
      name: "Account group settings",
      view: "accountGroup",
      icon: RectangleStackIcon,
      current: currentView === "accountGroup",
    },
    {
      name: "Billing settings",
      view: "billing",
      icon: CreditCardIcon,
      current: currentView === "billing",
    },
    {
      name: "xManager connection",
      view: "xmanagerConnection",
      icon: ServerStackIcon,
      current: currentView === "xmanagerConnection",
    },
    {
      name: "KYC configuration",
      view: "kycConfiguration",
      icon: DocumentTextIcon,
      current: currentView === "kycConfiguration",
    },
    {
      name: "PSP configuration",
      view: "pspConfiguration",
      icon: CurrencyDollarIcon,
      current: currentView === "pspConfiguration",
    },
    {
      name: "Custom domain",
      view: "domainPage",
      icon: GlobeAltIcon,
      current: currentView === "domainPage",
    },
    {
      name: "Sign in/sign up links",
      view: "signInSignUpSettings",
      icon: UsersIcon,
      current: currentView === "signInSignUpSettings",
    },
    {
      name: "Bonus settings",
      view: "bonusSettings",
      icon: SparklesIcon,
      current: currentView === "bonusSettings",
    },
    {
      name: "Email configuration",
      view: "emailConfiguration",
      icon: AtSymbolIcon,
      current: currentView === "emailConfiguration",
    },
    {
      name: "Change password",
      view: "password",
      icon: LockClosedIcon,
      current: currentView === "password",
    },
    {
      name: "xManager migration",
      view: "migration",
      icon: CloudArrowDownIcon,
      current: currentView === "migration",
    },
  ];

  const openProfileUpdateModalWithField = (
    fieldName,
    fieldValue,
    collectionName
  ) => {
    setCurrentFieldName(fieldName);
    setCurrentFieldValue(fieldValue);
    setProfileUpdateOpen(true);
    setCurrentCollectionName(collectionName);
  };

  const navigateToDomainSettings = () => {
    setCurrentView("domainSettings"); // Assuming 'domainSettings' is the identifier for DomainSettings.js view
    localStorage.setItem("currentView", "domainSettings");
  };

  // Check if user object or userData is null or undefined
  if (!broker || !broker.userData) {
    console.log(
      "Broker or broker.userData is null or undefined. Displaying spinner."
    );
    return (
      <div className="fixed inset-0 flex items-center justify-center dark:bg-gray-900">
        <svg
          className="animate-spin h-10 w-10 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }

  return (
    <>
      <div className="mx-auto max-w-full pt-1 lg:flex lg:gap-x-16 lg:px-8 bg-white dark:bg-gray-900 min-h-screen">
        <aside className="flex border-b overflow-x-auto border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-6">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <button // Changed from 'a' to 'button' since it's not a link
                    className={classNames(
                      item.current
                        ? "bg-gray-50 dark:bg-gray-800 text-green-600"
                        : "text-gray-700 dark:text-gray-400 hover:text-green-600 hover:bg-gray-50 dark:hover:bg-gray-800",
                      "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold min-w-full"
                    )}
                    onClick={() => {
                      setCurrentView(item.view);
                      localStorage.setItem("currentView", item.view);
                    }}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-green-600"
                          : "text-gray-400 group-hover:text-green-600",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </aside>

        <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-6">
          {currentView === "profile" && (
            <ProfileSettings
              openProfileUpdateModalWithField={openProfileUpdateModalWithField}
            />
          )}
          {currentView === "whitelabel" && (
            <WhiteLabelSettings
              openProfileUpdateModalWithField={openProfileUpdateModalWithField}
            />
          )}
          {currentView === "accountGroup" && <AccountGroupSettings />}
          {currentView === "xmanagerConnection" && (
            <ConnectionSettingsXmanager />
          )}
          {currentView === "kycConfiguration" && <KYCSettings />}
          {currentView === "pspConfiguration" && <PSPSettings />}
          {currentView === "domainPage" && (
            <DomainPage navigateToDomainSettings={navigateToDomainSettings} />
          )}
          {currentView === "domainSettings" && (
            <DomainSettings
              currentView={currentView}
              setCurrentView={setCurrentView}
            />
          )}
          {currentView === "signInSignUpSettings" && (
            <SignInSignUpSettings
              currentView={currentView}
              setCurrentView={setCurrentView}
            />
          )}
          {currentView === "bonusSettings" && (
            <BonusSettings
              currentView={currentView}
              setCurrentView={setCurrentView}
            />
          )}
          {currentView === "emailConfiguration" && (
            <EmailConfiguration
              currentView={currentView}
              setCurrentView={setCurrentView}
            />
          )}
          {currentView === "password" && <UserChangePassword />}
          {currentView === "migration" && <MigrationDashboard />}
          {currentView === "billing" && <BillingActionPanel />}
        </main>
      </div>
      {isProfileUpdateOpen && (
        <ProfileUpdateFormBroker
          open={isProfileUpdateOpen}
          setOpen={setProfileUpdateOpen}
          fieldName={currentFieldName}
          fieldValue={currentFieldValue}
          collectionName={currentCollectionName}
        />
      )}
    </>
  );
}
