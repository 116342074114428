import React, { useContext, useState, useEffect, useMemo } from "react";
import { BrokerContext } from "../../../contexts/BrokerContext.js";
import { useNotification } from "../../../contexts/NotificationContext.js";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import axios from "axios";

export default function ConnectionSettingsXmanager() {
  const [formData, setFormData] = useState({
    demoPort: "443",
    demoHost: "xm-demo.tradingplatform.pro",
    xmanagerDemoLogin: "",
    xmanagerDemoPassword: "",
    livePort: "443",
    liveHost: "xm-real.tradingplatform.pro",
    xmanagerLiveLogin: "",
    xmanagerLivePassword: "",
  });

  const [isDemoLoading, setIsDemoLoading] = useState(false);
  const [isLiveLoading, setIsLiveLoading] = useState(false);
  const [isDemoVerified, setIsDemoVerified] = useState(false);
  const [isLiveVerified, setIsLiveVerified] = useState(false);
  const { showNotification } = useNotification();
  const brokerContextValue = useContext(BrokerContext);
  const broker = useMemo(() => {
    return brokerContextValue?.broker || {};
  }, [brokerContextValue]);

  useEffect(() => {
    const fetchCredentialsFromFirestore = async () => {
      if (!broker.brokerData || !broker.brokerData.uid) return; // Guard clause

      const db = getFirestore();
      const docRef = doc(db, "brokers", broker.brokerData.uid);

      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        // Use the data (if it exists) to update the formData state
        setFormData((prevState) => ({
          ...prevState,
          demoPort: data.xmanagerDemoCredentials.demoPort || prevState.demoPort,
          demoHost: data.xmanagerDemoCredentials.demoHost || prevState.demoHost,
          xmanagerDemoLogin:
            data.xmanagerDemoCredentials.xmanagerDemoLogin || "",
          xmanagerDemoPassword:
            data.xmanagerDemoCredentials.xmanagerDemoPassword || "",
          livePort: data.xmanagerLiveCredentials.livePort || prevState.livePort,
          liveHost: data.xmanagerLiveCredentials.liveHost || prevState.liveHost,
          xmanagerLiveLogin:
            data.xmanagerLiveCredentials.xmanagerLiveLogin || "",
          xmanagerLivePassword:
            data.xmanagerLiveCredentials.xmanagerLivePassword || "",
        }));
      }
    };

    // Ensure broker.brokerData and broker.brokerData.uid are defined before fetching data
    if (broker.brokerData && broker.brokerData.uid) {
      fetchCredentialsFromFirestore();
    }
  }, [broker]);

  const handleInputChange = (e) => {
    const { name: inputName, value: inputValue } = e.target;

    let transformedValue = inputValue;

    setFormData({
      ...formData,
      [inputName]: transformedValue,
    });
  };

  const handleVerifyDemoConnectionSettings = async () => {
    const requestData = {
      xmanagerUrl: formData.demoHost,
      username: formData.xmanagerDemoLogin,
      password: formData.xmanagerDemoPassword,
    };

    setIsDemoLoading(true);

    try {
      const response = await axios.post(
        "https://us-central1-xohsync.cloudfunctions.net/testXManagerConnection",
        requestData
      );
      if (response.status === 200) {
        setIsDemoVerified(true);
        showNotification("success", "Credentials saved successfully!");
      } else {
        setIsDemoVerified(false);
        // Handle other response scenarios here if needed
      }
    } catch (error) {
      setIsDemoVerified(false);

      // If the error response is available and contains a message, use it. Otherwise, use a generic error message.
      const errorMessage =
        error.response?.data?.message ||
        "Invalid credentials! Please update your credentials and try again.";
      showNotification("error", errorMessage);
    } finally {
      setIsDemoLoading(false);
    }
  };

  const handleVerifyLiveConnectionSettings = async () => {
    const requestData = {
      xmanagerUrl: formData.liveHost,
      username: formData.xmanagerLiveLogin,
      password: formData.xmanagerLivePassword,
    };

    setIsLiveLoading(true);

    try {
      const response = await axios.post(
        "https://us-central1-xohsync.cloudfunctions.net/testXManagerConnection",
        requestData
      );
      if (response.status === 200) {
        setIsLiveVerified(true);
        showNotification("success", "Credentials saved successfully!");
      } else {
        setIsLiveVerified(false);
        // Handle other response scenarios here if needed
      }
    } catch (error) {
      setIsLiveVerified(false);

      // If the error response is available and contains a message, use it. Otherwise, use a generic error message.
      const errorMessage =
        error.response?.data?.message ||
        "Invalid credentials! Please update your credentials and try again.";
      showNotification("error", errorMessage);
    } finally {
      setIsLiveLoading(false);
    }
  };

  // Check if user object or userData is null or undefined
  if (!broker || !broker.userData) {
    return (
      <div className="fixed inset-0 flex items-center justify-center dark:bg-gray-900">
        <svg
          className="animate-spin h-10 w-10 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }

  const updateCredentialsToFirestore = async () => {
    if (!broker.brokerData || !broker.brokerData.uid) return; // Guard clause

    const db = getFirestore();
    const docRef = doc(db, "brokers", broker.brokerData.uid);

    try {
      await setDoc(
        docRef,
        {
          xmanagerDemoCredentials: {
            demoPort: formData.demoPort,
            demoHost: formData.demoHost,
            xmanagerDemoLogin: formData.xmanagerDemoLogin,
            xmanagerDemoPassword: formData.xmanagerDemoPassword,
          },
          xmanagerLiveCredentials: {
            livePort: formData.livePort,
            liveHost: formData.liveHost,
            xmanagerLiveLogin: formData.xmanagerLiveLogin,
            xmanagerLivePassword: formData.xmanagerLivePassword,
          },
        },
        { merge: true }
      ); // The merge: true option ensures we only update the fields provided, leaving others untouched

      showNotification("success", "Credentials updated successfully!");
    } catch (error) {
      console.error("Firestore update error:", error);
      showNotification(
        "error",
        "Failed to update credentials. Please try again."
      );
    }
  };

  return (
    <div className="max-w-7xl min-h-screen dark:bg-gray-900">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
          xManager configuration
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          Configure your xManager API connection for both the demo and live
          environment.
        </p>
      </div>

      <div className="mt-6 grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 dark:border-gray-700 pb-12 md:grid-cols-3">
        <div className="grid min-w-max grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >
              Demo xManager login
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="xmanagerDemoLogin"
                onChange={handleInputChange}
                id="demo-login"
                required
                disabled={isDemoVerified}
                placeholder={formData.xmanagerDemoLogin || "xManager login"}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >
              Demo xManager password
            </label>
            <div className="mt-2">
              <input
                type="password"
                name="xmanagerDemoPassword"
                onChange={handleInputChange}
                id="demo-password"
                required
                disabled={isDemoVerified}
                placeholder="••••••••"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >
              Demo host port
            </label>
            <div className="mt-2">
              <input
                type="number"
                name="demoPort"
                required
                onChange={handleInputChange}
                id="demo-port"
                disabled={isDemoVerified}
                placeholder={formData.demoPort || "Demo port"}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >
              Demo host address
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="demoHost"
                required
                onChange={handleInputChange}
                id="demo-host"
                disabled={isDemoVerified}
                placeholder={formData.demoHost || "Demo host address"}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <button
              type="button"
              onClick={handleVerifyDemoConnectionSettings}
              className={`rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ${
                isDemoVerified
                  ? "bg-primary-50 text-primary-600"
                  : "bg-white text-gray-900"
              }`}
              disabled={isDemoLoading || isDemoVerified} // Disable the button during loading or after verification
            >
              {isDemoLoading ? (
                <div className="flex items-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-900"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Verifying...
                </div>
              ) : isDemoVerified ? (
                "Success! Credentials verified."
              ) : (
                "Test demo xManager connection"
              )}
            </button>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >
              Live xManager login
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="xmanagerLiveLogin"
                onChange={handleInputChange}
                id="live-login"
                required
                disabled={isLiveVerified}
                placeholder={
                  formData.xmanagerLiveLogin || "xManager live login"
                }
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >
              Live xManager password
            </label>
            <div className="mt-2">
              <input
                type="password"
                name="xmanagerLivePassword"
                onChange={handleInputChange}
                id="live-password"
                required
                disabled={isLiveVerified}
                placeholder="••••••••"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >
              Live host port
            </label>
            <div className="mt-2">
              <input
                type="number"
                name="livePort"
                required
                onChange={handleInputChange}
                id="live-port"
                disabled={isLiveVerified}
                placeholder={formData.livePort || "Live port"}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >
              Live host address
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="liveHost"
                required
                onChange={handleInputChange}
                id="live-host"
                disabled={isLiveVerified}
                placeholder={formData.liveHost || "Live host address"}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <button
              type="button"
              onClick={handleVerifyLiveConnectionSettings}
              className={`rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ${
                isLiveVerified
                  ? "bg-primary-50 text-primary-600"
                  : "bg-white text-gray-900"
              }`}
              disabled={isLiveLoading || isLiveVerified} // Disable the button during loading or after verification
            >
              {isLiveLoading ? (
                <div className="flex items-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-900"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Verifying...
                </div>
              ) : isLiveVerified ? (
                "Success! Credentials verified."
              ) : (
                "Test live xManager connection"
              )}
            </button>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6 sm:col-span-6">
            <button
              type="button"
              onClick={updateCredentialsToFirestore}
              className="rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
              Save new xManager credentials
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
