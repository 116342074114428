import React, { createContext, useContext, useState, useEffect } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector"; // Optional: for detecting language from the browser settings

// Importing translation files
import translationEN from "../locales/en.json";
import translationSV from "../locales/sv.json";
import translationPL from "../locales/pl.json";

const resources = {
  en: {
    translation: translationEN,
  },
  sv: {
    translation: translationSV,
  },
  pl: {
    translation: translationPL,
  },
};

const initialLanguage = localStorage.getItem("i18nextLng") || "en";

// Initialize i18n outside of the component
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: initialLanguage, // default language
    fallbackLng: "en",
    detection: {
      order: [
        "localStorage", // Make localStorage the first to prioritize it
        "querystring",
        "cookie",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      caches: ["localStorage", "cookie"], // Specify where to cache the language selection
    },
    interpolation: {
      escapeValue: false,
    },
  });

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );

  // This useEffect now only handles language changes
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const changeLanguage = (lng) => {
    localStorage.setItem("i18nextLng", lng); // Save the selected language to localStorage
    setLanguage(lng);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
