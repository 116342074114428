import { Fragment, useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { UsersIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import debounce from "lodash/debounce";
import { BrokerContext } from "../../contexts/BrokerContext";
import algoliasearch from "algoliasearch/lite";
import getSymbolFromCurrency from "currency-symbol-map";

// With these lines:
const algoliaAppId = process.env.REACT_APP_ALGOLIA_APP_ID;
const algoliaSearchOnlyApiKey =
  process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY;

const algoliaClient = algoliasearch(algoliaAppId, algoliaSearchOnlyApiKey);
const usersIndex = algoliaClient.initIndex("users");
const transactionsIndex = algoliaClient.initIndex("transactions");

async function fetchDataByQuery(index, queryString, brokerUID) {
  const lowerQueryString = queryString.toLowerCase();
  try {
    const filters =
      index === usersIndex
        ? `brokerUID:${brokerUID} AND NOT status:broker` // Exclude broker status if we're searching the usersIndex
        : `brokerUID:${brokerUID}`; // Keep it as-is for other indices

    const { hits } = await index.search(lowerQueryString, {
      filters: filters,
      userToken: brokerUID,
    });
    return hits;
  } catch (error) {
    console.error("Error fetching data from Algolia:", error);
    return [];
  }
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function renderAvatar(fullName, imageUrl, className) {
  if (!fullName) {
    console.warn("Full name is undefined for a user.");
    return null;
  }

  if (imageUrl) {
    return (
      <img className={`${className} rounded-full`} src={imageUrl} alt="" />
    );
  } else {
    const nameParts = fullName.split(" ");
    const initials =
      nameParts.length > 1
        ? (
            nameParts[0][0] + (nameParts[nameParts.length - 1][0] || "")
          ).toUpperCase()
        : nameParts[0][0].toUpperCase();
    return (
      <span
        className={`inline-flex ${className} items-center justify-center rounded-full bg-gray-500`}
      >
        <span className="text-sm font-medium leading-none text-white">
          {initials.toUpperCase()}
        </span>
      </span>
    );
  }
}

function StatusPill({ status }) {
  switch (status) {
    case "pending":
      return (
        <span className="inline-flex items-center rounded-md bg-yellow-50 dark:bg-yellow-400/10 px-2 py-1 text-xs font-medium text-yellow-800 dark:text-yellow-500 ring-1 ring-inset ring-yellow-600/20 dark:ring-yellow-400/20">
          Pending
        </span>
      );
    case "approved":
      return (
        <span className="inline-flex items-center rounded-md bg-primary-50 dark:bg-primary-500/10 px-2 py-1 text-xs font-medium text-primary-700 dark:text-primary-400 ring-1 ring-inset ring-primary-600/20 dark:ring-primary-500/20">
          Approved
        </span>
      );
    case "deposit":
      return (
        <span className="inline-flex items-center rounded-md bg-primary-50 dark:bg-primary-500/10 px-2 py-1 text-xs font-medium text-primary-700 dark:text-primary-400 ring-1 ring-inset ring-primary-600/20 dark:ring-primary-500/20">
          Deposit
        </span>
      );
    case "rejected":
      return (
        <span className="inline-flex items-center rounded-md bg-red-50 dark:bg-red-400/10 px-2 py-1 text-xs font-medium text-red-700 dark:text-red-400 ring-1 ring-inset ring-red-600/10 dark:ring-red-400/20">
          Rejected
        </span>
      );
    case "withdrawal":
      return (
        <span className="inline-flex items-center rounded-md bg-red-50 dark:bg-red-400/10 px-2 py-1 text-xs font-medium text-red-700 dark:text-red-400 ring-1 ring-inset ring-red-600/10 dark:ring-red-400/20">
          Withdrawal
        </span>
      );
    default:
      return (
        <span className="inline-flex items-center rounded-md bg-gray-50 dark:bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-600 dark:text-gray-400 ring-1 ring-inset ring-gray-500/10 dark:ring-gray-400/20">
          {status || "Unknown"}
        </span>
      );
  }
}

// Function to fetch user data by uid
async function fetchUserByUID(uid, brokerUID) {
  try {
    const { hits } = await usersIndex.search("", {
      filters: `objectID:${uid}`,
      userToken: brokerUID,
    });
    return hits[0]; // Assuming uid is unique, so only one hit should be returned
  } catch (error) {
    console.error("Error fetching user data from Algolia:", error);
    return null;
  }
}

export default function BrokerCommandPalette({
  isPaletteVisible,
  setIsPaletteVisible,
}) {
  const { broker } = useContext(BrokerContext);
  const brokerUID = broker?.brokerData?.uid;
  const [query, setQuery] = useState("");
  const [users, setUsers] = useState([]); // Moved inside the component
  const [recentSearches, setRecentSearches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isTransaction, setIsTransaction] = useState(false);
  const [transactions, setTransactions] = useState([]); // Add this line
  const [userMap, setUserMap] = useState({}); // New state variable to hold user data mapped by uid

  const navigate = useNavigate();

  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      const isTransactionQuery = looksLikeTransactionID(searchQuery);
      setIsTransaction(isTransactionQuery); // Set isTransaction
      const index = isTransactionQuery ? transactionsIndex : usersIndex;
      fetchDataByQuery(index, searchQuery, brokerUID).then((fetchedData) => {
        if (isTransactionQuery) {
          setTransactions(fetchedData); // Set transactions here if it's a transaction query
        } else {
          setUsers(fetchedData); // Set users here otherwise
        }
        setLoading(false);
        if (fetchedData.length > 0 && !isTransactionQuery) {
          // Added check for !isTransactionQuery here
          setRecentSearches((prev) => {
            const updatedSearches = prev.filter(
              (recentUser) =>
                !fetchedData.some(
                  (fetchedUser) => fetchedUser.uid === recentUser.uid
                )
            );
            const newRecent = [...fetchedData, ...updatedSearches];
            while (newRecent.length > 5) {
              newRecent.pop();
            }
            localStorage.setItem("recentSearches", JSON.stringify(newRecent));
            return newRecent;
          });
        }
      });
    }, 500),
    [brokerUID] // Now brokerUID is a dependency
  );

  // This useEffect handles the debounced search
  useEffect(() => {
    if (query) {
      setLoading(true); // Set loading to true right before triggering the debounced search
      debouncedSearch(query);
    }
  }, [query, debouncedSearch]);

  function looksLikeTransactionID(query) {
    return query.toLowerCase().startsWith("order");
  }

  useEffect(() => {
    // Function to fetch user data for each transaction and update the userMap state
    async function fetchUsersForTransactions() {
      const newUserMap = {};
      for (const transaction of transactions) {
        const userData = await fetchUserByUID(transaction.uid, brokerUID);
        if (userData) {
          newUserMap[transaction.uid] = userData;
        }
      }
      setUserMap(newUserMap);
    }

    if (transactions.length > 0) {
      fetchUsersForTransactions();
    }
  }, [transactions, brokerUID]);

  useEffect(() => {
    const loadedRecentSearches = localStorage.getItem("recentSearches");
    if (loadedRecentSearches) {
      setRecentSearches(JSON.parse(loadedRecentSearches));
    }
  }, []);

  return (
    <Transition.Root
      show={isPaletteVisible}
      as={Fragment}
      afterLeave={() => setQuery("")}
      appear
    >
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => setIsPaletteVisible(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-gray-900 bg-opacity-50 dark:bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 dark:divide-gray-700 overflow-hidden rounded-xl bg-white dark:bg-gray-800 shadow-2xl ring-1 ring-black dark:ring-white dark:ring-opacity-5 ring-opacity-5 transition-all">
              <Combobox
                onChange={(person) => {
                  navigate(`/broker/dashboard/client/${person.uid}`);
                  setIsPaletteVisible(false);
                }}
              >
                {({ activeOption }) => (
                  <>
                    <div className="relative">
                      <MagnifyingGlassIcon
                        className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <Combobox.Input
                        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 dark:text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                        placeholder="Search..."
                        onChange={(event) => {
                          setQuery(event.target.value);
                        }}
                      />
                    </div>

                    {loading ? (
                      <div className="flex justify-center items-center h-full px-6 py-14 text-sm sm:px-14">
                        <svg
                          aria-hidden="true"
                          className="w-10 h-10 text-gray-200 animate-spin dark:text-gray-400 fill-primary-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      </div>
                    ) : (
                      <div>
                        {isTransaction ? (
                          // Corrected the brackets here
                          // Render transaction data
                          <div>
                            <Combobox.Options
                              as="div"
                              static
                              hold
                              className="flex divide-x divide-gray-100 dark:divide-white/5 text-sm dark:text-gray-500 text-gray-700"
                            >
                              <div
                                className={classNames(
                                  "max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4",
                                  activeOption && "sm:h-96"
                                )}
                              >
                                {transactions.map((transaction, index) => (
                                  <Combobox.Option
                                    as="div"
                                    key={transaction.objectID}
                                    value={transaction}
                                    className={({ active }) =>
                                      classNames(
                                        "flex cursor-pointer select-none items-center rounded-md p-2",
                                        active &&
                                          "bg-gray-100 dark:bg-white/5 text-gray-900 dark:text-white"
                                      )
                                    }
                                  >
                                    {({ active }) => (
                                      <>
                                        {userMap[transaction.uid] ? (
                                          <>
                                            {renderAvatar(
                                              userMap[transaction.uid].fullName,
                                              userMap[transaction.uid].imageUrl,
                                              "h-8 w-8"
                                            )}
                                            <span className="ml-3 flex-auto truncate">
                                              {transaction.orderId
                                                .charAt(0)
                                                .toUpperCase() +
                                                transaction.orderId.slice(
                                                  1
                                                )}{" "}
                                              <span className="ml-2 inline-flex items-center rounded-md bg-gray-100 dark:bg-gray-400/10 px-1.5 py-0.5 text-xs font-medium text-gray-600 dark:text-gray-400">
                                                {transaction.timestamp
                                                  ? new Date(
                                                      transaction.timestamp
                                                        ._seconds * 1000
                                                    ).toLocaleDateString()
                                                  : "Timestamp not available"}
                                              </span>
                                            </span>
                                          </>
                                        ) : (
                                          <span>Loading user data...</span>
                                        )}

                                        {active && (
                                          <ChevronRightIcon
                                            className="ml-3 h-5 w-5 flex-none text-gray-400"
                                            aria-hidden="true"
                                          />
                                        )}
                                      </>
                                    )}
                                  </Combobox.Option>
                                ))}
                              </div>

                              {activeOption && (
                                <div className="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 dark:divide-white/5 overflow-y-auto sm:flex">
                                  <div className="flex-none p-6 text-center">
                                    {userMap[activeOption.uid] ? (
                                      <>
                                        {renderAvatar(
                                          userMap[activeOption.uid].fullName,
                                          userMap[activeOption.uid].imageUrl,
                                          "h-16 w-16"
                                        )}
                                        <h2 className="mt-3 font-semibold text-gray-900 dark:text-white">
                                          {userMap[activeOption.uid].fullName}
                                        </h2>
                                      </>
                                    ) : (
                                      <span>Loading user data...</span>
                                    )}

                                    <p className="text-sm leading-6 text-gray-500">
                                      Transaction date:{" "}
                                      {activeOption && activeOption.timestamp
                                        ? new Date(
                                            activeOption.timestamp._seconds *
                                              1000
                                          ).toLocaleString()
                                        : "Timestamp not available"}
                                    </p>
                                  </div>
                                  <div className="flex flex-auto flex-col justify-between p-6">
                                    <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700 dark:text-gray-400">
                                      <dt className="col-end-1 font-semibold text-gray-900 dark:text-white">
                                        Amount
                                      </dt>
                                      <dd>
                                        {activeOption.processed_currency
                                          ? getSymbolFromCurrency(
                                              activeOption.processed_currency
                                            ) +
                                            (
                                              activeOption.processed_amount /
                                              100
                                            ).toFixed(2)
                                          : "-"}
                                      </dd>
                                      <dt className="col-end-1 font-semibold text-gray-900 dark:text-white">
                                        Transaction type
                                      </dt>
                                      <dd>
                                        <StatusPill
                                          status={activeOption.transactionType}
                                        />
                                      </dd>
                                      <dt className="col-end-1 font-semibold text-gray-900 dark:text-white">
                                        Status
                                      </dt>
                                      <dd>
                                        <StatusPill
                                          status={activeOption.status}
                                        />
                                      </dd>
                                    </dl>
                                    <button
                                      type="button"
                                      className="mt-6 w-full rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                                      onClick={() => {
                                        navigate(
                                          `/broker/dashboard/client/${activeOption.uid}`
                                        );
                                        setIsPaletteVisible(false);
                                      }}
                                    >
                                      View profile
                                    </button>
                                  </div>
                                </div>
                              )}
                            </Combobox.Options>
                          </div>
                        ) : (
                          <div>
                            {(query === "" || users.length > 0) && (
                              <Combobox.Options
                                as="div"
                                static
                                hold
                                className="flex divide-x divide-gray-100 dark:divide-white/5"
                              >
                                <div
                                  className={classNames(
                                    "max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4",
                                    activeOption && "sm:h-96"
                                  )}
                                >
                                  {query === "" && (
                                    <h2 className="mb-4 mt-2 text-xs font-semibold text-gray-500">
                                      Recent searches
                                    </h2>
                                  )}
                                  <div className="-mx-2 text-sm text-gray-700 dark:text-gray-500">
                                    {(query === ""
                                      ? recentSearches // Directly use recentSearches if it contains user objects
                                      : users
                                    ) // Use users instead of filteredPeople
                                      .map((item, index) => (
                                        <Combobox.Option
                                          as="div"
                                          key={item.objectID}
                                          value={item}
                                          className={({ active }) =>
                                            classNames(
                                              "flex cursor-pointer select-none items-center rounded-md p-2",
                                              active &&
                                                "bg-gray-100 dark:bg-white/5 text-gray-900 dark:text-white"
                                            )
                                          }
                                        >
                                          {({ active }) => (
                                            <>
                                              {renderAvatar(
                                                item.fullName,
                                                item.imageUrl,
                                                "h-8 w-8"
                                              )}
                                              <span className="ml-3 flex-auto truncate">
                                                {item.fullName}
                                              </span>

                                              {active && (
                                                <ChevronRightIcon
                                                  className="ml-3 h-5 w-5 flex-none text-gray-400"
                                                  aria-hidden="true"
                                                />
                                              )}
                                            </>
                                          )}
                                        </Combobox.Option>
                                      ))}
                                  </div>
                                </div>

                                {activeOption && (
                                  <div className="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 dark:divide-white/5 overflow-y-auto sm:flex">
                                    <div className="flex-none p-6 text-center">
                                      {renderAvatar(
                                        activeOption.fullName,
                                        activeOption.imageUrl,
                                        "h-16 w-16"
                                      )}
                                      <h2 className="mt-3 font-semibold text-gray-900 dark:text-white">
                                        {activeOption.fullName}
                                      </h2>

                                      <p className="text-sm leading-6 text-gray-500">
                                        {activeOption.country}
                                      </p>
                                    </div>
                                    <div className="flex flex-auto flex-col justify-between p-6">
                                      <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700 dark:text-gray-400">
                                        <dt className="col-end-1 font-semibold text-gray-900 dark:text-white">
                                          Phone
                                        </dt>
                                        <dd>{activeOption.phone}</dd>
                                        <dt className="col-end-1 font-semibold text-gray-900 dark:text-white">
                                          Address
                                        </dt>
                                        <dd>{activeOption.address}</dd>
                                        <dt className="col-end-1 font-semibold text-gray-900 dark:text-white">
                                          Email
                                        </dt>
                                        <dd className="truncate">
                                          <a
                                            href={`mailto:${activeOption.email}`}
                                            className="text-primary-600 underline"
                                          >
                                            {activeOption.email}
                                          </a>
                                        </dd>
                                      </dl>
                                      <button
                                        type="button"
                                        className="mt-6 w-full rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                                        onClick={() => {
                                          navigate(
                                            `/broker/dashboard/client/${activeOption.objectID}`
                                          );
                                          setIsPaletteVisible(false);
                                        }}
                                      >
                                        View profile
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </Combobox.Options>
                            )}

                            {query !== "" && users.length === 0 ? (
                              <div className="px-6 py-14 text-center text-sm sm:px-14">
                                <UsersIcon
                                  className="mx-auto h-6 w-6 text-gray-400"
                                  aria-hidden="true"
                                />
                                <p className="mt-4 font-semibold text-gray-900 dark:text-white">
                                  No results found
                                </p>
                                <p className="mt-2 text-gray-500">
                                  We couldn’t find anything with that term.
                                  Please try again.
                                </p>
                              </div>
                            ) : null}
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
