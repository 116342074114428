import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // import useNavigate
import logo from "../../../logo.svg";
import logoDark from "../../../logoDark.svg";
import useDarkMode from "../../../utils/useDarkMode";
import { useNotification } from "../../../contexts/NotificationContext.js";
import { handleFirebaseError } from "../../../utils/handleFirebaseError.js";
import { httpsCallable, getFunctions } from "firebase/functions";
import {
  getFirestore,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import { sendPasswordResetEmail, getAuth } from "firebase/auth";

export default function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const darkModeEnabled = useDarkMode();
  const { showNotification } = useNotification(); // from NotificationContext
  const [logoUrls, setLogoUrls] = useState(null);
  const [logoLoading, setLogoLoading] = useState(true);

  useEffect(() => {
    // If the hostname doesn't start with "platform", exit early
    if (!window.location.hostname.startsWith("platform.")) {
      setLogoLoading(false); // Ensure loading is set to false
      return;
    }

    const fetchLogoUrls = async () => {
      try {
        setLogoLoading(true);
        const db = getFirestore();
        const domain = window.location.hostname.split(".").slice(-2).join("."); // Get the domain

        // Query the "whitelabel" collection where the domain field matches the domain
        const whitelabelQuery = query(
          collection(db, "whitelabel"),
          where("domain", "==", domain)
        );
        const whitelabelSnapshot = await getDocs(whitelabelQuery);

        // Check if any documents match the query
        if (!whitelabelSnapshot.empty) {
          // Get the first document from the query results
          const whitelabelDoc = whitelabelSnapshot.docs[0];

          // Update the logo URLs
          setLogoUrls({
            dark: whitelabelDoc.data().logoUrlDark,
            light: whitelabelDoc.data().logoUrlLight,
          });
        }
      } catch (error) {
        console.error("An error occurred while fetching logo URLs:", error);
      } finally {
        setLogoLoading(false); // Ensure this gets called even if an error occurs
      }
    };

    // Fetch the logo URLs
    fetchLogoUrls();
  }, []);

  const handleForgotPassword = async (email) => {
    setIsLoading(true);
    try {
      const generateResetTokenResponse = await generateResetTokenFunction({
        recipientEmail: email,
      });

      if (generateResetTokenResponse.success) {
        // Once the token is successfully generated, call the sendEmail function
        const sendEmailResponse = await sendEmailFunction({
          emailType: "reset-password",
          recipientEmail: email,
        });

        if (sendEmailResponse.success) {
          showNotification(
            "success",
            "Success! Check your email for further instructions."
          );
        } else {
          // If custom email sending fails, send a Firebase reset password email
          const auth = getAuth();
          await sendPasswordResetEmail(auth, email);
          showNotification(
            "success",
            "Success! Check your email for further instructions."
          );
        }
      }
    } catch (error) {
      handleFirebaseError(error, showNotification);
    }
    setIsLoading(false);
  };

  const sendEmailFunction = async ({ emailType, recipientEmail }) => {
    try {
      const functions = getFunctions();
      const sendEmail = httpsCallable(functions, "sendEmail");
      const response = await sendEmail({ emailType, recipientEmail });
      return response.data;
    } catch (error) {
      console.error("Error sending email:", error);
      showNotification("error", "An error occurred while sending the email.");
      return { success: false };
    }
  };

  const generateResetTokenFunction = async ({ recipientEmail }) => {
    try {
      const functions = getFunctions();
      const generateResetToken = httpsCallable(functions, "generateResetToken");
      const response = await generateResetToken({ recipientEmail });
      return response.data;
    } catch (error) {
      console.error("Error generating reset token:", error);
      showNotification(
        "error",
        "An error occurred while generating reset token."
      );
      return { success: false };
    }
  };

  useEffect(() => {
    const rememberedEmail = localStorage.getItem("email");
    if (rememberedEmail) {
      setEmail(rememberedEmail);
    }
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    await handleForgotPassword(email); // Call the handleForgotPassword function with the entered email
  };

  return (
    <>
      <div className="flex min-h-screen flex-1 flex-col justify-center py-12 px-6 sm:px-6 lg:px-8 bg-white dark:bg-gray-900">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {logoLoading ? (
            <div className="animate-pulse rounded-full bg-gray-200 dark:bg-white/10 h-10 w-10 mx-auto"></div>
          ) : (
            <img
              className="mx-auto h-10 w-auto"
              src={
                logoUrls
                  ? darkModeEnabled
                    ? logoUrls.dark
                    : logoUrls.light
                  : darkModeEnabled
                  ? logoDark
                  : logo
              }
              alt="Logo"
            />
          )}
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
            Reset password
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500 text-center">
            Enter your email below, we'll send you a reset link for your
            password.
          </p>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 text-left"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Email"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-primary-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                disabled={isLoading} // disable the button while loading
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <div className="flex items-center">
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4 -ml-1 mr-3 text-gray-200 animate-spin dark:text-gray-500 fill-primary-600 dark:fill-white"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    Sending email...
                  </div>
                ) : (
                  "Reset password"
                )}
              </button>
              <div className="relative mt-10">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-200 dark:border-white/10" />
                </div>
              </div>
            </div>
          </form>
        </div>

        <p className="mt-10 text-center text-sm text-gray-500 dark:text-gray-400">
          Already have an account?{" "}
          <Link
            to="/sign-in"
            className="font-semibold leading-6 text-primary-600 hover:text-primary-500"
          >
            Sign in
          </Link>
        </p>
      </div>
    </>
  );
}
