import { useState, useContext } from "react";
import { UserContext } from "../../contexts/UserContext.js";
import {
  UserCircleIcon,
  CheckBadgeIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import ProfileUpdateForm from "../Modals/ProfileUpdateForm";
import UserProfileSettings from "./UserComponents/UserProfileSettings.js";
import UserManualKYC from "./UserComponents/UserManualKYC.js";
import UserChangePassword from "./UserComponents/UserChangePassword.js";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Settings() {
  const { user } = useContext(UserContext);
  const [isProfileUpdateOpen, setProfileUpdateOpen] = useState(false);
  const [currentFieldName, setCurrentFieldName] = useState("");
  const [currentFieldValue, setCurrentFieldValue] = useState("");
  const [currentView, setCurrentView] = useState(
    localStorage.getItem("currentView") || "profile"
  );

  let secondaryNavigation = [
    {
      name: "Profile information",
      view: "profile",
      icon: UserCircleIcon,
      current: currentView === "profile",
    },
    {
      name: "Change password",
      view: "password",
      icon: LockClosedIcon,
      current: currentView === "password",
    },
  ];

  if (user.userData.kycStatus !== "approved_kyc") {
    secondaryNavigation.push({
      name: "Account verification",
      view: "kyc",
      icon: CheckBadgeIcon,
      current: currentView === "kyc",
    });
  }

  const openProfileUpdateModalWithField = (fieldName, fieldValue) => {
    setCurrentFieldName(fieldName);
    setCurrentFieldValue(fieldValue);
    setProfileUpdateOpen(true);
  };

  // Check if user object or userData is null or undefined
  if (!user || !user.userData) {
    return (
      <div className="fixed inset-0 flex items-center justify-center dark:bg-gray-900">
        <svg
          className="animate-spin h-10 w-10 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }

  return (
    <>
      <div className="mx-auto max-w-full lg:pl-14 lg:flex lg:gap-x-4 bg-white dark:bg-gray-900 min-h-screen lg:pr-6">
        <aside className="flex lg:border-r lg:border-l dark:border-white/5 lg:block lg:w-80 lg:flex-none lg:py-3 p-2">
          <nav className="flex-none sm:px-6 lg:px-4 lg:sticky lg:top-3 sm:mx-auto sm:py-4 lg:py-0">
            <ul className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <button // Changed from 'a' to 'button' since it's not a link
                    className={classNames(
                      item.current
                        ? "bg-gray-50 dark:bg-white/5 text-green-600"
                        : "text-gray-700 dark:text-gray-400 hover:text-green-600 hover:bg-gray-50 dark:hover:bg-gray-800",
                      "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold min-w-full"
                    )}
                    onClick={() => {
                      setCurrentView(item.view);
                      localStorage.setItem("currentView", item.view);
                    }}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-green-600"
                          : "text-gray-400 group-hover:text-green-600",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </aside>

        <main className="px-4 sm:px-6 lg:flex-auto lg:px-0 lg:py-3">
          {currentView === "profile" && (
            <UserProfileSettings
              openProfileUpdateModalWithField={openProfileUpdateModalWithField}
            />
          )}
          {currentView === "kyc" && <UserManualKYC />}
          {currentView === "password" && <UserChangePassword />}
        </main>
      </div>

      <ProfileUpdateForm
        open={isProfileUpdateOpen}
        setOpen={setProfileUpdateOpen}
        fieldName={currentFieldName} // Pass the field name here
        fieldValue={currentFieldValue} // Pass the field value here
      />
    </>
  );
}
