import { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { BrokerContext } from "../../../contexts/BrokerContext.js";
import Pagination from "../../Shared/Pagination.js";
import getSymbolFromCurrency from "currency-symbol-map";
import algoliasearch from "algoliasearch/lite";
import { Tooltip } from "flowbite-react";
import EmptyState from "../../Shared/EmptyState.js";

// With these lines:
const algoliaAppId = process.env.REACT_APP_ALGOLIA_APP_ID;
const algoliaSearchOnlyApiKey =
  process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY;

const algoliaClient = algoliasearch(algoliaAppId, algoliaSearchOnlyApiKey);
const transactionsIndex = algoliaClient.initIndex("transactions");
const usersIndex = algoliaClient.initIndex("users");

export default function TransactionsDashboard() {
  const { broker } = useContext(BrokerContext);
  const brokerUID = broker?.brokerData?.uid;
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);

  const [filters, setFilters] = useState({
    status: {
      pending: true,
      approved: true,
      rejected: true,
    },
    transactionType: {
      deposit: true,
      withdrawal: true,
    },
  });

  const fetchData = useCallback(async () => {
    const startDocNum = (currentPage - 1) * itemsPerPage;

    // Create an array to hold the filters
    let filtersArray = [];

    // Add brokerUID filter
    if (brokerUID) {
      filtersArray.push(`brokerUID:${brokerUID}`);
    }

    // Add status filters
    let statusFilters = [];
    Object.keys(filters.status).forEach((key) => {
      if (
        filters.status[key] &&
        Object.values(filters.status).filter(Boolean).length !== 3
      ) {
        statusFilters.push(`status:${key}`);
      }
    });
    if (statusFilters.length > 0) {
      filtersArray.push(`(${statusFilters.join(" OR ")})`);
    }

    // Add transaction type filters
    let transactionTypeFilters = [];
    Object.keys(filters.transactionType).forEach((key) => {
      if (
        filters.transactionType[key] &&
        Object.values(filters.transactionType).filter(Boolean).length !== 2
      ) {
        transactionTypeFilters.push(`transactionType:${key}`);
      }
    });
    if (transactionTypeFilters.length > 0) {
      filtersArray.push(`(${transactionTypeFilters.join(" OR ")})`);
    }

    // Convert the filters array to a string
    const filtersString = filtersArray.join(" AND ");

    console.log(`Fetching data from Algolia with parameters: 
    Index: ${transactionsIndex.indexName}
    Broker UID: ${brokerUID}
    Page: ${currentPage}
    Items per page: ${itemsPerPage}
    Filters: ${filtersString}`);

    // Perform the search
    const searchResults = await transactionsIndex.search("", {
      filters: filtersString,
      offset: startDocNum,
      length: itemsPerPage,
    });

    setTotalItems(searchResults.nbHits);

    // Extract the hits (i.e., the matching transactions)
    const transactionsData = await Promise.all(
      searchResults.hits.map(async (transaction) => {
        // Convert Firestore Timestamps to JavaScript Dates
        if (transaction.date && transaction.date._seconds) {
          transaction.date = new Date(transaction.date._seconds * 1000);
        }

        // Fetch the user's email from the users index
        if (transaction.uid) {
          const userSearchResults = await usersIndex.search(transaction.uid);
          if (userSearchResults.hits.length > 0) {
            transaction.email = userSearchResults.hits[0].email;
          }
        }

        return transaction;
      })
    );

    // Update the state
    setTransactions(transactionsData);
    setLoading(false);
  }, [currentPage, itemsPerPage, filters, brokerUID]);

  const handleStatusChange = (newStatus) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      status:
        newStatus === ""
          ? { pending: true, approved: true, rejected: true }
          : { [newStatus]: true },
    }));
  };

  const handleTransactionTypeChange = (newTransactionType) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      transactionType:
        newTransactionType === ""
          ? { deposit: true, withdrawal: true }
          : { [newTransactionType]: true },
    }));
  };

  useEffect(() => {
    if (brokerUID) {
      fetchData();
    }
  }, [brokerUID, currentPage, itemsPerPage, fetchData]);

  useEffect(() => {
    fetchData();
  }, [filters, fetchData]); // Call fetchData whenever filters changes

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  function StatusPill({ status }) {
    switch (status) {
      case "pending":
        return (
          <Tooltip content="This transaction is pending" placement="right">
            <div className="inline-flex items-center bg-blue-100 text-blue-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
              <svg
                className="w-[12px] h-[12px] mr-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm3.982 13.982a1 1 0 0 1-1.414 0l-3.274-3.274A1.012 1.012 0 0 1 9 10V6a1 1 0 0 1 2 0v3.586l2.982 2.982a1 1 0 0 1 0 1.414Z" />
              </svg>
              Pending
            </div>
          </Tooltip>
        );
      case "approved":
        return (
          <Tooltip content="This transaction is approved" placement="right">
            <div className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
              <svg
                className="w-[12px] h-[12px] mr-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              Approved
            </div>
          </Tooltip>
        );
      case "deposit":
        return (
          <Tooltip content="This transaction is a deposit" placement="right">
            <div className="inline-flex items-center bg-indigo-100 text-indigo-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3.5 w-3.5 mr-1"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
                />
              </svg>
              Deposit
            </div>
          </Tooltip>
        );
      case "rejected":
        return (
          <Tooltip
            content="This transaction has been rejected"
            placement="right"
          >
            <div className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
              <svg
                className="w-[12px] h-[12px] mr-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
              Rejected
            </div>
          </Tooltip>
        );
      case "declined":
        return (
          <Tooltip
            content="This transaction has been declined"
            placement="right"
          >
            <div className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
              <svg
                className="w-[12px] h-[12px] mr-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
              Declined
            </div>
          </Tooltip>
        );
      case "withdrawal":
        return (
          <Tooltip content="This transaction is a withdrawal" placement="right">
            <div className="inline-flex items-center bg-yellow-100 text-yellow-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-yellow-600 dark:text-yellow-300">
              <svg
                className="w-[12px] h-[12px] mr-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 15"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                  d="M1 7.5h11m0 0L8 3.786M12 7.5l-4 3.714M12 1h3c.53 0 1.04.196 1.414.544.375.348.586.82.586 1.313v9.286c0 .492-.21.965-.586 1.313A2.081 2.081 0 0 1 15 14h-3"
                />
              </svg>
              Withdrawal
            </div>
          </Tooltip>
        );
      default:
        return (
          <span className="inline-flex items-center rounded-md bg-gray-50 dark:bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-600 dark:text-gray-400 ring-1 ring-inset ring-gray-500/10 dark:ring-gray-400/20">
            {status || "Unknown"}
          </span>
        );
    }
  }

  return (
    <>
      {/* Start block */}
      <section className="bg-gray-50 dark:bg-gray-900 antialiased">
        <div className="mx-auto">
          <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
            <div className="px-4">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-3 md:space-y-0 md:space-x-4 py-3">
                <div>
                  <nav
                    className="mb-2 flex px-3 py-2 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600"
                    aria-label="Breadcrumb"
                  >
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                      <li className="inline-flex items-center">
                        <a
                          href=" "
                          className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-primary-600 dark:text-gray-300 dark:hover:text-white"
                        >
                          Dashboard
                        </a>
                      </li>
                      <li aria-current="page">
                        <div className="flex items-center">
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5 text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                            All Transactions
                          </span>
                        </div>
                      </li>
                    </ol>
                  </nav>
                  <h5>
                    <span className="dark:text-white">All Users</span>
                  </h5>
                </div>
              </div>

              <div className="pb-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 w-full">
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full px-2.5 py-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  onChange={(e) => {
                    if (e.target.value === "transactionStatus") {
                      setFilters({
                        status: {
                          pending: true,
                          approved: true,
                          rejected: true,
                          declined: true,
                        },
                        transactionType: {
                          deposit: true,
                          withdrawal: true,
                        },
                      });
                    } else {
                      handleStatusChange(e.target.value);
                    }
                  }}
                >
                  <option value="transactionStatus">Transaction Status</option>
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                </select>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full px-2.5 py-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  onChange={(e) => {
                    if (e.target.value === "transactionType") {
                      setFilters({
                        status: {
                          pending: true,
                          approved: true,
                          rejected: true,
                          declined: true,
                        },
                        transactionType: {
                          deposit: true,
                          withdrawal: true,
                        },
                      });
                    } else {
                      handleTransactionTypeChange(e.target.value);
                    }
                  }}
                >
                  <option value="transactionType">Transaction Type</option>
                  <option value="deposit">Deposit</option>
                  <option value="withdrawal">Withdrawal</option>
                </select>
              </div>
              <div className="border-t dark:border-gray-700 py-3 block md:flex items-center justify-between w-full">
                <div className="flex flex-wrap">
                  <div className="flex items-center text-sm font-medium text-gray-900 dark:text-white mr-4">
                    Show:
                  </div>
                  <div className="flex items-center mr-4">
                    <input
                      id="all-users"
                      type="radio"
                      defaultValue=""
                      name="show-only"
                      defaultChecked="true"
                      className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      onChange={() => {
                        setFilters({
                          status: {
                            pending: true,
                            approved: true,
                            rejected: true,
                            declined: true,
                          },
                          transactionType: {
                            deposit: true,
                            withdrawal: true,
                          },
                        });
                      }}
                    />
                    <label
                      htmlFor="all-users"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      All
                    </label>
                  </div>
                  <div className="flex items-center mr-4">
                    <input
                      id="sort-role"
                      type="radio"
                      defaultValue=""
                      name="show-only"
                      className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      onChange={() => {
                        setFilters({
                          status: {
                            pending: true,
                            approved: true,
                            rejected: true,
                            declined: true,
                          },
                          transactionType: {
                            deposit: true,
                            withdrawal: false,
                          },
                        });
                      }}
                    />
                    <label
                      htmlFor="sort-role"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Deposits
                    </label>
                  </div>
                  <div className="flex items-center mr-4">
                    <input
                      id="sort-type"
                      type="radio"
                      defaultValue=""
                      name="show-only"
                      className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      onChange={() => {
                        setFilters({
                          status: {
                            pending: true,
                            approved: true,
                            rejected: true,
                            declined: true,
                          },
                          transactionType: {
                            deposit: false,
                            withdrawal: true,
                          },
                        });
                      }}
                    />
                    <label
                      htmlFor="sort-type"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Withdrawals
                    </label>
                  </div>
                  <div className="flex items-center mr-4">
                    <input
                      id="sort-status"
                      type="radio"
                      defaultValue=""
                      name="show-only"
                      className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      onChange={() => {
                        setFilters({
                          status: {
                            pending: true,
                            approved: false,
                            rejected: false,
                            declined: false,
                          },
                          transactionType: {
                            deposit: true,
                            withdrawal: true,
                          },
                        });
                      }}
                    />
                    <label
                      htmlFor="sort-status"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Pending
                    </label>
                  </div>
                  <div className="flex items-center mr-4">
                    <input
                      id="sort-rating"
                      type="radio"
                      defaultValue=""
                      name="show-only"
                      className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      onChange={() => {
                        setFilters({
                          status: {
                            pending: false,
                            approved: true,
                            rejected: false,
                            declined: false,
                          },
                          transactionType: {
                            deposit: true,
                            withdrawal: true,
                          },
                        });
                      }}
                    />
                    <label
                      htmlFor="sort-rating"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Approved
                    </label>
                  </div>
                  <div className="flex items-center mr-4">
                    <input
                      id="sort-rating"
                      type="radio"
                      defaultValue=""
                      name="show-only"
                      className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      onChange={() => {
                        setFilters({
                          status: {
                            pending: false,
                            approved: false,
                            rejected: true,
                            declined: false,
                          },
                          transactionType: {
                            deposit: true,
                            withdrawal: true,
                          },
                        });
                      }}
                    />
                    <label
                      htmlFor="sort-rating"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Rejected
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-4 py-3">
                      Order ID
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Status
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Transaction Type
                    </th>
                    <th scope="col" className="px-4 py-3 whitespace-nowrap">
                      Date
                    </th>

                    <th scope="col" className="px-4 py-3 whitespace-nowrap">
                      Amount
                    </th>

                    <th scope="col" className="px-4 py-3 whitespace-nowrap">
                      User Email
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    Array.from({ length: itemsPerPage }).map((_, index) => (
                      <tr
                        key={index}
                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      >
                        <td className="px-4 py-3">
                          <div className="w-52 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                        </td>
                        <td className="px-4 py-3">
                          <div className="w-28 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                        </td>
                        <td className="px-4 py-3">
                          <div className="w-36 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                        </td>
                        <td className="px-4 py-3">
                          <div className="w-28 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                        </td>
                        <td className="px-4 py-3">
                          <div className="w-36 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                        </td>
                      </tr>
                    ))
                  ) : transactions.length > 0 ? (
                    transactions.map((transaction, transactionIdx) => (
                      <tr
                        key={transaction.orderId}
                        className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                        onClick={() =>
                          navigate(
                            `/broker/dashboard/client/${transaction.uid}`
                          )
                        }
                      >
                        <th
                          scope="row"
                          className="px-4 py-3 font-bold text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          <div className="flex items-center mr-3">
                            {transaction.orderId}
                          </div>
                        </th>

                        <td className="px-4 py-3">
                          <StatusPill status={transaction.status} />
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="flex items-center space-x-1.5">
                            <StatusPill status={transaction.transactionType} />
                          </div>
                        </td>
                        <td className="px-4 py-3 font-bold text-gray-900 whitespace-nowrap dark:text-white">
                          {transaction.timestamp &&
                          transaction.timestamp._seconds
                            ? new Date(
                                transaction.timestamp._seconds * 1000
                              ).toLocaleString(undefined, {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              })
                            : transaction.timestamp || "-"}
                        </td>

                        <td
                          className={`px-4 py-3 whitespace-nowrap ${
                            transaction.currency && transaction.amount
                              ? "font-bold text-gray-900 dark:text-white"
                              : "font-medium text-gray-500 dark:text-gray-400"
                          }`}
                        >
                          {transaction.currency && transaction.amount
                            ? getSymbolFromCurrency(transaction.currency) +
                              transaction.amount.toFixed(2)
                            : "Not available"}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          {transaction.email}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <EmptyState />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <nav
              className="flex flex-row justify-between items-center p-4"
              aria-label="Table navigation"
            >
              <button
                type="button"
                className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              >
                Download CSV
              </button>

              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />

              <p className="text-sm">
                <span className="font-normal text-gray-500 dark:text-gray-400">
                  Total transactions:{" "}
                </span>
                <span className="font-semibold text-gray-900 dark:text-white">
                  {totalItems}
                </span>
              </p>
            </nav>
          </div>
        </div>
      </section>
    </>
  );
}
