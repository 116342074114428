import { Fragment, useRef, useContext, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { useNotification } from "../../../contexts/NotificationContext";
import { UserContext } from "../../../contexts/UserContext";

export default function KYCConfirmModal({ open, onClose, onSubmit }) {
  const { showNotification } = useNotification();
  const { user } = useContext(UserContext);
  const uid = user?.userData?.uid;
  const cancelButtonRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const submitForApproval = async () => {
    setIsLoading(true); // Start loading

    const db = getFirestore();

    // Update kycStatus in the kyc collection
    const kycRef = doc(db, "kyc", uid);

    await setDoc(
      kycRef,
      {
        kycStatus: "pending",
        brokerUID: user.userData.brokerUID,
      },
      { merge: true }
    );

    // Update kycStatus in the users collection
    const userRef = doc(db, "users", uid);
    await setDoc(userRef, { kycStatus: "pending" }, { merge: true });

    showNotification("success", "Documents submitted for approval!");

    setIsLoading(false); // End loading

    // Invoke the callback function passed from the parent
    if (typeof onSubmit === "function") {
      onSubmit();
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white dark:bg-gray-800 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 dark:bg-yellow-400/10 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-yellow-600 dark:text-yellow-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
                      >
                        Submit documents
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Before proceeding, please ensure you've uploaded the
                          following documents:
                          <ul className="list-disc ml-5 mt-2">
                            <li className="mb-2">
                              <span className="text-gray-900 dark:text-white">
                                ID Document:
                              </span>{" "}
                              A valid government-issued identification (e.g.,
                              passport, driver's license).
                            </li>
                            <li className="mb-2">
                              <span className="text-gray-900 dark:text-white">
                                Proof of Address:
                              </span>{" "}
                              A recent bank statement, government
                              correspondence, or utility bill (e.g., water,
                              electricity, gas) dated within the last three
                              months.
                            </li>
                            <li className="mb-2">
                              <span className="text-gray-900 dark:text-white">
                                Selfie with ID Document:
                              </span>{" "}
                              A clear photo of yourself holding the ID document
                              where both your face and the ID are visible.
                            </li>
                          </ul>
                          Ensure all documents are legible and in the accepted
                          format. Incomplete or unclear submissions may delay
                          the approval process.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 dark:bg-white/5 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-3 sm:w-auto"
                    onClick={() => {
                      submitForApproval();
                      onClose();
                    }}
                    disabled={isLoading} // Disable the button when loading
                  >
                    {isLoading ? (
                      <svg
                        className="animate-spin h-5 w-5 mr-3 text-white" // Adjusted size for button
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      "Submit documents"
                    )}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
