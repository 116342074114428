import { useState, useContext, useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../../contexts/NotificationContext";
import { DisplayK4 } from "./DisplayK4";
import { UserContext } from "../../../contexts/UserContext";
import { getFirestore, doc, getDoc } from "firebase/firestore";

export function ExportK4() {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [k4Data, setK4Data] = useState(null);
  const [noTrades, setNoTrades] = useState(false); // State to track no trades scenario
  const { user } = useContext(UserContext);

  const functions = getFunctions();
  const firestore = getFirestore();
  const generateK4Report = httpsCallable(functions, "generateK4Report");

  // Define fetchK4Report function
  const fetchK4Report = async () => {
    const docRef = doc(firestore, "k4reports", user.userData.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("K4 Report Data:", docSnap.data());
      setK4Data(docSnap.data());
    } else {
      console.log("No K4 Report found.");
      setK4Data(null);
    }
  };

  // useEffect to call fetchK4Report on component mount and when dependencies change
  useEffect(() => {
    fetchK4Report();
  }, [user.userData.uid, firestore]);

  const handleGenerateReport = async () => {
    setIsLoading(true);
    try {
      const result = await generateK4Report({ year: 2023 });
      console.log("Report Data:", result.data);
      if (result.data && result.data.success) {
        showNotification("success", result.data.message);
        setNoTrades(false);
        await fetchK4Report(); // Fetch the latest K4 report data after successful generation
      } else {
        if (result.data.message === "No trades found for the given period") {
          showNotification("warning", result.data.message);
          setNoTrades(true);
        } else {
          showNotification("error", "Failed to generate report.");
        }
      }
    } catch (error) {
      console.error("Error generating report:", error);
      showNotification("error", "Error generating report: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (k4Data) {
    return <DisplayK4 pdfUrls={k4Data} />;
  }

  return (
    <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
      <h3 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
        {t("exportK4.title")}
      </h3>
      <p className="mb-4 font-light text-gray-500 dark:text-gray-400">
        {t("exportK4.description")}
      </p>
      <div
        className="p-4 mb-4 text-sm text-left text-orange-700 bg-orange-100 rounded-lg sm:mb-5 dark:bg-gray-700 dark:text-orange-300"
        role="alert"
      >
        <div className="items-center mb-1">
          <svg
            className="inline flex-shrink-0 mr-1 w-4 h-4"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
          <span className="sr-only">Danger</span>
          <span className="font-semibold leading-none">
            {" "}
            {t("exportK4.warning")}
          </span>
        </div>
        <p>{t("exportK4.warningDescription")}</p>
      </div>
      <div>
        <p className="font-light text-gray-500 dark:text-gray-400 mb-6">
          {t("exportK4.functionDescription")}
        </p>
        <ul className="space-y-4 text-left text-gray-500 dark:text-gray-400 mb-6">
          <li className="flex items-center space-x-3 rtl:space-x-reverse">
            <svg
              className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            <span>{t("exportK4.bullet1")}</span>
          </li>
          <li className="flex items-center space-x-3 rtl:space-x-reverse">
            <svg
              className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            <span>{t("exportK4.bullet2")}</span>
          </li>
          <li className="flex items-center space-x-3 rtl:space-x-reverse">
            <svg
              className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            <span>{t("exportK4.bullet3")}</span>
          </li>
        </ul>
      </div>
      <div className="flex justify-center items-center space-x-4">
        <button
          type="button"
          className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-green-600 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-900"
          onClick={handleGenerateReport}
          disabled={isLoading || noTrades}
        >
          {isLoading ? (
            <div className="flex items-center justify-center text-center">
              <svg
                aria-hidden="true"
                className="w-4 h-4 -ml-1 mr-3 text-gray-200 animate-spin dark:text-gray-500 fill-primary-600 dark:fill-white"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              {t("exportK4.loading")}
            </div>
          ) : (
            t("exportK4.ctaButton")
          )}
        </button>
      </div>
      {noTrades && (
        <div className="mt-4 text-center text-sm text-red-500">
          {t("exportK4.noTradesMessage")}{" "}
          {/* Translation key for no trades message */}
        </div>
      )}
    </div>
  );
}
