import ClientAccountDetails from "./ClientAccountDetails";
import ClientAccounts from "./ClientAccounts";
import ClientTransactions from "./ClientTransactions";
import ClientKYCDetails from "./ClientKYCDetails";
import ClientBonuses from "./ClientBonuses";

export default function ClientOverview({
  clientData,
  tradingAccountsData,
  transactionsData,
  bonusData,
}) {
  return (
    <>
      <div className="grid grid-cols-2 gap-4 mb-2">
        <ClientAccountDetails clientData={clientData} />
        <div>
          <div className="mb-4">
            <ClientKYCDetails clientData={clientData} />
          </div>
          <ClientBonuses bonusData={bonusData} />
        </div>
      </div>
      <div className="mb-4">
        <ClientAccounts tradingAccountsData={tradingAccountsData} />
      </div>
      <div className="mb-4">
        <ClientTransactions transactionsData={transactionsData} />
      </div>
    </>
  );
}
