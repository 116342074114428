import { Pagination as FlowbitePagination } from "flowbite-react";

function Pagination({ currentPage, totalPages, onPageChange }) {
  return (
    <div className="flex sm:justify-center">
      <FlowbitePagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
        showIcons
      />
    </div>
  );
}

export default Pagination;
