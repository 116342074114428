import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Table } from "flowbite-react";

export default function ClientBonuses({ bonusData }) {
  console.log("bonusData:", bonusData); // Add this line to log bonusData

  // Ensure bonusData is an array before proceeding
  if (!Array.isArray(bonusData)) {
    return (
      <div className="py-4 text-center relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
        <svg
          className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-700"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <DocumentMagnifyingGlassIcon />
        </svg>
        <h3 className="mt-2 text-sm font-semibold text-gray-900 dark:text-white">
          No bonuses.
        </h3>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          This user has no bonus history yet.
        </p>
      </div>
    );
  }

  // Normalize creditBonus data
  const normalizeCreditBonus = (creditBonus) => {
    // Assuming bonusHistory contains a nested object without a direct key
    if (
      typeof creditBonus.bonusHistory === "object" &&
      creditBonus.bonusHistory !== null &&
      Object.keys(creditBonus.bonusHistory).length > 0 // Ensure there's at least one key
    ) {
      // Access the first object within bonusHistory
      const bonus = Object.values(creditBonus.bonusHistory)[0]; // Access the nested object

      // Proceed with your normalization
      return [
        {
          type: "Credit Bonus",
          activationDate: bonus.activationDate,
          amount: bonus.amount,
          depositThreshold: bonus.depositThreshold,
          isEnabled: bonus.isEnabled,
          additionalInfo: `Type: ${bonus.type}`,
        },
      ];
    } else {
      console.error(
        "Expected bonusHistory to be an object with nested data, but received:",
        creditBonus.bonusHistory
      );
      return []; // Return an empty array to safely continue the operation
    }
  };

  // Normalize protectedPositions data
  const normalizeProtectedPositions = (protectedPositions) => {
    const { activationHistory, bonusSettings } = protectedPositions;

    // Directly use the object if it's not null, otherwise return an empty array
    if (activationHistory !== null) {
      return [
        {
          type: "Protected Position",
          activationDate: activationHistory.activationDate,
          amount: bonusSettings.amount,
          depositThreshold: bonusSettings.depositThreshold,
          isEnabled: bonusSettings.isEnabled,
          additionalInfo: `Used Positions: ${activationHistory.protectedPositionCount}`,
        },
      ];
    } else {
      console.error(
        "activationHistory is null, returning empty array to avoid errors."
      );
      return []; // Return an empty array to safely continue the operation
    }
  };

  // Preprocess and normalize data
  const normalizedData = bonusData.flatMap((bonus) => [
    ...(bonus.creditBonus ? normalizeCreditBonus(bonus.creditBonus) : []),
    ...(bonus.protectedPositions
      ? normalizeProtectedPositions(bonus.protectedPositions)
      : []),
  ]);

  return (
    <div className="overflow-x-auto">
      <Table>
        <Table.Head>
          <Table.HeadCell>Type</Table.HeadCell>
          <Table.HeadCell>Activation Date</Table.HeadCell>
          <Table.HeadCell>Amount</Table.HeadCell>
          <Table.HeadCell>Deposit Threshold</Table.HeadCell>
          <Table.HeadCell>Is Enabled</Table.HeadCell>
          <Table.HeadCell>Additional Info</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {normalizedData.map((item, index) => (
            <Table.Row
              key={index}
              className="bg-white dark:border-gray-700 dark:bg-gray-800"
            >
              <Table.Cell>{item.type}</Table.Cell>
              <Table.Cell>
                {item.activationDate && item.activationDate.seconds
                  ? new Date(item.activationDate.seconds * 1000).toLocaleString(
                      undefined,
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      }
                    )
                  : "N/A"}
              </Table.Cell>
              <Table.Cell>{item.amount}</Table.Cell>
              <Table.Cell>{item.depositThreshold}</Table.Cell>
              <Table.Cell>{item.isEnabled ? "Yes" : "No"}</Table.Cell>
              <Table.Cell>{item.additionalInfo}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
