import { Table } from "flowbite-react";
import { useTranslation } from "react-i18next";

export function DisplayK4({ pdfUrls = [] }) {
  const { t } = useTranslation();

  // Ensure pdfUrls is an array
  if (!Array.isArray(pdfUrls)) {
    if (typeof pdfUrls === "object" && pdfUrls !== null && pdfUrls.pdfUrls) {
      // Convert object values to an array if pdfUrls is an object
      pdfUrls = Object.values(pdfUrls.pdfUrls);
    } else {
      console.error(
        "Expected pdfUrls to be an array or array-like object, but received:",
        pdfUrls
      );
      return <div>Invalid data provided.</div>;
    }
  }

  console.log("Processed pdfUrls:", pdfUrls); // Debugging line to check the output

  return (
    <div className="overflow-x-auto">
      <h3 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
        {t("exportK4.displayTitle")}
      </h3>
      <p className="mb-4 font-light text-gray-500 dark:text-gray-400">
        {t("exportK4.displayDescription")}
      </p>
      <Table>
        <Table.Head>
          <Table.HeadCell>{t("exportK4.page")}</Table.HeadCell>
          <Table.HeadCell>
            <span>{t("exportK4.download")}</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {pdfUrls.map((url, index) => (
            <Table.Row
              key={index}
              className="bg-white dark:border-gray-700 dark:bg-gray-800"
            >
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {t("exportK4.page")} {index + 1}
              </Table.Cell>
              <Table.Cell>
                <div className="flex space-x-2">
                  <svg
                    className="w-[20px] h-[20px] text-blue-600 hover:underline dark:text-blue-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01"
                    />
                  </svg>
                  <a
                    href={url}
                    className="font-medium text-blue-600 hover:underline dark:text-blue-500"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("exportK4.download")} {t("exportK4.page")} {index + 1}
                  </a>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
