import { useState, useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";
import ProfileUpdateForm from "../../Modals/ProfileUpdateForm";
import { Avatar } from "flowbite-react";
import LanguageSelector from "../../Shared/LanguageSelector";
import { useTranslation } from "react-i18next";

export default function UserProfileSettings() {
  const { user } = useContext(UserContext);
  const [isProfileUpdateOpen, setProfileUpdateOpen] = useState(false);
  const [currentFieldName, setCurrentFieldName] = useState("");
  const [currentFieldValue, setCurrentFieldValue] = useState("");
  const { t } = useTranslation();

  const openProfileUpdateModalWithField = (fieldName, fieldValue) => {
    setCurrentFieldName(fieldName);
    setCurrentFieldValue(fieldValue);
    setProfileUpdateOpen(true);
  };

  // Check if user object or userData is null or undefined
  if (!user || !user.userData) {
    return (
      <div className="fixed inset-0 flex items-center justify-center dark:bg-gray-900">
        <svg
          className="animate-spin h-10 w-10 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }

  const { name, surname } = user.userData; // Destructuring name and surname from userData object
  const fullName = `${name} ${surname}`; // Combining name and surname into full name

  return (
    <>
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
          <div>
            <div className="flex items-center">
              <Avatar
                placeholderInitials={name
                  .split(" ")
                  .slice(0, 2) // Limit to the first two elements
                  .map((n) => n[0])
                  .join("")
                  .toUpperCase()}
                rounded
                bordered
                size="md"
                status="online"
              />
              <div className="ml-4">
                <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white text-left">
                  {t("profile.profile")}
                </h2>
                <p className="text-sm leading-6 text-gray-500 text-left">
                  {t("profile.profileDescription")}
                </p>
              </div>
              <div className="flex justify-end w-full">
                <LanguageSelector />
              </div>
            </div>
            <dl className="mt-6 space-y-6 divide-y divide-gray-100 dark:divide-gray-700 border-t border-gray-200 dark:border-gray-700 text-sm leading-6">
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
                  {t("profile.fullName")}
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900 dark:text-gray-400">
                    {fullName}
                  </div>
                  <button
                    type="button"
                    className="font-semibold text-primary-600 hover:text-primary-500"
                    onClick={() =>
                      openProfileUpdateModalWithField("fullName", fullName)
                    }
                  >
                    {t("profile.updateButton")}
                  </button>
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
                  {t("profile.emailAddress")}
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900 dark:text-gray-400">
                    {user.userData.email}
                  </div>
                  <button
                    type="button"
                    className="font-semibold text-primary-600 hover:text-primary-500"
                    onClick={() =>
                      openProfileUpdateModalWithField(
                        "email",
                        user.userData.email
                      )
                    }
                  >
                    {t("profile.updateButton")}
                  </button>
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
                  {t("onboarding.dateOfBirth")}
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900 dark:text-gray-400">
                    {user.userData.dob}
                  </div>
                  <button
                    type="button"
                    className="font-semibold text-primary-600 hover:text-primary-500"
                    onClick={() =>
                      openProfileUpdateModalWithField(
                        "date of birth",
                        user.userData.dob
                      )
                    }
                  >
                    {t("profile.updateButton")}
                  </button>
                </dd>
              </div>
            </dl>
          </div>

          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white text-left">
              {t("profile.addressTitle")}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500 dark:text-gray-500 text-left">
              {t("profile.addressDescription")}
            </p>

            <dl className="mt-6 space-y-6 divide-y divide-gray-100 dark:divide-gray-700 border-t border-gray-200 dark:border-gray-700 text-sm leading-6">
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
                  {t("onboarding.streetName")}
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900 dark:text-gray-400">
                    {user.userData.address}
                  </div>
                  <button
                    type="button"
                    className="font-semibold text-primary-600 hover:text-primary-500"
                    onClick={() =>
                      openProfileUpdateModalWithField(
                        "street name",
                        user.userData.address
                      )
                    }
                  >
                    {t("profile.updateButton")}
                  </button>
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
                  {t("onboarding.city")}
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900 dark:text-gray-400">
                    {user.userData.city}
                  </div>
                  <button
                    type="button"
                    className="font-semibold text-primary-600 hover:text-primary-500"
                    onClick={() =>
                      openProfileUpdateModalWithField(
                        "city",
                        user.userData.city
                      )
                    }
                  >
                    {t("profile.updateButton")}
                  </button>
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
                  {t("onboarding.zipCode")}
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900 dark:text-gray-400">
                    {user.userData.zip_code}
                  </div>
                  <button
                    type="button"
                    className="font-semibold text-primary-600 hover:text-primary-500"
                    onClick={() =>
                      openProfileUpdateModalWithField(
                        "zip code",
                        user.userData.zip_code
                      )
                    }
                  >
                    {t("profile.updateButton")}
                  </button>
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
                  {t("onboarding.country")}
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900 dark:text-gray-400">
                    {user.userData.country}
                  </div>
                  <button
                    type="button"
                    className="font-semibold text-primary-600 hover:text-primary-500"
                    onClick={() =>
                      openProfileUpdateModalWithField(
                        "country",
                        user.userData.country
                      )
                    }
                  >
                    {t("profile.updateButton")}
                  </button>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <ProfileUpdateForm
        open={isProfileUpdateOpen}
        setOpen={setProfileUpdateOpen}
        fieldName={currentFieldName} // Pass the field name here
        fieldValue={currentFieldValue} // Pass the field value here
      />
    </>
  );
}
