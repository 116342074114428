import React, { useContext } from "react";
import { BrokerContext } from "../../../contexts/BrokerContext.js";

export default function ProfileSettings({ openProfileUpdateModalWithField }) {
  const broker = useContext(BrokerContext)?.broker || {};

  // Check if user object or userData is null or undefined
  if (!broker || !broker.userData) {
    return (
      <div className="fixed inset-0 flex items-center justify-center dark:bg-gray-900">
        <svg
          className="animate-spin h-10 w-10 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }

  const { name, surname } = broker?.userData || {};
  const fullName = `${name} ${surname}`;

  return (
    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white text-left">
          Profile
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500 text-left">
          Your profile information and settings.
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 dark:divide-gray-700 border-t border-gray-200 dark:border-gray-700 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              Full name
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 dark:text-gray-400">{fullName}</div>
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() =>
                  openProfileUpdateModalWithField("full name", fullName)
                }
              >
                Update
              </button>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              Email address
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 dark:text-gray-400">
                {broker.userData.email}
              </div>
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() =>
                  openProfileUpdateModalWithField(
                    "email",
                    broker.userData.email
                  )
                }
              >
                Update
              </button>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              Notification email address
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 dark:text-gray-400">
                {broker.userData.notificationEmail || broker.userData.email}
              </div>
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() =>
                  openProfileUpdateModalWithField(
                    "notification email",
                    broker.userData.notificationEmail || broker.userData.email
                  )
                }
              >
                Update
              </button>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              Date of birth
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 dark:text-gray-400">
                {broker.userData.dob}
              </div>
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() =>
                  openProfileUpdateModalWithField(
                    "date of birth",
                    broker.userData.dob
                  )
                }
              >
                Update
              </button>
            </dd>
          </div>
        </dl>
      </div>

      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white text-left">
          Address
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500 dark:text-gray-500 text-left">
          Your address information and settings.
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 dark:divide-gray-700 border-t border-gray-200 dark:border-gray-700 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              Street
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 dark:text-gray-400">
                {broker.userData.address}
              </div>
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() =>
                  openProfileUpdateModalWithField(
                    "street name",
                    broker.userData.address
                  )
                }
              >
                Update
              </button>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              City
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 dark:text-gray-400">
                {broker.userData.city}
              </div>
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() =>
                  openProfileUpdateModalWithField("city", broker.userData.city)
                }
              >
                Update
              </button>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              Zip code
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 dark:text-gray-400">
                {broker.userData.zip_code}
              </div>
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() =>
                  openProfileUpdateModalWithField(
                    "zip code",
                    broker.userData.zip_code
                  )
                }
              >
                Update
              </button>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              Country
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 dark:text-gray-400">
                {broker.userData.country}
              </div>
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() =>
                  openProfileUpdateModalWithField(
                    "country",
                    broker.userData.country
                  )
                }
              >
                Update
              </button>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
