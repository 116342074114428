import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation

const StandalonePraxisIframe = ({
  onLoaded,
  onTransactionApproved,
  onTransactionAlert,
}) => {
  const containerRef = useRef(null);
  const cashierInitializedRef = useRef(false); // To track if cashier is initialized
  const location = useLocation(); // Get the current location

  // Extract redirectUrl from URL parameters
  const queryParams = new URLSearchParams(location.search);
  const redirectUrlParam = queryParams.get("redirectUrl"); // Assuming the parameter name is 'redirectUrl'
  const redirectUrl = redirectUrlParam
    ? `https://compute.praxispay.com/login/${redirectUrlParam}`
    : null;
  console.log("Redirect URL:", redirectUrl); // Log the redirect URL

  const authToken = queryParams.get("authToken"); // Get authToken from URL parameters
  console.log("Auth Token:", authToken); // Log the auth token

  useEffect(() => {
    console.log("useEffect triggered");

    const initializeCashier = () => {
      if (!redirectUrl || !containerRef.current || !authToken) return;
      if (cashierInitializedRef.current) return; // Avoid double initialization

      let cashier = window.PraxisCashier({
        auth_token: authToken,
        container: containerRef.current,
        autoclose: false,
        mode: "iframe",
        locale: "en-GB",
      });

      cashier.on("resize", function (data) {
        let iframe = cashier.getCashierIframe();

        if (iframe) {
          iframe.style.height = data.height + "px";
          iframe.style.minWidth = "100%";
        }
      });

      cashier.on("cashier_loaded", function (data) {
        console.log("Cashier loaded with data:", data);
        if (onLoaded && typeof onLoaded === "function") {
          onLoaded(data);
        }
      });

      // Modified event listener to handle transaction events
      cashier.on("transaction", async function (data) {
        console.log("Transaction event received with data:", data);

        if (data.status === "approved") {
          console.log("Transaction approved:", data.message);
          if (
            onTransactionApproved &&
            typeof onTransactionApproved === "function"
          ) {
            onTransactionApproved(data); // Call the callback with the transaction data for approved transactions
          }
        } else if (
          [
            "pending",
            "declined",
            "rejected",
            "error",
            "cancelled",
            "requested",
          ].includes(data.status)
        ) {
          // Handling only specific statuses: pending, rejected, error
          console.log(
            `Transaction ${data.status}:`,
            data.error_message || "Alert!"
          );
          if (onTransactionAlert && typeof onTransactionAlert === "function") {
            onTransactionAlert(data); // Call the callback with the transaction data for non-approved transactions
          }
        }
      });

      cashier.render();
      cashierInitializedRef.current = true; // Mark cashier as initialized
    };

    // Check if the SDK script is already loaded
    const existingScript = document.querySelector(
      "[src='https://cdn-gateway.praxispay.com/sdk/js/praxis_cashier.v1_3.js']"
    );

    if (!existingScript) {
      const script = document.createElement("script");
      script.src =
        "https://cdn-gateway.praxispay.com/sdk/js/praxis_cashier.v1_3.js";
      script.async = true;
      console.log("About to append the SDK script...");
      document.body.appendChild(script);
      console.log("SDK script appended!");

      // Listen for script load and then initialize the SDK
      script.onload = initializeCashier;

      return () => {
        // Cleanup to prevent multiple script tags on component remounts
        document.body.removeChild(script);
      };
    } else {
      // If script is already loaded, check if iframe exists before initializing the SDK
      if (
        redirectUrl &&
        containerRef.current &&
        authToken &&
        !containerRef.current.querySelector("iframe") &&
        !cashierInitializedRef.current // Only initialize if not done already
      ) {
        initializeCashier();
      }
    }
  }, [
    redirectUrl,
    authToken,
    onLoaded,
    onTransactionApproved,
    onTransactionAlert,
    location, // Add location to the dependency array
  ]);

  return (
    <div
      ref={containerRef}
      style={{ width: "100%", visibility: "visible" }}
    ></div>
  );
};

export default StandalonePraxisIframe;
