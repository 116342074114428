import React, { useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from "firebase/auth";
import { auth } from "../../../firebase";
import { useNotification } from "../../../contexts/NotificationContext";
import { handleFirebaseError } from "../../../utils/handleFirebaseError";

function validateStrongPassword(password) {
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password);

  return (
    password.length >= 8 &&
    hasUpperCase &&
    hasLowerCase &&
    hasNumber &&
    hasSpecialChar
  );
}

export default function UserChangePassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    password: null,
    confirmPassword: null,
  });
  const { showNotification } = useNotification();
  const [currentPassword, setCurrentPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const reauthenticate = async (currentPassword) => {
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    await reauthenticateWithCredential(user, credential);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let passwordError = null;
    let confirmPasswordError = null;

    if (!validateStrongPassword(password)) {
      passwordError = "Not a valid password.";
    }

    if (password !== confirmPassword) {
      confirmPasswordError = "Passwords do not match.";
    }

    if (!passwordError && !confirmPasswordError) {
      try {
        await reauthenticate(currentPassword); // Reauthenticate the user
        const user = auth.currentUser;
        await updatePassword(user, password);
        showNotification("success", "Password updated successfully");
      } catch (error) {
        // Use the handleFirebaseError function to get a user-friendly error message
        const errorMessage = handleFirebaseError(error.code);
        showNotification("error", errorMessage); // Display the user-friendly error message
      }
    }
    setIsLoading(false);

    setErrors({
      password: passwordError,
      confirmPassword: confirmPasswordError,
    });
  };

  return (
    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-xl">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white text-left">
          Change password
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500 text-left">
          Enter your new password below in order to change your account's
          password.
        </p>
        <label
          htmlFor="currentPassword"
          className="mt-4 block text-sm font-medium leading-6 text-gray-900 dark:text-white"
        >
          Current Password
        </label>
        <div className="relative mt-2 rounded-md shadow-sm">
          <input
            type="password"
            name="currentPassword"
            id="currentPassword"
            onChange={(e) => setCurrentPassword(e.target.value)}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
            placeholder="Enter your current password"
          />
        </div>
        <label
          htmlFor="password"
          className="mt-4 block text-sm font-medium leading-6 text-gray-900 dark:text-white"
        >
          New password
        </label>
        <div className="relative mt-2 rounded-md shadow-sm">
          <input
            type="password"
            name="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            className={`block w-full rounded-md py-1.5 pr-10 ${
              errors.password
                ? "ring-1 ring-red-300 dark:ring-red-400 text-red-900 dark:text-red-400 dark:bg-red-400/10"
                : "block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
            }`} // Add conditional styling
            placeholder="Enter your new password"
          />
          {errors.password && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {errors.password && (
          <p className="mt-2 text-sm text-red-600" id="password-error">
            {errors.password}
          </p>
        )}

        <label
          htmlFor="confirmPassword"
          className="mt-4 block text-sm font-medium leading-6 text-gray-900 dark:text-white"
        >
          Confirm Password
        </label>
        <div className="relative mt-2 rounded-md shadow-sm">
          <input
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            onChange={(e) => setConfirmPassword(e.target.value)}
            className={`block w-full rounded-md py-1.5 pr-10 ${
              errors.password
                ? "ring-1 ring-red-300 dark:ring-red-400 text-red-900 dark:bg-red-400/10"
                : "block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
            }`} // Add conditional styling
            placeholder="Confirm your new password"
          />
          {errors.password && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {errors.confirmPassword && (
          <p className="mt-2 text-sm text-red-600" id="confirmPassword-error">
            {errors.confirmPassword}
          </p>
        )}
        <button
          type="button"
          onClick={handleSubmit}
          className="rounded-md bg-primary-600 mt-4 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
        >
          {isLoading ? ( // If loading, show spinner and text, else show "Change password"
            <div className="flex items-center">
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Changing password...
            </div>
          ) : (
            "Change password"
          )}
        </button>
      </div>
    </div>
  );
}
