import { useState } from "react";
import countries from "i18n-iso-countries";
import english from "i18n-iso-countries/langs/en.json";
import ReactCountryFlag from "react-country-flag";
import { useLanguage } from "../../contexts/LanguageContext";

countries.registerLocale(english);

export default function LanguageSelector() {
  const { language, changeLanguage } = useLanguage();
  const [query] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to control dropdown visibility

  const languages = [
    { code: "en", name: "English" },
    { code: "sv", name: "Swedish" },
    { code: "pl", name: "Polish" },
  ];

  const filteredLanguages = query
    ? languages.filter((lang) =>
        lang.name.toLowerCase().includes(query.toLowerCase())
      )
    : languages;

  const handleLanguageChange = (lang) => {
    changeLanguage(lang.code);
    setDropdownOpen(false); // Close the dropdown after selection
  };

  const languageToCountryCode = {
    en: "GB", // Assuming you want to show the UK flag for English
    sv: "SE",
    pl: "PL",
  };

  const currentLanguage = languages.find((lang) => lang.code === language);

  return (
    <div className="relative flex items-center md:order-2 space-x-1 md:space-x-0 rtl:space-x-reverse">
      <button
        type="button"
        data-dropdown-toggle="language-dropdown-menu"
        className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
        onClick={() => setDropdownOpen(!dropdownOpen)} // Toggle dropdown visibility
      >
        {currentLanguage ? (
          <>
            <div className="flex items-center">
              <ReactCountryFlag
                countryCode={languageToCountryCode[currentLanguage.code]}
                svg
                style={{
                  width: "1.25em",
                  height: "1.25em",
                  marginRight: "0.75rem",
                }}
                title={currentLanguage.code}
              />

              {currentLanguage.name}
              <svg
                className="w-4 h-4 ml-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </>
        ) : (
          "Select Language"
        )}
      </button>
      {/* Dropdown */}
      {dropdownOpen && (
        <div
          className="absolute z-50 mt-2 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 w-full"
          style={{ top: "100%" }} // Centering the dropdown
        >
          <ul className="py-2 font-medium">
            {filteredLanguages.map((lang) => (
              <li key={lang.code}>
                <button
                  onClick={() => handleLanguageChange(lang)}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                >
                  <div className="inline-flex items-center">
                    <ReactCountryFlag
                      countryCode={languageToCountryCode[lang.code]}
                      svg
                      style={{
                        width: "1.25em",
                        height: "1.25em",
                        marginRight: "0.75rem",
                      }}
                      title={lang.code}
                    />
                    {lang.name}
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
