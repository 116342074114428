import {
  Fragment,
  useRef,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { UserContext } from "../../contexts/UserContext.js";
import { useNotification } from "../../contexts/NotificationContext.js";
import { functions, auth } from "../../firebase.js";
import { httpsCallable } from "firebase/functions";
import { useLanguage } from "../../contexts/LanguageContext.js";
import KYCInfoModal from "./KYCModals/KYCInfoModal.js";
import { CustomWithdrawalCashier } from "./PSPModals/CustomWithdrawalCashier/index.js";
import { useTranslation } from "react-i18next";
import { Label, Select } from "flowbite-react";

export default function WithdrawalModal({ open, setOpen, onCloseModal }) {
  const cancelButtonRef = useRef(null);
  const { showNotification } = useNotification(); // Use the notification hook
  const { user } = useContext(UserContext);
  const { language } = useLanguage();
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showKYCInfo, setShowKYCInfo] = useState(false);
  const [showCustomCashier, setShowCustomCashier] = useState(false);
  const [availableBalance, setAvailableBalance] = useState(null);
  const [isLoadingBalance, setIsLoadingBalance] = useState(false);
  const { t } = useTranslation();

  const handleWithdrawal = useCallback(async () => {
    if (
      user.userData.kycStatus !== "approved_kyc" &&
      !user.userData.allowWithdrawalBeforeKYC
    ) {
      setShowKYCInfo(true);
      return;
    }
    setShowCustomCashier(true);
  }, [user]);

  useEffect(() => {
    // Set the selectedAccountId if modal is opened.
    // If there's only one trading account, it gets selected.
    // If there are multiple trading accounts, the first one gets preselected.
    if (
      open &&
      user &&
      user.accountData &&
      user.accountData.trading_accounts &&
      user.accountData.trading_accounts.length > 0
    ) {
      setSelectedAccountId(user.accountData.trading_accounts[0].id_account);
    }
  }, [open, user]);

  const accounts =
    user && user.accountData && user.accountData.trading_accounts
      ? user.accountData.trading_accounts.filter(
          (account) => account.accountType !== "Demo"
        )
      : [];

  useEffect(() => {
    if (!open) {
      setShowKYCInfo(false);
    }
  }, [open]);

  const closeAllModals = useCallback(() => {
    // Close the deposit modal
    setOpen(false);
    // Close the KYCInfo modal
    setShowKYCInfo(false);
    // ... any other modals you have or might add in the future

    // If there's any other cleanup you want to do on modal close, add it here
  }, [setOpen]);

  const handleCustomWithdrawal = async (withdrawalData) => {
    try {
      setIsLoading(true);

      // Define the callable function from Firebase
      const routePSPWithdrawalRequest = httpsCallable(
        functions,
        "routePSPWithdrawalRequest"
      );

      // Collect required data
      const requestData = {
        uid: auth.currentUser.uid,
        brokerUID: user.userData.brokerUID,
        userLogin: selectedAccountId,
        language: language,
        amount: withdrawalData.amount,
        iban: withdrawalData.iban,
      };

      // Make the call
      const response = await routePSPWithdrawalRequest(requestData);

      console.log("Firebase function response:", response.data);

      // Handle the response accordingly
      if (response.data.success) {
        showNotification(
          "success",
          "Withdrawal request submitted successfully"
        );
        setOpen(false);
      } else {
        showNotification(
          "error",
          response.data.message || "Failed to submit withdrawal request"
        );
      }
    } catch (error) {
      console.error("Error initiating withdrawal:", error);
      showNotification("error", "Failed to initiate withdrawal.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAvailableBalance = useCallback(async () => {
    if (selectedAccountId) {
      setIsLoadingBalance(true);
      try {
        const routePSPWithdrawalRequest = httpsCallable(
          functions,
          "routePSPWithdrawalRequest"
        );

        const requestData = {
          uid: auth.currentUser.uid,
          brokerUID: user.userData.brokerUID,
          userLogin: selectedAccountId,
          language: language,
          selectedMethod: "custom",
        };

        const response = await routePSPWithdrawalRequest(requestData);

        if (response.data.availableBalance !== undefined) {
          setAvailableBalance(response.data.availableBalance);
        } else {
          console.error("Available balance not returned:", response.data);
          showNotification("error", "Failed to fetch available balance.");
        }
      } catch (error) {
        console.error("Error fetching available balance:", error);
        showNotification("error", "Failed to fetch available balance.");
      } finally {
        setIsLoadingBalance(false);
      }
    }
  }, [selectedAccountId, user, language, showNotification]);

  useEffect(() => {
    if (open && selectedAccountId && availableBalance === null) {
      fetchAvailableBalance();
    }
  }, [open, selectedAccountId, availableBalance, fetchAvailableBalance]);

  return showKYCInfo ? (
    <KYCInfoModal
      open={showKYCInfo}
      setOpen={setShowKYCInfo}
      onCloseModal={closeAllModals}
      // ... other props
    />
  ) : (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                {showCustomCashier ? (
                  <CustomWithdrawalCashier
                    onClose={() => setOpen(false)}
                    isLoading={isLoading}
                    isLoadingBalance={isLoadingBalance}
                    availableBalance={availableBalance}
                    accounts={accounts}
                    selectedAccountId={selectedAccountId}
                    setSelectedAccountId={setSelectedAccountId}
                    handleWithdrawal={handleCustomWithdrawal}
                    showNotification={showNotification}
                    user={user} // Add this line to pass the user prop
                    language={language} // Add this line to pass the language prop
                  />
                ) : (
                  <>
                    <div className="bg-white dark:bg-gray-900 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
                          >
                            {t("withdrawalModal.title")}
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                              {t("withdrawalModal.selectAccountMessage")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="mb-2 block">
                          <Label
                            htmlFor="account"
                            value={t("withdrawalModal.account")}
                          />
                        </div>
                        <Select
                          id="account"
                          required
                          value={selectedAccountId || ""}
                          onChange={(e) => setSelectedAccountId(e.target.value)}
                        >
                          {accounts.map((account) => (
                            <option
                              key={account.id_account}
                              value={account.id_account}
                            >
                              {t("withdrawalModal.accountId", {
                                id: account.login,
                              })}{" "}
                              - {account.currency}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                        onClick={handleWithdrawal}
                      >
                        {t("withdrawalModal.withdraw")}
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white dark:bg-white/10 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:bg-gray-50 dark:hover:bg-white/20 sm:mt-0 sm:w-auto"
                        onClick={() => setOpen(false)}
                      >
                        {t("withdrawalModal.cancel")}
                      </button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
