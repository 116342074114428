import Spinner from "../Spinner";
import { Tooltip } from "flowbite-react";
import UpdateUserModal from "../../Modals/BrokerModals/UpdateUserModal";
import { useState } from "react";
import { Avatar } from "flowbite-react";

export default function ClientAccountDetails({ clientData }) {
  const [openModal, setOpenModal] = useState(false);

  if (!clientData) {
    return <Spinner />;
  }

  let formattedDate = clientData.ftdDate
    ? clientData.ftdDate.toDate().toLocaleDateString()
    : "No deposit yet";

  function renderDivBasedOnClientData(client) {
    if (client.kycStatus === "approved_kyc") {
      return (
        <div
          data-tooltip-target="tooltip-role-1"
          className="inline-flex items-center bg-primary-100 text-primary-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-primary-900 dark:text-primary-300"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3.5 w-3.5 mr-1"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
            />
          </svg>
          Verified
        </div>
      );
    } else if (client.ftdDate) {
      return (
        <div
          data-tooltip-target="tooltip-role-1"
          className="inline-flex items-center bg-indigo-100 text-indigo-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3.5 w-3.5 mr-1"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
            />
          </svg>
          FTD
        </div>
      );
    } else {
      return (
        <div
          data-tooltip-target="tooltip-role-1"
          className="inline-flex items-center bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3.5 w-3.5 mr-1"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
          Unverified
        </div>
      );
    }
  }

  return (
    <>
      <div className="bg-white shadow-md dark:bg-gray-800 sm:rounded-lg mb-2">
        <div className="py-4 mx-auto">
          <div className="grid gap-4 px-4 mb-4 sm:mb-5 sm:grid-cols-3 sm:gap-6 md:gap-12">
            {/* Column */}
            <div className="sm:col-span-2">
              <div className="flex items-center space-x-4">
                <div className="mb-4">
                  <Avatar
                    placeholderInitials={clientData.fullName
                      .split(" ")
                      .slice(0, 2) // Limit to the first two elements
                      .map((n) => n[0])
                      .join("")
                      .toUpperCase()}
                    rounded
                    bordered
                    size="lg"
                    className="text-2xl"
                  />
                </div>

                <div>
                  <h2 className="flex items-center mb-2 text-xl font-bold leading-none text-gray-900 sm:text-2xl dark:text-white">
                    {clientData.name} {clientData.surname}
                    <span className="bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300 uppercase ml-2.5">
                      {clientData.live_id_person && clientData.demo_id_person
                        ? "Real, Demo"
                        : clientData.live_id_person
                        ? "Real"
                        : clientData.demo_id_person
                        ? "Demo"
                        : ""}
                    </span>
                  </h2>
                  {renderDivBasedOnClientData(clientData)}
                </div>
              </div>
              <dl className="mb-4">
                <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
                  Phone Number
                </dt>
                <dd className="flex items-center mb-4 text-gray-900 sm:mb-5 dark:text-white">
                  <svg
                    className="w-4 h-4 mr-1.5 text-gray-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 19 18"
                  >
                    <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                  </svg>

                  <span className="font-light text-gray-500 dark:text-gray-400">
                    {clientData.phone}
                  </span>
                </dd>
                <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
                  Email
                </dt>
                <dd className="flex items-center text-gray-900 dark:text-white">
                  <svg
                    className="w-4 h-4 mr-1.5 text-gray-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 18 20"
                  >
                    <path d="M16 0H4a2 2 0 0 0-2 2v1H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4.5a3 3 0 1 1 0 6 3 3 0 0 1 0-6ZM13.929 17H7.071a.5.5 0 0 1-.5-.5 3.935 3.935 0 1 1 7.858 0 .5.5 0 0 1-.5.5Z" />
                  </svg>

                  <span className="font-light text-gray-500 dark:text-gray-400">
                    {clientData.email}
                  </span>
                </dd>
              </dl>
              <dl>
                <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
                  Registered Address
                </dt>
                <dd className="flex items-center mb-4 text-gray-900 sm:mb-5 dark:text-white">
                  <svg
                    className="w-4 h-4 mr-1.5 text-gray-400 dark:text-gray-500"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="font-light text-gray-500 dark:text-gray-400">
                    {clientData.address}, {clientData.city}, {clientData.state},{" "}
                    {clientData.zip_code}, {clientData.country}
                  </span>
                </dd>
                <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
                  Date of Birth
                </dt>
                <dd className="flex items-center text-gray-900 dark:text-white">
                  <svg
                    className="w-4 h-4 mr-1.5 text-gray-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 14 18"
                  >
                    <path d="M7 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm2 1H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
                  </svg>

                  <span className="font-light text-gray-500 dark:text-gray-400">
                    {clientData.dob}
                  </span>
                </dd>
              </dl>
            </div>
            {/* Column */}
            <dl>
              <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
                User ID
                <button type="button" className="ml-2">
                  <Tooltip content="System-generated unique identifier for each user">
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4 text-gray-400 hover:text-gray-900 dark:text-gray-500 dark:hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Tooltip>
                </button>
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5 dark:text-gray-400 break-all w-full max-w-[200px]">
                {clientData.uid}
              </dd>
              <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
                Campaign ID
                <button type="button" className="ml-2">
                  <Tooltip content="Identifier for the campaign through which the user signed up">
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4 text-gray-400 hover:text-gray-900 dark:text-gray-500 dark:hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Tooltip>
                </button>
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5 dark:text-gray-400 break-all w-full max-w-[200px]">
                {clientData.campaignId ? clientData.campaignId : "No campaign"}
              </dd>
              <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
                FTD Date
                <button type="button" className="ml-2">
                  <Tooltip content="The date when the user made their first deposit">
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4 text-gray-400 hover:text-gray-900 dark:text-gray-500 dark:hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Tooltip>
                </button>
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5 dark:text-gray-400">
                {formattedDate}
              </dd>
              <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
                KYC Status
                <button type="button" className="ml-2">
                  <Tooltip content="Status of the user's Know Your Customer (KYC) verification">
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4 text-gray-400 hover:text-gray-900 dark:text-gray-500 dark:hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Tooltip>
                </button>
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5 dark:text-gray-400">
                {clientData.kycStatus === "approved_kyc"
                  ? "Approved"
                  : "Unverified"}
              </dd>
            </dl>
          </div>
          <div className="flex items-center justify-end px-4 space-x-4">
            <button
              type="button"
              className="text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              onClick={() => setOpenModal(true)}
            >
              <svg
                className="mr-1 -ml-1 w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4.109 17H1v-2a4 4 0 0 1 4-4h.87M10 4.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm7.95 2.55a2 2 0 0 1 0 2.829l-6.364 6.364-3.536.707.707-3.536 6.364-6.364a2 2 0 0 1 2.829 0Z"
                />
              </svg>
              Update user information
            </button>
          </div>
        </div>
      </div>

      <UpdateUserModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        clientData={clientData}
      />
    </>
  );
}
