import { Fragment, useRef, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { UserContext } from "../../contexts/UserContext.js";
import countries from "i18n-iso-countries";
import english from "i18n-iso-countries/langs/en.json";
import { useNotification } from "../../contexts/NotificationContext.js";
import { httpsCallable, getFunctions } from "firebase/functions";

countries.registerLocale(english);

const functions = getFunctions();
const countryOptions = Object.entries(countries.getNames("en")).map(
  ([code, name]) => (
    <option key={code} value={name}>
      {name}
    </option>
  )
);

const getInputType = (field) => {
  switch (field) {
    case "email":
      return "email";
    case "full name":
      return "text";
    case "date of birth":
      return "date";
    default:
      return "text"; // Default to 'text' type
  }
};

export default function ProfileUpdateForm({
  open,
  setOpen,
  fieldName,
  fieldValue,
}) {
  const { user } = useContext(UserContext);
  const [inputValue, setInputValue] = useState(fieldValue || "");
  const cancelButtonRef = useRef(null);
  const inputType = getInputType(fieldName);
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const today = new Date();
  const maxDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  )
    .toISOString()
    .split("T")[0];

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);
    try {
      // Close the modal
      setOpen(false);

      // Get the Firestore reference
      const db = getFirestore();
      const docRef = doc(db, "users", user.userData.uid);

      // Initialize an object to hold the updates
      const updates = {};

      switch (fieldName) {
        case "fullName": // Change this line
          const parts = inputValue.split(" ");
          const name = parts[0];
          const surname = parts.slice(1).join(" ");
          updates.name = name;
          updates.surname = surname;
          updates.fullName = inputValue;
          updates.fullNameLower = inputValue.toLowerCase();
          break;

        case "email":
          // Handle email update via cloud function
          const updateEmailFunction = httpsCallable(functions, "updateEmail");
          await updateEmailFunction({ newEmail: inputValue });
          updates["email"] = inputValue;
          break;

        case "date of birth":
          updates.dob = inputValue;
          break;

        case "street name":
          updates.address = inputValue;
          break;

        case "city":
          updates.city = inputValue;
          break;

        case "zip code":
          updates.zip_code = inputValue;
          break;

        case "country":
          updates.country = inputValue;
          break;
        default:
          // Handle the generic case
          updates[fieldName] = inputValue;
      }

      // Update the Firestore document with the updates object
      await updateDoc(docRef, updates);

      // Reload the page to reflect the changes
      window.location.reload();

      // Handle success here...
      console.log("Document successfully updated!");

      showNotification("success", "Updated information.");
    } catch (error) {
      // Handle errors here...
      console.error("Error updating document:", error);
      showNotification("error", "Error updating document!");
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 dark:bg-gray-900 dark:bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg">
                <div className="bg-white dark:bg-gray-800 px-4 pb-4 pt-5 sm:p-4 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:m-2 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
                      >
                        {`Update your ${fieldName
                          .replace(/([A-Z])/g, " $1")
                          .trim()}`}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          {`Change the ${fieldName
                            .replace(/([A-Z])/g, " $1")
                            .trim()} you want associated with your account.`}
                        </p>
                      </div>

                      <div className="mt-2">
                        {fieldName === "date of birth" ? (
                          <input
                            id="date-of-birth"
                            name="dob"
                            required
                            type="date"
                            autoComplete="bday"
                            max={maxDate}
                            value={inputValue}
                            onChange={handleChange}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                          />
                        ) : fieldName === "country" ? (
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="country"
                              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                            >
                              Country
                            </label>
                            <div className="mt-2">
                              <select
                                id="country"
                                name="country"
                                autoComplete="country-name"
                                value={inputValue}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:max-w-xs sm:text-sm sm:leading-6"
                              >
                                <option value="" disabled>
                                  Select a country
                                </option>
                                {countryOptions}
                              </select>
                            </div>
                          </div>
                        ) : (
                          <input
                            type={inputType}
                            name={fieldName}
                            id={fieldName}
                            value={inputValue}
                            onChange={handleChange}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 dark:placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-gray-900"
                            placeholder={fieldValue}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 dark:bg-white/5 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className={`text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800 ${
                      isLoading || !inputValue.trim()
                        ? "cursor-not-allowed"
                        : ""
                    }`}
                    onClick={handleSaveChanges}
                    disabled={isLoading || !inputValue.trim()}
                  >
                    {isLoading ? (
                      <div className="flex items-center">
                        <svg
                          aria-hidden="true"
                          className="w-4 h-4 -ml-1 mr-3 text-gray-200 animate-spin dark:text-gray-500 fill-primary-600 dark:fill-white"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        Saving changes...
                      </div>
                    ) : (
                      "Save changes"
                    )}
                  </button>
                  <button
                    type="button"
                    className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
