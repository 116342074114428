import React, { createContext, useState, useRef, useContext } from "react";

export const NotificationContext = createContext();

export const useNotification = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const timeoutRef = useRef(null);

  const showNotification = (type, message) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setNotification({ type, message });
    setIsVisible(true);

    timeoutRef.current = setTimeout(() => {
      setIsVisible(false);
    }, 5000);
  };

  const hideNotification = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setIsVisible(false);

    timeoutRef.current = setTimeout(() => {
      setNotification(null);
    }, 200); // Adjust this value to match the duration of your exit animation
  };

  return (
    <NotificationContext.Provider
      value={{ notification, isVisible, showNotification, hideNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
