import {
  CreditCardIcon,
  DocumentTextIcon,
  UserIcon,
  WalletIcon,
  SparklesIcon,
} from "@heroicons/react/20/solid";

const tabs = [
  { name: "Overview", icon: UserIcon, current: true },
  { name: "Account details", icon: UserIcon, current: false },
  { name: "Trading accounts", icon: WalletIcon, current: false },
  { name: "Transactions", icon: CreditCardIcon, current: false },
  { name: "KYC", icon: DocumentTextIcon, current: false },
  { name: "Bonus", icon: SparklesIcon, current: false },
];

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export default function ClientTabs({ activeTab, setActiveTab, clientData }) {
  return (
    <section className="flex items-center  bg-gray-50 dark:bg-gray-900">
      <div className="w-full mx-auto mb-4">
        {/* Start coding here */}
        <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <div className="px-4 divide-y dark:divide-gray-700">
            <div className="flex flex-col py-3 space-y-3 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-4">
              <div>
                <h5 className="mb-1 font-semibold dark:text-white">
                  {clientData
                    ? `${clientData.name} ${clientData.surname}`
                    : "Loading..."}
                </h5>
                <div className="flex items-center text-gray-500 dark:text-gray-400">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 mr-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="text-sm">
                    {clientData && clientData.city
                      ? clientData.city
                      : "Loading..."}
                    ,
                    {clientData && clientData.country
                      ? " " + toTitleCase(clientData.country)
                      : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col py-4 space-y-3 md:flex-row md:items-center md:justify-between md:space-y-0 md:py-0">
              <select
                id="list-navigation"
                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg md:hidden bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                value={activeTab}
                onChange={(e) => setActiveTab(e.target.value)}
              >
                {tabs.map((tab, index) => (
                  <option key={index} value={tab.name}>
                    {tab.name}
                  </option>
                ))}
              </select>
              <ul className="flex-wrap hidden text-sm font-medium text-center text-gray-500 md:flex dark:text-gray-400">
                {tabs.map((tab, index) => (
                  <li key={index} className="mr-2">
                    <button
                      className={`inline-flex items-center py-4 pr-4 border-b border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${
                        activeTab === tab.name
                          ? "text-primary-600 border-primary-600 active dark:text-white dark:border-white"
                          : ""
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab(tab.name);
                      }}
                    >
                      <tab.icon
                        className={`w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300 ${
                          activeTab === tab.name
                            ? "text-primary-600 border-primary-600 active dark:text-white dark:white"
                            : ""
                        }`}
                      />
                      {tab.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
