import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { SparklesIcon } from "@heroicons/react/24/outline";
import {
  ClipboardIcon,
  UserIcon,
  LockClosedIcon,
} from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

export default function WelcomeModal({ show, data }) {
  const [open, setOpen] = useState(true);
  const [copyUsernameText, setCopyUsernameText] = useState("Copy");
  const [copyPasswordText, setCopyPasswordText] = useState("Copy");
  const { t } = useTranslation();

  const copyToClipboard = (text, setText) => {
    navigator.clipboard.writeText(text);
    setText("Copied!");
    setTimeout(() => setText("Copy"), 2000); // Change back to 'Copy' after 2 seconds
  };

  // Update the modal visibility when the `show` prop changes
  useEffect(() => {
    setOpen(show);
  }, [show]);

  const cancelButtonRef = useRef(null);

  return (
    show && ( // Only render the modal if show is true
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 dark:bg-gray-800 bg-opacity-75 dark:bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-900 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-primary-100 dark:bg-primary-500/10">
                      <SparklesIcon
                        className="h-6 w-6 text-primary-600 dark:text-primary-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
                      >
                        {t("welcomeModal.modalTitle")}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          {t("welcomeModal.modalDescription")}
                        </p>
                      </div>

                      <div className="mt-6 flex justify-center items-center rounded-md shadow-sm max-w-sm mx-auto">
                        <div className="relative flex flex-grow items-stretch focus-within:z-50">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <UserIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                          <input
                            type="text"
                            name="username"
                            id="username"
                            value={data.username} // Set the value to the passed username
                            readOnly // Make the input read-only
                            disabled
                            className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 dark:bg-white/5 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                        <button
                          type="button"
                          className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold dark:bg-white/5 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-white/10 hover:bg-gray-50 dark:hover:bg-white/10"
                          onClick={() =>
                            copyToClipboard(data.username, setCopyUsernameText)
                          }
                        >
                          <ClipboardIcon
                            className="-ml-0.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          {copyUsernameText}
                        </button>
                      </div>
                      <div className="mt-2 flex justify-center items-center rounded-md shadow-sm max-w-sm mx-auto">
                        <div className="relative flex flex-grow items-stretch focus-within:z-50">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <LockClosedIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                          <input
                            type="text"
                            name="password"
                            id="password"
                            value={data.password} // Set the value to the passed username
                            readOnly // Make the input read-only
                            disabled
                            className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 dark:bg-white/5 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                        <button
                          type="button"
                          className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold dark:bg-white/5 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-white/10 hover:bg-gray-50 dark:hover:bg-white/10"
                          onClick={() =>
                            copyToClipboard(data.password, setCopyPasswordText)
                          }
                        >
                          <ClipboardIcon
                            className="-ml-0.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          {copyPasswordText}
                        </button>
                      </div>
                      <div className="mt-6">
                        <p className="text-sm text-gray-400 dark:text-gray-500">
                          {t("welcomeModal.modalInfoText")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                      onClick={() => setOpen(false)}
                    >
                      {t("welcomeModal.modalButton")}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  );
}
