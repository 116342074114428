import getSymbolFromCurrency from "currency-symbol-map";
import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";

const TimelineCard = ({ tierData }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full max-w-sm rounded-lg bg-white p-4 shadow md:p-6 dark:bg-gray-800">
      <div>
        <h5 className="pb-2 text-3xl font-bold leading-none text-gray-900 dark:text-white">
          {t("ibTimelineCard.title")}
        </h5>
        <p className="mb-2 max-w-xl text-gray-500 dark:text-gray-400">
          {t("ibTimelineCard.description")}
        </p>
      </div>
      <div className="mt-8 ml-4">
        <ol className="relative text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400">
          {tierData?.cpa?.enabled && tierData?.cpa?.fixedCommission > 0 && (
            <li className="mb-10 ms-6">
              <Tooltip content={t("ibTimelineCard.tooltipFixedCommission")}>
                <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg
                    className="w-5 h-5 text-green-500 dark:text-green-400"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM17.2071 9.70711C17.5976 9.31658 17.5976 8.68342 17.2071 8.29289C16.8166 7.90237 16.1834 7.90237 15.7929 8.29289L10.5 13.5858L8.20711 11.2929C7.81658 10.9024 7.18342 10.9024 6.79289 11.2929C6.40237 11.6834 6.40237 12.3166 6.79289 12.7071L9.79289 15.7071C10.1834 16.0976 10.8166 16.0976 11.2071 15.7071L17.2071 9.70711Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <div className="ml-2">
                  <h3 className="font-medium leading-tight pb-1">
                    {getSymbolFromCurrency(tierData.currency)}
                    {tierData.cpa.fixedCommission}{" "}
                    {t("ibTimelineCard.titleFixedCommission")}
                  </h3>
                  <p className="text-sm">
                    {t("ibTimelineCard.descriptionFixedCommission")}
                  </p>
                </div>
              </Tooltip>
            </li>
          )}
          {tierData?.volume?.enabled && (
            <>
              {tierData?.volume?.fixedLotCommission > 0 && (
                <li className="mb-10 ms-6">
                  <Tooltip content={t("ibTimelineCard.tooltipLotCommission")}>
                    <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                      <svg
                        className="w-5 h-5 text-green-500 dark:text-green-400"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM17 7C17 6.44772 16.5523 6 16 6C15.4477 6 15 6.44772 15 7V17C15 17.5523 15.4477 18 16 18C16.5523 18 17 17.5523 17 17V7ZM12 10C12.5523 10 13 10.4477 13 11V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V11C11 10.4477 11.4477 10 12 10ZM8 14C8.55228 14 9 14.4477 9 15V17C9 17.5523 8.55228 18 8 18C7.44772 18 7 17.5523 7 17V15C7 14.4477 7.44772 14 8 14Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <div className="ml-2">
                      <h3 className="font-medium leading-tight pb-1">
                        {getSymbolFromCurrency(tierData.currency)}
                        {tierData.volume.fixedLotCommission}{" "}
                        {t("ibTimelineCard.titleLotCommission")}
                      </h3>
                      <p className="text-sm">
                        {t("ibTimelineCard.descriptionLotCommission")}
                      </p>
                    </div>
                  </Tooltip>
                </li>
              )}
              {tierData?.volume?.percentageCommission > 0 && (
                <li className="mb-10 ms-6">
                  <Tooltip content={t("ibTimelineCard.tooltipPercentage")}>
                    <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                      <svg
                        className="w-5 h-5 text-green-500 dark:text-green-400"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.86585 2.1543C10.6719 0.615232 13.3281 0.615232 15.1341 2.1543C15.5877 2.54085 16.1517 2.77446 16.7458 2.82186C19.1111 3.01062 20.9894 4.88889 21.1781 7.25422C21.2255 7.84829 21.4591 8.41226 21.8457 8.86585C23.3848 10.6719 23.3848 13.3281 21.8457 15.1341C21.4591 15.5877 21.2255 16.1517 21.1781 16.7458C20.9894 19.1111 19.1111 20.9894 16.7458 21.1781C16.1517 21.2255 15.5877 21.4591 15.1341 21.8457C13.3281 23.3848 10.6719 23.3848 8.86585 21.8457C8.41226 21.4591 7.84829 21.2255 7.25422 21.1781C4.88889 20.9894 3.01062 19.1111 2.82186 16.7458C2.77446 16.1517 2.54085 15.5877 2.1543 15.1341C0.615232 13.3281 0.615232 10.6719 2.1543 8.86585C2.54085 8.41226 2.77446 7.84829 2.82186 7.25422C3.01062 4.88889 4.88889 3.01062 7.25422 2.82186C7.84829 2.77446 8.41226 2.54085 8.86585 2.1543ZM16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289C16.3166 6.90237 15.6834 6.90237 15.2929 7.29289L7.29289 15.2929C6.90237 15.6834 6.90237 16.3166 7.29289 16.7071C7.68342 17.0976 8.31658 17.0976 8.70711 16.7071L16.7071 8.70711ZM7.5 9C7.5 8.17157 8.17157 7.5 9 7.5C9.82843 7.5 10.5 8.17157 10.5 9C10.5 9.82843 9.82843 10.5 9 10.5C8.17157 10.5 7.5 9.82843 7.5 9ZM13.5 15C13.5 14.1716 14.1716 13.5 15 13.5C15.8284 13.5 16.5 14.1716 16.5 15C16.5 15.8284 15.8284 16.5 15 16.5C14.1716 16.5 13.5 15.8284 13.5 15Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <div className="ml-2">
                      <h3 className="font-medium leading-tight pb-1">
                        {tierData.volume.percentageCommission}%{" "}
                        {t("ibTimelineCard.titlePercentage")}
                      </h3>
                      <p className="text-sm">
                        {t("ibTimelineCard.descriptionPercentage")}
                      </p>
                    </div>
                  </Tooltip>
                </li>
              )}
              {tierData?.volume?.swapCommission > 0 && (
                <li className="ms-6">
                  <Tooltip content={t("ibTimelineCard.tooltipPercentageSwap")}>
                    <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                      <svg
                        className="w-5 h-5 text-green-500 dark:text-green-400"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 1C5.92487 1 1 5.92487 1 12C1 12.0992 1 12.1488 1.01014 12.2195C1.05559 12.5362 1.30639 12.8656 1.59956 12.9938C1.66497 13.0223 1.72287 13.0383 1.83866 13.0703C2.02017 13.1204 2.18381 13.1643 2.32042 13.1999C3.18106 13.3961 4.07777 13.5 4.99996 13.5C8.30564 13.5 11.2993 12.164 13.4706 10H12C11.4477 10 11 9.55228 11 9C11 8.44772 11.4477 8 12 8H16C16.5523 8 17 8.44772 17 9V13C17 13.5523 16.5523 14 16 14C15.4477 14 15 13.5523 15 13V11.2978C12.4598 13.8899 8.91796 15.5 4.99996 15.5C4.73986 15.5 4.48086 15.4931 4.22292 15.4791C3.21076 15.4244 2.70468 15.3971 2.42612 15.5856C2.18892 15.7461 2.02431 16.0187 1.99267 16.3033C1.95552 16.6376 2.15315 16.9686 2.54839 17.6307C4.46824 20.8464 7.98262 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <div className="ml-2">
                      <h3 className="font-medium leading-tight pb-1">
                        {tierData.volume.swapCommission}%{" "}
                        {t("ibTimelineCard.titlePercentageSwap")}
                      </h3>
                      <p className="text-sm">
                        {t("ibTimelineCard.descriptionPercentageSwap")}
                      </p>
                    </div>
                  </Tooltip>
                </li>
              )}
            </>
          )}
        </ol>
      </div>
    </div>
  );
};

export default TimelineCard;
