import {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useContext,
} from "react";
import {
  getFirestore,
  query,
  where,
  getDocs,
  collection,
  doc,
  limit,
  startAfter,
  endBefore,
  writeBatch,
} from "firebase/firestore";
import { useBrokerCommandPalette } from "../../../contexts/BrokerCommandPaletteProvider.js";
import { BrokerContext } from "../../../contexts/BrokerContext.js";
import { useNavigate } from "react-router-dom";
import Pagination from "../../Shared/Pagination.js";
import { DocumentCheckIcon } from "@heroicons/react/24/outline";
import { useNotification } from "../../../contexts/NotificationContext.js";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function LogsDashboard() {
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const { togglePaletteVisibility } = useBrokerCommandPalette();
  const { broker } = useContext(BrokerContext); // Assuming broker object has the brokerUID
  const [people, setPeople] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // You can adjust this number as needed
  const [totalItems, setTotalItems] = useState(0);
  const [pageCursors, setPageCursors] = useState([]);
  const { showNotification } = useNotification(); // Use the hook here

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    const fetchPendingErrors = async () => {
      const db = getFirestore();

      let baseQuery = query(
        collection(db, "errors"),
        where("brokerUID", "==", broker.userData.uid),
        where("status", "==", "pending"),
        limit(itemsPerPage)
      );

      const errorLogsQuery =
        currentPage === 1
          ? baseQuery
          : currentPage > pageCursors.length
          ? query(baseQuery, startAfter(pageCursors[pageCursors.length - 1]))
          : query(baseQuery, endBefore(pageCursors[currentPage - 2]));

      const errorLogsSnapshot = await getDocs(errorLogsQuery);
      const errorLogsData = errorLogsSnapshot.docs.map((doc) => ({
        docId: doc.id, // Store the document ID in the docId property
        ...doc.data(),
        timestamp:
          doc.data().timestamp &&
          typeof doc.data().timestamp.toDate === "function"
            ? doc.data().timestamp.toDate().toLocaleString(undefined, {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
            : doc.data().timestamp || "Timestamp unavailable", // You can customize this placeholder as needed
      }));

      if (
        errorLogsSnapshot.docs.length > 0 &&
        currentPage > pageCursors.length
      ) {
        setPageCursors([
          ...pageCursors,
          errorLogsSnapshot.docs[errorLogsSnapshot.docs.length - 1],
        ]);
      }

      const totalErrorLogsSnapshot = await getDocs(
        query(
          collection(db, "errors"),
          where("brokerUID", "==", broker.userData.uid),
          where("status", "==", "pending")
        )
      );
      setTotalItems(totalErrorLogsSnapshot.size);

      setPeople(errorLogsData); // Consider renaming this state to setErrorLogs or something similar
      setLoading(false);
    };

    fetchPendingErrors();
  }, [broker, currentPage, itemsPerPage, pageCursors]);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedPeople.length > 0 && selectedPeople.length < people.length;
    setChecked(selectedPeople.length === people.length);
    setIndeterminate(isIndeterminate);
    if (checkbox.current) {
      checkbox.current.indeterminate = isIndeterminate;
    }
  }, [selectedPeople, people.length]);

  if (!people || people.length === 0) {
    return (
      <div className="py-12 text-center">
        <svg
          className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-700"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <DocumentCheckIcon />
        </svg>
        <h3 className="mt-2 text-sm font-semibold text-gray-900 dark:text-white">
          All caught up!
        </h3>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          There are no pending logs to handle at the moment.
        </p>
        <button
          type="button"
          onClick={() => navigate("/broker/dashboard/clients")}
          className="mt-6 inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
        >
          <ArrowLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          View all clients
        </button>
      </div>
    );
  }

  function toggleAll() {
    if (checked || indeterminate) {
      setSelectedPeople([]);
    } else {
      setSelectedPeople(people);
    }
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  function bulkDismiss() {
    const db = getFirestore();
    const batchWrite = writeBatch(db);

    selectedPeople.forEach((errorLog) => {
      // Update status in the 'errors' collection
      const errorRef = doc(db, "errors", errorLog.docId); // Use docId here
      batchWrite.update(errorRef, { status: "dismissed" });
    });

    batchWrite
      .commit()
      .then(() => {
        console.log("Bulk dismiss successful!");

        showNotification("success", "Bulk dismiss successful!");

        // Filter out the dismissed items from the error logs array
        const updatedErrorLogs = people.filter(
          (errorLog) => !selectedPeople.includes(errorLog)
        );
        setPeople(updatedErrorLogs);

        // Reset the selected people array
        setSelectedPeople([]);
      })
      .catch((error) => {
        console.error("Error updating documents: ", error);

        showNotification("error", "Bulk dismiss failed!");
      });
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8 pt-4 bg-white dark:bg-gray-900">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
            Pending error logs
          </h1>
          <p className="mt-2 text-sm text-gray-700 dark:text-gray-400">
            A list of all the pending error logs. Click the "View details"
            button to view and handle error logs.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="rounded-full bg-white dark:bg-white/5 px-3.5 py-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 hover:bg-gray-50 dark:hover:bg-white/10"
            onClick={togglePaletteVisibility}
          >
            Search{" "}
            <span className="text-gray-500 font-normal">
              ({navigator.userAgent.includes("Mac") ? "⌘" : "CTRL"} + K)
            </span>
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="relative">
              {selectedPeople.length > 0 && (
                <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white dark:bg-gray-900 sm:left-12">
                  <button
                    type="button"
                    className="inline-flex items-center rounded bg-white dark:bg-white/10 px-2 py-1 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:bg-gray-50 dark:hover:bg-white/5 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    onClick={bulkDismiss} // Updated to the new function name
                  >
                    Dismiss selected logs
                  </button>
                </div>
              )}
              <table className="min-w-full table-fixed divide-y divide-gray-300 dark:divide-gray-700">
                <thead>
                  <tr>
                    <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 dark:border-gray-700 text-green-600 focus:ring-green-600 dark:bg-white/10"
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                    >
                      Error message
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                    >
                      Error type
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                    >
                      Function name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                    >
                      <span className="sr-only">Actions</span>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-900">
                  {loading
                    ? Array.from({ length: itemsPerPage }).map((_, idx) => (
                        <tr key={idx}>
                          <td className="border-b border-gray-200 dark:border-gray-800 whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6 lg:pl-8">
                            <div className="animate-pulse h-4 bg-gray-300 dark:bg-white/5 rounded"></div>
                          </td>
                          <td className="border-b border-gray-200 dark:border-gray-800 whitespace-nowrap hidden px-3 py-4 sm:table-cell">
                            <div className="animate-pulse h-4 bg-gray-300 dark:bg-white/5 rounded w-1/2"></div>
                          </td>
                          <td className="border-b border-gray-200 dark:border-gray-800 whitespace-nowrap hidden px-3 py-4 sm:table-cell">
                            <div className="animate-pulse h-4 bg-gray-300 dark:bg-white/5 rounded w-1/3"></div>
                          </td>
                          <td className="border-b border-gray-200 dark:border-gray-800 whitespace-nowrap hidden px-3 py-4 sm:table-cell">
                            <div className="animate-pulse h-4 bg-gray-300 dark:bg-white/5 rounded w-1/4"></div>
                          </td>
                          <td className="border-b border-gray-200 dark:border-gray-800 relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-8 lg:pr-8">
                            <div className="animate-pulse h-4 bg-gray-300 dark:bg-white/5 rounded w-1/5"></div>
                          </td>
                        </tr>
                      ))
                    : people.map((errorLog) => (
                        <tr
                          key={errorLog.docId}
                          className={
                            selectedPeople.includes(errorLog)
                              ? "bg-gray-50"
                              : undefined
                          }
                        >
                          <td className="relative px-7 sm:w-12 sm:px-6 dark:bg-gray-900">
                            {selectedPeople.includes(errorLog) && (
                              <div className="absolute inset-y-0 left-0 w-0.5 bg-green-600" />
                            )}
                            <input
                              type="checkbox"
                              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 dark:border-gray-700 text-green-600 focus:ring-green-600 dark:bg-white/10"
                              value={errorLog.uid}
                              checked={selectedPeople.includes(errorLog)}
                              onChange={(e) =>
                                setSelectedPeople(
                                  e.target.checked
                                    ? [...selectedPeople, errorLog]
                                    : selectedPeople.filter(
                                        (p) => p !== errorLog
                                      )
                                )
                              }
                            />
                          </td>
                          <td
                            className={classNames(
                              "whitespace-nowrap py-4 pr-3 text-sm font-medium dark:bg-gray-900",
                              selectedPeople.includes(errorLog)
                                ? "text-green-600"
                                : "text-gray-900 dark:text-white"
                            )}
                          >
                            {errorLog.errorMessage}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400 dark:bg-gray-900">
                            {errorLog.errorType}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400 dark:bg-gray-900">
                            {errorLog.functionName}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400 dark:bg-gray-900">
                            {/* Format the timestamp as needed */}
                            {errorLog.timestamp}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400 dark:bg-gray-900">
                            <span className="inline-flex items-center rounded-md bg-yellow-50 dark:bg-yellow-400/10 px-2 py-1 text-xs font-medium text-yellow-800 dark:text-yellow-500 ring-1 ring-inset ring-yellow-600/20 dark:ring-yellow-400/20">
                              {capitalizeFirstLetter(errorLog.status)}
                            </span>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3 dark:bg-gray-900">
                            {/* Action buttons, like viewing details or resolving the error */}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3 dark:bg-gray-900">
                            <button
                              className="text-green-600 hover:text-green-900 focus:outline-none"
                              onClick={() =>
                                navigate(
                                  `/broker/dashboard/logs/${errorLog.docId}`
                                )
                              } // Use person.uid here
                            >
                              View details
                              <span className="sr-only">, {errorLog.name}</span>
                            </button>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(totalItems / itemsPerPage)}
                onPageChange={(newPage) => setCurrentPage(newPage)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
