import { PhotoIcon } from "@heroicons/react/24/solid";
import React, { useContext, useState } from "react";
import { BrokerContext } from "../../../../contexts/BrokerContext.js";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { useNotification } from "../../../../contexts/NotificationContext.js";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

export default function NewCampaign({ setCurrentView }) {
  const broker = useContext(BrokerContext)?.broker || {};
  const { showNotification } = useNotification();
  const [isSaving, setIsSaving] = useState(false);
  const [isProtectedPositionsEnabled, setIsProtectedPositionsEnabled] =
    useState(false);
  const [campaignName, setCampaignName] = useState(""); // New state for campaign name
  const [uploadStatus, setUploadStatus] = useState("");
  const [fileRef, setFileRef] = useState(null); // New state for file reference
  const [isUploading, setIsUploading] = useState(false);
  const [fileUrl, setFileUrl] = useState(null); // New state for file URL
  const [amount, setAmount] = useState(0);
  const [expirationHours, setExpirationHours] = useState(0);
  const [depositThreshold, setDepositThreshold] = useState(0);
  const [creditBonus, setCreditBonus] = useState(false);
  const [creditType, setCreditType] = useState("Percentage");
  const [creditAmount, setCreditAmount] = useState(0);
  const [creditDepositThreshold, setCreditDepositThreshold] = useState(0);

  const handleAmountChange = (e) => setAmount(e.target.value);
  const handleExpirationHoursChange = (e) => setExpirationHours(e.target.value);
  const handleDepositThresholdChange = (e) =>
    setDepositThreshold(e.target.value);

  const handleCreditBonusChange = (e) => {
    setCreditBonus(e.target.checked);
  };

  const handleCreditTypeChange = (e) => {
    setCreditType(e.target.value);
  };

  const handleCreditAmountChange = (e) => {
    setCreditAmount(e.target.value);
  };

  const handleCreditDepositThresholdChange = (e) => {
    setCreditDepositThreshold(e.target.value);
  };

  function generateUniqueCampaignId() {
    const timestamp = Date.now();
    const randomString = Math.random().toString(36).substring(2, 15);
    return `campaign_${timestamp}_${randomString}`;
  }

  const handleCampaignNameChange = (e) => {
    setCampaignName(e.target.value); // Update state when input changes
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) return;

    // Allowed file types
    const allowedTypes = ["image/png", "image/jpeg"];
    // Maximum file size (5 MB)
    const maxSize = 5 * 1024 * 1024;

    if (!allowedTypes.includes(selectedFile.type)) {
      showNotification("error", "Only PNG or JPG files are allowed.");
      return;
    }

    if (selectedFile.size > maxSize) {
      showNotification("error", "File size should not exceed 5MB.");
      return;
    }

    await uploadFile(selectedFile); // Trigger file upload immediately
  };

  const uploadFile = async (file) => {
    if (!file) return null;

    setIsUploading(true); // Start uploading

    try {
      const storage = getStorage();
      const fileName = `${Date.now()}_${file.name}`;
      const storageRef = ref(
        storage,
        `/brokers/${broker.brokerData.uid}/campaigns/${fileName}`
      );

      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      console.log("Storage URL:", url); // Log the storage URL

      console.log("Calling Cloud Function with:", { storageUrl: url }); // Log the data being sent to the Cloud Function

      // Call the Cloud Function
      const response = await fetch(
        "https://us-central1-xohsync.cloudfunctions.net/uploadToCloudinary",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ storageUrl: url }),
        }
      );

      if (!response.ok) {
        throw new Error("HTTP error " + response.status);
      }

      const data = await response.json();

      const cloudinaryUrl = data.url;
      console.log("Received from Cloud Function:", cloudinaryUrl); // Log the data received from the Cloud Function

      setFileUrl(cloudinaryUrl); // Save the Cloudinary URL to the state
      setUploadStatus(fileName); // Update upload status with file name
      setFileRef(storageRef); // Save the reference for later use

      return cloudinaryUrl;
    } catch (error) {
      console.error("Error uploading file:", error);
      showNotification("error", "Failed to upload file. Please try again.");
      setUploadStatus(""); // Reset upload status on error
    } finally {
      setIsUploading(false); // End uploading
    }

    return null;
  };

  const handleRemoveFile = async () => {
    if (fileRef) {
      try {
        await deleteObject(fileRef);
        showNotification("success", "File removed successfully.");
        setFileRef(null); // Reset the file reference
      } catch (error) {
        console.error("Error removing file:", error);
        showNotification("error", "Failed to remove file. Please try again.");
      }
    }

    setUploadStatus("");
  };

  const handleSaveSettings = async () => {
    setIsSaving(true);

    const db = getFirestore();
    const newCampaignId = generateUniqueCampaignId();

    // Query the "domains" collection
    const domainDocRef = doc(db, "domains", broker.brokerData.uid);
    const domainDoc = await getDoc(domainDocRef);

    let campaignUrl = "https://www.xohsync.com/campaign-registration";
    if (domainDoc.exists()) {
      const domainData = domainDoc.data();
      if (domainData.status === "verified") {
        campaignUrl = `https://platform.${domainData.domain}/campaign-registration`;
      }
    }

    // Create URL object
    const url = new URL(campaignUrl);

    // Create URLSearchParams object
    const params = new URLSearchParams();
    params.append("brokerUID", broker.brokerData.uid);
    params.append("campaignId", newCampaignId);

    // Append params to the URL
    url.search = params.toString();

    const newCampaignData = {
      name: campaignName,
      campaignId: newCampaignId,
      status: "active",
      coverPhoto: fileUrl,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      bonus: {
        protectedPositions: {
          isEnabled: isProtectedPositionsEnabled,
          amount: Number(amount),
          expirationHours: Number(expirationHours),
          depositThreshold: Number(depositThreshold),
        },
        creditBonus: {
          isEnabled: creditBonus,
          type: creditType,
          amount: Number(creditAmount),
          depositThreshold: Number(creditDepositThreshold),
        },
      },
      campaignUrl: url.toString(), // Add the campaignUrl with params to the campaign data
    };

    const campaignDocRef = doc(
      db,
      "campaigns",
      broker.brokerData.uid,
      "campaigns",
      newCampaignId
    );

    try {
      await setDoc(campaignDocRef, newCampaignData);
      showNotification("success", "Campaign created successfully!");
      setCurrentView("Active Campaigns");
    } catch (error) {
      console.error("Firestore update error:", error);
      showNotification(
        "error",
        "Failed to update/create campaign. Please try again."
      );
    } finally {
      setIsSaving(false);
    }
  };

  if (!broker || !broker.userData) {
    return (
      <div className="fixed inset-0 flex items-center justify-center dark:bg-gray-900">
        <svg
          className="animate-spin h-10 w-10 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }

  return (
    <form>
      <div className="space-y-12 sm:space-y-16 px-3 mt-8">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            Create a new campaign
          </h2>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600 dark:text-gray-400">
            Campaigns are used for creating specific sign up URLs that can be
            used to track FTDs and assign specific bonuses.
          </p>

          <div className="mt-10 space-y-8 border-b border-gray-900/10 dark:border-white/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 dark:sm:divide-white/10 sm:border-t sm:pb-0">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white sm:pt-1.5"
              >
                Campaign name
              </label>
              <div className="">
                <input
                  type="text" // Changed from type="email" to type="text"
                  name="campaignName" // Updated to "campaignName"
                  id="campaignName" // Updated to "campaignName"
                  className="block w-full rounded-md border-0 py-1.5 dark:bg-white/5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  placeholder="Enter campaign name here"
                  value={campaignName} // Set value to the state
                  onChange={handleCampaignNameChange} // Update state on change
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label
                htmlFor="cover-photo"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white sm:pt-1.5"
              >
                Campaign cover photo
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                {!uploadStatus && ( // This checks if uploadStatus is not active
                  <div className="flex max-w-2xl justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                    <div className="text-center">
                      <PhotoIcon
                        className="mx-auto h-12 w-12 text-gray-300 dark:text-gray-600"
                        aria-hidden="true"
                      />
                      <div className="mt-4 flex text-sm leading-6 text-gray-600 dark:text-gray-400">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md bg-white dark:bg-gray-900 font-semibold text-primary-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-600 focus-within:ring-offset-2 hover:text-primary-500 flex items-center" // Added flex and items-center for alignment
                        >
                          {isUploading && (
                            <svg
                              className="animate-spin -ml-1 mr-2 h-5 w-5 text-primary-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          )}
                          <span>Upload a file</span>
                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            className="sr-only"
                            onChange={handleFileChange}
                          />
                        </label>
                        <p className="pl-1">to use as cover photo</p>
                      </div>
                      <p className="text-xs leading-5 text-gray-600 dark:text-gray-500">
                        PNG, JPG up to 5MB
                      </p>
                    </div>
                  </div>
                )}
                {uploadStatus && (
                  <div className="">
                    <span className="inline-flex items-center gap-x-0.5 rounded-md bg-primary-100 dark:bg-primary-500/10 px-2 py-1 text-xs font-medium text-primary-700 dark:text-primary-400">
                      Uploaded: {uploadStatus}
                      <button
                        type="button"
                        className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-primary-600/20 dark:hover:bg-primary-300"
                        onClick={handleRemoveFile} // Attach the event handler here
                      >
                        <span className="sr-only">Remove</span>
                        <svg
                          viewBox="0 0 14 14"
                          className="h-3.5 w-3.5 stroke-primary-800/50 dark:stroke-primary-400/50 group-hover:stroke-primary-800/75"
                        >
                          <path d="M4 4l6 6m0-6l-6 6" />
                        </svg>
                        <span className="absolute -inset-1" />
                      </button>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            Bonus configuration
          </h2>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600 dark:text-gray-400">
            Select applicable bonuses for registrations via this campaign.
          </p>

          <div className="mt-10 space-y-10 border-b border-gray-900/10 dark:border-white/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 dark:sm:divide-white/10 sm:border-t sm:pb-0">
            <fieldset>
              <legend className="sr-only">Bonus Configuration</legend>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6">
                <div className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">
                  Bonus for FTDs
                </div>
                <div className="mt-4 sm:col-span-2 sm:mt-0">
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="protectedPositions"
                        name="protectedPositions"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 dark:border-gray-700 dark:bg-gray-800 text-primary-600 focus:ring-primary-600"
                        checked={isProtectedPositionsEnabled}
                        onChange={(e) =>
                          setIsProtectedPositionsEnabled(e.target.checked)
                        }
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="protectedPositions"
                        className="font-medium text-gray-900 dark:text-white"
                      >
                        Protected positions
                      </label>
                      <p
                        id="protectedPositions-description"
                        className="text-gray-500"
                      >
                        If enabled, FTDs will receive protected positions.
                      </p>
                    </div>
                  </div>
                  {isProtectedPositionsEnabled && (
                    <div className="pt-6 sm:flex">
                      <div>
                        <label
                          htmlFor="amount"
                          className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                        >
                          Amount of protected positions
                        </label>
                        <div className="mt-2">
                          <input
                            type="number"
                            name="amount"
                            id="amount"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-white/5"
                            placeholder="0"
                            value={amount}
                            onChange={handleAmountChange}
                          />
                        </div>
                        <p className="mt-2 text-sm text-gray-500" id="amount">
                          How many protected positions
                        </p>
                      </div>

                      <div className="ml-6">
                        <label
                          htmlFor="expirationHours"
                          className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                        >
                          Expiration in hours
                        </label>
                        <div className="mt-2">
                          <input
                            type="number"
                            name="expirationHours"
                            id="expirationHours"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-white/5"
                            placeholder="0"
                            value={expirationHours}
                            onChange={handleExpirationHoursChange}
                          />
                        </div>
                        <p
                          className="mt-2 text-sm text-gray-500"
                          id="expirationHours"
                        >
                          Maximum time before force close
                        </p>
                      </div>
                      <div className="ml-6">
                        <label
                          htmlFor="depositThreshold"
                          className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                        >
                          Deposit threshold
                        </label>
                        <div className="mt-2">
                          <input
                            type="number"
                            name="depositThreshold"
                            id="depositThreshold"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-white/5"
                            placeholder="0"
                            value={depositThreshold}
                            onChange={handleDepositThresholdChange}
                          />
                        </div>
                        <p
                          className="mt-2 text-sm text-gray-500"
                          id="depositThreshold"
                        >
                          Minimum deposit for bonus activation
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="relative flex gap-x-3 mt-6">
                    <div className="flex h-6 items-center">
                      <input
                        id="protectedPositions"
                        name="protectedPositions"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 dark:border-gray-700 dark:bg-white/5 text-primary-600 focus:ring-primary-600"
                        checked={creditBonus}
                        onChange={handleCreditBonusChange}
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="protectedPositions"
                        className="font-medium text-gray-900 dark:text-white"
                      >
                        Credit bonus
                      </label>
                      <p
                        id="protectedPositions-description"
                        className="text-gray-500"
                      >
                        If enabled, FTDs will receive a credit bonus.
                      </p>
                    </div>
                  </div>
                  {creditBonus && (
                    <div className="pt-6 sm:flex">
                      <div>
                        <label
                          htmlFor="amount"
                          className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                        >
                          Credit type
                        </label>
                        <div className="mt-2">
                          <select
                            id="creditType"
                            name="creditType"
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 dark:bg-white/5 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-primary-600 sm:text-sm sm:leading-6"
                            defaultValue={creditType}
                            onChange={handleCreditTypeChange}
                          >
                            <option>Percentage</option>
                            <option>Fixed</option>
                          </select>
                        </div>
                        <p className="mt-2 text-sm text-gray-500" id="amount">
                          Percentage or fixed
                        </p>
                      </div>

                      <div className="ml-6">
                        <label
                          htmlFor="expirationHours"
                          className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                        >
                          Credit amount
                        </label>
                        <div className="mt-2">
                          <input
                            type="number"
                            name="creditAmount"
                            id="creditAmount"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-white/5"
                            placeholder="Enter amount"
                            value={creditAmount}
                            onChange={handleCreditAmountChange}
                          />
                        </div>
                        <p
                          className="mt-2 text-sm text-gray-500"
                          id="expirationHours"
                        >
                          Amount in % or account currency
                        </p>
                      </div>
                      <div className="ml-6">
                        <label
                          htmlFor="depositThreshold"
                          className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                        >
                          Deposit threshold
                        </label>
                        <div className="mt-2">
                          <input
                            type="number"
                            name="creditDepositThreshold"
                            id="creditDepositThreshold"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-white/5"
                            placeholder="0"
                            value={creditDepositThreshold}
                            onChange={handleCreditDepositThresholdChange}
                          />
                        </div>
                        <p
                          className="mt-2 text-sm text-gray-500"
                          id="depositThreshold"
                        >
                          Minimum deposit for bonus activation
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          onClick={handleSaveSettings}
          className="rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
          disabled={isSaving}
        >
          {isSaving ? (
            <div className="flex items-center">
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Creating campaign...
            </div>
          ) : (
            "Create campaign"
          )}
        </button>
      </div>
    </form>
  );
}
