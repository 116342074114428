import { getAuth } from "firebase/auth";
import {
  getDoc,
  doc,
  query,
  where,
  getDocs,
  collection,
} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";

export const getCurrentUser = () => {
  const auth = getAuth();
  return auth.currentUser;
};

export const fetchUserData = async () => {
  const auth = getAuth();
  const userAuth = auth.currentUser;

  if (userAuth != null) {
    const uid = userAuth.uid;
    const db = getFirestore();

    // Fetch data from the users collection
    const userDocRef = doc(db, "users", uid);
    const userDocSnap = await getDoc(userDocRef);

    // Fetch data from the xmanager_accounts collection
    const accountDocRef = doc(db, "xmanager_accounts", uid);
    const accountDocSnap = await getDoc(accountDocRef);

    if (userDocSnap.exists() && accountDocSnap.exists()) {
      const userData = userDocSnap.data();
      userData.uid = userDocRef.id;

      // Check and set kycStatus if not present or null
      if (!userData.kycStatus) {
        userData.kycStatus = "unverified";
      }

      const accountData = accountDocSnap.data();

      // Get the brokerUID from userData
      const brokerUID = userData.brokerUID;

      // Fetch data from the brokers collection using brokerUID
      const brokerDocRef = doc(db, "whitelabel", brokerUID);
      const brokerDocSnap = await getDoc(brokerDocRef);

      // Set default values
      userData.themeColor = "Green";
      userData.logoUrlLight =
        "https://firebasestorage.googleapis.com/v0/b/xohsync.appspot.com/o/brokers%2FzZNKIqjPWzQjhXlE9xfS1xlAZIF2%2Flight?alt=media&token=62d5d35e-bae6-455f-8291-d7f45758951b";
      userData.logoUrlDark =
        "https://firebasestorage.googleapis.com/v0/b/xohsync.appspot.com/o/brokers%2FzZNKIqjPWzQjhXlE9xfS1xlAZIF2%2Fdark?alt=media&token=96766c4e-28ae-4e6c-8f7b-a08f14f4baca";
      userData.iframeUrlDark = "https://xs5.xopenhub.pro/xohsync";
      userData.iframeUrlLight = "https://xs5.xopenhub.pro/xohsync";
      userData.allowDepositBeforeKYC = false; // Default KYC rule
      userData.allowWithdrawalBeforeKYC = false; // Default KYC rule
      userData.isBrokerUIDFetched = true;

      if (brokerDocSnap.exists()) {
        // Extract themeColor, logoUrlLight, logoUrlDark, iframeUrlDark, iframeUrlLight, and KYC rules
        const {
          themeColor,
          logoUrlLight,
          logoUrlDark,
          iframeUrlDark,
          iframeUrlLight,
          allowDepositBeforeKYC,
          allowWithdrawalBeforeKYC,
          favicon, // Assuming this is where your favicon URL is stored
          displayName,
        } = brokerDocSnap.data();

        // Overwrite the default values with the data from brokerDocSnap
        userData.themeColor = themeColor;
        userData.logoUrlLight = logoUrlLight;
        userData.logoUrlDark = logoUrlDark;
        userData.iframeUrlDark = iframeUrlDark;
        userData.iframeUrlLight = iframeUrlLight;
        userData.allowDepositBeforeKYC = allowDepositBeforeKYC; // KYC rule
        userData.allowWithdrawalBeforeKYC = allowWithdrawalBeforeKYC; // KYC rule
        userData.favicon = favicon;
        userData.displayName = displayName;
      }

      // Initialize ibEnabled as false
      userData.ibEnabled = false;

      // Determine the tier to fetch
      let tier = userData.ibTier || "tier1";

      // Fetch the specific tier document from the ibCommission subcollection
      const tierDocRef = doc(db, "whitelabel", brokerUID, "ibCommission", tier);
      const tierDocSnap = await getDoc(tierDocRef);

      if (tierDocSnap.exists()) {
        const data = tierDocSnap.data();
        if (
          (data.cpa && data.cpa.enabled) ||
          (data.volume && data.volume.enabled)
        ) {
          userData.ibEnabled = true;
        }
      }

      return {
        userData: userData,
        accountData: accountData,
      };
    } else {
      console.log("No such document or incomplete data!");

      // New logic to fetch brokerUID by domain
      let brokerUID = null;

      const domain = window.location.hostname.split(".").slice(-2).join(".");

      // New logic to fetch brokerUID by domain
      console.log("Fetching brokerUID by domain...");

      const q = query(
        collection(db, "whitelabel"),
        where("domain", "==", domain)
      );

      console.log(domain);

      let displayName = null; // Declare displayName variable

      await getDocs(q)
        .then((querySnapshot) => {
          console.log("Query executed successfully.");

          if (querySnapshot.empty) {
            console.log("No documents found with the domain:", domain);
          } else {
            querySnapshot.forEach((doc) => {
              brokerUID = doc.id; // Set the brokerUID
              displayName = doc.data().displayName; // Set the displayName
              console.log("brokerUID set to:", brokerUID);
            });
          }
        })
        .catch((error) => {
          console.error("Error executing the query:", error);
        });

      return {
        userData: {
          uid: userAuth.uid,
          hasCompletedOnboarding: false,
          brokerUID: brokerUID,
          isBrokerUIDFetched: true,
          displayName: displayName,
        },
      };
    }
  } else {
    console.log("User is not authenticated.");
    return null;
  }
};
