import { useState, useEffect, useCallback } from "react";
import { Label, Select, TextInput, Button } from "flowbite-react";
import { Icon } from "@iconify/react";
import { functions, auth } from "../../../../firebase.js";
import { httpsCallable } from "firebase/functions";
import { useLanguage } from "../../../../contexts/LanguageContext.js";
import { useTranslation } from "react-i18next";

const paymentMethod = {
  id: "sepa",
  name: "Bank transfer",
  description: "Withdraw funds to your bank account via SEPA transfer",
  icon: "simple-icons:sepa",
};

export function CustomWithdrawalCashier({
  onClose,
  isLoading,
  isLoadingBalance,
  availableBalance,
  accounts,
  selectedAccountId,
  setSelectedAccountId,
  handleWithdrawal,
  showNotification,
  user,
}) {
  const [amount, setAmount] = useState("");
  const [iban, setIban] = useState("");
  const [isIbanVerified, setIsIbanVerified] = useState(false);
  const [isVerifyingIban, setIsVerifyingIban] = useState(false);
  const [verifiedIbanDetails, setVerifiedIbanDetails] = useState(null);
  const { language } = useLanguage();
  const { t } = useTranslation();

  const selectedAccount = accounts.find(
    (account) => account.id_account === selectedAccountId
  );

  const maxAmount = availableBalance / 100; // Convert cents to whole currency units

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (value === "" || /^\d*\.?\d*$/.test(value)) {
      const numValue = parseFloat(value);
      if (value === "" || isNaN(numValue)) {
        setAmount(value);
      } else if (numValue <= maxAmount) {
        setAmount(value);
      } else {
        setAmount(maxAmount.toString());
        showNotification("warning", "Amount exceeds available balance");
      }
    }
  };

  const handleIbanChange = (e) => {
    // Remove all spaces and convert to uppercase
    const cleanedIban = e.target.value.replace(/\s/g, "").toUpperCase();
    setIban(cleanedIban);
    setIsIbanVerified(false);
  };

  const verifyIban = useCallback(async () => {
    if (!user || !user.userData) {
      showNotification("error", "User data is not available");
      return;
    }

    setIsVerifyingIban(true);
    try {
      const routePSPWithdrawalRequest = httpsCallable(
        functions,
        "routePSPWithdrawalRequest"
      );

      const requestData = {
        uid: auth.currentUser.uid,
        brokerUID: user.userData.brokerUID,
        userLogin: selectedAccountId,
        language: language,
        selectedMethod: "mifinity-verify",
        iban: iban,
      };

      const response = await routePSPWithdrawalRequest(requestData);

      if (response.data.valid) {
        setIsIbanVerified(true);
        setVerifiedIbanDetails({
          bankName: response.data.bankName,
          country: response.data.country,
          valid: response.data.valid,
        });
        showNotification("success", "IBAN verified successfully");
      } else {
        setIsIbanVerified(false);
        setVerifiedIbanDetails(null);
        showNotification("error", "Invalid IBAN");
      }
    } catch (error) {
      console.error("Error verifying IBAN:", error);
      showNotification("error", "Failed to verify IBAN");
    } finally {
      setIsVerifyingIban(false);
    }
  }, [iban, selectedAccountId, user, language, showNotification]);

  // Add a check for user data before rendering
  if (!user || !user.userData) {
    return <div>Loading user data...</div>;
  }

  const handleWithdrawalWrapper = async ({ amount, iban }) => {
    if (!user || !user.userData || !verifiedIbanDetails) {
      showNotification("error", "Missing required data for withdrawal");
      return;
    }

    try {
      // First, call the original handleWithdrawal function
      await handleWithdrawal({ amount, iban });

      // Then, call the new sendWithdrawalEmailToBroker function
      const sendWithdrawalEmailToBroker = httpsCallable(
        functions,
        "sendWithdrawalEmailToBroker"
      );

      const emailData = {
        brokerUID: user.userData.brokerUID,
        userUID: auth.currentUser.uid,
        amount: parseFloat(amount),
        currency: selectedAccount.currency,
        iban: iban,
        bankName: verifiedIbanDetails.bankName,
        country: verifiedIbanDetails.country,
        selectedAccount: selectedAccountId,
      };

      const result = await sendWithdrawalEmailToBroker(emailData);

      // Use the success message from the backend
      showNotification("success", result.data.message);

      // Close the parent modal after successful withdrawal and email notification
      onClose();
    } catch (error) {
      console.error("Error in handleWithdrawalWrapper:", error);
      // Use the error message from the backend if available
      const errorMessage =
        error.message || "Failed to process withdrawal request.";
      showNotification("error", errorMessage);
    }
  };

  return (
    <div className="relative max-h-full w-full max-w-lg">
      <div className="relative rounded-lg bg-white dark:bg-gray-800 sm:p-5">
        {/* Modal header */}
        <div className="mb-4 flex items-center justify-between rounded-t border-b pb-4 dark:border-gray-700 sm:mb-5">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            {t("withdrawalCashier.withdraw")}
          </h3>
        </div>

        {/* Payment method */}
        <div className="mb-4 flex items-center justify-between rounded-lg border p-4 ps-4 bg-blue-50 dark:bg-blue-900/20 border-blue-500 dark:border-blue-600">
          <div className="flex-grow">
            <div className="flex items-start">
              <div className="ms-4 text-sm">
                <label className="font-medium text-gray-900 dark:text-white">
                  {t("withdrawalCashier.bankTransfer")}
                </label>
                <p className="text-xs font-normal text-gray-500 dark:text-gray-400">
                  {t("withdrawalCashier.bankTransferDescription")}
                </p>
              </div>
            </div>
          </div>
          <div className="shrink-0">
            <Icon
              icon={paymentMethod.icon}
              className="h-8 w-8 text-gray-600 dark:text-gray-400"
            />
          </div>
        </div>

        {/* Account and amount inputs */}
        <div className="mb-6 grid grid-cols-2 gap-4">
          <div>
            <div className="mb-2 block">
              <Label htmlFor="account" value={t("withdrawalCashier.account")} />
            </div>
            <Select
              id="account"
              required
              value={selectedAccountId}
              onChange={(e) => setSelectedAccountId(e.target.value)}
            >
              {accounts.map((account) => (
                <option key={account.id_account} value={account.id_account}>
                  {account.id_account}
                </option>
              ))}
            </Select>
          </div>
          <div>
            <div className="mb-2 block">
              <Label
                htmlFor="amount"
                value={t("withdrawalCashier.amount", {
                  currency: selectedAccount?.currency || "",
                })}
              />
            </div>
            <TextInput
              id="amount"
              type="text"
              value={amount}
              onChange={handleAmountChange}
              placeholder={t("withdrawalCashier.amountPlaceholder")}
              step="0.01"
            />
            {!isLoadingBalance && (
              <p className="mt-1 text-sm text-gray-500">
                {t("withdrawalCashier.available", {
                  amount: maxAmount.toFixed(2),
                  currency: selectedAccount?.currency,
                })}
              </p>
            )}
          </div>
        </div>

        {/* IBAN input and verify button */}
        <div className="mb-6">
          <div className="mb-2 block">
            <Label htmlFor="iban" value={t("withdrawalCashier.iban")} />
          </div>
          <div className="flex">
            <TextInput
              id="iban"
              type="text"
              value={iban}
              onChange={handleIbanChange}
              className="flex-grow"
              placeholder={t("withdrawalCashier.ibanPlaceholder")}
            />
            <Button
              onClick={verifyIban}
              className="ml-2"
              disabled={!iban || isVerifyingIban}
              color="blue"
            >
              {isVerifyingIban
                ? t("withdrawalCashier.verifying")
                : t("withdrawalCashier.verify")}
            </Button>
          </div>
        </div>

        {/* New horizontal checklist for verified IBAN details */}
        {isIbanVerified && verifiedIbanDetails && (
          <div className="mt-1">
            <div className="flex flex-wrap items-center gap-4">
              {["valid", "bankName", "country"].map((detail) => (
                <div key={detail} className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400 mr-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M1 5.917 5.724 10.5 15 1.5"
                    />
                  </svg>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {detail === "valid"
                      ? t("withdrawalCashier.validIban")
                      : t(`withdrawalCashier.${detail}`, {
                          value:
                            verifiedIbanDetails[detail] ||
                            t("withdrawalCashier.notAvailable"),
                        })}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Action buttons */}
        <div className="mt-4 flex items-center justify-end space-x-4 border-t border-gray-200 pt-4 dark:border-gray-700">
          <Button color="gray" onClick={onClose}>
            {t("withdrawalCashier.close")}
          </Button>
          <Button
            onClick={() => handleWithdrawalWrapper({ amount, iban })}
            disabled={
              isLoading || !isIbanVerified || !amount || isLoadingBalance
            }
            color="blue"
          >
            {isLoading
              ? t("withdrawalCashier.processing")
              : t("withdrawalCashier.requestWithdrawal")}
          </Button>
        </div>
      </div>
    </div>
  );
}
