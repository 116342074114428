import React, { useEffect, useState, useContext } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { BrokerContext } from "../../../../contexts/BrokerContext";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";

export default function MigrationPreview({ onFinalizeMigration, isLoading }) {
  const broker = useContext(BrokerContext)?.broker || {};
  const brokerUID = broker.brokerData.uid;

  const [userRecords, setUserRecords] = useState(0);
  const [accountRecords, setAccountRecords] = useState(0);

  useEffect(() => {
    const fetchRecords = async () => {
      const db = getFirestore();

      // Fetching User Records
      const userCollection = collection(
        db,
        `temp-migration-users-${brokerUID}`
      );
      const userSnapshot = await getDocs(userCollection);
      setUserRecords(userSnapshot.size);

      // Fetching Account Records
      const accountCollection = collection(
        db,
        `xmanager-accounts-${brokerUID}`
      );
      const accountSnapshot = await getDocs(accountCollection);

      // Initializing trading account counter
      let totalTradingAccounts = 0;

      // Iterating through each document to count the trading accounts
      accountSnapshot.docs.forEach((doc) => {
        const data = doc.data();
        const tradingAccounts = data.trading_accounts;
        if (tradingAccounts && Array.isArray(tradingAccounts)) {
          totalTradingAccounts += tradingAccounts.length;
        }
      });

      // Setting the state with the total number of trading accounts
      setAccountRecords(totalTradingAccounts);
    };

    fetchRecords();
  }, [brokerUID]);

  function convertUsersToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;

    // Define the order of the columns in the CSV file
    const columnOrder = [
      "live_id_person",
      "hasCompletedOnboarding",
      "street_name",
      "zip_code",
      "fullNameLower",
      "kycStatus",
      "city",
      "phone",
      "state",
      "surname",
      "dob",
      "imported",
      "country",
      "name",
      "fullName",
      "email",
      "status",
      "address",
      "brokerUID",
    ];

    // Headers
    let str = columnOrder.map((value) => `"${value}"`).join(",") + "\r\n";

    // Data Rows
    array.forEach((item) => {
      str += columnOrder
        .map((key) => {
          const value = item[key];
          return `"${value !== undefined ? value : ""}"`; // Handling missing or undefined values
        })
        .join(",");
      str += "\r\n";
    });

    return str;
  }

  function convertAccountsToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;

    // Define the order of the columns in the Users CSV file
    const columnOrder = ["person", "trading_accounts", "email"];

    // Headers
    let str = columnOrder.map((value) => `"${value}"`).join(",") + "\r\n";

    // Data Rows
    array.forEach((item) => {
      str += columnOrder
        .map((key) => {
          let value = item[key];

          // Handling nested objects or arrays
          if (key === "person") {
            value = Array.isArray(value) ? value.join(", ") : value;
          } else if (key === "trading_accounts") {
            // Extracting the login property from each trading_account object
            value = Array.isArray(value)
              ? value.map((account) => account.login).join(", ")
              : value;
          }

          return `"${value !== undefined ? value : ""}"`; // Handling missing or undefined values
        })
        .join(",");
      str += "\r\n";
    });

    return str;
  }

  function downloadCSV(csv, filename) {
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const handleDownload = async (collectionName, filename, convertFunction) => {
    const db = getFirestore();
    const collectionRef = collection(db, collectionName);
    const snapshot = await getDocs(collectionRef);

    const data = snapshot.docs.map((doc) => doc.data());
    const csv = convertFunction(data); // Using the passed conversion function here
    downloadCSV(csv, `${filename}.csv`);
  };

  return (
    <div className="mt-10">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
            Preview imported data
          </h1>
          <p className="mt-2 text-sm text-gray-700 dark:text-gray-400">
            Below you can download and review the data files that will be
            imported into the system. Please ensure that the information is
            accurate and complete before proceeding to the final migration step.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-primary-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            onClick={onFinalizeMigration}
            disabled={isLoading} // Disable the button when loading
          >
            {isLoading ? ( // Conditional rendering of button content
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Processing migration...
              </>
            ) : (
              "Finalize migration"
            )}
          </button>
        </div>
      </div>
      <div className="-mx-4 mt-10 ring-1 ring-gray-300 dark:ring-white/10 sm:mx-0 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300 dark:divide-white/10">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-6"
              >
                Record type
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white lg:table-cell"
              >
                Amount of records
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Select</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="relative py-4 pl-4 pr-3 text-sm sm:pl-6">
                <div className="font-medium text-gray-900 dark:text-white">
                  Users
                </div>
              </td>
              <td className="hidden px-3 py-3.5 text-sm text-gray-500 dark:text-gray-400 lg:table-cell">
                {userRecords} users founds
              </td>
              <td className="relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                <button
                  type="button"
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-white dark:bg-white/10 px-2.5 py-1.5 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/5 hover:bg-gray-50 dark:hover:bg-white/20 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  onClick={
                    () =>
                      handleDownload(
                        `temp-migration-users-${brokerUID}`,
                        "Users",
                        convertUsersToCSV
                      ) // Passing convertUsersToCSV function
                  }
                >
                  <ArrowDownTrayIcon
                    className="-ml-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  Download Users CSV
                </button>
              </td>
            </tr>
            <tr>
              <td className="relative py-4 pl-4 pr-3 text-sm sm:pl-6">
                <div className="font-medium text-gray-900 dark:text-white">
                  Accounts
                </div>
              </td>
              <td className="hidden px-3 py-3.5 text-sm text-gray-500 dark:text-gray-400 lg:table-cell">
                {accountRecords} trading accounts found
              </td>
              <td className="relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                <button
                  type="button"
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-white dark:bg-white/10 px-2.5 py-1.5 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/5 hover:bg-gray-50 dark:hover:bg-white/20 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  onClick={
                    () =>
                      handleDownload(
                        `xmanager-accounts-${brokerUID}`,
                        "Accounts",
                        convertAccountsToCSV
                      ) // Passing convertAccountsToCSV function
                  }
                >
                  <ArrowDownTrayIcon
                    className="-ml-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  Download Accounts CSV
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
