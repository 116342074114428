import { useContext, useEffect, useState, useCallback } from "react";
import { BrokerContext } from "../../../../contexts/BrokerContext.js";
import EmptyState from "../../../Shared/EmptyState.js";
import { getFunctions, httpsCallable } from "firebase/functions";
import getSymbolFromCurrency from "currency-symbol-map";
import { useNotification } from "../../../../contexts/NotificationContext.js";
import { Link } from "react-router-dom";

export default function IBPendingPayouts() {
  const { broker } = useContext(BrokerContext);
  const brokerUID = broker?.brokerData?.uid;
  const [loading, setLoading] = useState(true);
  const [pendingPayouts, setPendingPayouts] = useState([]);

  const [isEmpty, setIsEmpty] = useState(false);
  const [cancelPayoutLoadingId, setCancelPayoutLoadingId] = useState(null);
  const [processPayoutLoadingId, setProcessPayoutLoadingId] = useState(null);
  const { showNotification } = useNotification();

  const fetchPendingPayouts = useCallback(async () => {
    const functions = getFunctions();
    const queryBigQueryIB = httpsCallable(functions, "queryBigQueryIB");
    try {
      const result = await queryBigQueryIB({
        type: "fetchPendingPayouts",
        brokerUID: brokerUID,
        timePeriod: "1",
      });
      console.log("Incoming data:", result.data); // Log the incoming data
      const pendingPayouts = result.data.pendingPayouts.map((payout) => ({
        ...payout,
        data: JSON.parse(payout.data), // Parse the data string into an object
      }));
      if (!pendingPayouts.length) {
        setLoading(false);
        setIsEmpty(true);
        return;
      }
      setPendingPayouts(pendingPayouts);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching pending payouts:", error);
      setLoading(false);
    }
  }, [brokerUID]); // Add dependencies here

  // Update useEffect
  useEffect(() => {
    if (brokerUID) {
      setLoading(true);
      fetchPendingPayouts();
    }
  }, [brokerUID, fetchPendingPayouts]);

  const handleCancelPayoutRequest = async (payoutRequestId, userId) => {
    setCancelPayoutLoadingId(payoutRequestId);
    const functions = getFunctions();
    const cancelPayoutRequest = httpsCallable(functions, "cancelPayoutRequest");
    try {
      const result = await cancelPayoutRequest({
        payoutRequestId,
        ibUID: userId,
      });
      showNotification("success", "Payout request cancelled successfully");
      console.log(result.data);
      // Fetch the latest pending balances after successfully cancelling a request
      await fetchPendingPayouts();
    } catch (error) {
      console.error("Error cancelling payout request:", error);
      showNotification("error", "Error cancelling payout request");
    } finally {
      setCancelPayoutLoadingId(null);
    }
  };

  const handleProcessPayoutRequest = async (
    payoutRequestId,
    approve,
    ibUID
  ) => {
    setProcessPayoutLoadingId(payoutRequestId); // Set loading state for the specific payout request
    const functions = getFunctions();
    const processPayoutRequest = httpsCallable(
      functions,
      "processPayoutRequest"
    );
    try {
      const result = await processPayoutRequest({
        payoutRequestId,
        approve,
        ibUID,
        brokerUID, // Assuming brokerUID is available in the component's scope
        comment: "Approved", // Add a default comment or customize as needed
      });
      showNotification("success", "Payout request processed successfully");
      console.log(result.data);
      // Optionally, fetch the latest pending payouts to update the UI
      await fetchPendingPayouts();
    } catch (error) {
      console.error("Error processing payout request:", error);
      showNotification("error", "Error processing payout request");
    } finally {
      setProcessPayoutLoadingId(null); // Reset the loading state
    }
  };

  return (
    <>
      {/* Start block */}
      <section className="bg-gray-50 dark:bg-gray-900 antialiased">
        <div className="mx-auto">
          <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
            <div className="px-4">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-3 md:space-y-0 md:space-x-4 py-3">
                <div>
                  <nav
                    className="mb-2 flex px-3 py-2 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600"
                    aria-label="Breadcrumb"
                  >
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                      <li className="inline-flex items-center">
                        <Link
                          to="/broker/dashboard/clients"
                          className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-primary-600 dark:text-gray-300 dark:hover:text-white"
                        >
                          Dashboard
                        </Link>
                      </li>
                      <li aria-current="page">
                        <div className="flex items-center">
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5 text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                            Pending Payouts
                          </span>
                        </div>
                      </li>
                    </ol>
                  </nav>
                  <h5>
                    <span className="dark:text-white">
                      Pending payout requests
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-4 py-3 whitespace-nowrap">
                      User ID
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Amount
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Currency
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Date
                    </th>
                    <th scope="col" className="px-4 py-3 whitespace-nowrap">
                      Status
                    </th>
                    <th scope="col" className="px-4 py-3 whitespace-nowrap">
                      Payout
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 whitespace-nowrap"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {loading
                    ? Array.from({ length: 10 }).map((_, index) => (
                        <tr
                          key={index}
                          className="bg-white dark:border-gray-700 dark:bg-gray-800"
                        >
                          <td className="px-4 py-3">
                            <div className="w-52 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="w-28 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="w-36 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="w-28 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="w-36 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="w-36 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                          </td>
                        </tr>
                      ))
                    : pendingPayouts.map((payout, index) => (
                        <tr
                          key={`${payout.uid}-${index}`}
                          className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                        >
                          <td className="px-4 py-3 whitespace-nowrap">
                            {payout.data.userId}
                          </td>
                          <th
                            scope="row"
                            className="px-4 py-3 font-bold text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {payout.data.amount}
                            {getSymbolFromCurrency(payout.data.currency)}
                          </th>
                          <td className="px-4 py-3 whitespace-nowrap">
                            {payout.data.currency}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            {new Date(
                              payout.data.dateRequested._seconds * 1000
                            ).toLocaleString("en-GB", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            })}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            <span className="bg-yellow-100 text-yellow-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                              Pending
                            </span>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            Account ID: {payout.data.idAccount}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            <button
                              type="button"
                              onClick={() =>
                                handleProcessPayoutRequest(
                                  payout.document_id,
                                  true,
                                  payout.data.userId
                                )
                              }
                              className="text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-4 focus:ring-primary-300 font-bold rounded-lg text-sm px-3 py-2 text-center mr-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                            >
                              {processPayoutLoadingId === payout.document_id ? (
                                <div className="flex items-center justify-center text-center">
                                  <svg
                                    aria-hidden="true"
                                    className="w-4 h-4 -ml-1 mr-3 text-gray-200 animate-spin dark:text-gray-500 fill-primary-600 dark:fill-white"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                  Processing...
                                </div>
                              ) : (
                                "Approve payout"
                              )}
                            </button>
                            <button
                              type="button"
                              onClick={() =>
                                handleCancelPayoutRequest(
                                  payout.document_id,
                                  payout.data.userId
                                )
                              } // Assuming balance.id is the document ID
                              className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-bold rounded-lg text-sm px-3 py-2 text-center me-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
                            >
                              {cancelPayoutLoadingId === payout.document_id ? (
                                <div className="flex items-center justify-center text-center">
                                  <svg
                                    aria-hidden="true"
                                    className="w-4 h-4 -ml-1 mr-3 text-gray-200 animate-spin dark:text-gray-500 fill-primary-600 dark:fill-white"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                  Cancelling...
                                </div>
                              ) : (
                                "Cancel"
                              )}
                            </button>
                          </td>
                        </tr>
                      ))}
                  {!loading && isEmpty && (
                    <tr>
                      <td colSpan={6}>
                        <EmptyState />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <nav
              className="flex flex-row justify-between items-center p-4"
              aria-label="Table navigation"
            >
              <p className="text-sm">
                <span className="font-normal text-gray-500 dark:text-gray-400">
                  Pending requests:{" "}
                </span>
                <span className="font-semibold text-gray-900 dark:text-white">
                  {pendingPayouts.length}
                </span>
              </p>
            </nav>
          </div>
        </div>
      </section>
    </>
  );
}
