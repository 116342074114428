import React, { useContext, useState, useEffect } from "react";
import { BrokerContext } from "../../../contexts/BrokerContext.js";
import { getFirestore, doc, getDoc } from "firebase/firestore";

export default function DomainPage({ navigateToDomainSettings }) {
  const broker = useContext(BrokerContext)?.broker || {};
  const [configuredDomain, setConfiguredDomain] = useState("");
  const db = getFirestore();

  useEffect(() => {
    const fetchDomain = async () => {
      const domainDataRef = doc(db, "domains", broker.brokerData.uid);
      const docSnap = await getDoc(domainDataRef);
      if (docSnap.exists() && docSnap.data().status === "verified") {
        setConfiguredDomain(docSnap.data().domain);
      }
    };

    fetchDomain();
  }, [broker.brokerData.uid, db]);

  // Check if user object or userData is null or undefined
  if (!broker || !broker.userData) {
    return (
      <div className="fixed inset-0 flex items-center justify-center dark:bg-gray-900">
        <svg
          className="animate-spin h-10 w-10 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white text-left">
          Custom domain configuration
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500 text-left">
          Set up your custom domain for your brokerage.
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 dark:divide-gray-700 border-t border-gray-200 dark:border-gray-700 text-sm leading-6">
          <div className="pt-6">
            <div>
              <label
                htmlFor="customDomain"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Configured domain
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset dark:bg-gray-800 ring-gray-300 dark:ring-gray-700 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-600 sm:max-w-md">
                  <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                    https://
                  </span>
                  <input
                    type="text"
                    name="customDomain"
                    id="customDomain"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 dark:text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="platform.xohsync.io"
                    value={configuredDomain}
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div className="mt-6">
              <button
                onClick={navigateToDomainSettings}
                type="button"
                className="rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                Configure new custom domain
              </button>
            </div>
          </div>
        </dl>
      </div>
    </div>
  );
}
