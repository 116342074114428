import { useState } from "react";
import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Table } from "flowbite-react";
import getSymbolFromCurrency from "currency-symbol-map";
import Pagination from "../Pagination";

export default function ClientTransactions({ transactionsData }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Receive the prop
  // If transactionsData is empty or not provided, show the empty state
  if (!transactionsData || transactionsData.length === 0) {
    return (
      <div className="py-4 text-center relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
        <svg
          className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-700"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <DocumentMagnifyingGlassIcon />
        </svg>
        <h3 className="mt-2 text-sm font-semibold text-gray-900 dark:text-white">
          No transactions
        </h3>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          This user has no transaction history yet.
        </p>
      </div>
    );
  }

  // Calculate total pages
  const totalPages = Math.ceil(transactionsData.length / itemsPerPage);

  // First, sort the entire transactionsData array
  const sortedData = transactionsData.sort((a, b) => {
    if (
      a.timestamp &&
      a.timestamp.toDate &&
      b.timestamp &&
      b.timestamp.toDate
    ) {
      // To sort in descending order, ensure b's timestamp is subtracted from a's
      return b.timestamp.toDate() - a.timestamp.toDate();
    }
    return 0;
  });

  // Then, slice the sorted data for the current page
  const currentData = sortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Update page function
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  function StatusPill({ status }) {
    switch (status) {
      case "pending":
        return (
          <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
            Pending
          </span>
        );
      case "approved":
        return (
          <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
            Approved
          </span>
        );
      case "rejected":
        return (
          <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
            Rejected
          </span>
        );
      case "declined":
        return (
          <span className="bg-pink-100 text-pink-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300">
            Declined
          </span>
        );
      default:
        return (
          <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
            {status || "Unknown"}
          </span>
        );
    }
  }

  return (
    <div className="overflow-x-auto">
      <Table>
        <Table.Head>
          <Table.HeadCell>Transaction ID</Table.HeadCell>
          <Table.HeadCell>Payment method</Table.HeadCell>
          <Table.HeadCell>Status</Table.HeadCell>
          <Table.HeadCell>Processed amount</Table.HeadCell>
          <Table.HeadCell>Currency</Table.HeadCell>
          <Table.HeadCell>Conversion rate</Table.HeadCell>
          <Table.HeadCell>Date</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {currentData && // Use currentData instead of transactionsData
            currentData
              .sort((a, b) => {
                if (
                  a.timestamp &&
                  a.timestamp.toDate &&
                  b.timestamp &&
                  b.timestamp.toDate
                ) {
                  // To sort in descending order, ensure b's timestamp is subtracted from a's
                  return b.timestamp.toDate() - a.timestamp.toDate();
                }
                return 0;
              })
              .map(
                (
                  transaction // Use sorted transactionsData
                ) => (
                  <Table.Row
                    key={transaction.orderId}
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  >
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {transaction.orderId || "-"}
                    </Table.Cell>
                    <Table.Cell>
                      {" "}
                      {transaction.payment_method || "-"}
                    </Table.Cell>
                    <Table.Cell>
                      <StatusPill status={transaction.status} />
                    </Table.Cell>
                    <Table.Cell>
                      {transaction.processed_currency
                        ? getSymbolFromCurrency(
                            transaction.processed_currency
                          ) + (transaction.processed_amount / 100).toFixed(2)
                        : "-"}
                    </Table.Cell>
                    <Table.Cell>
                      {transaction.processed_currency || "-"}
                    </Table.Cell>
                    <Table.Cell>
                      {transaction.conversion_rate || "-"}
                    </Table.Cell>
                    <Table.Cell>
                      {transaction.timestamp && transaction.timestamp.toDate
                        ? transaction.timestamp
                            .toDate()
                            .toLocaleString(undefined, {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })
                        : transaction.timestamp || "-"}
                    </Table.Cell>
                  </Table.Row>
                )
              )}
        </Table.Body>
      </Table>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
