import { useState, useEffect, useContext } from "react";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import Spinner from "../../Shared/Spinner";
import { useNotification } from "../../../contexts/NotificationContext";
import { UserContext } from "../../../contexts/UserContext";
import KYCConfirmModal from "../../Modals/KYCModals/KYCConfirmModal";

// Utility function to transform title to Firestore field name
const transformTitleToFieldName = (title) => {
  return title.charAt(0).toLowerCase() + title.slice(1).replace(/\s/g, "");
};

export default function UserManualKYC() {
  // Remove the `clientData` prop
  const [fileUrls, setFileUrls] = useState({});
  const [loading, setLoading] = useState(true);
  const { showNotification } = useNotification();
  const [uploadingFiles, setUploadingFiles] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReviewPending, setIsReviewPending] = useState(false);

  const { user } = useContext(UserContext); // Get user object from UserContext
  const uid = user?.userData?.uid; // Access the user's UID

  // Drag and Drop Handlers
  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e, fileType) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      handleFileUpload(file, fileType);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (uid) {
        const db = getFirestore();

        // Fetch KYC data
        const kycRef = doc(db, "kyc", uid);
        const kycSnapshot = await getDoc(kycRef);

        if (kycSnapshot.exists()) {
          setFileUrls(kycSnapshot.data());
        } else {
          console.log("No KYC data found for this client!");
        }

        // Fetch user data for kycStatus
        const userRef = doc(db, "users", uid);
        const userSnapshot = await getDoc(userRef);

        if (
          userSnapshot.exists() &&
          userSnapshot.data().kycStatus === "pending"
        ) {
          setIsReviewPending(true);
        }

        setLoading(false);
      }
    };

    fetchData();
  }, [uid]);

  const files = [
    {
      title: "ID Document Front",
      size: "",
      source: fileUrls[transformTitleToFieldName("ID Document Front")],
    },
    {
      title: "ID Document Back",
      size: "",
      source: fileUrls[transformTitleToFieldName("ID Document Back")],
    },
    {
      title: "Proof of Address",
      size: "",
      source: fileUrls[transformTitleToFieldName("Proof of Address")],
    },
    {
      title: "Selfie with ID-document",
      size: "",
      source: fileUrls[transformTitleToFieldName("Selfie with ID-document")],
    },
  ];

  const handleFileUpload = async (file, fileType) => {
    const storage = getStorage();
    let storagePath = "";

    switch (fileType) {
      case "ID Document Front":
        storagePath = `kyc-uploads/${user.userData.uid}/id-document/front.${
          file.type.split("/")[1]
        }`;
        break;
      case "ID Document Back":
        storagePath = `kyc-uploads/${user.userData.uid}/id-document/back.${
          file.type.split("/")[1]
        }`;
        break;
      case "Proof of Address":
        storagePath = `kyc-uploads/${
          user.userData.uid
        }/proof-of-address/document.${file.type.split("/")[1]}`;
        break;
      case "Selfie with ID-document":
        storagePath = `kyc-uploads/${user.userData.uid}/selfie/selfie.${
          file.type.split("/")[1]
        }`;
        break;
      default:
        console.error("Invalid file type");
        return;
    }

    const storageRef = ref(storage, storagePath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Set the file as uploading right before starting the upload
    setUploadingFiles((prev) => ({ ...prev, [fileType]: true }));

    // Listen to upload progress, complete, and error events
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Handle progress...
      },
      (error) => {
        console.error("Upload failed:", error);
        showNotification("error", "File upload failed!"); // Show error notification
        setUploadingFiles((prev) => ({ ...prev, [fileType]: false }));
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        const fieldName = transformTitleToFieldName(fileType);

        // Update Firestore with the download URL
        const db = getFirestore();
        const docRef = doc(db, "kyc", user.userData.uid);
        await setDoc(
          docRef,
          {
            [fieldName]: downloadURL,
          },
          { merge: true }
        );

        // Update local state
        setFileUrls((prevState) => ({
          ...prevState,
          [fieldName]: downloadURL,
        }));

        showNotification("success", "File uploaded successfully!"); // Show success notification
        setUploadingFiles((prev) => ({ ...prev, [fileType]: false }));
      }
    );
  };

  const getFileExtensionFromUrl = (url) => {
    return url.split(".").pop().split("?")[0];
  };

  const handleDeleteFile = async (fileType) => {
    const storage = getStorage();
    let storagePath = "";

    const fileExtension = getFileExtensionFromUrl(
      fileUrls[transformTitleToFieldName(fileType)]
    );

    switch (fileType) {
      case "ID Document Front":
        storagePath = `kyc-uploads/${user.userData.uid}/id-document/front.${fileExtension}`;
        break;
      case "ID Document Back":
        storagePath = `kyc-uploads/${user.userData.uid}/id-document/back.${fileExtension}`;
        break;
      case "Proof of Address":
        storagePath = `kyc-uploads/${user.userData.uid}/proof-of-address/document.${fileExtension}`;
        break;
      case "Selfie with ID-document":
        storagePath = `kyc-uploads/${user.userData.uid}/selfie/selfie.${fileExtension}`;
        break;
      default:
        console.error("Invalid file type");
        return;
    }

    const storageRef = ref(storage, storagePath);

    // Delete from Firebase Storage
    try {
      await deleteObject(storageRef);

      // Once the file is deleted from Storage, delete the URL from Firestore
      const db = getFirestore();
      const docRef = doc(db, "kyc", user.userData.uid);
      const fieldName = transformTitleToFieldName(fileType);

      await setDoc(
        docRef,
        {
          [fieldName]: null,
        },
        { merge: true }
      );

      // Update local state
      setFileUrls((prevState) => ({ ...prevState, [fieldName]: null }));

      showNotification("success", "File deleted successfully!");
    } catch (error) {
      console.error("Error deleting file:", error);
      showNotification("error", "Failed to delete file!");
    }
  };

  const steps = [
    {
      id: "Step 1",
      name: "Account registration",
      status: "complete",
    },
    {
      id: "Step 2",
      name: "Account verification",
      status: isReviewPending ? "complete" : "current",
    },
    {
      id: "Step 3",
      name: "Account review",
      status: isReviewPending ? "current" : "upcoming",
    },
  ];

  const areAllDocumentsUploaded = () => {
    return (
      fileUrls[transformTitleToFieldName("ID Document Front")] &&
      fileUrls[transformTitleToFieldName("ID Document Back")] &&
      fileUrls[transformTitleToFieldName("Proof of Address")] &&
      fileUrls[transformTitleToFieldName("Selfie with ID-document")]
    );
  };

  return (
    <div>
      <div>
        <nav aria-label="Progress">
          <ol className="space-y-4 md:flex md:space-x-8 md:space-y-0 mb-16">
            {steps.map((step) => (
              <li key={step.name} className="md:flex-1 dark:text-white">
                {step.status === "complete" ? (
                  <a
                    href={step.href}
                    className="group flex flex-col border-l-4 border-primary-600 py-2 pl-4  md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                  >
                    <span className="text-sm font-medium text-primary-600 ">
                      {step.id}
                    </span>
                    <span className="text-sm font-medium dark:text-white">
                      {step.name}
                    </span>
                  </a>
                ) : step.status === "current" ? (
                  <a
                    href={step.href}
                    className="flex flex-col border-l-4 border-primary-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                    aria-current="step"
                  >
                    <span className="text-sm font-medium text-primary-600">
                      {step.id}
                    </span>
                    <span className="text-sm font-medium">
                      {step.name}
                      {step.name === "Account review" && (
                        <span className="ml-2">
                          <svg
                            aria-hidden="true"
                            className="h-3 w-3 inline-block text-gray-200 animate-spin dark:text-gray-600 fill-primary-600 dark:fill-white"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        </span>
                      )}
                    </span>
                  </a>
                ) : (
                  <a
                    href={step.href}
                    className="group flex flex-col border-l-4 border-gray-200 dark:border-white/5 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                  >
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                      {step.id}
                    </span>
                    <span className="text-sm font-medium dark:text-white">
                      {step.name}
                    </span>
                  </a>
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <label
        htmlFor="fileList"
        className="mb-2 block text-sm font-medium leading-6 text-gray-900 dark:text-white"
      >
        Please upload the following documents:
      </label>
      {loading ? (
        <Spinner />
      ) : (
        <ul
          id="fileList"
          className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-2 xl:gap-x-8"
        >
          {files.map((file) => (
            <li key={file.title} className="relative">
              {uploadingFiles[file.title] ? (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <svg
                    className="animate-spin h-10 w-10 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              ) : file.source ? (
                file.source.toLowerCase().includes(".pdf") ? (
                  <div
                    className={`group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 dark:bg-gray-900 ${
                      isReviewPending ? "opacity-50" : ""
                    }`}
                  >
                    <iframe
                      src={file.source}
                      className={`w-full h-full ${
                        isReviewPending ? "filter grayscale" : ""
                      }`}
                      title="PDF Preview"
                    ></iframe>
                    <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                      <span className="isolate inline-flex rounded-md shadow-sm">
                        <button
                          type="button"
                          className="relative inline-flex items-center rounded-l-md bg-white dark:bg-gray-900 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:bg-gray-50 dark:hover:bg-gray-950 focus:z-10"
                          onClick={() => window.open(file.source, "_blank")}
                          disabled={isReviewPending}
                        >
                          View file
                        </button>
                        <button
                          type="button"
                          className="relative -ml-px inline-flex items-center bg-white dark:bg-gray-900 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:bg-gray-50 dark:hover:bg-gray-950 focus:z-10"
                          onClick={() => handleDeleteFile(file.title)}
                          disabled={isReviewPending}
                        >
                          Delete
                        </button>
                        <button
                          type="button"
                          className="relative -ml-px inline-flex items-center rounded-r-md bg-white dark:bg-gray-900 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:bg-gray-50 dark:hover:bg-gray-950 focus:z-10"
                          onClick={() =>
                            document
                              .getElementById(`file-upload-${file.title}`)
                              .click()
                          }
                          disabled={isReviewPending}
                        >
                          Upload new
                        </button>
                        <input
                          id={`file-upload-${file.title}`}
                          name={`file-upload-${file.title}`}
                          type="file"
                          className="sr-only"
                          onChange={(e) =>
                            handleFileUpload(e.target.files[0], file.title)
                          }
                          disabled={isReviewPending}
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className={`group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 dark:bg-gray-900 ${
                        isReviewPending ? "opacity-50" : ""
                      }`}
                    >
                      <img
                        src={file.source}
                        alt=""
                        className={`pointer-events-none object-cover group-hover:opacity-75 ${
                          isReviewPending ? "filter grayscale" : ""
                        }`}
                      />
                      <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                        <span className="isolate inline-flex rounded-md shadow-sm">
                          <button
                            type="button"
                            className="relative inline-flex items-center rounded-l-md bg-white dark:bg-gray-900 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:bg-gray-50 dark:hover:bg-gray-950 focus:z-10"
                            onClick={() => window.open(file.source, "_blank")}
                            disabled={isReviewPending}
                          >
                            View file
                          </button>
                          <button
                            type="button"
                            className="relative -ml-px inline-flex items-center bg-white dark:bg-gray-900 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:bg-gray-50 dark:hover:bg-gray-950 focus:z-10"
                            onClick={() => handleDeleteFile(file.title)}
                            disabled={isReviewPending}
                          >
                            Delete
                          </button>
                          <button
                            type="button"
                            className="relative -ml-px inline-flex items-center rounded-r-md bg-white dark:bg-gray-900 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:bg-gray-50 dark:hover:bg-gray-950 focus:z-10"
                            onClick={() =>
                              document
                                .getElementById(`file-upload-${file.title}`)
                                .click()
                            }
                            disabled={isReviewPending}
                          >
                            Upload new
                          </button>
                          <input
                            id={`file-upload-${file.title}`}
                            name={`file-upload-${file.title}`}
                            type="file"
                            className="sr-only"
                            onChange={(e) =>
                              handleFileUpload(e.target.files[0], file.title)
                            }
                            disabled={isReviewPending}
                          />
                        </span>
                      </div>
                    </div>
                    <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900 dark:text-white">
                      {file.title}
                    </p>
                  </>
                )
              ) : (
                <div
                  className="col-span-full"
                  onDragOver={onDragOver}
                  onDrop={(e) => onDrop(e, file.title)}
                >
                  <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 dark:border-white/10 px-6 py-10">
                    <div className="text-center">
                      <DocumentPlusIcon
                        className="mx-auto h-12 w-12 text-gray-300 dark:text-gray-700"
                        aria-hidden="true"
                      />
                      <div className="mt-4 flex text-sm leading-6 text-gray-600 dark:text-gray-400">
                        <label
                          htmlFor={`file-upload-empty-${file.title}`}
                          className="relative cursor-pointer rounded-md bg-white dark:bg-gray-900 font-semibold text-primary-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-600 focus-within:ring-offset-2 hover:text-primary-500"
                        >
                          <span>Upload a file</span>
                          <input
                            id={`file-upload-empty-${file.title}`}
                            name={`file-upload-empty-${file.title}`}
                            type="file"
                            className="sr-only"
                            onChange={(e) =>
                              handleFileUpload(e.target.files[0], file.title)
                            }
                          />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-xs leading-5 text-gray-600">
                        PNG, JPG, PDF up to 5MB
                      </p>
                    </div>
                  </div>
                  <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900 dark:text-white">
                    {file.title}
                  </p>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
      <KYCConfirmModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={() => setIsReviewPending(true)}
      />
      <button
        type="button"
        className={`mt-8 float-right rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm ${
          isReviewPending
            ? "bg-gray-500 cursor-not-allowed"
            : "bg-primary-600 hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
        }`}
        onClick={() => {
          if (areAllDocumentsUploaded()) {
            setIsModalOpen(true);
          } else {
            showNotification(
              "warning",
              "Please upload all the required documents before submitting."
            );
          }
        }}
        disabled={isReviewPending}
      >
        {isReviewPending ? (
          <>
            <span className="mr-2">
              <svg
                aria-hidden="true"
                className="w-4 h-4 -ml-1 mr-1 inline-block text-gray-200 animate-spin dark:text-gray-600 fill-primary-600 dark:fill-white"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </span>
            Review in progress...
          </>
        ) : (
          "Click here to submit your documents"
        )}
      </button>
    </div>
  );
}
