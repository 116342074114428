import React, { useState, useEffect } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate, useLocation, Link } from "react-router-dom"; // import useNavigate
import logo from "../../logo.svg";
import logoDark from "../../logoDark.svg";
import useDarkMode from "../../utils/useDarkMode";
import { useNotification } from "../../contexts/NotificationContext";
import { handleFirebaseError } from "../../utils/handleFirebaseError";
import {
  getFirestore,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import GoogleSignIn from "../Shared/GoogleSignIn";
import LanguageSelector from "../Shared/LanguageSelector.js";
import { useTranslation } from "react-i18next";

function validateStrongPassword(password) {
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password);

  return (
    password.length >= 8 &&
    hasUpperCase &&
    hasLowerCase &&
    hasNumber &&
    hasSpecialChar
  );
}

function validateEmail(email) {
  // Regular expression for email validation
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return pattern.test(email);
}

export default function Registration() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const { showNotification } = useNotification(); // from NotificationContext
  const [logoUrls, setLogoUrls] = useState(null);
  const [logoLoading, setLogoLoading] = useState(true);
  const [isPasswordHovered, setIsPasswordHovered] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const { t } = useTranslation();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const getQuery = useQuery();
  const brokerUID = getQuery.get("brokerUID");
  const ib = getQuery.get("ib");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const darkModeEnabled = useDarkMode();

  useEffect(() => {
    if (ib) {
      localStorage.setItem("ib", ib);
    }
  }, [ib]);

  useEffect(() => {
    if (!brokerUID && !window.location.hostname.startsWith("platform")) {
      navigate("/incorrectlink");
      return; // Make sure to return so that the rest of the code in the effect doesn't run.
    }
  }, [brokerUID, navigate]);

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Reset errors
    setPasswordError("");
    setConfirmPasswordError("");

    // Validate email
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      setIsLoading(false);
      return;
    }

    // Validate strong password
    if (!validateStrongPassword(password)) {
      setPasswordError(
        "Password must be at least 8 characters long and include an uppercase letter, lowercase letter, number, and special character."
      );
      setIsLoading(false);
      return;
    }

    // Check if password and confirmPassword match
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      setIsLoading(false);
      return;
    }

    // Reset the confirm password error if passwords match
    setConfirmPasswordError("");

    // Continue with the rest of the form processing...
    const auth = getAuth();

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      console.log(user); // Log the user object to the console.

      // Conditionally include brokerUID in the redirect URL
      const redirectUrl =
        brokerUID && brokerUID !== "null" && brokerUID !== ""
          ? `/onboarding?brokerUID=${brokerUID}${ib ? `&ib=${ib}` : ""}`
          : `/onboarding${ib ? `?ib=${ib}` : ""}`;

      navigate(redirectUrl);
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = handleFirebaseError(errorCode); // translate error
      showNotification("error", errorMessage);
      console.log(`Error code: ${errorCode}, Error message: ${errorMessage}`);
    } finally {
      setIsLoading(false); // set loading to false after registration is finished
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setPassword(value);

    setPasswordRequirements({
      minLength: value.length >= 8,
      hasUpperCase: /[A-Z]/.test(value),
      hasLowerCase: /[a-z]/.test(value),
      hasNumber: /\d/.test(value),
      hasSpecialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value),
    });
  };

  const renderSvg = (condition) => {
    if (condition) {
      return (
        <svg
          className="w-3.5 h-3.5 me-2 text-green-400 dark:text-green-500"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 16 12"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M1 5.917 5.724 10.5 15 1.5"
          />
        </svg>
      );
    } else {
      return (
        <svg
          className="w-3 h-3 me-2.5 text-gray-300 dark:text-gray-400"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
      );
    }
  };

  const getStepColor = (index, totalRequirementsMet) => {
    return index < totalRequirementsMet
      ? "bg-green-300 dark:bg-green-400"
      : "bg-gray-200 dark:bg-gray-600";
  };

  // Calculate the total number of requirements met
  const totalRequirementsMet = [
    passwordRequirements.hasUpperCase && passwordRequirements.hasLowerCase,
    passwordRequirements.hasSpecialChar,
    passwordRequirements.minLength,
  ].filter(Boolean).length;

  const handleAuthentication = () => {
    // Conditionally include brokerUID in the redirect URL
    const redirectUrl =
      brokerUID && brokerUID !== "null" && brokerUID !== ""
        ? `/onboarding?brokerUID=${brokerUID}${ib ? `&ib=${ib}` : ""}`
        : `/onboarding${ib ? `?ib=${ib}` : ""}`;

    navigate(redirectUrl);
  };

  useEffect(() => {
    // If the hostname doesn't start with "platform", exit early
    if (!window.location.hostname.startsWith("platform.")) {
      setLogoLoading(false); // Ensure loading is set to false
      return;
    }

    const fetchLogoUrls = async () => {
      try {
        setLogoLoading(true);
        const db = getFirestore();
        const domain = window.location.hostname.split(".").slice(-2).join("."); // Get the domain

        // Query the "whitelabel" collection where the domain field matches the domain
        const whitelabelQuery = query(
          collection(db, "whitelabel"),
          where("domain", "==", domain)
        );
        const whitelabelSnapshot = await getDocs(whitelabelQuery);

        // Check if any documents match the query
        if (!whitelabelSnapshot.empty) {
          // Get the first document from the query results
          const whitelabelDoc = whitelabelSnapshot.docs[0];

          // Update the logo URLs
          setLogoUrls({
            dark: whitelabelDoc.data().logoUrlDark,
            light: whitelabelDoc.data().logoUrlLight,
          });
        }
      } catch (error) {
        console.error("An error occurred while fetching logo URLs:", error);
      } finally {
        setLogoLoading(false); // Ensure this gets called even if an error occurs
      }
    };

    // Fetch the logo URLs
    fetchLogoUrls();
  }, []);

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          {logoLoading ? (
            <div className="mb-6 animate-pulse rounded-full bg-gray-200 dark:bg-white/10 h-10 w-10 mx-auto"></div>
          ) : (
            <img
              className="mb-6 mx-auto h-10 w-auto"
              src={
                logoUrls
                  ? darkModeEnabled
                    ? logoUrls.dark
                    : logoUrls.light
                  : darkModeEnabled
                  ? logoDark
                  : logo
              }
              alt="Logo"
            />
          )}

          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white text-center">
                {t("registration.createAccountTitle")}
              </h1>

              <GoogleSignIn
                parentIsLoading={isLoading}
                onAuthentication={handleAuthentication}
              />
              <div className="flex items-center">
                <div className="w-full h-0.5 bg-gray-200 dark:bg-gray-700"></div>
                <div className="px-5 text-center text-gray-500 dark:text-gray-400">
                  {t("registration.or")}
                </div>
                <div className="w-full h-0.5 bg-gray-200 dark:bg-gray-700"></div>
              </div>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {t("auth.emailLabel")}
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="name@email.com"
                    onChange={handleEmailChange}
                    value={email}
                  />
                  {emailError && (
                    <div
                      className="flex p-4 mt-2 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-900 dark:text-red-400"
                      role="alert"
                    >
                      <svg
                        className="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                      </svg>
                      <span className="sr-only">Danger</span>
                      <div>
                        <span className="font-medium">
                          {t("registration.validEmail")}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {t("registration.password")}
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    required
                    value={password}
                    onChange={handlePasswordChange}
                    onMouseEnter={() => setIsPasswordHovered(true)}
                    onMouseLeave={() => {
                      if (!isPasswordFocused) {
                        setIsPasswordHovered(false);
                      }
                    }}
                    onFocus={() => {
                      setIsPasswordHovered(true);
                      setIsPasswordFocused(true);
                    }}
                    onBlur={() => {
                      setIsPasswordHovered(false);
                      setIsPasswordFocused(false);
                    }}
                  />
                  <div
                    id="popover-password"
                    role="tooltip"
                    className={`mt-2 absolute z-10 inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400 ${
                      isPasswordHovered ? "" : "invisible opacity-0"
                    }`}
                  >
                    <div className="p-3 space-y-2">
                      <h3 className="font-semibold text-gray-900 dark:text-white">
                        {t("registration.passwordTip")}
                      </h3>
                      <div className="grid grid-cols-3 gap-2">
                        <div
                          className={`h-1 ${getStepColor(
                            0,
                            totalRequirementsMet
                          )}`}
                        />
                        <div
                          className={`h-1 ${getStepColor(
                            1,
                            totalRequirementsMet
                          )}`}
                        />
                        <div
                          className={`h-1 ${getStepColor(
                            2,
                            totalRequirementsMet
                          )}`}
                        />
                      </div>
                      <p>{t("registration.additionalRequirement")}</p>
                      <ul>
                        <li className="flex items-center mb-1">
                          {renderSvg(
                            passwordRequirements.hasUpperCase &&
                              passwordRequirements.hasLowerCase
                          )}
                          {t("registration.requirement_1")}
                        </li>
                        <li className="flex items-center mb-1">
                          {renderSvg(passwordRequirements.hasSpecialChar)}
                          {t("registration.requirement_2")}
                        </li>
                        <li className="flex items-center">
                          {renderSvg(passwordRequirements.minLength)}
                          {t("registration.requirement_3")}
                        </li>
                      </ul>
                    </div>
                  </div>
                  {passwordError && (
                    <div
                      className="flex p-4 mt-2 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-900 dark:text-red-400"
                      role="alert"
                    >
                      <svg
                        className="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                      </svg>
                      <span className="sr-only">Danger</span>
                      <div>
                        <span className="font-medium">
                          {t("registration.requirementsInfoMessage")}
                        </span>
                        <ul className="mt-1.5 list-disc list-inside">
                          <li>{t("registration.requirement_1")}</li>
                          <li>{t("registration.requirement_2")}</li>
                          <li>{t("registration.requirement_3")}</li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="confirm-password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {t("registration.confirmPassword")}
                  </label>
                  <input
                    id="confirmPassword"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                  {confirmPasswordError && (
                    <div
                      className="flex p-4 mt-2 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-900 dark:text-red-400"
                      role="alert"
                    >
                      <svg
                        className="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                      </svg>
                      <span className="sr-only">Danger</span>
                      <div>
                        <span className="font-medium">
                          {t("registration.passwordsNotMatching")}
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                <button
                  type="submit"
                  className="flex w-full justify-center text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  disabled={isLoading} // disable the button while loading
                >
                  {isLoading ? (
                    <>
                      <svg
                        aria-hidden="true"
                        className="w-4 h-4 -ml-1 mr-3 text-gray-200 animate-spin dark:text-gray-500 fill-primary-600 dark:fill-white"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      {t("registration.createAccountButtonLoading")}
                    </>
                  ) : (
                    t("registration.createAccountButton")
                  )}
                </button>

                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  {t("registration.existingAccountText")}{" "}
                  <Link
                    to="/sign-in"
                    className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    {t("registration.existingAccountLink")}
                  </Link>
                </p>
              </form>
              <div className="mx-auto flex justify-center items-center">
                <LanguageSelector />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
