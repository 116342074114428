import React, { useContext, useEffect, useState, useMemo } from "react";
import { BrokerContext } from "../../../contexts/BrokerContext.js";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { useNotification } from "../../../contexts/NotificationContext.js";
import currencySymbols from "currency-symbol-map/map";
import TestPraxisModal from "../../Modals/TestPraxisModal.js";

export default function PSPSettings() {
  const brokerContext = useContext(BrokerContext);
  const broker = useMemo(() => brokerContext?.broker || {}, [brokerContext]);
  const { showNotification } = useNotification();
  const [pspProviders, setPspProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("None");
  const [praxisAppKey, setPraxisAppKey] = useState("");
  const [praxisMerchantId, setPraxisMerchantId] = useState("");
  const [praxisAppSecret, setPraxisAppSecret] = useState("");
  const [praxisURL, setPraxisURL] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [allowDepositBeforeKYC, setAllowDepositBeforeKYC] = useState(false);
  const [allowWithdrawalBeforeKYC, setAllowWithdrawalBeforeKYC] =
    useState(false);
  const [baseCurrency, setBaseCurrency] = useState("USD"); // Default to USD
  const [isTestPraxisModalVisible, setIsTestPraxisModalVisible] =
    useState(false); // Add this line

  const toggleTestPraxisModal = () => {
    setIsTestPraxisModalVisible(!isTestPraxisModalVisible);
  };

  const handleBaseCurrencyChange = (e) => {
    setBaseCurrency(e.target.value);
  };

  const handleAllowDepositChange = (e) => {
    setAllowDepositBeforeKYC(e.target.checked);
  };

  const handleAllowWithdrawalChange = (e) => {
    setAllowWithdrawalBeforeKYC(e.target.checked);
  };

  const handlePraxisAppKeyChange = (e) => {
    setPraxisAppKey(e.target.value);
  };

  const handlePraxisMerchantIdChange = (e) => {
    setPraxisMerchantId(e.target.value);
  };

  const handlePraxisAppSecretChange = (e) => {
    setPraxisAppSecret(e.target.value);
  };

  const handlePraxisURLChange = (e) => {
    setPraxisURL(e.target.value);
  };

  const handleSaveSettings = async () => {
    setIsSaving(true); // <-- Start saving

    const db = getFirestore();
    const brokerDoc = doc(db, "brokers", broker.brokerData.uid);
    const whitelabelDoc = doc(db, "whitelabel", broker.brokerData.uid);

    // Check the praxisURL and set the provider accordingly
    const provider =
      praxisURL === "https://pci-gw-test.praxispay.com/cashier/cashier"
        ? "Praxis Cashier Test"
        : selectedProvider;

    const pspData = {
      pspCredentials: {
        provider,
        praxisAppKey,
        praxisMerchantId,
        praxisAppSecret,
        praxisURL,
      },
      allowDepositBeforeKYC,
      allowWithdrawalBeforeKYC,
      baseCurrency, // <-- Add this line to save baseCurrency
    };

    try {
      await setDoc(brokerDoc, pspData, { merge: true });
      await setDoc(
        whitelabelDoc,
        {
          // <-- New line
          allowDepositBeforeKYC,
          allowWithdrawalBeforeKYC,
        },
        { merge: true }
      ); // <-- New line
      showNotification("success", "Settings updated successfully!");
    } catch (error) {
      console.error("Firestore update error:", error);
      showNotification("error", "Failed to update settings. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const fetchPspProviders = async () => {
      const db = getFirestore();
      const pspProvidersDoc = doc(db, "providers", "available_psp_providers");
      const docSnap = await getDoc(pspProvidersDoc);

      if (docSnap.exists()) {
        setPspProviders(docSnap.data().providers || []);
      }
    };

    fetchPspProviders();
  }, []);

  useEffect(() => {
    const fetchBrokerPspSettings = async () => {
      const db = getFirestore();
      const brokerDocRef = doc(db, "brokers", broker.brokerData.uid);
      const brokerDocSnap = await getDoc(brokerDocRef);

      if (brokerDocSnap.exists() && brokerDocSnap.data().pspCredentials) {
        const pspData = brokerDocSnap.data().pspCredentials;
        setSelectedProvider(pspData.provider || "None");
        setPraxisAppKey(pspData.praxisAppKey || "");
        setPraxisMerchantId(pspData.praxisMerchantId || "");
        setPraxisAppSecret(pspData.praxisAppSecret || "");
      }
    };

    fetchBrokerPspSettings();
  }, [broker.brokerData.uid]);

  useEffect(() => {
    const fetchBrokerKycSettings = async () => {
      if (broker && broker.brokerData && broker.brokerData.uid) {
        const db = getFirestore();
        const brokerDocRef = doc(db, "brokers", broker.brokerData.uid);
        const brokerDocSnap = await getDoc(brokerDocRef);

        if (brokerDocSnap.exists()) {
          const kycData = brokerDocSnap.data();
          setAllowDepositBeforeKYC(kycData.allowDepositBeforeKYC || false);
          setAllowWithdrawalBeforeKYC(
            kycData.allowWithdrawalBeforeKYC || false
          );
        }
      }
    };

    fetchBrokerKycSettings();
  }, [broker.brokerData.uid, broker]);

  const handleProviderChange = (e) => {
    setSelectedProvider(e.target.value);
  };

  // Check if user object or userData is null or undefined
  if (!broker || !broker.userData) {
    return (
      <div className="fixed inset-0 flex items-center justify-center dark:bg-gray-900">
        <svg
          className="animate-spin h-10 w-10 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white text-left">
          PSP configuration
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500 text-left">
          Set up your PSP provider and modify the related PSP rules.
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 dark:divide-gray-700 border-t border-gray-200 dark:border-gray-700 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <fieldset>
              <div className="space-y-5">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="allowDeposit"
                      name="allowDeposit"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 dark:border-gray-700 dark:bg-gray-800 text-primary-600 focus:ring-primary-600"
                      checked={allowDepositBeforeKYC}
                      onChange={handleAllowDepositChange}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="comments"
                      className="font-medium text-gray-900 dark:text-white"
                    >
                      Allow deposit before KYC
                    </label>
                    <p id="comments-description" className="text-gray-500">
                      If enabled, users are allowed to deposit without a
                      verified KYC.
                    </p>
                  </div>
                </div>

                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="allowWithdrawal"
                      name="allowWithdrawal"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 dark:border-gray-700 dark:bg-gray-800 text-primary-600 focus:ring-primary-600"
                      onChange={handleAllowWithdrawalChange}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="comments"
                      className="font-medium text-gray-900 dark:text-white"
                    >
                      Allow withdrawal before KYC
                    </label>
                    <p id="comments-description" className="text-gray-500">
                      If enabled, users are allowed to request a withdrawal
                      without a verified KYC.
                    </p>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </dl>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 dark:divide-gray-700 border-t border-gray-200 dark:border-gray-700 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <div>
              <label
                htmlFor="provider"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Select a PSP provider from the list
              </label>
              <select
                id="provider"
                name="provider"
                onChange={handleProviderChange}
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus:ring-2 focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-gray-800"
                value={selectedProvider}
              >
                {pspProviders.map((provider, index) => (
                  <option key={index}>{provider}</option>
                ))}
              </select>
            </div>

            <div className="ml-6">
              <label
                htmlFor="baseCurrency"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Base currency
              </label>
              <select
                id="baseCurrency"
                name="baseCurrency"
                onChange={handleBaseCurrencyChange}
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus:ring-2 focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-gray-800"
                value={baseCurrency}
              >
                {Object.keys(currencySymbols).map((currency) => (
                  <option key={currency} value={currency}>
                    {currency} ({currencySymbols[currency]})
                  </option>
                ))}
              </select>
            </div>
          </div>
        </dl>

        {selectedProvider === "Praxis Cashier" && (
          <dl className="mt-6 space-y-6 divide-y divide-gray-100 dark:divide-gray-700 border-t border-gray-200 dark:border-gray-700 text-sm leading-6">
            <div className="pt-6 sm:flex">
              <div>
                <label
                  htmlFor="praxisAppKey"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Praxis Application Key
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="praxisAppKey"
                    id="praxisAppKey"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-gray-800"
                    value={praxisAppKey} // Step 2
                    onChange={handlePraxisAppKeyChange}
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500" id="praxisAppKey">
                  Application Key from Praxis
                </p>
              </div>

              <div className="ml-6">
                <label
                  htmlFor="praxisMerchantId"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Praxis Merchant ID
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="praxisMerchantId"
                    id="praxisMerchantId"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-gray-800"
                    value={praxisMerchantId}
                    onChange={handlePraxisMerchantIdChange}
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500" id="praxisAppKey">
                  API Merchant ID from Praxis
                </p>
              </div>

              <div className="ml-6">
                <label
                  htmlFor="praxisAppSecret"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Praxis Application Secret
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    name="praxisAppSecret"
                    id="praxisAppSecret"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-gray-800"
                    value={praxisAppSecret}
                    onChange={handlePraxisAppSecretChange}
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500" id="praxisAppKey">
                  Application Secret from Praxis
                </p>
              </div>
            </div>
            <div className="pt-6 sm:flex">
              <div className="mt-2 pt-4">
                <label
                  htmlFor="praxisURL"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white pb-2"
                >
                  Praxis API URL
                </label>
                <input
                  type="url"
                  name="praxisURL"
                  id="praxisURL"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-gray-800"
                  value={praxisURL}
                  onChange={handlePraxisURLChange}
                />
                <p className="mt-2 text-sm text-gray-500" id="praxisAppKey">
                  Praxis API URL, used to differentiate between staging or
                  production environment.
                </p>
              </div>
              <div className="mt-2 pt-4 ml-6">
                <label
                  htmlFor="testPraxisModal"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white pb-2"
                >
                  Test Praxis
                </label>
                <button
                  type="button"
                  className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  onClick={toggleTestPraxisModal} // Attach the toggle function here
                >
                  Generate test link
                </button>
              </div>
            </div>
          </dl>
        )}

        <div className="mt-6">
          <button
            type="button"
            onClick={handleSaveSettings}
            className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
            disabled={isSaving} // Disabling the button while saving
          >
            {isSaving ? (
              <div className="flex items-center">
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 -ml-1 mr-3 text-gray-200 animate-spin dark:text-gray-500 fill-primary-600 dark:fill-white"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                Saving settings...
              </div>
            ) : (
              "Save settings"
            )}
          </button>
        </div>
      </div>
      <TestPraxisModal
        isVisible={isTestPraxisModalVisible}
        onClose={() => setIsTestPraxisModalVisible(false)}
      />
    </div>
  );
}
