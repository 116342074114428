import React, { useState, useEffect, useContext } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom"; // import useNavigate
import logo from "../../logo.svg";
import logoDark from "../../logoDark.svg";
import useDarkMode from "../../utils/useDarkMode";
import { useNotification } from "../../contexts/NotificationContext.js";
import { handleFirebaseError } from "../../utils/handleFirebaseError.js";
import { UserContext } from "../../contexts/UserContext";
import { BrokerContext } from "../../contexts/BrokerContext";
import {
  getFirestore,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import GoogleSignIn from "../Shared/GoogleSignIn.js";

export default function SignInBroker() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false); // remember me checkbox state
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const darkModeEnabled = useDarkMode();
  const { showNotification } = useNotification(); // from NotificationContext
  const { user, loading: userLoading } = useContext(UserContext);
  const { broker, loading: brokerLoading } = useContext(BrokerContext);
  const [resolvedUserType, setResolvedUserType] = useState(null); // "user", "broker", or "unauthenticated"
  const [isAuthenticated, setIsAuthenticated] = useState(false); // <-- New state to track successful Firebase authentication
  const [logoUrls, setLogoUrls] = useState(null);
  const [logoLoading, setLogoLoading] = useState(true);

  useEffect(() => {
    // If the hostname doesn't start with "platform", exit early
    if (!window.location.hostname.startsWith("platform.")) {
      setLogoLoading(false); // Ensure loading is set to false
      return;
    }

    const fetchLogoUrls = async () => {
      try {
        setLogoLoading(true);
        const db = getFirestore();
        const domain = window.location.hostname.split(".").slice(-2).join("."); // Get the domain

        // Query the "whitelabel" collection where the domain field matches the domain
        const whitelabelQuery = query(
          collection(db, "whitelabel"),
          where("domain", "==", domain)
        );
        const whitelabelSnapshot = await getDocs(whitelabelQuery);

        // Check if any documents match the query
        if (!whitelabelSnapshot.empty) {
          // Get the first document from the query results
          const whitelabelDoc = whitelabelSnapshot.docs[0];

          // Update the logo URLs
          setLogoUrls({
            dark: whitelabelDoc.data().logoUrlDark,
            light: whitelabelDoc.data().logoUrlLight,
          });
        }
      } catch (error) {
        console.error("An error occurred while fetching logo URLs:", error);
      } finally {
        setLogoLoading(false); // Ensure this gets called even if an error occurs
      }
    };

    // Fetch the logo URLs
    fetchLogoUrls();
  }, []);

  useEffect(() => {
    const rememberedEmail = localStorage.getItem("email");
    if (rememberedEmail) {
      setEmail(rememberedEmail);
      setRemember(true);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const auth = getAuth();

    if (remember) {
      localStorage.setItem("email", email);
    } else {
      localStorage.removeItem("email");
    }

    try {
      await signInWithEmailAndPassword(auth, email, password);
      setIsAuthenticated(true); // Set isAuthenticated to true once Firebase authentication succeeds
    } catch (error) {
      const errorMessage = handleFirebaseError(error.code); // translate error
      showNotification("error", errorMessage);
      console.log(`Error code: ${error.code}, Error message: ${errorMessage}`);
    } finally {
      setIsLoading(false); // set loading to false after login is finished
    }
  };

  useEffect(() => {
    // If the user has been authenticated and both contexts have resolved, determine the user type
    if (isAuthenticated && !userLoading && !brokerLoading) {
      if (user && user.status === "broker") {
        setResolvedUserType("broker");
      } else if (!user && broker) {
        setResolvedUserType("broker");
      } else if (user && user.status !== "broker") {
        setResolvedUserType("user");
      } else {
        // Handle any other case, like setting the user type to "unauthenticated".
      }
    }
  }, [isAuthenticated, userLoading, brokerLoading, broker, user]);

  useEffect(() => {
    // Only execute this effect if both contexts have resolved
    if (!userLoading && !brokerLoading) {
      if (resolvedUserType === "user") {
        navigate("/dashboard");
      } else if (resolvedUserType === "broker") {
        navigate("/broker/dashboard/clients");
      }
    }
  }, [resolvedUserType, userLoading, brokerLoading, navigate]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleRememberChange = (event) => {
    setRemember(event.target.checked);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // Add handleAuthentication function
  const handleAuthentication = () => {
    setIsAuthenticated(true);
    setIsLoading(false);
  };

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
            {logoLoading ? (
              <div className="animate-pulse rounded-full bg-gray-200 dark:bg-white/10 h-10 w-10 mx-auto"></div>
            ) : (
              <img
                className="mx-auto h-10 w-auto"
                src={
                  logoUrls
                    ? darkModeEnabled
                      ? logoUrls.dark
                      : logoUrls.light
                    : darkModeEnabled
                    ? logoDark
                    : logo
                }
                alt="Logo"
              />
            )}
          </div>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Sign in to your account
              </h1>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Your email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="name@email.com"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="remember"
                        aria-describedby="remember"
                        type="checkbox"
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                        required=""
                        checked={remember}
                        onChange={handleRememberChange}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="remember"
                        className="text-gray-500 dark:text-gray-300"
                      >
                        Remember me
                      </label>
                    </div>
                  </div>
                  <Link
                    to="/forgotpassword/"
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Forgot password?
                  </Link>
                </div>
                <button
                  type="submit"
                  className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  disabled={isLoading} // disable the button while loading
                >
                  {isLoading ? (
                    <div className="flex items-center justify-center text-center">
                      <svg
                        aria-hidden="true"
                        className="w-4 h-4 -ml-1 mr-3 text-gray-200 animate-spin dark:text-gray-600 fill-primary-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      Signing in...
                    </div>
                  ) : (
                    "Sign in"
                  )}
                </button>
                <div className="relative mt-10">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-200 dark:border-white/10" />
                  </div>
                  <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-white dark:bg-gray-800 px-6 text-gray-900 dark:text-white">
                      Or continue with
                    </span>
                  </div>
                </div>
                <GoogleSignIn
                  parentIsLoading={isLoading}
                  onAuthentication={handleAuthentication}
                />
                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Don’t have an account yet?{" "}
                  <Link
                    to="/broker/registration"
                    className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Sign up
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
