import { useState, useContext, useEffect } from "react";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { BrokerContext } from "../../../../contexts/BrokerContext";
import { useNotification } from "../../../../contexts/NotificationContext";
import IBSlideOver from "./IBSlideOver";
import getSymbolFromCurrency from "currency-symbol-map";
import { Table } from "flowbite-react";
import { Tooltip } from "flowbite-react";

const tiers = [
  {
    id: 1,
    name: "Master-IB",
    href: "#",
    status: "Tier 1",
    dueDate: "March 17, 2023",
    dueDateTime: "2023-03-17T00:00Z",
  },
  {
    id: 2,
    name: "Sub-IB",
    href: "#",
    status: "Tier 2",
    dueDate: "May 5, 2023",
    dueDateTime: "2023-05-05T00:00Z",
  },
  {
    id: 3,
    name: "Subsequent IBs",
    href: "#",
    status: "Tier 3",
    dueDate: "May 25, 2023",
    dueDateTime: "2023-05-25T00:00Z",
  },
];

export default function IBSettings() {
  const [open, setOpen] = useState(false);
  const { broker } = useContext(BrokerContext);
  const brokerUID = broker?.brokerData?.uid;
  const { showNotification } = useNotification(); // from NotificationContext
  const db = getFirestore();
  const [selectedTier, setSelectedTier] = useState(null);
  const [tierData, setTierData] = useState({ tier1: {}, tier2: {}, tier3: {} });
  const [baseCurrency, setBaseCurrency] = useState("USD");
  const [commissionRatios, setCommissionRatios] = useState({
    tier1: 33,
    tier2: 33,
    tier3: 34,
  });
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!broker || !broker.brokerData) {
      console.log("Broker data is not available");
      return;
    }

    const fetchCommissionSettings = async (tierName) => {
      const docRef = doc(db, "whitelabel", brokerUID, "ibCommission", tierName);
      const docSnap = await getDoc(docRef);

      if (docSnap?.exists()) {
        const data = docSnap.data();
        setTierData((prevData) => ({ ...prevData, [tierName]: data }));
      } else {
        console.log(`No such document for ${tierName}!`);
      }
    };

    ["tier1", "tier2", "tier3"].forEach(fetchCommissionSettings);
  }, [brokerUID, db, broker]);

  const handleCommissionChange = (tierId, value) => {
    setCommissionRatios((prevRatios) => ({
      ...prevRatios,
      [tierId]: value,
    }));
  };

  useEffect(() => {
    if (!broker || !broker.brokerData) {
      console.log("Broker data is not available");
      return;
    }

    const fetchCommissionRatios = async () => {
      const docRef = doc(
        db,
        "whitelabel",
        brokerUID,
        "ibCommission",
        "commissionRatios"
      );
      const docSnap = await getDoc(docRef);

      if (docSnap?.exists()) {
        const data = docSnap.data();
        const ratios = data.commissionRatios; // Access the commissionRatios map
        setCommissionRatios({
          tier1: ratios.tier1 || 0,
          tier2: ratios.tier2 || 0,
          tier3: ratios.tier3 || 0,
        });
      } else {
        console.log(`No such document for commissionRatios!`);
        setCommissionRatios({ tier1: 0, tier2: 0, tier3: 0 });
      }
    };

    fetchCommissionRatios();
  }, [brokerUID, db, broker]);

  function getCPABadgeStatus(tierId) {
    const tier = tierData[`tier${tierId}`];
    const isCPAEnabled = tier?.cpa?.enabled;

    let tooltipContent = "";

    if (isCPAEnabled) {
      tooltipContent += `CPA Fixed Commission: ${getSymbolFromCurrency(
        baseCurrency
      )}${tier.cpa.fixedCommission}, CPA Percentage: ${tier.cpa.percentage}%`;
    }

    if (isCPAEnabled) {
      return (
        <Tooltip content={tooltipContent}>
          <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
            Enabled
          </span>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip content="No CPA commission data available">
          <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
            Disabled
          </span>
        </Tooltip>
      );
    }
  }

  function getVolumeBadgeStatus(tierId) {
    const tier = tierData[`tier${tierId}`];
    const isVolumeEnabled = tier?.volume?.enabled;

    let tooltipContent = "";

    if (isVolumeEnabled) {
      tooltipContent += `Volume Fixed Lot Commission: ${getSymbolFromCurrency(
        baseCurrency
      )}${tier.volume.fixedLotCommission}, Volume Percentage Commission: ${
        tier.volume.percentageCommission
      }%, Volume Swap Commission: ${getSymbolFromCurrency(baseCurrency)}${
        tier.volume.swapCommission
      }`;
    }

    if (isVolumeEnabled) {
      return (
        <Tooltip content={tooltipContent}>
          <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
            Enabled
          </span>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip content="No volume commission data available">
          <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
            Disabled
          </span>
        </Tooltip>
      );
    }
  }

  useEffect(() => {
    const fetchBrokerData = async () => {
      if (!brokerUID) return;
      const brokerRef = doc(db, "brokers", brokerUID);
      const brokerSnap = await getDoc(brokerRef);

      if (brokerSnap.exists()) {
        const brokerData = brokerSnap.data();
        setBaseCurrency(brokerData.baseCurrency);
      } else {
        console.log("No such document!");
      }
    };

    fetchBrokerData();
  }, [brokerUID, db]);

  const saveRatioSettings = async () => {
    setIsSaving(true);

    const docRef = doc(
      db,
      "whitelabel",
      brokerUID,
      "ibCommission",
      "commissionRatios"
    );

    // Convert all values in commissionRatios to numbers
    const commissionRatiosToSave = Object.fromEntries(
      Object.entries(commissionRatios).map(([key, value]) => [
        key,
        Number(value),
      ])
    );

    // Save the commission ratios
    await setDoc(
      docRef,
      { commissionRatios: commissionRatiosToSave },
      { merge: true }
    );

    showNotification("success", "Commission settings saved successfully!");
    setIsSaving(false);
  };

  return (
    <div className="bg-gray-50 dark:bg-gray-900 antialiased">
      <Table>
        <Table.Head>
          <Table.HeadCell>Tier</Table.HeadCell>
          <Table.HeadCell>Tier Level</Table.HeadCell>
          <Table.HeadCell>CPA Commission</Table.HeadCell>
          <Table.HeadCell>Volume Commission</Table.HeadCell>
          <Table.HeadCell>Ratio</Table.HeadCell>
          <Table.HeadCell>
            <span className="sr-only">Edit</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {tiers.map((tier) => (
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {tier.name}
              </Table.Cell>
              <Table.Cell>{tier.status}</Table.Cell>
              <Table.Cell>{getCPABadgeStatus(tier.id)}</Table.Cell>
              <Table.Cell>{getVolumeBadgeStatus(tier.id)}</Table.Cell>
              <Table.Cell className="flex items-center">
                <input
                  id="commissionRatioInput"
                  type="number"
                  min="0"
                  max="100"
                  value={commissionRatios[`tier${tier.id}`]}
                  onChange={(e) =>
                    handleCommissionChange(`tier${tier.id}`, e.target.value)
                  }
                  className="block rounded-md border-0 py-1.5 dark:bg-white/5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm"
                />
                <span className="ml-2">%</span>
              </Table.Cell>
              <Table.Cell>
                <a
                  href={tier.href}
                  className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                  onClick={() => {
                    setOpen(true);
                    setSelectedTier(tier);
                  }}
                >
                  Modify tier
                </a>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <IBSlideOver
        open={open}
        setOpen={setOpen}
        tier={selectedTier}
        tierData={tierData}
        baseCurrency={baseCurrency}
      />
    </div>
  );
}
