import ClientTabs from "../../Shared/ClientDetailComponents/ClientTabs";
import ClientAccountDetails from "../../Shared/ClientDetailComponents/ClientAccountDetails";
import ClientAccounts from "../../Shared/ClientDetailComponents/ClientAccounts";
import ClientTransactions from "../../Shared/ClientDetailComponents/ClientTransactions";
import ClientKYCDetails from "../../Shared/ClientDetailComponents/ClientKYCDetails";
import ClientBonuses from "../../Shared/ClientDetailComponents/ClientBonuses";
import { useParams } from "react-router-dom";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { useState, useEffect, useContext } from "react";
import { BrokerContext } from "../../../contexts/BrokerContext";
import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import { Link } from "react-router-dom";
import ClientOverview from "../../Shared/ClientDetailComponents/ClientOverview";

export default function ClientDetails() {
  const { clientId } = useParams();
  const [clientData, setClientData] = useState(null);
  const [tradingAccountsData, setTradingAccountsData] = useState(null);
  const [clientTransactions, setClientTransactions] = useState(null);
  const [bonusData, setBonusData] = useState(null);
  const [activeTab, setActiveTab] = useState("Overview");
  const { broker } = useContext(BrokerContext);
  const brokerUID = broker?.brokerData?.uid;

  useEffect(() => {
    if (!brokerUID) {
      // If brokerUID is not available, don't try to fetch data
      console.log("brokerUID is not available yet.");
      return;
    }

    const db = getFirestore();

    // Set up the real-time listener for clientData
    const unsubscribeClient = onSnapshot(
      doc(db, "users", clientId),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          setClientData(docSnapshot.data());
        } else {
          console.log("No such client!");
        }
      }
    );

    const fetchData = async () => {
      // Fetch tradingAccountsData
      const tradingAccountsDoc = doc(db, "xmanager_accounts", clientId);
      const tradingAccountsSnapshot = await getDoc(tradingAccountsDoc);

      if (tradingAccountsSnapshot.exists()) {
        setTradingAccountsData(tradingAccountsSnapshot.data().trading_accounts);
      } else {
        console.log("No trading accounts data found for this client!");
      }

      // Fetch client transactions
      const transactionsQuery = query(
        collection(db, "transactions"),
        where("uid", "==", clientId),
        where("brokerUID", "==", brokerUID)
      );
      const transactionsSnapshot = await getDocs(transactionsQuery);

      if (!transactionsSnapshot.empty) {
        const transactionsData = transactionsSnapshot.docs.map((doc) =>
          doc.data()
        );
        setClientTransactions(transactionsData);
      } else {
        console.log("No transactions found for this client!");
      }

      // Fetch bonus data by directly accessing the document with ID equal to clientId
      const bonusDocRef = doc(db, "bonus", clientId);
      const bonusDocSnapshot = await getDoc(bonusDocRef);
      if (bonusDocSnapshot.exists()) {
        console.log("Bonus document data:", bonusDocSnapshot.data()); // Log the data of the document
        setBonusData([bonusDocSnapshot.data()]); // Store the bonus data in an array
      } else {
        console.log("No bonus data found for this client!");
      }
    };

    fetchData();

    // Cleanup function: Unsubscribe from the real-time updates when the component is unmounted
    return () => {
      unsubscribeClient();
    };
  }, [clientId, brokerUID]);

  return (
    <>
      <div className="mb-4">
        <Breadcrumb aria-label="Breadcrumb">
          <Breadcrumb.Item icon={HiHome}>
            <Link to="/broker/dashboard/clients">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to="/broker/dashboard/clients">All Users</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {clientData
              ? `${clientData.name} ${clientData.surname}`
              : "Loading..."}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <ClientTabs
        clientData={clientData}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {activeTab === "Overview" && (
        <ClientOverview
          clientData={clientData}
          tradingAccountsData={tradingAccountsData}
          transactionsData={clientTransactions}
          bonusData={bonusData}
        />
      )}
      {activeTab === "Account details" && (
        <ClientAccountDetails clientData={clientData} />
      )}
      {activeTab === "Trading accounts" && (
        <ClientAccounts tradingAccountsData={tradingAccountsData} />
      )}
      {activeTab === "Transactions" && (
        <ClientTransactions transactionsData={clientTransactions} />
      )}
      {activeTab === "KYC" && <ClientKYCDetails clientData={clientData} />}
      {activeTab === "Bonus" && <ClientBonuses bonusData={bonusData} />}
    </>
  );
}
