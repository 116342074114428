import React, { createContext, useState, useEffect } from "react";
import { fetchUserData } from "../services/userService";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase.js";
import { signOut } from "firebase/auth";
import { useNotification } from "./NotificationContext";

const UserContext = createContext(null);

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { showNotification } = useNotification();

  useEffect(() => {
    setLoading(true); // set loading to true when auth state changes

    const unsubscribe = onAuthStateChanged(auth, async (userAuth) => {
      if (userAuth) {
        const userData = await fetchUserData();
        setUser(userData);
      } else {
        setUser(null);
      }
      setLoading(false); // set loading to false after fetching or processing the auth state change
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user && user.userData) {
      const { favicon, displayName } = user.userData;

      // Setting the page title
      document.title = displayName || "XOH Sync";

      // Setting the favicon
      let link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = favicon || "/Favicon.ico";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
  }, [user]);

  const logout = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
        showNotification("success", "Successfully logged out!"); // display the successful message
      })
      .catch((error) => {
        // Handle any errors
        showNotification("error", "Error during logout.");
      });
  };

  return (
    <UserContext.Provider
      value={{ user, setUser, fetchUserData, logout, loading }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
