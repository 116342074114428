import React, { useContext, useEffect, useState } from "react";
import { BrokerContext } from "../../../contexts/BrokerContext.js";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { useNotification } from "../../../contexts/NotificationContext.js";

export default function BonusSettings() {
  const broker = useContext(BrokerContext)?.broker || {};
  const { showNotification } = useNotification();
  const [isSaving, setIsSaving] = useState(false);
  const [protectedPositions, setProtectedPositions] = useState(false);
  const [amount, setAmount] = useState(0);
  const [expirationHours, setExpirationHours] = useState(0);
  const [depositThreshold, setDepositThreshold] = useState(0);
  const [noData, setNoData] = useState(false);
  const [creditBonus, setCreditBonus] = useState(false);
  const [creditType, setCreditType] = useState("Percentage");
  const [creditAmount, setCreditAmount] = useState(0);
  const [creditDepositThreshold, setCreditDepositThreshold] = useState(0);

  const handleAmountChange = (e) => setAmount(e.target.value);
  const handleExpirationHoursChange = (e) => setExpirationHours(e.target.value);
  const handleDepositThresholdChange = (e) =>
    setDepositThreshold(e.target.value);
  const handleProtectedPositionsChange = (e) => {
    setProtectedPositions(e.target.checked);
  };

  const handleCreditBonusChange = (e) => {
    setCreditBonus(e.target.checked);
  };

  const handleCreditTypeChange = (e) => {
    setCreditType(e.target.value);
  };

  const handleCreditAmountChange = (e) => {
    setCreditAmount(e.target.value);
  };

  const handleCreditDepositThresholdChange = (e) => {
    setCreditDepositThreshold(e.target.value);
  };

  const handleSaveSettings = async () => {
    setIsSaving(true);

    const db = getFirestore();
    const brokerDoc = doc(db, "brokers", broker.brokerData.uid);
    const bonusData = {
      bonusSettings: {
        protectedPositions: {
          isEnabled: protectedPositions,
          amount: Number(amount),
          expirationHours: Number(expirationHours),
          depositThreshold: Number(depositThreshold),
        },
        creditBonus: {
          isEnabled: creditBonus,
          type: creditType,
          amount: Number(creditAmount),
          depositThreshold: Number(creditDepositThreshold), // Add this line
        },
      },
    };

    try {
      await setDoc(brokerDoc, bonusData, { merge: true });
      showNotification("success", "Bonus settings updated successfully!");
    } catch (error) {
      console.error("Firestore update error:", error);
      showNotification(
        "error",
        "Failed to update bonus settings. Please try again."
      );
    } finally {
      setIsSaving(false);
    }
  };

  // Update your useEffect hook
  useEffect(() => {
    const fetchBrokerBonusSettings = async () => {
      const db = getFirestore();
      const brokerDocRef = doc(db, "brokers", broker.brokerData.uid);
      const brokerDocSnap = await getDoc(brokerDocRef);

      if (brokerDocSnap.exists()) {
        const bonusData = brokerDocSnap.data().bonusSettings || {};

        // Using optional chaining to ensure that if any property is missing, it won't break the app
        setProtectedPositions(bonusData.protectedPositions?.isEnabled || false);
        setAmount(bonusData.protectedPositions?.amount || 0);
        setExpirationHours(bonusData.protectedPositions?.expirationHours || 0);
        setDepositThreshold(
          bonusData.protectedPositions?.depositThreshold || 0
        );
        setCreditBonus(bonusData.creditBonus?.isEnabled || false);
        setCreditType(bonusData.creditBonus?.type || "");
        setCreditAmount(bonusData.creditBonus?.amount || 0);
        setCreditDepositThreshold(bonusData.creditBonus?.depositThreshold || 0);
      } else {
        setNoData(true);
      }
    };

    fetchBrokerBonusSettings();
  }, [broker.brokerData.uid]);

  // Update your component's return statement
  if (noData) {
    return <div>No data to display</div>;
  }

  if (!broker || !broker.userData) {
    return (
      <div className="fixed inset-0 flex items-center justify-center dark:bg-gray-900">
        <svg
          className="animate-spin h-10 w-10 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white text-left">
          Bonus settings
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500 text-left">
          Configure your bonus settings.
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 dark:divide-gray-700 border-t border-gray-200 dark:border-gray-700 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <fieldset>
              <div className="space-y-5">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="protectedPositions"
                      name="protectedPositions"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 dark:border-gray-700 dark:bg-white/5 text-primary-600 focus:ring-primary-600"
                      checked={protectedPositions}
                      onChange={handleProtectedPositionsChange}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="protectedPositions"
                      className="font-medium text-gray-900 dark:text-white"
                    >
                      Protected positions
                    </label>
                    <p
                      id="protectedPositions-description"
                      className="text-gray-500"
                    >
                      If enabled, FTDs will receive protected positions.
                    </p>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </dl>

        {protectedPositions && (
          <div className="pt-6 sm:flex">
            <div>
              <label
                htmlFor="amount"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Amount of protected positions
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-white/5"
                  placeholder="0"
                  value={amount}
                  onChange={handleAmountChange}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500" id="amount">
                How many protected positions
              </p>
            </div>

            <div className="ml-6">
              <label
                htmlFor="expirationHours"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Expiration in hours
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="expirationHours"
                  id="expirationHours"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-white/5"
                  placeholder="0"
                  value={expirationHours}
                  onChange={handleExpirationHoursChange}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500" id="expirationHours">
                Maximum time before force close
              </p>
            </div>
            <div className="ml-6">
              <label
                htmlFor="depositThreshold"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Deposit threshold
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="depositThreshold"
                  id="depositThreshold"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-white/5"
                  placeholder="0"
                  value={depositThreshold}
                  onChange={handleDepositThresholdChange}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500" id="depositThreshold">
                Minimum deposit for bonus activation
              </p>
            </div>
          </div>
        )}

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 dark:divide-gray-700 border-t border-gray-200 dark:border-gray-700 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <fieldset>
              <div className="space-y-5">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="protectedPositions"
                      name="protectedPositions"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 dark:border-gray-700 dark:bg-white/5 text-primary-600 focus:ring-primary-600"
                      checked={creditBonus}
                      onChange={handleCreditBonusChange}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="protectedPositions"
                      className="font-medium text-gray-900 dark:text-white"
                    >
                      Credit bonus
                    </label>
                    <p
                      id="protectedPositions-description"
                      className="text-gray-500"
                    >
                      If enabled, FTDs will receive a credit bonus.
                    </p>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </dl>

        {creditBonus && (
          <div className="pt-6 sm:flex">
            <div>
              <label
                htmlFor="amount"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Credit type
              </label>
              <div className="mt-2">
                <select
                  id="creditType"
                  name="creditType"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 dark:bg-white/5 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-primary-600 sm:text-sm sm:leading-6"
                  defaultValue={creditType}
                  onChange={handleCreditTypeChange}
                >
                  <option>Percentage</option>
                  <option>Fixed</option>
                </select>
              </div>
              <p className="mt-2 text-sm text-gray-500" id="amount">
                Percentage or fixed
              </p>
            </div>

            <div className="ml-6">
              <label
                htmlFor="expirationHours"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Credit amount
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="creditAmount"
                  id="creditAmount"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-white/5"
                  placeholder="Enter amount"
                  value={creditAmount}
                  onChange={handleCreditAmountChange}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500" id="expirationHours">
                Amount in % or account currency
              </p>
            </div>
            <div className="ml-6">
              <label
                htmlFor="depositThreshold"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Deposit threshold
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="creditDepositThreshold"
                  id="creditDepositThreshold"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-white/5"
                  placeholder="0"
                  value={creditDepositThreshold}
                  onChange={handleCreditDepositThresholdChange}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500" id="depositThreshold">
                Minimum deposit for bonus activation
              </p>
            </div>
          </div>
        )}

        <div className="mt-6">
          <button
            type="button"
            onClick={handleSaveSettings}
            className="rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            disabled={isSaving}
          >
            {isSaving ? (
              <div className="flex items-center">
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Saving settings...
              </div>
            ) : (
              "Save settings"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
