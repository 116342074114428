import React, { useState } from "react";
import ActiveCampaigns from "./ActiveCampaigns";
import NewCampaign from "./NewCampaign";
import TrackingSettings from "./TrackingSettings";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CampaignManagerDashboard() {
  const [currentView, setCurrentView] = useState("Active Campaigns");

  const tabs = [
    {
      name: "Active Campaigns",
      current: currentView === "Active Campaigns",
      onClick: () => setCurrentView("Active Campaigns"),
    },
    {
      name: "New Campaign",
      current: currentView === "New Campaign",
      onClick: () => setCurrentView("New Campaign"),
    },
    {
      name: "Tracking Settings",
      current: currentView === "Tracking Settings",
      onClick: () => setCurrentView("Tracking Settings"),
    },
  ];

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 dark:border-white/10 focus:border-primary-500 focus:ring-primary-500"
          defaultValue={tabs.find((tab) => tab.current)?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block px-6 mt-6">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={tab.onClick}
              className={classNames(
                tab.current
                  ? "bg-gray-100 dark:bg-white/5 text-gray-700 dark:text-white"
                  : "text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-white/90",
                "rounded-md px-3 py-2 text-sm font-medium"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              {tab.name}
            </button>
          ))}
        </nav>
        {currentView === "Active Campaigns" && (
          <ActiveCampaigns setCurrentView={setCurrentView} />
        )}
        {currentView === "New Campaign" && (
          <NewCampaign setCurrentView={setCurrentView} />
        )}
        {currentView === "Tracking Settings" && <TrackingSettings />}
      </div>
    </div>
  );
}
