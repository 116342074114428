import { Fragment, useEffect, useState, useMemo } from "react";
import React, { useContext, useCallback } from "react";
import { BrokerContext } from "../../../../contexts/BrokerContext.js";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import {
  collection,
  getDocs,
  getFirestore,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { useNotification } from "../../../../contexts/NotificationContext.js";
import { DocumentDuplicateIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ActiveCampaigns({ setCurrentView }) {
  const brokerContext = useContext(BrokerContext);
  const broker = useMemo(() => brokerContext?.broker || {}, [brokerContext]);
  const [campaigns, setCampaigns] = useState([]);
  const { showNotification } = useNotification();
  const [copyStatus, setCopyStatus] = useState({});

  const fetchCampaigns = useCallback(async () => {
    const db = getFirestore();
    const campaignsCollection = collection(
      db,
      "campaigns",
      broker.brokerData.uid,
      "campaigns"
    );
    const campaignSnapshot = await getDocs(campaignsCollection);
    const campaignsList = campaignSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setCampaigns(campaignsList);
  }, [broker.brokerData.uid]); // Add dependencies here

  useEffect(() => {
    fetchCampaigns();
  }, [fetchCampaigns]); // fetchCampaigns is now memoized

  const deleteCampaign = async (id) => {
    const db = getFirestore();
    const campaignRef = doc(
      db,
      "campaigns",
      broker.brokerData.uid,
      "campaigns",
      id
    );
    await deleteDoc(campaignRef);
    // Refresh the campaigns list after deletion
    showNotification("success", "Success! Campaign has been deleted.");
    fetchCampaigns();
  };

  return (
    <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8 mt-8">
      {campaigns.length > 0 ? (
        campaigns.map((campaign) => (
          <li
            key={campaign.id}
            className="overflow-hidden rounded-xl border border-gray-200 dark:border-white/10"
          >
            <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 dark:border-white/5 dark:bg-white/5 p-6">
              <img
                src={campaign.coverPhoto}
                alt={campaign.name}
                className="h-12 w-12 flex-none rounded-lg bg-white dark:bg-gray-900 object-cover ring-1 ring-gray-900/10 dark:ring-white/5"
              />
              <div className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                {campaign.name}
              </div>
              <Menu as="div" className="relative ml-auto">
                <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Open options</span>
                  <EllipsisHorizontalIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white dark:bg-gray-800 py-2 shadow-lg ring-1 ring-gray-900/5 dark:ring-white/5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="test.com"
                          className={classNames(
                            active ? "bg-gray-50 dark:bg-white/5" : "",
                            "block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-white"
                          )}
                        >
                          View<span className="sr-only">, {campaign.name}</span>
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="test.com"
                          className={classNames(
                            active ? "bg-gray-50 dark:bg-white/5" : "",
                            "block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-white"
                          )}
                        >
                          Edit<span className="sr-only">, {campaign.name}</span>
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => deleteCampaign(campaign.id)}
                          className={classNames(
                            active ? "bg-gray-50 dark:bg-white/5" : "",
                            "block px-3 py-1 text-sm leading-6 text-red-500 w-full text-left"
                          )}
                        >
                          Delete
                          <span className="sr-only">, {campaign.name}</span>
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <dl className="-my-3 divide-y divide-gray-100 dark:divide-white/10 px-6 py-4 text-sm leading-6">
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Campaign link:</dt>
                <dd className="text-gray-700">
                  <button
                    type="button"
                    onClick={async () => {
                      await navigator.clipboard.writeText(campaign.campaignUrl);
                      setCopyStatus({ ...copyStatus, [campaign.id]: true });
                      setTimeout(() => {
                        setCopyStatus({ ...copyStatus, [campaign.id]: false });
                      }, 2000);
                    }}
                    className="inline-flex items-center gap-x-1.5 rounded-md bg-white dark:bg-white/5 px-2.5 py-1.5 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 hover:bg-gray-50 dark:hover:bg-white/10"
                  >
                    <DocumentDuplicateIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    {copyStatus[campaign.id] ? "Copied!" : "Copy link"}
                  </button>
                </dd>
              </div>
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">ID: </dt>
                <dd className="text-gray-700">
                  {campaign.campaignId || "N/A"}
                </dd>
              </div>
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Bonus</dt>
                <dd className="flex items-start gap-x-2">
                  <div className="font-medium text-gray-900 dark:text-white">
                    {campaign.bonus?.isProtectedPositionsEnabled
                      ? "Protected positions"
                      : campaign.bonus?.protectedPositions?.isEnabled &&
                        "Protected positions"}
                    {campaign.bonus?.protectedPositions?.isEnabled &&
                      campaign.bonus?.creditBonus?.isEnabled &&
                      ", "}
                    {campaign.bonus?.creditBonus?.isEnabled && "Credit Bonus"}
                    {!campaign.bonus?.protectedPositions?.isEnabled &&
                      !campaign.bonus?.creditBonus?.isEnabled &&
                      !campaign.bonus?.isProtectedPositionsEnabled &&
                      "No bonus"}
                  </div>
                  <span className="inline-flex items-center rounded-md bg-primary-50 dark:bg-primary-500/10 px-1.5 py-1 text-xs font-medium text-primary-700 dark:text-primary-400 ring-1 ring-inset ring-primary-600/20 dark:ring-primary-500/20">
                    Active
                  </span>
                </dd>
              </div>
            </dl>
          </li>
        ))
      ) : (
        <button
          type="button"
          className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 dark:border-white/10 p-12 text-center hover:border-gray-400 dark:hover:border-white/20 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          onClick={() => setCurrentView("New Campaign")}
        >
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
            />
          </svg>
          <span className="mt-2 block text-sm font-semibold text-gray-900 dark:text-white">
            Create a new campaign
          </span>
        </button>
      )}
    </ul>
  );
}
