import { useContext, useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BrokerContext } from "../../../contexts/BrokerContext.js";
import Pagination from "../../Shared/Pagination.js"; // <-- Import the Pagination component
import { Tooltip } from "flowbite-react";
import algoliasearch from "algoliasearch/lite";
import EmptyState from "../../Shared/EmptyState.js";

export default function ClientsDashboard() {
  const { broker } = useContext(BrokerContext);
  const brokerUID = broker?.brokerData?.uid;
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20); // or whatever number you want
  const [totalItems, setTotalItems] = useState(0);
  const prevPageRef = useRef();
  const [isEmpty, setIsEmpty] = useState(false);
  const [filters, setFilters] = useState(""); // New state variable for filters
  const prevFiltersRef = useRef();
  const navigate = useNavigate();

  const algoliaAppId = process.env.REACT_APP_ALGOLIA_APP_ID;
  const algoliaSearchOnlyApiKey =
    process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY;

  const algoliaClient = algoliasearch(algoliaAppId, algoliaSearchOnlyApiKey);
  const usersIndex = useMemo(() => {
    return algoliaClient.initIndex("users");
  }, [algoliaClient]);

  async function fetchDataForTable(
    index,
    brokerUID,
    page,
    itemsPerPage,
    filtersExtra = ""
  ) {
    try {
      const filters = `brokerUID:${brokerUID} AND NOT status:broker ${filtersExtra}`; // Exclude broker status

      console.log(`Fetching data from Algolia with parameters: 
      Index: ${index.indexName}
      Broker UID: ${brokerUID}
      Page: ${page}
      Items per page: ${itemsPerPage}
      Filters: ${filters}`);

      const { hits, nbHits } = await index.search("", {
        filters: filters,
        hitsPerPage: itemsPerPage,
        page: page - 1, // Algolia uses 0-indexed pages
        userToken: brokerUID,
      });
      return { hits, totalItems: nbHits };
    } catch (error) {
      console.error("Error fetching data from Algolia:", error);
      return { hits: [], totalItems: 0 };
    }
  }

  useEffect(() => {
    const filtersChanged =
      JSON.stringify(filters) !== JSON.stringify(prevFiltersRef.current);

    if (brokerUID && (currentPage !== prevPageRef.current || filtersChanged)) {
      const fetchData = async () => {
        setLoading(true);

        let filterString = "";
        const filterConditions = {
          userStatus:
            filters.userStatus === "unverified"
              ? `NOT kycStatus:approved_kyc`
              : `kycStatus:${filters.userStatus}`,
          accountType: `${filters.accountType} != 0`,
          country: `country:${filters.country}`,
        };

        for (const [key, value] of Object.entries(filterConditions)) {
          if (filters[key]) {
            filterString += ` AND ${value}`;
          }
        }

        const { hits, totalItems } = await fetchDataForTable(
          usersIndex,
          brokerUID,
          currentPage,
          itemsPerPage,
          filterString // Use the filter string
        );
        if (!hits.length) {
          setLoading(false);
          setIsEmpty(true);
          return;
        }
        setTotalItems(totalItems);
        setClients(hits);
        setLoading(false);
      };

      fetchData();
      prevPageRef.current = currentPage;
      prevFiltersRef.current = filters;
    }
  }, [brokerUID, currentPage, itemsPerPage, usersIndex, filters]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  function renderDivBasedOnClientData(client) {
    if (client.kycStatus === "approved_kyc") {
      return (
        <Tooltip
          content="This user has completed the KYC verification process"
          placement="right"
        >
          <div className="inline-flex items-center bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-primary-900 dark:text-primary-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-3.5 w-3.5 mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
              />
            </svg>
            Verified
          </div>
        </Tooltip>
      );
    } else if (client.ftdDate) {
      return (
        <Tooltip
          content="This user is an FTD (First Time Deposit)"
          placement="right"
        >
          <div className="inline-flex items-center bg-indigo-100 text-indigo-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-3.5 w-3.5 mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
              />
            </svg>
            FTD
          </div>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          content="This user has not completed the KYC verification process"
          placement="right"
        >
          <div className="inline-flex items-center bg-gray-100 text-gray-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-3.5 w-3.5 mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
            Unverified
          </div>
        </Tooltip>
      );
    }
  }

  return (
    <>
      {/* Start block */}
      <section className="bg-gray-50 dark:bg-gray-900 antialiased">
        <div className="mx-auto">
          <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
            <div className="px-4">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-3 md:space-y-0 md:space-x-4 py-3">
                <div>
                  <nav
                    className="mb-2 flex px-3 py-2 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600"
                    aria-label="Breadcrumb"
                  >
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                      <li className="inline-flex items-center">
                        <a
                          href=" "
                          className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-primary-600 dark:text-gray-300 dark:hover:text-white"
                        >
                          Dashboard
                        </a>
                      </li>
                      <li aria-current="page">
                        <div className="flex items-center">
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5 text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                            All Users
                          </span>
                        </div>
                      </li>
                    </ol>
                  </nav>
                  <h5>
                    <span className="dark:text-white">All Users</span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-4 py-3">
                      User
                    </th>
                    <th scope="col" className="px-4 py-3">
                      User Status
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Email
                    </th>
                    <th scope="col" className="px-4 py-3 whitespace-nowrap">
                      Account Type
                    </th>

                    <th scope="col" className="px-4 py-3 whitespace-nowrap">
                      Country
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading
                    ? Array.from({ length: itemsPerPage }).map((_, index) => (
                        <tr
                          key={index}
                          className="bg-white dark:border-gray-700 dark:bg-gray-800"
                        >
                          <td className="px-4 py-3">
                            <div className="w-52 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="w-28 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="w-36 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="w-28 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="w-36 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                          </td>
                        </tr>
                      ))
                    : clients.map((client, index) => (
                        <tr
                          key={client.uid}
                          className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                          onClick={() =>
                            navigate(`/broker/dashboard/client/${client.uid}`)
                          }
                        >
                          <th
                            scope="row"
                            className="px-4 py-3 font-bold text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <div className="flex items-center mr-3">
                              <div className="relative inline-flex items-center justify-center h-8 w-8 mr-3 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                                <span className="font-medium text-gray-600 dark:text-gray-300">
                                  {(client.name ? client.name[0] : "") +
                                    (client.surname ? client.surname[0] : "")}
                                </span>
                              </div>
                              {client.name} {client.surname}
                            </div>
                          </th>

                          <td className="px-4 py-3">
                            {renderDivBasedOnClientData(client)}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            <div className="flex items-center space-x-1.5">
                              {client.email}
                            </div>
                          </td>
                          <td className="px-4 py-3 font-bold text-gray-900 whitespace-nowrap dark:text-white">
                            {client.live_id_person && client.demo_id_person
                              ? "Real, Demo"
                              : client.live_id_person
                              ? "Real"
                              : client.demo_id_person
                              ? "Demo"
                              : ""}
                          </td>

                          <td className="px-4 py-3 whitespace-nowrap">
                            {client.country ? toTitleCase(client.country) : ""}
                          </td>
                        </tr>
                      ))}
                  {!loading && clients.length === 0 && (
                    <tr>
                      <td colSpan={6}>
                        <EmptyState />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <nav
              className="flex flex-row justify-between items-center p-4"
              aria-label="Table navigation"
            >
              <button
                type="button"
                className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              >
                Download CSV
              </button>

              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />

              <p className="text-sm">
                <span className="font-normal text-gray-500 dark:text-gray-400">
                  Total users:{" "}
                </span>
                <span className="font-semibold text-gray-900 dark:text-white">
                  {totalItems}
                </span>
              </p>
            </nav>
          </div>
        </div>
      </section>
    </>
  );
}
