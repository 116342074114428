import { useEffect, useState, useContext } from "react";
import {
  getFirestore,
  query,
  where,
  getDocs,
  collection,
  doc,
  getDoc,
  limit,
  startAfter,
  endBefore,
} from "firebase/firestore";
import { BrokerContext } from "../../../contexts/BrokerContext.js";
import { useNavigate } from "react-router-dom";
import Pagination from "../../Shared/Pagination.js";
import EmptyState from "../../Shared/EmptyState.js";

export default function KYCDashboard() {
  const { broker } = useContext(BrokerContext); // Assuming broker object has the brokerUID
  const [people, setPeople] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // You can adjust this number as needed
  const [totalItems, setTotalItems] = useState(0);
  const [pageCursors, setPageCursors] = useState([]);

  useEffect(() => {
    const fetchPendingKYCs = async () => {
      const db = getFirestore();

      let baseQuery = query(
        collection(db, "kyc"),
        where("brokerUID", "==", broker.userData.uid),
        where("kycStatus", "==", "pending"),
        limit(itemsPerPage)
      );

      const kycQuery =
        currentPage === 1
          ? baseQuery
          : currentPage > pageCursors.length
          ? query(baseQuery, startAfter(pageCursors[pageCursors.length - 1]))
          : query(baseQuery, endBefore(pageCursors[currentPage - 2]));

      const kycSnapshot = await getDocs(kycQuery);
      const usersData = [];

      for (let kycDoc of kycSnapshot.docs) {
        const userRef = doc(db, "users", kycDoc.id);
        const userDoc = await getDoc(userRef);

        if (
          userDoc.exists() &&
          userDoc.data().brokerUID === broker.userData.uid
        ) {
          usersData.push({
            uid: kycDoc.id,
            name: userDoc.data().fullName,
            country: userDoc.data().country,
            email: userDoc.data().email,
          });
        }
      }

      if (kycSnapshot.docs.length > 0 && currentPage > pageCursors.length) {
        setPageCursors([
          ...pageCursors,
          kycSnapshot.docs[kycSnapshot.docs.length - 1],
        ]);
      }

      const totalKYCSnapshot = await getDocs(
        query(
          collection(db, "kyc"),
          where("brokerUID", "==", broker.userData.uid),
          where("kycStatus", "==", "pending")
        )
      );
      setTotalItems(totalKYCSnapshot.size);

      setPeople(usersData);
      setLoading(false);
    };

    fetchPendingKYCs();
  }, [broker, currentPage, itemsPerPage, pageCursors]);

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900 antialiased">
        <div className="mx-auto">
          <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
            <div className="px-4">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-3 md:space-y-0 md:space-x-4 py-3">
                <div>
                  <nav
                    className="mb-2 flex px-3 py-2 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600"
                    aria-label="Breadcrumb"
                  >
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                      <li className="inline-flex items-center">
                        <a
                          href=" "
                          className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-primary-600 dark:text-gray-300 dark:hover:text-white"
                        >
                          Dashboard
                        </a>
                      </li>
                      <li aria-current="page">
                        <div className="flex items-center">
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5 text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                            KYC Submissions
                          </span>
                        </div>
                      </li>
                    </ol>
                  </nav>
                  <h5>
                    <span className="dark:text-white">All Users</span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-4 py-3">
                      User
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Country
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Email
                    </th>
                    <th scope="col" className="px-4 py-3 whitespace-nowrap">
                      Status
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading
                    ? Array.from({ length: itemsPerPage }).map((_, index) => (
                        <tr
                          key={index}
                          className="bg-white dark:border-gray-700 dark:bg-gray-800"
                        >
                          <td className="px-4 py-3">
                            <div className="w-52 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="w-28 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="w-36 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="w-28 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="w-36 h-4 bg-gray-200 animate-pulse rounded-full dark:bg-gray-700"></div>
                          </td>
                        </tr>
                      ))
                    : people.map((person, index) => (
                        <tr
                          key={person.uid}
                          className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                          onClick={() =>
                            navigate(`/broker/dashboard/kyc/${person.uid}`)
                          }
                        >
                          <th
                            scope="row"
                            className="px-4 py-3 font-bold text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <div className="flex items-center mr-3">
                              <div className="relative inline-flex items-center justify-center h-8 w-8 mr-3 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                                <span className="font-medium text-gray-600 dark:text-gray-300">
                                  {(person.name ? person.name[0] : "") +
                                    (person.surname ? person.surname[0] : "")}
                                </span>
                              </div>
                              {person.name} {person.surname}
                            </div>
                          </th>
                          <th
                            scope="row"
                            className="px-4 py-3 font-bold text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <div className="flex items-center mr-3">
                              {person.country}
                            </div>
                          </th>
                          <th
                            scope="row"
                            className="px-4 py-3 font-bold text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <div className="flex items-center mr-3">
                              {person.email}
                            </div>
                          </th>
                          <th
                            scope="row"
                            className="px-4 py-3 font-bold text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <div className="flex items-center mr-3">
                              <span className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
                                <svg
                                  className="w-2.5 h-2.5 me-1.5"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm3.982 13.982a1 1 0 0 1-1.414 0l-3.274-3.274A1.012 1.012 0 0 1 9 10V6a1 1 0 0 1 2 0v3.586l2.982 2.982a1 1 0 0 1 0 1.414Z" />
                                </svg>
                                Pending approval
                              </span>
                            </div>
                          </th>

                          <th
                            scope="row"
                            className="px-4 py-3 font-bold text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <button
                              className="text-primary-600 hover:text-primary-900 focus:outline-none"
                              onClick={() =>
                                navigate(`/broker/dashboard/kyc/${person.uid}`)
                              } // Use person.uid here
                            >
                              View KYC
                              <span className="sr-only">, {person.name}</span>
                            </button>
                          </th>
                        </tr>
                      ))}
                  {!loading && people.length === 0 && (
                    <tr>
                      <td colSpan={6}>
                        <EmptyState />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <nav
              className="flex flex-row justify-between items-center p-4"
              aria-label="Table navigation"
            >
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(totalItems / itemsPerPage)}
                onPageChange={setCurrentPage}
              />

              <p className="text-sm">
                <span className="font-normal text-gray-500 dark:text-gray-400">
                  Total pending:{" "}
                </span>
                <span className="font-semibold text-gray-900 dark:text-white">
                  {totalItems}
                </span>
              </p>
            </nav>
          </div>
        </div>
      </section>
    </>
  );
}
