"use client";

import React from "react";
import { Clipboard, Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";

export default function CryptoDepositInfo({ cryptoData, onClose }) {
  const { t } = useTranslation();

  return (
    <div className="p-6">
      <div className="flex mb-6 justify-between">
        <div className="max-w-xs">
          <h2 className="flex items-center mb-2 text-xl font-bold leading-none text-gray-900 sm:text-2xl dark:text-white">
            {t("cryptoCashier.cryptoDeposit")}
          </h2>
          <p className="font-light text-md max-w-md text-gray-500 dark:text-gray-400 mb-2">
            {t("cryptoCashier.copyAddressInstructions")}
          </p>
          <p className="font-light text-md max-w-md text-gray-500 dark:text-gray-400">
            {t("cryptoCashier.automaticCredit")}
          </p>
        </div>
        <Tooltip
          content={t("cryptoCashier.qrCodeTooltip")}
          placement="left"
          arrow={false}
        >
          <img
            src={`data:image/png;base64,${cryptoData.qr_code}`}
            alt="QR Code"
            className="w-20 h-20 object-contain rounded"
          />
        </Tooltip>
      </div>

      <div>
        {/* Address section */}
        <div className="mb-4">
          <div className="mb-2 block">
            <label
              htmlFor="address"
              className="text-sm font-medium text-gray-900 dark:text-white"
            >
              {t("cryptoCashier.cryptoAddress")}
            </label>
          </div>
          <div className="grid w-full max-w-full">
            <div className="relative">
              <Tooltip
                content={t("cryptoCashier.addressTooltip")}
                arrow={false}
                placement="bottom"
              >
                <input
                  id="address"
                  type="text"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-500 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-400 dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  value={cryptoData.address}
                  disabled
                  readOnly
                />
              </Tooltip>
              <Clipboard.WithIconText valueToCopy={cryptoData.address} />
            </div>
          </div>
        </div>

        {/* Crypto Amount section */}
        <div className="mb-4">
          <label
            htmlFor="crypto-amount"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {t("cryptoCashier.cryptoAmount")}
          </label>
          <div className="grid w-full max-w-full">
            <div className="relative">
              <Tooltip
                content={t("cryptoCashier.cryptoAmountTooltip")}
                arrow={false}
                placement="bottom"
              >
                <input
                  id="crypto-amount"
                  type="text"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-500 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-400 dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  value={`${cryptoData.crypto_amount} ${cryptoData.currency}`}
                  disabled
                  readOnly
                />
              </Tooltip>
              <Clipboard.WithIconText
                valueToCopy={`${cryptoData.crypto_amount} ${cryptoData.currency}`}
              />
            </div>
          </div>
        </div>

        {cryptoData.crypto_destination_tag && (
          <div className="mb-4">
            <label
              htmlFor="destination-tag"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {t("cryptoCashier.destinationTag")}
            </label>
            <div className="grid w-full max-w-full">
              <div className="relative">
                <Tooltip
                  content={t("cryptoCashier.destinationTagTooltip")}
                  arrow={false}
                  placement="bottom"
                >
                  <input
                    id="destination-tag"
                    type="text"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-500 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-400 dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    value={cryptoData.crypto_destination_tag}
                    disabled
                    readOnly
                  />
                </Tooltip>
                <Clipboard.WithIconText
                  valueToCopy={cryptoData.crypto_destination_tag}
                />
              </div>
            </div>
          </div>
        )}

        {/* Currency section */}
        <div className="mb-4">
          <div className="mb-2 block">
            <label
              htmlFor="currency"
              className="text-sm font-medium text-gray-900 dark:text-white"
            >
              {t("cryptoCashier.cryptocurrency")}
            </label>
          </div>
          <div className="grid w-full max-w-full">
            <div className="relative">
              <Tooltip
                content={t("cryptoCashier.cryptocurrencyTooltip")}
                arrow={false}
                placement="bottom"
              >
                <input
                  id="currency"
                  type="text"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-500 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-400 dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  value={cryptoData.currency}
                  disabled
                  readOnly
                />
              </Tooltip>
              <Clipboard.WithIconText valueToCopy={cryptoData.currency} />
            </div>
          </div>
        </div>
      </div>

      {/* Fiat Amount section */}
      <div className="mb-4">
        <label
          htmlFor="fiat-amount"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {t("cryptoCashier.fiatAmount")}
        </label>
        <div className="grid w-full max-w-full">
          <div className="relative">
            <Tooltip
              content={t("cryptoCashier.fiatAmountTooltip")}
              arrow={false}
              placement="bottom"
            >
              <input
                id="fiat-amount"
                type="text"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-500 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-400 dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                value={`${cryptoData.receive_amount} ${cryptoData.currency_receive}`}
                disabled
                readOnly
              />
            </Tooltip>
            <Clipboard.WithIconText
              valueToCopy={`${cryptoData.receive_amount} ${cryptoData.currency_receive}`}
            />
          </div>
        </div>
      </div>

      <button
        onClick={onClose}
        className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
      >
        {t("cryptoCashier.done")}
      </button>
    </div>
  );
}
