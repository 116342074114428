import { useState } from "react";
import PendingBalanceIB from "./Balance/PendingBalanceIB";
import BalanceIBHistory from "./Balance/BalanceIBHistory";
import { useTranslation } from "react-i18next";

const ClientIBBalance = ({ totalCommission, currency }) => {
  const [currentView, setCurrentView] = useState("pendingBalance");
  const { t } = useTranslation();

  return (
    <div className="md:flex mt-4">
      <ul className="flex-column space-y space-y-4 text-sm font-medium text-gray-500 dark:text-gray-400 md:me-4 mb-4 md:mb-0">
        <li>
          <button
            className={`inline-flex items-center px-4 py-3 rounded-lg ${
              currentView === "pendingBalance"
                ? "text-white bg-primary-700 active w-full dark:bg-primary-600"
                : "bg-gray-50 dark:bg-gray-800 hover:text-gray-900 hover:bg-gray-100 w-full dark:hover:bg-gray-700 dark:hover:text-white"
            }`}
            onClick={() => setCurrentView("pendingBalance")}
            aria-current={currentView === "pendingBalance" ? "page" : undefined}
          >
            <svg
              className={`w-4 h-4 me-2 ${
                currentView === "pendingBalance"
                  ? "text-white"
                  : "text-gray-500 dark:text-gray-400"
              }`}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.1064 15.388C17.3543 14.8945 17.9553 14.6954 18.4489 14.9433C20.0833 15.7644 21.4649 17.0715 22.4648 18.6822C22.6163 18.9262 22.8368 19.2521 22.9052 19.6937C22.9785 20.1665 22.8502 20.6186 22.659 20.9624C22.4678 21.3061 22.1513 21.6536 21.711 21.8407C21.2923 22.0186 20.8369 22 20.5 22C19.9477 22 19.5 21.5523 19.5 21C19.5 20.4477 19.9477 20 20.5 20C20.7152 20 20.8283 19.9994 20.9091 19.9939L20.9112 19.9902C20.9303 19.9558 20.785 19.7683 20.7656 19.737C19.9433 18.4124 18.8284 17.3722 17.5511 16.7305C17.0576 16.4826 16.8585 15.8815 17.1064 15.388Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.1045 3.02273C15.3502 2.52815 15.9504 2.32646 16.445 2.57225C18.2536 3.47103 19.5 5.33904 19.5 7.5C19.5 9.66096 18.2536 11.529 16.445 12.4278C15.9504 12.6735 15.3502 12.4719 15.1045 11.9773C14.8587 11.4827 15.0604 10.8825 15.5549 10.6367C16.7098 10.0628 17.5 8.87276 17.5 7.5C17.5 6.12724 16.7098 4.9372 15.5549 4.36327C15.0604 4.11749 14.8587 3.51731 15.1045 3.02273Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.99997 7.5C3.99997 4.46243 6.4624 2 9.49997 2C12.5375 2 15 4.46243 15 7.5C15 10.5376 12.5375 13 9.49997 13C6.4624 13 3.99997 10.5376 3.99997 7.5Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.49997 14C12.6962 14 15.5535 15.8041 17.2971 18.4201C17.4507 18.6506 17.6145 18.8964 17.729 19.13C17.8669 19.4111 17.9659 19.7341 17.9427 20.1198C17.9243 20.4275 17.8222 20.7129 17.7009 20.9395C17.5795 21.1661 17.3986 21.4093 17.1527 21.5952C16.8234 21.8442 16.4685 21.9315 16.1548 21.968C15.8786 22.0001 15.5514 22.0001 15.2219 22C11.4088 21.9993 7.59266 21.9993 3.77804 22C3.4485 22.0001 3.12135 22.0001 2.84511 21.968C2.53141 21.9315 2.17652 21.8442 1.84721 21.5952C1.60138 21.4093 1.4204 21.1661 1.29906 20.9395C1.17772 20.7129 1.07567 20.4275 1.0572 20.1198C1.03405 19.7341 1.13305 19.4111 1.27089 19.13C1.38542 18.8964 1.54923 18.6506 1.70284 18.4201C3.44643 15.8041 6.30373 14 9.49997 14Z"
                fill="currentColor"
              />
            </svg>
            {t("ibBalanceTabs.pending")}
          </button>
        </li>
        <li>
          <button
            className={`inline-flex items-center px-4 py-3 rounded-lg ${
              currentView === "balanceHistory"
                ? "text-white bg-primary-700 active w-full dark:bg-primary-600"
                : "bg-gray-50 dark:bg-gray-800 hover:text-gray-900 hover:bg-gray-100 w-full dark:hover:bg-gray-700 dark:hover:text-white"
            }`}
            onClick={() => setCurrentView("balanceHistory")}
            aria-current={currentView === "balanceHistory" ? "page" : undefined}
          >
            <svg
              className={`w-4 h-4 me-2 ${
                currentView === "balanceHistory"
                  ? "text-white"
                  : "text-gray-500 dark:text-gray-400"
              }`}
              aria-hidden="true"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.7979 21.1334C11.3081 21.0089 11.7988 20.8486 12.2356 20.6475C13.3323 22.0778 15.0584 23 17 23C20.3137 23 23 20.3137 23 17C23 13.6863 20.3137 11 17 11C15.9071 11 14.8825 11.2922 14 11.8027V10.6974C14 10.5884 13.9116 10.5 13.8026 10.5C13.7356 10.5 13.6737 10.5343 13.6347 10.5887C13.5109 10.761 13.3666 10.9105 13.2197 11.0368C12.8167 11.3832 12.2921 11.6499 11.7308 11.854C10.6 12.2652 9.10403 12.5 7.5 12.5C5.89597 12.5 4.4 12.2652 3.26917 11.854C2.70787 11.6499 2.18326 11.3832 1.78029 11.0368C1.63341 10.9105 1.48911 10.761 1.36533 10.5887C1.32625 10.5343 1.26436 10.5 1.1974 10.5C1.08838 10.5 1 10.5884 1 10.6974V13C1 13.6772 1.38057 14.1931 1.78029 14.5368C2.18326 14.8832 2.70787 15.1499 3.26917 15.354C4.4 15.7652 5.89597 16 7.5 16C9.10403 16 10.6 15.7652 11.7308 15.354C12.2921 15.1499 12.8167 14.8832 13.2197 14.5368C13.3666 14.4105 13.5109 14.261 13.6347 14.0887C13.6737 14.0343 13.7356 14 13.8026 14C13.9116 14 14 14.0884 14 14.1974V15C14 15.6772 13.6194 16.1931 13.2197 16.5368C12.8167 16.8832 12.2921 17.1499 11.7308 17.354C10.6 17.7652 9.10403 18 7.5 18C5.89597 18 4.4 17.7652 3.26917 17.354C2.70787 17.1499 2.18326 16.8832 1.78029 16.5368C1.62895 16.4067 1.47826 16.2416 1.36042 16.0839C1.32174 16.0322 1.26168 16 1.19706 16C1.08761 16 0.999483 16.0898 1.00092 16.1992C1.01099 16.9659 1 17.7334 1 18.5C1 19.1772 1.38057 19.6931 1.78029 20.0368C2.18326 20.3832 2.70787 20.6499 3.26917 20.854C4.4 21.2652 5.89597 21.5 7.5 21.5C8.70215 21.5 9.8356 21.3682 10.7979 21.1334Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 7.5V5C1 4.32275 1.38057 3.80688 1.78029 3.46322C2.15808 3.13843 2.64277 2.88375 3.16438 2.685C3.19915 2.67175 3.23409 2.65875 3.26917 2.64599C4.4 2.23478 5.89597 2 7.5 2C9.10403 2 10.6 2.23478 11.7308 2.64599C12.2921 2.8501 12.8167 3.11678 13.2197 3.46322C13.6194 3.80688 14 4.32275 14 5V7.5C14 8.17725 13.6194 8.69312 13.2197 9.03678C12.8167 9.38322 12.2921 9.6499 11.7308 9.85401C10.6 10.2652 9.10403 10.5 7.5 10.5C5.89597 10.5 4.4 10.2652 3.26917 9.85401C2.70787 9.6499 2.18326 9.38322 1.78029 9.03678C1.38057 8.69312 1 8.17725 1 7.5Z"
                fill="currentColor"
              />
            </svg>
            {t("ibBalanceTabs.history")}
          </button>
        </li>
      </ul>
      {currentView === "pendingBalance" && (
        <PendingBalanceIB
          totalCommission={totalCommission}
          currency={currency}
        />
      )}
      {currentView === "balanceHistory" && <BalanceIBHistory />}
    </div>
  );
};

export default ClientIBBalance;
