import React, { useContext, useState } from "react";
import { BrokerContext } from "../../../contexts/BrokerContext.js";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { useNotification } from "../../../contexts/NotificationContext.js";
import ColorPicker from "./ColorPicker.js";

export default function ProfileSettings({ openProfileUpdateModalWithField }) {
  const broker = useContext(BrokerContext)?.broker || {};
  const { showNotification } = useNotification();
  const [selectedColor, setSelectedColor] = useState(
    broker.whiteLabelData?.themeColor || null
  );

  function handleFileUpload(file, uid, type) {
    const storage = getStorage();
    const storageRef = ref(storage, `brokers/${uid}/${type}`); // Save logo based on type

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Handle progress here if you want
      },
      (error) => {
        console.error("Error uploading file:", error);
        showNotification("error", "Failed to upload the file."); // <-- Show error notification
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          saveLogoUrlToFirestore(uid, downloadURL, type);
          showNotification("success", "File uploaded successfully!");
        });
      }
    );
  }

  function saveLogoUrlToFirestore(uid, url, type) {
    const db = getFirestore();
    const docRef = doc(db, "whitelabel", uid);

    const logoField = type === "dark" ? "logoUrlDark" : "logoUrlLight";

    updateDoc(docRef, {
      [logoField]: url, // Use computed property name to set the correct logo URL field
    })
      .then(() => {
        console.log("Document successfully updated!");
        window.location.reload(); // <-- Here's the reload
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  }

  // Check if user object or userData is null or undefined
  if (!broker || !broker.userData) {
    return (
      <div className="fixed inset-0 flex items-center justify-center dark:bg-gray-900">
        <svg
          className="animate-spin h-10 w-10 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }

  function onFileInputChange(event, type) {
    const file = event.target.files[0];
    if (file) {
      // 1. Check the file type
      const validFileTypes = ["image/jpeg", "image/png", "image/svg+xml"];
      if (!validFileTypes.includes(file.type)) {
        showNotification(
          "error",
          "Invalid file format. Please upload SVG, PNG, or JPG files only."
        );
        return;
      }

      // 2. Check the file size (e.g., not more than 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
      if (file.size > maxSize) {
        showNotification(
          "error",
          "File size is too large. Please upload files smaller than 5MB."
        );
        return;
      }

      handleFileUpload(file, broker.userData.uid, type); // Assuming broker.userData.uid is available
    }
  }

  function saveThemeColorToFirestore(uid, color) {
    const db = getFirestore();
    const docRef = doc(db, "whitelabel", uid);

    updateDoc(docRef, {
      themeColor: color,
    })
      .then(() => {
        console.log("Theme color successfully updated!");
        showNotification("success", "Theme color updated successfully!");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error updating theme color: ", error);
        showNotification("error", "Failed to update theme color.");
      });
  }

  return (
    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white text-left">
          XOH Sync appearance
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500 text-left">
          Customize theme and set an X Open Hub platform URL. Upload both a dark
          and light version of your logo, SVG is highly recommended.
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 dark:divide-gray-700 border-t border-gray-200 dark:border-gray-700 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              Broker logo dark mode
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="flex flex-col w-full">
                {/* Display the logo if broker.whiteLabelData.logoUrl exists */}
                {broker.whiteLabelData.logoUrlDark && (
                  <div className="mb-4 bg-gray-800 p-2 rounded">
                    {" "}
                    {/* <-- added classes here */}
                    <img
                      src={broker.whiteLabelData.logoUrlDark}
                      alt="Broker Logo"
                      className="mb-2 w-auto h-12"
                    />
                    <p className="text-xs text-gray-500">
                      Current logo in dark mode
                    </p>
                  </div>
                )}

                <label
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  htmlFor="file_input"
                >
                  Upload logo
                </label>
                <input
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400"
                  aria-describedby="file_input_dark_help"
                  id="file_input_dark"
                  type="file"
                  onChange={(e) => onFileInputChange(e, "dark")}
                />
                <p
                  className="mt-1 text-sm text-gray-900 dark:text-gray-500"
                  id="file_input_help"
                >
                  SVG, PNG, or JPG (Recommended SVG 140x40px).
                </p>
              </div>
            </dd>
          </div>

          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              Broker logo light mode
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="flex flex-col w-full">
                {/* Display the logo if broker.whiteLabelData.logoUrl exists */}
                {broker.whiteLabelData.logoUrlLight && (
                  <div className="mb-4 bg-gray-200 p-2 rounded">
                    {" "}
                    {/* <-- added classes here */}
                    <img
                      src={broker.whiteLabelData.logoUrlLight}
                      alt="Broker Logo"
                      className="mb-2 w-auto h-12"
                    />
                    <p className="text-xs text-gray-500">
                      Current logo in light mode
                    </p>
                  </div>
                )}

                <label
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  htmlFor="file_input"
                >
                  Upload logo
                </label>
                <input
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400"
                  aria-describedby="file_input_dark_help"
                  id="file_input_dark"
                  type="file"
                  onChange={(e) => onFileInputChange(e, "light")}
                />
                <p
                  className="mt-1 text-sm text-gray-900 dark:text-gray-500"
                  id="file_input_help"
                >
                  SVG, PNG, or JPG (Recommended SVG 140x40px).
                </p>
              </div>
            </dd>
          </div>

          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              Theme
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <ColorPicker
                selectedColor={selectedColor}
                onColorChange={setSelectedColor}
              />
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() => {
                  if (selectedColor) {
                    saveThemeColorToFirestore(
                      broker.userData.uid,
                      selectedColor
                    );
                  } else {
                    showNotification(
                      "warning",
                      "Please select a theme color before saving."
                    );
                  }
                }}
              >
                Save theme settings
              </button>
            </dd>
          </div>

          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              X Open Hub link dark mode
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 dark:text-gray-400">
                {broker.whiteLabelData.iframeUrlDark}
              </div>
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() =>
                  openProfileUpdateModalWithField(
                    "dark mode platform link",
                    broker.whiteLabelData.iframeUrlDark,
                    "whitelabel"
                  )
                }
              >
                Update
              </button>
            </dd>
          </div>

          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              X Open Hub link light mode
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 dark:text-gray-400">
                {broker.whiteLabelData.iframeUrlLight}
              </div>
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() =>
                  openProfileUpdateModalWithField(
                    "light mode platform link",
                    broker.whiteLabelData.iframeUrlLight,
                    "whitelabel"
                  )
                }
              >
                Update
              </button>
            </dd>
          </div>
        </dl>
      </div>

      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white text-left">
          White label settings
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500 text-left">
          Customize your brokerage information and white label settings.
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 dark:divide-gray-700 border-t border-gray-200 dark:border-gray-700 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              Display name
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 dark:text-gray-400">
                {broker.brokerData.companyData.displayName}
              </div>
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() =>
                  openProfileUpdateModalWithField(
                    "display name",
                    broker.brokerData.companyData.displayName,
                    "brokers"
                  )
                }
              >
                Update
              </button>
            </dd>
          </div>

          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              Company name
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 dark:text-gray-400">
                {broker.brokerData.companyData.companyName}
              </div>
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() =>
                  openProfileUpdateModalWithField(
                    "company name",
                    broker.brokerData.companyData.companyName,
                    "brokers"
                  )
                }
              >
                Update
              </button>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              Support email
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 dark:text-gray-400">
                {broker.brokerData.email}
              </div>
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() =>
                  openProfileUpdateModalWithField(
                    "support email",
                    broker.brokerData.email,
                    "brokers"
                  )
                }
              >
                Update
              </button>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              Support phone number
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 dark:text-gray-400">
                {broker.brokerData.companyData.supportPhoneNumber}
              </div>
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() =>
                  openProfileUpdateModalWithField(
                    "support phone number",
                    broker.brokerData.companyData.supportPhoneNumber,
                    "brokers"
                  )
                }
              >
                Update
              </button>
            </dd>
          </div>
        </dl>
      </div>

      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white text-left">
          Company address
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500 dark:text-gray-500 text-left">
          Company address information and settings.
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 dark:divide-gray-700 border-t border-gray-200 dark:border-gray-700 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              Street name
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 dark:text-gray-400">
                {broker.brokerData.companyData.addressCompany}
              </div>
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() =>
                  openProfileUpdateModalWithField(
                    "company street name",
                    broker.brokerData.companyData.addressCompany,
                    "brokers"
                  )
                }
              >
                Update
              </button>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              City
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 dark:text-gray-400">
                {broker.brokerData.companyData.cityCompany}
              </div>
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() =>
                  openProfileUpdateModalWithField(
                    "city of incorporation",
                    broker.brokerData.companyData.cityCompany,
                    "brokers"
                  )
                }
              >
                Update
              </button>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              State
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 dark:text-gray-400">
                {broker.brokerData.companyData.stateCompany}
              </div>
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() =>
                  openProfileUpdateModalWithField(
                    "company state",
                    broker.brokerData.companyData.stateCompany,
                    "brokers"
                  )
                }
              >
                Update
              </button>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              Zip code
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 dark:text-gray-400">
                {broker.brokerData.companyData.zipCodeCompany}
              </div>
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() =>
                  openProfileUpdateModalWithField(
                    "company zip code",
                    broker.brokerData.companyData.zipCodeCompany,
                    "brokers"
                  )
                }
              >
                Update
              </button>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
              Country of incorporation
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 dark:text-gray-400">
                {broker.brokerData.companyData.countryCompany}
              </div>
              <button
                type="button"
                className="font-semibold text-primary-600 hover:text-primary-500"
                onClick={() =>
                  openProfileUpdateModalWithField(
                    "company country",
                    broker.brokerData.companyData.countryCompany,
                    "brokers"
                  )
                }
              >
                Update
              </button>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
