import { Fragment, useState, useContext, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ShieldExclamationIcon } from "@heroicons/react/24/outline";
import { httpsCallable, getFunctions } from "firebase/functions";
import { UserContext } from "../../../contexts/UserContext";
import { useNotification } from "../../../contexts/NotificationContext.js";
import { auth } from "../../../firebase";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../contexts/LanguageContext.js";

export default function KYCInfoModal({ open, setOpen, onCloseModal }) {
  const functions = getFunctions();
  const { user } = useContext(UserContext);
  const { language } = useLanguage();
  const [isLoading, setIsLoading] = useState(false);
  const { showNotification } = useNotification(); // Use the notification hook
  const [kycResponse, setKycResponse] = useState(null);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
    onCloseModal && onCloseModal(); // Call the callback if it exists
  };

  const handleVerification = async () => {
    try {
      setIsLoading(true);
      const routeKYC = httpsCallable(functions, "routeKYCRequest");
      const response = await routeKYC({
        brokerUID: user.userData.brokerUID, // Replace with actual data
        UID: auth.currentUser.uid,
        language: language,
      });

      if (response.data.authToken && response.data.type) {
        const url = `https://ivs.idenfy.com/api/v2/redirect?authToken=${response.data.authToken}`;
        window.location.href = url;
      }

      setKycResponse(response.data);

      console.log(response.data); // Log or handle the response as needed
    } catch (error) {
      setIsLoading(false);
      console.error("Error during verification:", error);
      showNotification("error", "Failed to initiate verification.");
      // You can show an error message to the user or handle it as needed
    } finally {
      setIsLoading(false); // Ensure that loading stops in both success and error scenarios
    }
  };

  useEffect(() => {
    if (kycResponse && kycResponse.type === "iDenfy") {
      function receiveMessage(event) {
        console.log(event);
        // handle the event data here
      }

      window.addEventListener("message", receiveMessage);

      return () => {
        window.removeEventListener("message", receiveMessage);
      };
    }
  }, [kycResponse]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-gray-900 dark:bg-opacity-75 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-primary-100 dark:bg-primary-500/10">
                    <ShieldExclamationIcon
                      className="h-6 w-6 text-primary-600 dark:text-primary-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
                    >
                      {t("kycInfoModal.infoTitle")}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {t("kycInfoModal.infoDescription")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 text-center">
                  <button
                    type="button"
                    className=" text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                    onClick={handleVerification} // Changed from handleClose to handleVerification
                    disabled={isLoading} // Disable the button while loading
                  >
                    {isLoading ? (
                      <div className="flex items-center">
                        <svg
                          aria-hidden="true"
                          className="w-4 h-4 -ml-1 mr-3 text-gray-200 animate-spin dark:text-gray-500 fill-primary-600 dark:fill-white"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        {t("kycInfoModal.infoButtonLoading")}
                      </div>
                    ) : (
                      t("kycInfoModal.infoButton")
                    )}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
