import React, { useEffect } from "react";
import { useNotification } from "../../../contexts/NotificationContext";

const faqs = [
  {
    id: 1,
    question: "Can I host my CRM/Trader's Room on a custom domain?",
    answer:
      "Absolutely! XOH Sync supports custom domain hosting to maintain your brand's identity. Our team will guide you through the process to ensure a seamless setup.",
  },
  {
    id: 2,
    question:
      "What payment systems does XOH Sync integrate with for deposits and withdrawals?",
    answer:
      "We currently support Praxis Cashier, a leading payment system, for all deposit and withdrawal transactions, ensuring secure and efficient financial operations.",
  },
  {
    id: 3,
    question: "How does XOH Sync handle KYC?",
    answer:
      "XOH Sync integrates with iDenfy for KYC to provide an automated, seamless identity verification process, enhancing security and compliance.",
  },
  {
    id: 4,
    question: "Does XOH Sync send transactional emails?",
    answer:
      "Yes, XOH Sync automatically sends transactional emails to clients regarding deposits, KYC verifications, and other important account activities.",
  },
  {
    id: 5,
    question:
      "Is there a way to migrate my existing clients from X Open Hub to XOH Sync?",
    answer:
      "Certainly! We provide a comprehensive migration tool to easily transfer all your existing X Open Hub clients directly into XOH Sync with minimal disruption.",
  },
  {
    id: 6,
    question: "What happens if I decide to cancel my subscription?",
    answer:
      "If you choose to cancel your subscription, you can do so at any time. You'll have access to your account until the end of the billing cycle, with no additional charges.",
  },
  // More questions...
];

export default function SubscriptionSuccessPage() {
  const { showNotification } = useNotification(); // Use the notification hook

  useEffect(() => {
    showNotification(
      "success",
      "Welcome aboard! Your XOH Sync subscription is active."
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs once on component mount

  // Define the keyframes and animation styles
  const animationStyles = `
@keyframes gradientX {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}
.animate-gradient-x {
  background-size: 200% 200%;
  animation: gradientX 8s ease infinite;
  position: relative;
}
.animate-gradient-x::before, .animate-gradient-x::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 1px; /* Adjust the height of the border here */
  background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
  background-size: 200% 100%;
  animation: gradientX 8s ease infinite;
}
.animate-gradient-x::before {
  top: 0;
}
.animate-gradient-x::after {
  bottom: 0;
}
`;

  return (
    <>
      {/* Inject the styles directly into the component */}
      <style>{animationStyles}</style>
      <div className="animate-gradient-x bg-gradient-to-r from-blue-600 via-purple-600 to-pink-600 dark:from-blue-700 dark:via-purple-700 dark:to-pink-700">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Welcome to XOH Sync!
            <br />
            Your free trial has begun.
          </h2>
          <div className="mt-10 flex flex-col items-center gap-y-4 lg:mt-0 lg:flex-row lg:gap-x-6 lg:flex-shrink-0">
            <a
              href="/broker/dashboard/settings"
              className="rounded-md bg-white px-5 py-3 text-base font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:ring focus-visible:ring-gray-300 focus-visible:ring-opacity-50"
            >
              Start Configuration
            </a>
            <a
              href="/broker/dashboard/clients"
              className="text-base font-semibold text-white hover:text-gray-200"
            >
              Go to Dashboard <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-900">
        <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900 dark:text-white">
              Frequently asked questions
            </h2>
            <p className="mt-6 text-base leading-7 text-gray-600 dark:text-gray-400">
              Have a different question and can’t find the answer you’re looking
              for? Reach out to our support team by{" "}
              <a
                href="mailto:support@xohsync.com"
                className="font-semibold text-primary-600 hover:text-primary-500"
              >
                sending us an email
              </a>{" "}
              and we’ll get back to you as soon as we can.
            </p>
          </div>
          <div className="mt-20">
            <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
              {faqs.map((faq) => (
                <div key={faq.id}>
                  <dt className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600 dark:text-gray-400">
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
