import { useState, useMemo, useEffect } from "react";
import { Label, Select, TextInput } from "flowbite-react";
import { Icon } from "@iconify/react";
import getCurrencySymbol from "currency-symbol-map";
import { useTranslation } from "react-i18next";

const paymentMethods = [
  {
    id: "card",
    name: "Credit/Debit Card",
    description: "Instant deposit using your Visa or Mastercard",
    icon: "solar:card-bold-duotone",
    minAmount: 200,
  },
  // {
  //   id: "card-alt",
  //   name: "Alternative Card Payment",
  //   description: "Secure card deposit with additional providers",
  //   icon: "solar:card-bold-duotone",
  //   minAmount: 200,
  // },
  {
    id: "crypto",
    name: "Cryptocurrency",
    description: "Deposit using Bitcoin, Ethereum, or other digital currencies",
    icon: "mdi:ethereum",
    minAmount: 200,
  },
  {
    id: "mifinity",
    name: "MiFinity Wallet",
    description: "Fast and secure e-wallet transfers",
    icon: "solar:wallet-bold-duotone",
    minAmount: 10,
  },
  // Add more payment methods here
];

export function CustomCashier({
  onClose,
  redirectUrl,
  authToken,
  onLoaded,
  isHidden,
  onTransactionApproved,
  onTransactionAlert,
  isLoading,
  accounts,
  selectedAccountId,
  setSelectedAccountId,
  handleDeposit,
  amount,
  setAmount,
  selectedMethod,
  setSelectedMethod,
  selectedCurrency,
  setSelectedCurrency,
}) {
  const [amountTouched, setAmountTouched] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // Reset currency when changing payment method
    if (selectedMethod === "crypto") {
      setSelectedCurrency("BTC");
    } else {
      setSelectedCurrency("EUR");
    }
  }, [selectedMethod]);

  const currencyOptions = useMemo(() => {
    if (selectedMethod === "crypto") {
      return ["BTC", "ETH", "XRP", "USDT (ERC20)"];
    }
    return ["EUR", "USD", "GBP"];
  }, [selectedMethod]);

  useEffect(() => {
    if (amount !== "") {
      setAmountTouched(true);
    }
  }, [amount]);

  useEffect(() => {
    if (
      selectedMethod === "crypto" &&
      !currencyOptions.includes(selectedCurrency)
    ) {
      setSelectedCurrency(currencyOptions[0]);
    }
  }, [selectedMethod, currencyOptions, selectedCurrency]);

  const selectedPaymentMethod = useMemo(() => {
    return paymentMethods.find((method) => method.id === selectedMethod);
  }, [selectedMethod]);

  const handleAmountChange = (e) => {
    const value = e.target.value;
    // Only allow numbers and commas
    if (/^[0-9,]*$/.test(value)) {
      setAmount(value);
      setAmountTouched(true);
    }
  };

  const isAmountValid =
    amount !== "" &&
    parseFloat(amount) >= (selectedPaymentMethod?.minAmount || 0);

  const showAmountError = amountTouched && !isAmountValid;

  const isDepositButtonDisabled = isLoading || !isAmountValid;

  return (
    <>
      <div className="relative max-h-full w-full max-w-lg">
        {/* Modal content */}
        <div className="relative rounded-lg bg-white dark:bg-gray-800 sm:p-5">
          {/* Modal header */}
          <div className="mb-4 flex items-center justify-between rounded-t border-b pb-4 dark:border-gray-700 sm:mb-5">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              {t("customCashier.deposit")}
            </h3>
          </div>
          {/* Modal body */}
          {paymentMethods.map((method) => (
            <div
              key={method.id}
              onClick={() => setSelectedMethod(method.id)}
              className={`mb-4 flex cursor-pointer items-center justify-between rounded-lg border p-4 ps-4 transition-colors ${
                selectedMethod === method.id
                  ? "border-blue-500 bg-blue-50 dark:border-blue-600 dark:bg-blue-900/20"
                  : "border-gray-200 bg-gray-50 dark:border-gray-600 dark:bg-gray-700"
              } md:mb-5`}
            >
              <div className="flex-grow">
                <div className="flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id={`${method.id}-modal`}
                      aria-describedby={`${method.id}-text-modal`}
                      type="radio"
                      name="payment-method"
                      value={method.id}
                      checked={selectedMethod === method.id}
                      onChange={() => setSelectedMethod(method.id)}
                      className="h-4 w-4 border-gray-300 bg-white text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                    />
                  </div>
                  <div className="ms-4 text-sm">
                    <label
                      htmlFor={`${method.id}-modal`}
                      className="font-medium text-gray-900 dark:text-white"
                    >
                      {t(`customCashier.paymentMethods.${method.id}.name`)}
                    </label>
                    <p
                      id={`${method.id}-text-modal`}
                      className="text-xs font-normal text-gray-500 dark:text-gray-400"
                    >
                      {t(
                        `customCashier.paymentMethods.${method.id}.description`
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="shrink-0">
                <Icon
                  icon={method.icon}
                  className="h-8 w-8 text-gray-600 dark:text-gray-400"
                />
              </div>
            </div>
          ))}

          <div className="mb-6 grid grid-cols-3 gap-4">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="account" value={t("customCashier.account")} />
              </div>
              <Select
                id="account"
                required
                value={selectedAccountId}
                onChange={(e) => setSelectedAccountId(e.target.value)}
              >
                {accounts.map((account) => (
                  <option key={account.id_account} value={account.id_account}>
                    {account.id_account}
                  </option>
                ))}
              </Select>
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="currency" value={t("customCashier.currency")} />
              </div>
              <Select
                id="currency"
                required
                value={selectedCurrency}
                onChange={(e) => setSelectedCurrency(e.target.value)}
              >
                {currencyOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </div>
            {selectedMethod === "crypto" ? (
              <div className="col-span-1">
                <div className="mb-2 block">
                  <Label
                    htmlFor="eurAmount"
                    value={t("customCashier.amountEuro")}
                  />
                </div>
                <div className="relative">
                  <TextInput
                    id="eurAmount"
                    type="text"
                    sizing="md"
                    value={amount}
                    onChange={handleAmountChange}
                    onBlur={() => setAmountTouched(true)}
                    min={selectedPaymentMethod?.minAmount || 0}
                    step="0.01"
                    color={showAmountError ? "failure" : undefined}
                    helperText={
                      showAmountError && (
                        <>
                          <span className="font-medium">
                            {t("customCashier.minAmount")}:
                          </span>{" "}
                          €{selectedPaymentMethod?.minAmount}
                        </>
                      )
                    }
                  />
                </div>
              </div>
            ) : (
              <div className="col-span-1">
                <div className="mb-2 block">
                  <Label htmlFor="amount" value={t("customCashier.amount")} />
                </div>
                <div className="relative">
                  <TextInput
                    id="amount"
                    type="text"
                    sizing="md"
                    value={amount}
                    onChange={handleAmountChange}
                    onBlur={() => setAmountTouched(true)}
                    min={selectedPaymentMethod?.minAmount || 0}
                    step="0.01"
                    color={showAmountError ? "failure" : undefined}
                    helperText={
                      showAmountError && (
                        <>
                          <span className="font-medium">
                            {t("customCashier.minAmount")}:
                          </span>{" "}
                          {getCurrencySymbol(selectedCurrency)}
                          {selectedPaymentMethod?.minAmount}
                        </>
                      )
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div className="mt-4 items-center justify-end space-x-0 space-y-4 border-t border-gray-200 pt-4 dark:border-gray-700 sm:flex sm:space-x-4 sm:space-y-0 md:mt-5 md:pt-5">
            <button
              type="button"
              onClick={onClose}
              className="w-full rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700 sm:w-auto"
            >
              {t("customCashier.close")}
            </button>
            <button
              type="button"
              onClick={handleDeposit}
              disabled={isDepositButtonDisabled}
              className="flex w-full items-center justify-center rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 sm:mt-0 sm:w-auto disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? (
                <>
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-3 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  {t("customCashier.loading")}
                </>
              ) : (
                t("customCashier.deposit")
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
