import React, { useEffect, useState, useContext } from "react";
import { Table } from "flowbite-react";
import { useNotification } from "../../../../../contexts/NotificationContext";
import { getFirestore, collection, query, getDocs } from "firebase/firestore";
import { UserContext } from "../../../../../contexts/UserContext";
import EmptyState from "../../../../Shared/EmptyState";
import getSymbolFromCurrency from "currency-symbol-map";
import { useTranslation } from "react-i18next";

const BalanceIBHistory = ({ totalCommission, currency }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showNotification } = useNotification();
  const { user } = useContext(UserContext); // Destructure user from context
  const [payoutHistory, setPayoutHistory] = useState([]); // Renamed state variable and setter
  const firestore = getFirestore();
  const { t } = useTranslation();

  const fetchPayoutHistory = async () => {
    setIsLoading(true);
    console.log("Fetching payout history for user:", user?.userData?.uid); // Log the user ID being queried
    try {
      if (user && user.userData) {
        const ibUID = user.userData.uid;
        console.log(`Querying Firestore for ib/${ibUID}/payoutHistory`); // Log the path being queried
        const q = query(collection(firestore, `ib/${ibUID}/payoutHistory`));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          console.log("No documents found in payoutHistory."); // Log if no documents were found
        } else {
          console.log(
            `Found ${querySnapshot.docs.length} documents in payoutHistory.`
          ); // Log the number of documents found
        }
        const fetchedData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("Fetched data:", fetchedData); // Log the fetched data
        setPayoutHistory(fetchedData);
      } else {
        console.log("User or user.userData is undefined."); // Log if user data is not available
      }
    } catch (error) {
      console.error("Error fetching payout history:", error);
      showNotification("Error fetching data", "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Call fetchPayoutHistory on component mount
    if (user && user.userData && user.userData.uid) {
      fetchPayoutHistory();
    }
  }, [user, firestore]);

  return (
    <div className="p-6 bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full">
      <div>
        <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-2">
          {t("ibBalance.historyTitle")}
        </h3>
        <p className="mb-2 max-w-xl">{t("ibBalance.historyDescription")}</p>
      </div>
      <div className="mt-4 overflow-x-auto">
        <Table hoverable>
          <Table.Head>
            <Table.HeadCell>{t("ibBalance.status")}</Table.HeadCell>
            <Table.HeadCell>{t("ibBalance.amount")}</Table.HeadCell>
            <Table.HeadCell>{t("ibBalance.currency")}</Table.HeadCell>
            <Table.HeadCell>{t("ibBalance.date")}</Table.HeadCell>
            <Table.HeadCell>{t("ibBalance.accountId")}</Table.HeadCell>
            <Table.HeadCell>{t("ibBalance.comment")}</Table.HeadCell>
            <Table.HeadCell></Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {isLoading ? (
              Array.from({ length: 10 }).map(
                (
                  _,
                  index // Assuming you want to render 5 skeleton rows
                ) => (
                  <Table.Row
                    key={index}
                    className="bg-white dark:border-gray-700 dark:bg-gray-700"
                  >
                    {Array.from({ length: 7 }).map(
                      (
                        _,
                        cellIndex // Assuming 4 cells per row
                      ) => (
                        <Table.Cell
                          key={cellIndex}
                          className="whitespace-nowrap font-medium text-gray-900 dark:text-white"
                        >
                          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-800 max-w-[150px] animate-pulse"></div>
                        </Table.Cell>
                      )
                    )}
                  </Table.Row>
                )
              )
            ) : payoutHistory.length > 0 ? (
              payoutHistory.map((payout, index) => (
                <Table.Row
                  key={index}
                  className="bg-white dark:border-gray-700 dark:bg-gray-700"
                >
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {payout.status === "approved" ? (
                      <span className="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                        Approved
                      </span>
                    ) : (
                      <span className="bg-pink-100 text-pink-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300">
                        Denied
                      </span>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {`${getSymbolFromCurrency(payout.currency)}${
                      payout.amount
                    }`}
                  </Table.Cell>
                  <Table.Cell>{payout.currency}</Table.Cell>
                  <Table.Cell>
                    {new Date(
                      payout.processedDate.seconds * 1000
                    ).toLocaleString("en-GB", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    })}
                  </Table.Cell>
                  <Table.Cell>{payout.userLogin}</Table.Cell>
                  <Table.Cell>{payout.comment}</Table.Cell>
                  <Table.Cell>{payout.idAccount}</Table.Cell>
                </Table.Row>
              ))
            ) : (
              // Render EmptyState if payoutHistory is empty
              <tr>
                <td colSpan="100%" className="text-center py-5">
                  <EmptyState />
                </td>
              </tr>
            )}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default BalanceIBHistory;
