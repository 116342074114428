import { useState, useEffect, useContext } from "react";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import Spinner from "../Spinner";
import { useNotification } from "../../../contexts/NotificationContext";
import { BrokerContext } from "../../../contexts/BrokerContext";
import { query, where, getDocs, collection } from "firebase/firestore";
import { Button } from "flowbite-react";
import { Accordion } from "flowbite-react";

// Utility function to transform title to Firestore field name
const transformTitleToFieldName = (title) => {
  return title.charAt(0).toLowerCase() + title.slice(1).replace(/\s/g, "");
};

export default function BrokerKYCDocumentManager({ clientData }) {
  const { broker } = useContext(BrokerContext);
  const brokerUID = broker?.brokerData?.uid;
  const [fileUrls, setFileUrls] = useState({});
  const [loading, setLoading] = useState(true);
  const [uploadingFiles, setUploadingFiles] = useState({});
  const { showNotification } = useNotification();

  useEffect(() => {
    const fetchData = async () => {
      // Check if brokerUID is null or undefined
      if (!brokerUID) {
        console.log("Broker UID is null, skipping fetch.");
        setLoading(false);
        return;
      }

      // Additionally, check if clientData or clientData.uid is null or undefined
      if (!clientData || !clientData.uid) {
        console.log("Client data or client UID is null, skipping fetch.");
        setLoading(false);
        return;
      }

      const db = getFirestore();
      const q = query(
        collection(db, "kyc"),
        where("brokerUID", "==", brokerUID)
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        let data = {};
        querySnapshot.forEach((doc) => {
          if (doc.id === clientData.uid) {
            data = doc.data();
          }
        });
        setFileUrls(data);
      } else {
        console.log("No KYC data found for this client!");
      }

      setLoading(false); // <-- Set loading to false once data is fetched
    };

    fetchData();
  }, [clientData, brokerUID]);

  const files = [
    {
      title: "ID Document Front",
      size: "",
      source: fileUrls[transformTitleToFieldName("ID Document Front")],
    },
    {
      title: "ID Document Back",
      size: "",
      source: fileUrls[transformTitleToFieldName("ID Document Back")],
    },
    {
      title: "Proof of Address",
      size: "",
      source: fileUrls[transformTitleToFieldName("Proof of Address")],
    },
    {
      title: "Selfie with ID-document",
      size: "",
      source: fileUrls[transformTitleToFieldName("Selfie with ID-document")],
    },
  ];

  const handleFileUpload = async (file, fileType) => {
    const storage = getStorage();
    let storagePath = "";

    switch (fileType) {
      case "ID Document Front":
        storagePath = `kyc-uploads/${clientData.uid}/id-document/front.${
          file.type.split("/")[1]
        }`;
        break;
      case "ID Document Back":
        storagePath = `kyc-uploads/${clientData.uid}/id-document/back.${
          file.type.split("/")[1]
        }`;
        break;
      case "Proof of Address":
        storagePath = `kyc-uploads/${
          clientData.uid
        }/proof-of-address/document.${file.type.split("/")[1]}`;
        break;
      case "Selfie with ID-document":
        storagePath = `kyc-uploads/${clientData.uid}/selfie/selfie.${
          file.type.split("/")[1]
        }`;
        break;
      default:
        console.error("Invalid file type");
        return;
    }

    const storageRef = ref(storage, storagePath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Mark the file as being uploaded
    setUploadingFiles((prev) => ({
      ...prev,
      [fileType]: true,
    }));

    // Listen to upload progress, complete, and error events
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Handle progress...
      },
      (error) => {
        console.error("Upload failed:", error);
        showNotification("error", "File upload failed!"); // Show error notification

        // Mark the file upload as complete (even if it failed)
        setUploadingFiles((prev) => ({
          ...prev,
          [fileType]: false,
        }));
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        const fieldName = transformTitleToFieldName(fileType);

        // Update Firestore with the download URL
        const db = getFirestore();
        const docRef = doc(db, "kyc", clientData.uid);
        await setDoc(
          docRef,
          {
            [fieldName]: downloadURL,
          },
          { merge: true }
        );

        // Update local state
        setFileUrls((prevState) => ({
          ...prevState,
          [fieldName]: downloadURL,
        }));

        showNotification("success", "File uploaded successfully!"); // Show success notification

        // Mark the file upload as complete
        setUploadingFiles((prev) => ({
          ...prev,
          [fileType]: false,
        }));
      }
    );
  };

  const getFileExtensionFromUrl = (url) => {
    return url.split(".").pop().split("?")[0];
  };

  const handleDeleteFile = async (fileType) => {
    const storage = getStorage();
    let storagePath = "";

    const fileExtension = getFileExtensionFromUrl(
      fileUrls[transformTitleToFieldName(fileType)]
    );

    switch (fileType) {
      case "ID Document Front":
        storagePath = `kyc-uploads/${clientData.uid}/id-document/front.${fileExtension}`;
        break;
      case "ID Document Back":
        storagePath = `kyc-uploads/${clientData.uid}/id-document/back.${fileExtension}`;
        break;
      case "Proof of Address":
        storagePath = `kyc-uploads/${clientData.uid}/proof-of-address/document.${fileExtension}`;
        break;
      case "Selfie with ID-document":
        storagePath = `kyc-uploads/${clientData.uid}/selfie/selfie.${fileExtension}`;
        break;
      default:
        console.error("Invalid file type");
        return;
    }

    const storageRef = ref(storage, storagePath);

    // Delete from Firebase Storage
    try {
      await deleteObject(storageRef);

      // Once the file is deleted from Storage, delete the URL from Firestore
      const db = getFirestore();
      const docRef = doc(db, "kyc", clientData.uid);
      const fieldName = transformTitleToFieldName(fileType);

      await setDoc(
        docRef,
        {
          [fieldName]: null,
        },
        { merge: true }
      );

      // Update local state
      setFileUrls((prevState) => ({ ...prevState, [fieldName]: null }));

      showNotification("success", "File deleted successfully!");
    } catch (error) {
      console.error("Error deleting file:", error);
      showNotification("error", "Failed to delete file!");
    }
  };

  // Handle drag over event
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.add(
      "border-primary-500",
      "dark:border-primary-500"
    ); // Add a visual indicator
  };

  // Handle file drop event
  const handleDrop = (e, fileTitle) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.remove(
      "border-primary-500",
      "dark:border-primary-500"
    ); // Remove the visual indicator

    const file = e.dataTransfer.files[0];
    if (file) {
      handleFileUpload(file, fileTitle);
    }
  };

  // Handle drag leave event
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.remove(
      "border-primary-500",
      "dark:border-primary-500"
    ); // Remove the visual indicator
  };

  return (
    <div>
      <Accordion collapseAll className="">
        <Accordion.Panel>
          <Accordion.Title>User KYC Documents</Accordion.Title>
          <Accordion.Content>
            {loading ? (
              <Spinner />
            ) : (
              <ul
                id="fileList"
                className="grid grid-cols-2 gap-x-2 gap-y-4 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-2 xl:gap-x-4"
              >
                {files.map((file) => (
                  <li key={file.title} className="relative">
                    {uploadingFiles[file.title] ? (
                      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <svg
                          className="animate-spin h-10 w-10 text-gray-500"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </div>
                    ) : file.source ? (
                      file.source.toLowerCase().includes(".pdf") ? (
                        <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 dark:bg-gray-900">
                          <iframe
                            src={file.source}
                            className="w-full h-full"
                            title="PDF Preview"
                          ></iframe>
                          <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                            <span className="isolate inline-flex rounded-md shadow-sm">
                              <Button.Group>
                                <Button
                                  onClick={() =>
                                    window.open(file.source, "_blank")
                                  }
                                  color="dark"
                                >
                                  View file
                                </Button>
                                <Button
                                  onClick={() => handleDeleteFile(file.title)}
                                  color="dark"
                                >
                                  Delete
                                </Button>
                                <Button
                                  onClick={() =>
                                    document
                                      .getElementById(
                                        `file-upload-${file.title}`
                                      )
                                      .click()
                                  }
                                  color="dark"
                                >
                                  Upload new
                                </Button>
                              </Button.Group>
                              <input
                                id={`file-upload-${file.title}`}
                                name={`file-upload-${file.title}`}
                                type="file"
                                className="sr-only"
                                onChange={(e) =>
                                  handleFileUpload(
                                    e.target.files[0],
                                    file.title
                                  )
                                }
                              />
                            </span>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="group aspect-h-7 aspect-w-10 block w-full h-64 overflow-hidden rounded-lg bg-gray-100 dark:bg-gray-900">
                            <img
                              src={file.source}
                              alt=""
                              className="pointer-events-none object-cover group-hover:opacity-75"
                            />
                            <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                              <span className="isolate inline-flex rounded-md shadow-sm">
                                <Button.Group>
                                  <Button
                                    onClick={() =>
                                      window.open(file.source, "_blank")
                                    }
                                    color="dark"
                                  >
                                    View file
                                  </Button>
                                  <Button
                                    onClick={() => handleDeleteFile(file.title)}
                                    color="dark"
                                  >
                                    Delete
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      document
                                        .getElementById(
                                          `file-upload-${file.title}`
                                        )
                                        .click()
                                    }
                                    color="dark"
                                  >
                                    Upload new
                                  </Button>
                                </Button.Group>

                                <input
                                  id={`file-upload-${file.title}`}
                                  name={`file-upload-${file.title}`}
                                  type="file"
                                  className="sr-only"
                                  onChange={(e) =>
                                    handleFileUpload(
                                      e.target.files[0],
                                      file.title
                                    )
                                  }
                                />
                              </span>
                            </div>
                          </div>
                          <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900 dark:text-white">
                            {file.title}
                          </p>
                        </>
                      )
                    ) : (
                      <div
                        className="col-span-full"
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, file.title)}
                        onDragLeave={handleDragLeave}
                      >
                        <div className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                          <div className="flex flex-col items-center justify-center pt-5 pb-6 text-center pointer-events-none">
                            <svg
                              className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 16"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                              />
                            </svg>
                            <div className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <p className="my-2 block truncate text-sm font-medium text-gray-900 dark:text-white">
                                {file.title}
                              </p>
                              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">
                                  Click to upload
                                </span>{" "}
                                or drag and drop
                              </p>
                            </div>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              PNG, JPG, PDF up to 5MB
                            </p>
                          </div>
                          <div
                            className="absolute inset-0 flex items-center justify-center pointer-events-none"
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, file.title)}
                            onDragLeave={handleDragLeave}
                          >
                            <label
                              htmlFor={`file-upload-empty-${file.title}`}
                              className="w-full h-full cursor-pointer pointer-events-auto"
                            >
                              <input
                                id={`file-upload-empty-${file.title}`}
                                name={`file-upload-empty-${file.title}`}
                                type="file"
                                className="sr-only"
                                onChange={(e) =>
                                  handleFileUpload(
                                    e.target.files[0],
                                    file.title
                                  )
                                }
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
    </div>
  );
}
