import React, { useContext, useEffect, useState } from "react";
import { BrokerContext } from "../../../contexts/BrokerContext.js";
import { useNotification } from "../../../contexts/NotificationContext.js";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import EmailDomainConfigModal from "../../Modals/EmailDomainConfigModal.js";

export default function EmailConfiguration() {
  const [domainName, setDomainName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const { showNotification } = useNotification();
  const brokerContextValue = useContext(BrokerContext);
  const [isModalOpen, setIsModalOpen] = useState(false); // New state to control the modal
  const [modalData, setModalData] = useState(null); // New state to store the response.data

  // Check if user object or userData is null or undefined
  if (!brokerContextValue || !brokerContextValue.broker.userData) {
    // Render loading or error state
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const brokerUID = brokerContextValue.broker.userData.uid;
        const db = getFirestore();
        const brokerRef = doc(db, "brokers", brokerUID);
        const brokerDoc = await getDoc(brokerRef);

        if (brokerDoc.exists && brokerDoc.data().emailConfig) {
          const emailConfig = brokerDoc.data().emailConfig;
          setDomainName(emailConfig.name || "");
        }
      } catch (error) {
        console.error("Error fetching broker data:", error.message);
      }
    };

    fetchData();
  }, [brokerContextValue]);

  const handleInputChange = (e) => {
    setDomainName(e.target.value);
  };

  const addMailgunDomain = async () => {
    setIsSaving(true);
    try {
      const brokerUID = brokerContextValue.broker.userData.uid;
      const db = getFirestore();
      const brokerRef = doc(db, "brokers", brokerUID);
      const brokerDoc = await getDoc(brokerRef);

      console.log("Broker Document Snapshot:", brokerDoc);

      if (brokerDoc.exists) {
        const emailConfig = brokerDoc.data().emailConfig;
        const sendingDnsRecords = emailConfig
          ? emailConfig.sendingDnsRecords
          : [];

        if (
          emailConfig &&
          emailConfig.name === domainName &&
          emailConfig.status === "verified"
        ) {
          showNotification(
            "warning",
            "This domain is already verified and in use."
          );
          setIsSaving(false);
          return;
        }

        // Check if the entered domainName is already present in the sendingDnsRecords
        if (
          sendingDnsRecords &&
          sendingDnsRecords.some((record) => record.name === domainName)
        ) {
          setIsModalOpen(true);
          setModalData(brokerDoc.data().emailConfig);
        } else {
          const functions = getFunctions();
          const addDomainFunction = httpsCallable(
            functions,
            "addMailgunDomain"
          );
          const response = await addDomainFunction({ domainName });
          console.log(response.data);

          // Wait for a few seconds to ensure Firestore has been updated
          await new Promise((resolve) => setTimeout(resolve, 5000));

          // Fetch the updated document from Firestore
          const updatedBrokerDoc = await getDoc(brokerRef);
          setModalData(updatedBrokerDoc.data().emailConfig);
          setIsModalOpen(true);
        }
      } else {
        const functions = getFunctions();
        const addDomainFunction = httpsCallable(functions, "addMailgunDomain");
        const response = await addDomainFunction({ domainName });
        console.log(response.data);
        showNotification("success", "Domain added successfully!");
      }
    } catch (error) {
      console.error("Error adding Mailgun domain:", error.message);
      showNotification("error", "Failed to add domain. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="max-w-7xl min-h-screen dark:bg-gray-900">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
          Email configuration
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          Configure your custom email settings in order to send email
          notifications from your company email.
        </p>
      </div>

      <div className="mt-6 grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 dark:border-gray-700 pb-12 md:grid-cols-3">
        <div className="grid min-w-max grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
          <div className="sm:col-span-6">
            <label
              htmlFor="domain-name"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >
              Sender's domain
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="domainName"
                value={domainName} // Set the value prop to the domainName state variable
                onChange={handleInputChange}
                id="domain-name"
                required
                placeholder="Enter your domain and proceed with verification"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6 sm:col-span-6">
            <button
              type="button"
              onClick={addMailgunDomain}
              className="rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              disabled={isSaving}
            >
              {isSaving ? (
                <div className="flex items-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Verifying...
                </div>
              ) : (
                "Add new domain"
              )}
            </button>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <EmailDomainConfigModal
          open={isModalOpen}
          setOpen={setIsModalOpen}
          data={modalData}
          domainName={domainName}
        />
      )}
    </div>
  );
}
