import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { useNotification } from "../../../contexts/NotificationContext";
import {
  CheckCircleIcon,
  ArrowTopRightOnSquareIcon,
  HomeIcon,
} from "@heroicons/react/20/solid";

function capitalizeFirstLetter(string) {
  // Check if the string is defined and not null
  if (string && typeof string === "string") {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    // If the string is not defined or null, return an empty string or any placeholder you prefer
    return "";
  }
}

export default function LogsDetails() {
  const { docId } = useParams(); // Update clientId to docId
  const [errorData, setErrorData] = useState(null);
  const [userData, setUserData] = useState(null);
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const pages = [
    { name: "All logs", href: "/broker/dashboard/logs", current: false },
    {
      name: `Log: ${docId}`, // Using template literal here
      href: `/broker/dashboard/logs/${docId}`, // Using template literal here
      current: true,
    },
  ];

  function dismissError() {
    const db = getFirestore();

    // Get a reference to the error document that you want to update
    const errorRef = doc(db, "errors", docId);

    // Update the status of the error log to "dismissed"
    setDoc(errorRef, { status: "dismissed" }, { merge: true })
      .then(() => {
        console.log("Error dismissed successfully!");

        showNotification("success", "Error dismissed successfully!");

        // Navigate back to the logs dashboard or any other page as needed
        navigate("/broker/dashboard/logs");
      })
      .catch((error) => {
        console.error("Error updating document: ", error);

        showNotification("error", "Error dismiss failed!");
      });
  }

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();

      // Fetch error data
      const errorRef = doc(db, "errors", docId);
      const errorDoc = await getDoc(errorRef);

      if (errorDoc.exists()) {
        const errorData = errorDoc.data();
        // Format timestamp
        errorData.timestamp =
          errorData.timestamp?.toDate().toLocaleString(undefined, {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }) || "Timestamp unavailable";

        setErrorData(errorData);

        // Fetch user data with uid from errorData
        const userRef = doc(db, "users", errorData.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      }
    };

    fetchData();
  }, [docId]);

  return (
    <>
      <nav
        className="flex border-b border-gray-200 dark:border-white/10 bg-white dark:bg-gray-900"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full space-x-4 px-4 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <a
                href="/broker/dashboard/clients"
                className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </a>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name} className="flex">
              <div className="flex items-center">
                <svg
                  className="h-full w-6 flex-shrink-0 text-gray-200 dark:text-white/10"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <a
                  href={page.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 dark:hover:text-white"
                  aria-current={page.current ? "page" : undefined}
                >
                  {page.name}
                </a>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <div className="px-4 sm:px-6 lg:px-8 pt-4 bg-white dark:bg-gray-900">
        <div className="flex justify-between items-center px-4 sm:px-0">
          <div>
            <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
              Log Details
            </h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">
              Ensure that necessary actions are taken to resolve or dismiss the
              issues identified, to maintain system performance and user
              experience.
            </p>
          </div>
          <div>
            <a
              href={`/broker/dashboard/client/${errorData?.uid}`}
              target="_blank"
              rel="noopener noreferrer"
              className="mr-2 inline-flex items-center gap-x-2 rounded-md bg-white dark:bg-white/5 px-3.5 py-2.5 text-sm font-semibold text-gray-900 dark:text-white shadow-sm hover:bg-gray-50 dark:hover:bg-white/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 dark:focus-visible:outline-gray-400"
            >
              <ArrowTopRightOnSquareIcon
                className="-ml-0.5 h-5 w-5"
                aria-hidden="true"
              />
              View user
            </a>
            <button
              type="button"
              className="inline-flex items-center gap-x-2 rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              onClick={dismissError}
            >
              <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Resolve log
            </button>
          </div>
        </div>

        <div className="mt-6 border-t border-gray-100 dark:border-white/10">
          <dl className="divide-y divide-gray-100 dark:divide-white/10">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                Status
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700 dark:text-gray-500 sm:col-span-2 sm:mt-0">
                <span className="inline-flex items-center rounded-md bg-yellow-50 dark:bg-yellow-400/10 px-2 py-1 text-xs font-medium text-yellow-800 dark:text-yellow-500 ring-1 ring-inset ring-yellow-600/20 dark:ring-yellow-400/20">
                  {capitalizeFirstLetter(errorData?.status)}
                </span>
              </dd>
            </div>

            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                Error message
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700 dark:text-gray-500 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{errorData?.errorMessage}</span>
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                Date
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700 dark:text-gray-500 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{errorData?.timestamp}</span>
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                Function name
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700 dark:text-gray-500 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{errorData?.functionName}</span>
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                Name of user
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700 dark:text-gray-500 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{userData?.fullName}</span>
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                User's email
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-gray-700 dark:text-gray-500 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{userData?.email}</span>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}
