import { getFirestore, doc, setDoc } from "firebase/firestore";
import { useNotification } from "../../../contexts/NotificationContext";
import BrokerKYCDocumentManager from "../ManualKYC/BrokerKYCDocumentManager";
import React, { useState, useEffect, useCallback } from "react";
import { Dropdown } from "flowbite-react";
import { HiCheck, HiClock, HiArchive } from "react-icons/hi";

export default function ClientKYCDetails({ clientData }) {
  const { showNotification } = useNotification();
  // Determine the default input value based on the kycStatus
  const determineInputValue = useCallback(() => {
    switch (clientData?.kycStatus) {
      case "approved_kyc":
        return "Approved";
      case "pending":
        return "Pending approval";
      default:
        return "Verification not started";
    }
  }, [clientData?.kycStatus]);

  const [inputValue, setInputValue] = useState(determineInputValue());

  // Update inputValue whenever clientData changes
  useEffect(() => {
    setInputValue(determineInputValue());
  }, [clientData, determineInputValue]);

  const handleKYCChange = async (newStatus) => {
    const db = getFirestore();

    const userRef = doc(db, "users", clientData.uid);
    const kycRef = doc(db, "kyc", clientData.uid); // Assuming the document ID in the kyc collection is the same as the user's UID

    let updatedStatus;
    let newInputValue;
    switch (newStatus) {
      case "completed":
        updatedStatus = "approved_kyc";
        newInputValue = "Approved";
        break;
      case "pending":
        updatedStatus = "pending";
        newInputValue = "Pending approval";
        break;
      default:
        updatedStatus = null;
        newInputValue = "Verification not started";
    }

    setInputValue(newInputValue); // Update the local state

    try {
      await setDoc(userRef, { kycStatus: updatedStatus }, { merge: true });
      await setDoc(kycRef, { kycStatus: updatedStatus }, { merge: true });

      // Show a success notification
      showNotification("success", "KYC status updated successfully!");
    } catch (error) {
      console.error("Error updating KYC status:", error);
      showNotification(
        "error",
        "Failed to update KYC status. Please try again."
      );
    }
  };

  function mapInputValueToStatus(inputValue) {
    switch (inputValue) {
      case "KYC Approved":
        return "approved";
      case "KYC Approval Pending":
        return "pending";
      default:
        return "unknown";
    }
  }

  function StatusPill({ status }) {
    switch (status) {
      case "pending":
        return "ml-2 bg-yellow-100 text-yellow-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300";
      case "approved":
        return "ml-2 bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300";
      default:
        return "ml-2 bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300";
    }
  }

  return (
    <div>
      <section className="flex items-center bg-gray-50 dark:bg-gray-900">
        <div className="w-full mx-auto">
          {/* Start coding here */}
          <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
            <div className="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
              <div>
                <h5 className="mr-3 font-semibold dark:text-white">
                  KYC Status:
                  <span
                    className={StatusPill({
                      status: mapInputValueToStatus(inputValue),
                    })}
                  >
                    {inputValue}
                  </span>
                </h5>
              </div>
              <Dropdown
                label=""
                dismissOnClick={true}
                renderTrigger={() => (
                  <button
                    type="button"
                    className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                  >
                    <svg
                      className="h-3.5 w-3.5 mr-2 -ml-1"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 8"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
                      />
                    </svg>
                    Change status
                  </button>
                )}
              >
                <Dropdown.Header>
                  <span className="block text-sm">Select a new status</span>
                </Dropdown.Header>
                <Dropdown.Item
                  icon={HiCheck}
                  onClick={() => handleKYCChange("completed")}
                >
                  Approved
                </Dropdown.Item>
                <Dropdown.Item
                  icon={HiClock}
                  onClick={() => handleKYCChange("pending")}
                >
                  Pending approval
                </Dropdown.Item>
                <Dropdown.Item
                  icon={HiArchive}
                  onClick={() => handleKYCChange("notStarted")}
                >
                  Not initiated
                </Dropdown.Item>
              </Dropdown>
            </div>
            <div className="px-4 py-4">
              <BrokerKYCDocumentManager clientData={clientData} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
