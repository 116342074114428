import { Fragment, useState, useContext, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Switch } from "@headlessui/react";
import { BrokerContext } from "../../../../contexts/BrokerContext";
import { doc, setDoc, getFirestore, getDoc } from "firebase/firestore";
import { useNotification } from "../../../../contexts/NotificationContext";
import getSymbolFromCurrency from "currency-symbol-map";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function IBSlideOver({
  open,
  setOpen,
  tier,
  tierData,
  baseCurrency,
}) {
  const { broker } = useContext(BrokerContext);
  const brokerUID = broker?.brokerData?.uid;
  const [cpaEnabled, setCpaEnabled] = useState(false);
  const [volumeEnabled, setVolumeEnabled] = useState(false);
  const db = getFirestore();
  const [fixedCommission, setFixedCommission] = useState(0.0);
  const [percentageCPA, setPercentageCPA] = useState(0.0);
  const [maxCommissionCap, setMaxCommissionCap] = useState(0.0);
  const [fixedLotCommission, setFixedLotCommission] = useState(0.0);
  const [percentageCommission, setPercentageCommission] = useState(0.0);
  const [swapCommission, setSwapCommission] = useState(0.0);
  const [isSaving, setIsSaving] = useState(false);
  const { showNotification } = useNotification(); // from NotificationContext
  const [configuredDomain, setConfiguredDomain] = useState("");
  const tierId = tier ? tier.id : null;

  useEffect(() => {
    if (!tierData || !tier) return;

    const data = tierData[`tier${tier.id}`];

    if (data) {
      setCpaEnabled(data.cpa?.enabled);
      setFixedCommission(data.cpa?.fixedCommission);
      setPercentageCPA(data.cpa?.percentage);
      setMaxCommissionCap(data.cpa?.maxCommissionCap);
      setVolumeEnabled(data.volume?.enabled);
      setFixedLotCommission(data.volume?.fixedLotCommission);
      setPercentageCommission(data.volume?.percentageCommission);
      setSwapCommission(data.volume?.swapCommission);
    } else {
      console.log("No such document!");
    }
  }, [tierData, tier]);

  useEffect(() => {
    const fetchDomain = async () => {
      const domainDataRef = doc(db, "domains", broker.brokerData.uid);
      const docSnap = await getDoc(domainDataRef);
      if (docSnap.exists() && docSnap.data().status === "verified") {
        setConfiguredDomain(docSnap.data().domain);
      }
    };

    fetchDomain();
  }, [broker.brokerData.uid, db]);

  // Check if user object or userData is null or undefined
  if (!broker || !broker.userData) {
    return (
      <div className="fixed inset-0 flex items-center justify-center dark:bg-gray-900">
        <svg
          className="animate-spin h-10 w-10 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }

  const saveCommissionSettings = async () => {
    setIsSaving(true);
    const tierSettings = {
      currency: baseCurrency,
      cpa: {
        enabled: cpaEnabled || false,
        fixedCommission: fixedCommission || 0,
        percentage: percentageCPA || 0,
        maxCommissionCap: maxCommissionCap || 0,
      },
      volume: {
        enabled: volumeEnabled || false,
        fixedLotCommission: fixedLotCommission || 0,
        percentageCommission: percentageCommission || 0,
        swapCommission: swapCommission || 0,
      },
      url: configuredDomain
        ? `https://platform.${configuredDomain}/registration?brokerUID=${broker.brokerData.uid}`
        : `https://xohsync.com/registration?brokerUID=${broker.brokerData.uid}`,
    };

    const docRef = doc(
      db,
      "whitelabel",
      brokerUID,
      "ibCommission",
      `tier${tier.id}`
    );
    await setDoc(docRef, tierSettings, { merge: true });
    showNotification("success", "Commission settings saved successfully!");
    setIsSaving(false);
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={setOpen}>
        <div className="fixed inset-0 bg-gray-500/60 dark:bg-gray-900/60" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
                  <div className="flex h-full flex-col divide-y divide-gray-200 dark:divide-white/10 bg-white dark:bg-gray-800 shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-auto py-6">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                            Modify Commission Settings: Tier {tierId}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        <div className="mt-6">
                          <Switch.Group
                            as="div"
                            className="flex items-center justify-between"
                          >
                            <span className="flex flex-grow flex-col">
                              <Switch.Label
                                as="span"
                                className="text-sm font-medium leading-6 text-gray-900 dark:text-white"
                                passive
                              >
                                Enable CPA commission
                              </Switch.Label>
                              <Switch.Description
                                as="span"
                                className="text-sm text-gray-500 dark:text-gray-400"
                              >
                                When enabled the IB will receive a commission
                                for FTDs.
                              </Switch.Description>
                            </span>
                            <Switch
                              checked={cpaEnabled}
                              onChange={setCpaEnabled}
                              className={classNames(
                                cpaEnabled
                                  ? "bg-primary-600"
                                  : "bg-gray-200 dark:bg-white/10",
                                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2"
                              )}
                            >
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  cpaEnabled
                                    ? "translate-x-5"
                                    : "translate-x-0",
                                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                )}
                              />
                            </Switch>
                          </Switch.Group>
                          <div className="mt-4">
                            <label
                              htmlFor="price"
                              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                            >
                              Fixed commission
                            </label>
                            <div className="relative mt-2 rounded-md shadow-sm">
                              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <span className="text-gray-500 dark:text-gray-400 sm:text-sm">
                                  {getSymbolFromCurrency(baseCurrency)}
                                </span>
                              </div>
                              <input
                                type="number"
                                name="fixedCommission"
                                id="fixedCommission"
                                value={fixedCommission}
                                onChange={(e) => {
                                  setFixedCommission(e.target.value);
                                  setCpaEnabled(true);
                                }}
                                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 dark:text-white dark:bg-white/5 ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 dark:placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                placeholder="0.00"
                                aria-describedby="price-currency"
                              />
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                <span
                                  className="text-gray-500 dark:text-gray-400 sm:text-sm"
                                  id="price-currency"
                                >
                                  {baseCurrency}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="mt-4">
                            <label
                              htmlFor="price"
                              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                            >
                              Percentage CPA
                            </label>
                            <div className="relative mt-2 rounded-md shadow-sm ">
                              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <span className="text-gray-500 dark:text-gray-400 sm:text-sm">
                                  %
                                </span>
                              </div>
                              <input
                                type="number"
                                name="percentageCPA"
                                id="percentageCPA"
                                value={percentageCPA}
                                onChange={(e) => {
                                  setPercentageCPA(e.target.value);
                                  setCpaEnabled(true);
                                }}
                                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 dark:text-white dark:bg-white/5 ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 dark:placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                placeholder="0.00"
                                aria-describedby="price-currency"
                              />
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                <span
                                  className="text-gray-500 dark:text-gray-400 sm:text-sm"
                                  id="price-currency"
                                >
                                  of deposit amount
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-4">
                          <label
                            htmlFor="price"
                            className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                          >
                            Maximum commission cap
                          </label>
                          <div className="relative mt-2 rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span className="text-gray-500 dark:text-gray-400 sm:text-sm">
                                {getSymbolFromCurrency(baseCurrency)}
                              </span>
                            </div>
                            <input
                              type="number"
                              name="maxCommissionCap"
                              id="maxCommissionCap"
                              value={maxCommissionCap}
                              onChange={(e) => {
                                setMaxCommissionCap(e.target.value);
                                setCpaEnabled(true);
                              }}
                              className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 dark:text-white dark:bg-white/5 ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 dark:placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                              placeholder="0.00"
                              aria-describedby="price-currency"
                            />
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <span
                                className="text-gray-500 dark:text-gray-400 sm:text-sm"
                                id="price-currency"
                              >
                                {baseCurrency}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="border-t border-gray-200 my-6 dark:border-white/10">
                          <div className="mt-6">
                            <Switch.Group
                              as="div"
                              className="flex items-center justify-between"
                            >
                              <span className="flex flex-grow flex-col">
                                <Switch.Label
                                  as="span"
                                  className="text-sm font-medium leading-6 text-gray-900 dark:text-white"
                                  passive
                                >
                                  Enable volume commission
                                </Switch.Label>
                                <Switch.Description
                                  as="span"
                                  className="text-sm text-gray-500 dark:text-gray-400"
                                >
                                  When enabled the IB will receive a commission
                                  for sub-IBs trading volume.
                                </Switch.Description>
                              </span>
                              <Switch
                                checked={volumeEnabled}
                                onChange={setVolumeEnabled}
                                className={classNames(
                                  volumeEnabled
                                    ? "bg-primary-600"
                                    : "bg-gray-200 dark:bg-white/10",
                                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2"
                                )}
                              >
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    volumeEnabled
                                      ? "translate-x-5"
                                      : "translate-x-0",
                                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                  )}
                                />
                              </Switch>
                            </Switch.Group>
                            <div className="mt-4">
                              <label
                                htmlFor="price"
                                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                              >
                                Fixed lot commission
                              </label>
                              <div className="relative mt-2 rounded-md shadow-sm ">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                  <span className="text-gray-500 dark:text-gray-400 sm:text-sm">
                                    {getSymbolFromCurrency(baseCurrency)}
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  name="fixedLotCommission"
                                  id="fixedLotCommission"
                                  value={fixedLotCommission}
                                  onChange={(e) => {
                                    setFixedLotCommission(e.target.value);
                                    setVolumeEnabled(true);
                                  }}
                                  className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 dark:text-white dark:bg-white/5 ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 dark:placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                  placeholder="0.00"
                                  aria-describedby="price-currency"
                                />
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                  <span
                                    className="text-gray-500 dark:text-gray-400 sm:text-sm"
                                    id="price-currency"
                                  >
                                    per lot
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="mt-4">
                              <label
                                htmlFor="price"
                                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                              >
                                Percentage of commission
                              </label>
                              <div className="relative mt-2 rounded-md shadow-sm ">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                  <span className="text-gray-500 dark:text-gray-400 sm:text-sm">
                                    %
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  name="percentageCommission"
                                  id="percentageCommission"
                                  value={percentageCommission}
                                  onChange={(e) => {
                                    setPercentageCommission(e.target.value);
                                    setVolumeEnabled(true);
                                  }}
                                  className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 dark:text-white dark:bg-white/5 ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 dark:placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                  placeholder="0.00"
                                  aria-describedby="price-currency"
                                />
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                  <span
                                    className="text-gray-500 dark:text-gray-400 sm:text-sm"
                                    id="price-currency"
                                  >
                                    of total commission
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="mt-4">
                              <label
                                htmlFor="price"
                                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                              >
                                Swap commission
                              </label>
                              <div className="relative mt-2 rounded-md shadow-sm ">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                  <span className="text-gray-500 dark:text-gray-400 sm:text-sm">
                                    %
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  name="swapCommission"
                                  id="swapCommission"
                                  value={swapCommission}
                                  onChange={(e) => {
                                    setSwapCommission(e.target.value);
                                    setVolumeEnabled(true);
                                  }}
                                  className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 dark:text-white dark:bg-white/5 ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 dark:placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                  placeholder="0.00"
                                  aria-describedby="price-currency"
                                />
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                  <span
                                    className="text-gray-500 dark:text-gray-400 sm:text-sm"
                                    id="price-currency"
                                  >
                                    of total swap
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md bg-white dark:bg-white/5 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 hover:ring-gray-400 dark:hover:ring-white/20"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="ml-4 inline-flex justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
                        onClick={saveCommissionSettings}
                        disabled={isSaving}
                      >
                        {isSaving ? (
                          <>
                            <svg
                              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            Saving...
                          </>
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
