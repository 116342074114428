import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { CheckIcon } from "@heroicons/react/24/solid";
import StartMigration from "./StartMigration";
import MigrationPreview from "./MigrationPreview";
import FinishedMigration from "./FinishedMigration";
import { BrokerContext } from "../../../../contexts/BrokerContext";
import { useNotification } from "../../../../contexts/NotificationContext";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../firebase";
import { getFirestore, collection, getDocs } from "firebase/firestore";

export default function MigrationDashboard() {
  const brokerContext = useContext(BrokerContext);
  const broker = useMemo(() => brokerContext?.broker || {}, [brokerContext]);
  const [currentStep, setCurrentStep] = useState(0); // 0-based index for steps
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFileUrl, setUploadedFileUrl] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isFinishLoading, setIsFinishLoading] = useState(false);

  const firestore = getFirestore();

  useEffect(() => {
    const checkCollections = async () => {
      const brokerUID = broker.brokerData.uid;
      const tempMigrationUsersRef = collection(
        firestore,
        `temp-migration-users-${brokerUID}`
      );
      const xmanagerAccountsRef = collection(
        firestore,
        `xmanager-accounts-${brokerUID}`
      );

      const tempMigrationUsersSnapshot = await getDocs(tempMigrationUsersRef);
      const xmanagerAccountsSnapshot = await getDocs(xmanagerAccountsRef);

      if (
        !tempMigrationUsersSnapshot.empty ||
        !xmanagerAccountsSnapshot.empty
      ) {
        setCurrentStep(1);
      }
    };

    checkCollections();
  }, [broker, firestore]);

  // Set isFileUploaded to true when a file is uploaded
  const onFileUploaded = useCallback((fileUrl) => {
    setUploadedFileUrl(fileUrl);
    setIsFileUploaded(true);
  }, []);

  const steps = [
    { id: "01", name: "Import users & accounts" },
    { id: "02", name: "Preview imports" },
    { id: "03", name: "Migration finished" },
  ].map((step, index) => ({
    ...step,
    status:
      index === currentStep
        ? "current"
        : index < currentStep
        ? "complete"
        : "upcoming",
    href: "#", // You can set hrefs dynamically if needed
  }));

  const handleMigrationStart = useCallback(async () => {
    setIsLoading(true);
    try {
      showNotification("info", "Migration process started."); // Initial notification

      // Accessing the brokerUID from the BrokerContext
      const brokerUID = broker.brokerData.uid;

      if (!uploadedFileUrl) {
        throw new Error("CSV file not uploaded.");
      }

      // Defining the callable function
      const processCSV = httpsCallable(functions, "processCSV");

      // Calling the function with brokerUID and uploadedFileUrl as parameters
      const result = await processCSV({ brokerUID, csvUrl: uploadedFileUrl });

      console.log(result); // You can handle the result as needed

      // Moving to the next step after the function execution
      setCurrentStep(currentStep + 1);
      showNotification("success", "Migration step completed successfully."); // Success notification
    } catch (error) {
      console.error("Error during migration:", error);
      showNotification(
        "error",
        "An error occurred during the migration process."
      );
    } finally {
      setIsLoading(false); // Reset loading state to false when migration is done
    }
  }, [broker, uploadedFileUrl, currentStep, showNotification]); // Add other dependencies if any

  useEffect(() => {
    if (isFileUploaded && currentStep === 0) {
      handleMigrationStart();
      setIsFileUploaded(false); // Reset isFileUploaded when the migration process starts
    }
  }, [isFileUploaded, currentStep, handleMigrationStart]);

  const handleMigrationPreview = async () => {
    setIsLoading(true); // Setting loading state to true when migration starts
    try {
      // Accessing the brokerUID from the BrokerContext
      const brokerUID = broker.brokerData.uid;

      // Defining the callable function for migrateUsers with a custom timeout
      const migrateUsers = httpsCallable(functions, "migrateUsers", {
        timeout: 540000, // Setting timeout to 120 seconds (120,000 milliseconds)
      });

      // Calling the migrateUsers function with brokerUID as a parameter
      const result = await migrateUsers({ brokerUID });

      console.log(result); // You can handle the result as needed

      // Check the success status and handle accordingly
      if (result.data.success) {
        console.log(result.data.message); // Logging the success message
        setCurrentStep(currentStep + 1);
        showNotification("success", result.data.message); // Showing the success message as a notification
      } else {
        throw new Error(result.data.message || "An unknown error occurred.");
      }
    } catch (error) {
      console.error("Error during user migration:", error);
      showNotification(
        "error",
        "An error occurred during the user migration process."
      );
    } finally {
      setIsLoading(false); // Reset loading state to false when migration is done
    }
  };

  const handleRevertMigration = async () => {
    setIsLoading(true);
    try {
      const brokerUID = broker.brokerData.uid;
      const revertImport = httpsCallable(functions, "revertImport");
      await revertImport({ brokerUID });
      showNotification("success", "Migration reverted successfully.");

      setCurrentStep(0);
    } catch (error) {
      console.error("Error during reverting migration:", error);
      showNotification("error", "An error occurred while reverting migration.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFinishMigration = async () => {
    setIsFinishLoading(true);
    try {
      //  const brokerUID = broker.brokerData.uid;
      //  const sendMigrationEmails = httpsCallable(
      //    functions,
      //    "sendMigrationEmails"
      //  );
      //  await sendMigrationEmails({ brokerUID });
      showNotification("success", "Migration completed successfully.");
    } catch (error) {
      console.error("Error during sending migration emails:", error);
      showNotification(
        "error",
        "An error occurred while sending migration emails."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <nav aria-label="Progress">
      <ol className="divide-y dark:bg-white/5 divide-gray-300 dark:divide-white/10 rounded-md border border-gray-300 dark:border-white/10 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            {step.status === "complete" ? (
              <a href={step.href} className="group flex w-full items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-primary-600 group-hover:bg-primary-800">
                    <CheckIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900 dark:text-white">
                    {step.name}
                  </span>
                </span>
              </a>
            ) : step.status === "current" ? (
              <a
                href={step.href}
                className="flex items-center px-6 py-4 text-sm font-medium"
                aria-current="step"
              >
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-primary-600">
                  <span className="text-primary-600">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-primary-600">
                  {step.name}
                </span>
              </a>
            ) : (
              <a href={step.href} className="group flex items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 dark:border-white/10 group-hover:border-gray-400 dark:group-hover:border-white/5">
                    <span className="text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-200">
                      {step.id}
                    </span>
                  </span>
                  <span className="ml-4 text-sm font-medium dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-200">
                    {step.name}
                  </span>
                </span>
              </a>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div
                  className="absolute right-0 top-0 hidden h-full w-5 md:block"
                  aria-hidden="true"
                >
                  <svg
                    className="h-full w-full text-gray-300 dark:text-white/10"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
      {currentStep === 0 && (
        <StartMigration
          onStartMigration={handleMigrationStart}
          isLoading={isLoading}
          onFileUploaded={onFileUploaded}
        />
      )}
      {currentStep === 1 && (
        <MigrationPreview
          onFinalizeMigration={handleMigrationPreview}
          isLoading={isLoading}
          // You can pass additional props necessary for the MigrationPreview component
        />
      )}
      {currentStep === 2 && (
        <FinishedMigration
          onRevertMigration={handleRevertMigration}
          onFinishMigration={handleFinishMigration}
          isLoading={isLoading}
          isFinishLoading={isFinishLoading}
        />
      )}
    </nav>
  );
}
