import { useState, useContext, useEffect } from "react";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { BrokerContext } from "../../../../contexts/BrokerContext";
import { useNotification } from "../../../../contexts/NotificationContext";
import TrackingToggleButton from "./TrackingToggleButton";

export default function TrackingSettings() {
  const [metaPixelId, setMetaPixelId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { broker } = useContext(BrokerContext);
  const brokerUID = broker?.brokerData?.uid;
  const { showNotification } = useNotification(); // from NotificationContext
  const db = getFirestore();
  const [enabled, setEnabled] = useState(false);
  const [zapierConnected, setZapierConnected] = useState(false);
  const [googleTagManagerId, setGoogleTagManagerId] = useState("");
  const [isGoogleTagManagerIdLoading, setIsGoogleTagManagerIdLoading] =
    useState(false);
  const [googleTagManagerEnabled, setGoogleTagManagerEnabled] = useState(false);
  const [propellerAdsUrl, setPropellerAdsUrl] = useState("");
  const [isPropellerAdsUrlLoading, setIsPropellerAdsUrlLoading] =
    useState(false);
  const [propellerAdsEnabled, setPropellerAdsEnabled] = useState(false);

  // Fetch zapierConnected in the useEffect hook
  useEffect(() => {
    const fetchSettings = async () => {
      const docRef = doc(db, "brokers", brokerUID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setZapierConnected(docSnap.data().zapierConnected);
        // ... rest of your code
      } else {
        console.log("No such document!");
      }
    };

    fetchSettings();
  }, [db, brokerUID]);

  useEffect(() => {
    const fetchSettings = async () => {
      const docRef = doc(db, "campaigns", brokerUID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setMetaPixelId(docSnap.data().metaPixelId);
        setEnabled(docSnap.data().metaPixelEnabled);
        setGoogleTagManagerId(docSnap.data().googleTagManagerId);
        setGoogleTagManagerEnabled(docSnap.data().googleTagManagerEnabled);
        setPropellerAdsUrl(docSnap.data().propellerAdsUrl);
        setPropellerAdsEnabled(docSnap.data().propellerAdsEnabled);
      } else {
        console.log("No such document!");
      }
    };

    fetchSettings();
  }, [db, brokerUID]);

  const saveMetaPixelId = async (event) => {
    event.preventDefault();
    if (!metaPixelId) {
      showNotification("error", "Please enter a Meta Pixel ID");
      return;
    }

    setIsLoading(true);
    try {
      // Update the document in Firestore to include both metaPixelId and the enabled state
      await setDoc(doc(db, "campaigns", brokerUID), {
        metaPixelId,
        metaPixelEnabled: enabled, // Add this line to save the enabled state
      });
      showNotification(
        "success",
        "Meta Pixel ID and tracking status saved successfully"
      );
    } catch (error) {
      showNotification(
        "error",
        "An error occurred while saving the Meta Pixel ID and tracking status"
      );
    }
    setIsLoading(false);
  };

  const saveGoogleTagManagerId = async (event) => {
    event.preventDefault();
    if (!googleTagManagerId) {
      showNotification("error", "Please enter a Google Tag Manager ID");
      return;
    }

    setIsGoogleTagManagerIdLoading(true);
    try {
      // Update the document in Firestore to include the Google Tag Manager ID and its enabled state
      await setDoc(
        doc(db, "campaigns", brokerUID),
        {
          googleTagManagerId,
          googleTagManagerEnabled, // Add this line to save the enabled state
        },
        { merge: true }
      ); // Use merge: true to only update googleTagManagerId and googleTagManagerEnabled fields
      showNotification("success", "Google Tag Manager ID saved successfully");
    } catch (error) {
      showNotification(
        "error",
        "An error occurred while saving the Google Tag Manager ID"
      );
    }
    setIsGoogleTagManagerIdLoading(false);
  };

  const savePropellerAdsUrl = async (event) => {
    event.preventDefault();
    if (!propellerAdsUrl) {
      showNotification("error", "Please enter a PropellerAds S2S Postback URL");
      return;
    }

    setIsPropellerAdsUrlLoading(true);
    try {
      // Update the document in Firestore to include the PropellerAds S2S Postback URL
      await setDoc(
        doc(db, "campaigns", brokerUID),
        {
          propellerAdsUrl,
          propellerAdsEnabled, // Add this line to save the enabled state
        },
        { merge: true }
      ); // Use merge: true to only update propellerAdsUrl field
      showNotification(
        "success",
        "PropellerAds S2S Postback URL saved successfully"
      );
    } catch (error) {
      showNotification(
        "error",
        "An error occurred while saving the PropellerAds S2S Postback URL"
      );
    }
    setIsPropellerAdsUrlLoading(false);
  };

  return (
    <div className="space-y-10 divide-y divide-gray-900/10 dark:divide-white/10 px-3">
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-8 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            Zapier Configuration
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Zapier is required for the Meta Conversions API and adding the
            registered leads and clients to your CRM.
          </p>
        </div>

        <form className="bg-white dark:bg-white/5 shadow-sm ring-1 ring-gray-900/5 dark:ring-white/10 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label className="block text-sm font-medium leading-6 mb-2 text-gray-900 dark:text-white">
                  Zapier Status
                </label>
                {zapierConnected ? (
                  <span className="inline-flex items-center gap-x-1.5 rounded-full bg-primary-100 dark:bg-primary-500/10 px-2 py-1 text-xs font-medium text-primary-700 dark:text-primary-400">
                    <svg
                      className="h-1.5 w-1.5 fill-primary-500 dark:fill-primary-400 animate-ping"
                      viewBox="0 0 6 6"
                      aria-hidden="true"
                    >
                      <circle cx={3} cy={3} r={3} />
                    </svg>
                    Zapier is currently connected with this account.
                  </span>
                ) : (
                  <span className="inline-flex items-center gap-x-1.5 rounded-full bg-red-100 dark:bg-red-400/10 px-2 py-1 text-xs font-medium text-red-700 dark:text-red-400">
                    <svg
                      className="h-1.5 w-1.5 fill-red-500 dark:fill-red-400 animate-ping"
                      viewBox="0 0 6 6"
                      aria-hidden="true"
                    >
                      <circle cx={3} cy={3} r={3} />
                    </svg>
                    Zapier is currently not connected.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 dark:border-white/10 px-4 py-4 sm:px-8">
            <button
              type="button" // Change to 'button' to prevent form submission
              className="rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              onClick={() =>
                window.open(
                  "https://zapier.com/developer/public-invite/194777/247a5b25465c81ba7a8dc5d6985c3be5/",
                  "_blank"
                )
              }
            >
              Add new Zapier account
            </button>
          </div>
        </form>
      </div>
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-8 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            Meta Pixel Configuration
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            The Meta Pixel is a piece of code on your website that can help you
            better understand the effectiveness of your advertising and the
            actions people take on your site, like visiting a page or adding an
            item to their cart.
          </p>
        </div>

        <form className="bg-white dark:bg-white/5 shadow-sm ring-1 ring-gray-900/5 dark:ring-white/10 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Meta Pixel ID
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="metaPixelId"
                    id="metaPixelId"
                    autoComplete="off"
                    value={metaPixelId}
                    onChange={(e) => {
                      setMetaPixelId(e.target.value);
                      // Automatically set the toggle to true if there is text input
                      setEnabled(!!e.target.value);
                    }}
                    placeholder="Example ID: 38977124314"
                    className="block w-full rounded-md border-0 py-1.5 dark:bg-white/5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="mt-6">
                  <TrackingToggleButton
                    enabled={enabled}
                    setEnabled={setEnabled}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 dark:border-white/10 px-4 py-4 sm:px-8">
            <button
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900 dark:text-white"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              onClick={(event) => saveMetaPixelId(event)}
            >
              {isLoading ? (
                <div className="flex items-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Saving...
                </div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      </div>
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-8 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            Google Tag Manager Configuration
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Google Tag Manager is a tool that allows you to manage and deploy
            marketing tags (snippets of code or tracking pixels) on your website
            (or mobile app) without having to modify the code. This can help you
            better understand and optimize your advertising campaigns and user
            actions on your site, such as page visits or registrations.
          </p>
        </div>

        <form className="bg-white dark:bg-white/5 shadow-sm ring-1 ring-gray-900/5 dark:ring-white/10 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Google Tag Manager ID
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="googleTagManagerId"
                    id="googleTagManagerId"
                    autoComplete="off"
                    value={googleTagManagerId}
                    onChange={(e) => {
                      setGoogleTagManagerId(e.target.value);
                      // Automatically set the toggle to true if there is text input
                      setGoogleTagManagerEnabled(!!e.target.value);
                    }}
                    placeholder="Example ID: GTM-N8SWQFSS2"
                    className="block w-full rounded-md border-0 py-1.5 dark:bg-white/5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="mt-6">
                  <TrackingToggleButton
                    enabled={googleTagManagerEnabled}
                    setEnabled={setGoogleTagManagerEnabled}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 dark:border-white/10 px-4 py-4 sm:px-8">
            <button
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900 dark:text-white"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              onClick={(event) => saveGoogleTagManagerId(event)}
            >
              {isGoogleTagManagerIdLoading ? (
                <div className="flex items-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Saving...
                </div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      </div>
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-8 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            PropellerAds Conversion Tracker
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            PropellerAds S2S Postback URL tracking is a tool that allows you to
            track conversions or actions users take on your website without
            having to modify the code. This can help you better understand and
            optimize your advertising campaigns and user actions on your site,
            such as page visits or registrations.
          </p>
        </div>

        <form className="bg-white dark:bg-white/5 shadow-sm ring-1 ring-gray-900/5 dark:ring-white/10 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  PropellerAds S2S Postback URL
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="propellerAdsUrl"
                    id="propellerAdsUrl"
                    autoComplete="off"
                    value={propellerAdsUrl}
                    onChange={(e) => {
                      setPropellerAdsUrl(e.target.value);
                      // Automatically set the toggle to true if there is text input
                      setPropellerAdsEnabled(!!e.target.value);
                    }}
                    placeholder="Example URL: https://..."
                    className="block w-full rounded-md border-0 py-1.5 dark:bg-white/5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="mt-6">
                  <TrackingToggleButton
                    enabled={propellerAdsEnabled}
                    setEnabled={setPropellerAdsEnabled}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 dark:border-white/10 px-4 py-4 sm:px-8">
            <button
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900 dark:text-white"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              onClick={(event) => savePropellerAdsUrl(event)}
            >
              {isPropellerAdsUrlLoading ? (
                <div className="flex items-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Saving...
                </div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
