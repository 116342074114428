import { Fragment, useState, useContext, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import AccountSelection from "../Shared/AccountSelection";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { UserContext } from "../../contexts/UserContext";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useNotification } from "../../contexts/NotificationContext";
import { httpsCallable, getFunctions } from "firebase/functions";
import { useTranslation } from "react-i18next";

function validateStrongPassword(password) {
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password);

  return (
    password.length >= 8 &&
    hasUpperCase &&
    hasLowerCase &&
    hasNumber &&
    hasSpecialChar
  );
}

const sendEmail = async (recipientEmail, username) => {
  const functions = getFunctions();

  // Create a reference to the callable function
  const sendEmailFunction = httpsCallable(functions, "sendEmail");

  try {
    // Data to be sent to the cloud function
    const emailData = {
      emailType: "new-trading-account",
      recipientEmail: recipientEmail,
      username: username,
      password: "Your selected password during account creation",
    };

    // Calling the cloud function and passing the data
    const result = await sendEmailFunction(emailData);
    console.log("Email sent successfully:", result.data);
  } catch (error) {
    console.error("Error sending email:", error);
  }
};

export default function CreateNewXManagerAccount({ open, setOpen }) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [selectedAccountType, setSelectedAccountType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { showNotification } = useNotification();
  const functions = getFunctions();
  const createNewXManagerAccount = httpsCallable(
    functions,
    "createNewXManagerAccount"
  );
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const handleAccountChange = useCallback((accountType) => {
    console.log("Selected Account Type:", accountType);
    setSelectedAccountType(accountType);
  }, []); // No dependencies, so this function remains the same across renders

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSave = async (event) => {
    event.preventDefault();

    setIsLoading(true); // Set loading to true when starting the request

    // Validate strong password
    if (!validateStrongPassword(password)) {
      setPasswordError(
        "Password must be at least 8 characters long and include an uppercase letter, lowercase letter, number, and special character."
      );
      setIsLoading(false);
      return;
    }

    // Check if password and confirmPassword match
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      setIsLoading(false);
      return;
    }

    // Reset the confirm password error if passwords match
    setConfirmPasswordError("");

    const db = getFirestore();
    const userDocRef = doc(db, "users", user.userData.uid);

    // Fetch user document from Firestore
    const userDocSnapshot = await getDoc(userDocRef);

    if (!userDocSnapshot.exists()) {
      // Handle error - maybe show a notification to the user
      console.error("User not found in Firestore");
      return;
    }

    const fullUserDocument = userDocSnapshot.data();

    const dataToSubmit = {
      userData: {
        name: fullUserDocument.name,
        surname: fullUserDocument.surname,
        city: fullUserDocument.city,
        address: fullUserDocument.address,
        country: fullUserDocument.country,
        dob: fullUserDocument.dob,
        zip_code: fullUserDocument.zip_code,
        state: fullUserDocument.state,
        phone: fullUserDocument.phone,
        email: fullUserDocument.email,
        uid: fullUserDocument.uid,
        accountType: selectedAccountType.name,
        password: password,
        demo_id_person: fullUserDocument.demo_id_person,
        live_id_person: fullUserDocument.live_id_person,
      },
      brokerUID: fullUserDocument.brokerUID,
      type: selectedAccountType.type,
    };
    console.log("Data to Submit:", dataToSubmit);

    try {
      // Call the Firebase Cloud Function
      const result = await createNewXManagerAccount(dataToSubmit);
      console.log("Response from Cloud Function:", result.data);

      // Check if the Cloud Function returned the expected successful response
      if (result.data && result.data.status === "success") {
        showNotification("success", result.data.message);
        setOpen(false); // close the dialog after a successful operation
        sendEmail(user.userData.email, result.data.id_account);
      } else {
        // Handle error - show error message to the user
        const errorMessage =
          result.data && result.data.message
            ? result.data.message
            : "Failed to create account. Please try again.";
        showNotification("error", errorMessage);
      }
    } catch (error) {
      console.error("Error calling Cloud Function: ", error);
      showNotification("error", "Unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false); // Set loading back to false once the request is complete
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <form
                    className="flex h-full flex-col divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-900 shadow-xl dark:shadow-white/5"
                    onSubmit={handleSave}
                  >
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-primary-700 px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-white">
                            {t("createNewAccount.title")}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-primary-700 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-primary-300">
                            {t("createNewAccount.description")}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 dark:divide-gray-700 px-4 sm:px-6">
                          <div className="pb-5 pt-6">
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                            >
                              {t("createNewAccount.accountType")}
                            </label>
                            <p className="mt-1 pb-5 text-sm leading-6 text-gray-600 dark:text-gray-400">
                              {t("createNewAccount.accountTypeDescription")}
                            </p>

                            <AccountSelection
                              onAccountChange={handleAccountChange}
                            />
                          </div>
                          <div className="space-y-6 pb-6 pt-4">
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                              >
                                {t("createNewAccount.passwordTitle")}
                              </label>
                              <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
                                {t("createNewAccount.passwordDescription")}
                              </p>
                              <div className="relative mt-2 rounded-md shadow-sm">
                                <input
                                  id="password"
                                  name="password"
                                  type="password"
                                  autoComplete="current-password"
                                  required
                                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm ${
                                    passwordError && passwordError.length > 0
                                      ? "text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500"
                                      : ""
                                  } sm:text-sm sm:leading-6`}
                                  value={password}
                                  onChange={handlePasswordChange}
                                  placeholder={t(
                                    "createNewAccount.choosePassword"
                                  )}
                                />
                                {passwordError && (
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    <ExclamationCircleIcon
                                      className="h-5 w-5 text-red-500"
                                      aria-hidden="true"
                                    />
                                  </div>
                                )}
                              </div>
                              {passwordError && (
                                <p
                                  className="mt-2 text-sm text-red-600"
                                  id="password-error"
                                >
                                  {passwordError}
                                </p>
                              )}
                            </div>

                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                              >
                                {t("createNewAccount.confirmPassword")}
                              </label>
                              <div className="relative mt-2 rounded-md shadow-sm">
                                <input
                                  id="confirmPassword"
                                  name="password"
                                  type="password"
                                  autoComplete="current-password"
                                  required
                                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm ${
                                    confirmPasswordError &&
                                    confirmPasswordError.length > 0
                                      ? "text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500"
                                      : ""
                                  } sm:text-sm sm:leading-6`}
                                  value={confirmPassword}
                                  onChange={handleConfirmPasswordChange}
                                  placeholder={t(
                                    "createNewAccount.confirmPassword"
                                  )}
                                />
                                {confirmPasswordError && (
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    <ExclamationCircleIcon
                                      className="h-5 w-5 text-red-500"
                                      aria-hidden="true"
                                    />
                                  </div>
                                )}
                              </div>
                              {confirmPasswordError && (
                                <p
                                  className="mt-2 text-sm text-red-600"
                                  id="confirm-password-error"
                                >
                                  {confirmPasswordError}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-primary-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                        disabled={isLoading} // disable the button while loading
                      >
                        {isLoading ? (
                          <div className="flex items-center">
                            <svg
                              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            {t("createNewAccount.createAccountLoading")}
                          </div>
                        ) : (
                          t("createNewAccount.createAccount")
                        )}
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
