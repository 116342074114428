import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // import useNavigate
import logo from "../../../logo.svg";
import logoDark from "../../../logoDark.svg";
import useDarkMode from "../../../utils/useDarkMode";
import { useNotification } from "../../../contexts/NotificationContext.js";
import { httpsCallable, getFunctions } from "firebase/functions";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import {
  getFirestore,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";

function validateStrongPassword(password) {
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password);

  return (
    password.length >= 8 &&
    hasUpperCase &&
    hasLowerCase &&
    hasNumber &&
    hasSpecialChar
  );
}

export default function ResetPasswordPage() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const darkModeEnabled = useDarkMode();
  const { showNotification } = useNotification(); // from NotificationContext
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    password: null,
    confirmPassword: null,
  });
  const [password, setPassword] = useState("");
  const token = new URLSearchParams(window.location.search).get("token");
  const functions = getFunctions();
  const resetPasswordFunc = httpsCallable(functions, "resetPassword");
  const [logoUrls, setLogoUrls] = useState(null);
  const [logoLoading, setLogoLoading] = useState(true);

  useEffect(() => {
    // If the hostname doesn't start with "platform", exit early
    if (!window.location.hostname.startsWith("platform.")) {
      setLogoLoading(false); // Ensure loading is set to false
      return;
    }

    const fetchLogoUrls = async () => {
      try {
        setLogoLoading(true);
        const db = getFirestore();
        const domain = window.location.hostname.split(".").slice(-2).join("."); // Get the domain

        // Query the "whitelabel" collection where the domain field matches the domain
        const whitelabelQuery = query(
          collection(db, "whitelabel"),
          where("domain", "==", domain)
        );
        const whitelabelSnapshot = await getDocs(whitelabelQuery);

        // Check if any documents match the query
        if (!whitelabelSnapshot.empty) {
          // Get the first document from the query results
          const whitelabelDoc = whitelabelSnapshot.docs[0];

          // Update the logo URLs
          setLogoUrls({
            dark: whitelabelDoc.data().logoUrlDark,
            light: whitelabelDoc.data().logoUrlLight,
          });
        }
      } catch (error) {
        console.error("An error occurred while fetching logo URLs:", error);
      } finally {
        setLogoLoading(false); // Ensure this gets called even if an error occurs
      }
    };

    // Fetch the logo URLs
    fetchLogoUrls();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({ password: null, confirmPassword: null }); // Clear previous errors

    console.log(`Password to be sent: ${password}`); // Log the password

    if (!validateStrongPassword(password)) {
      setErrors({
        ...errors,
        password:
          "Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.",
      });
      return;
    }

    if (password !== confirmPassword) {
      setErrors({
        ...errors,
        confirmPassword: "Passwords do not match.",
      });
      return;
    }

    try {
      setIsLoading(true); // Start loading
      const result = await resetPasswordFunc({ token, newPassword: password });

      if (result.data.success) {
        showNotification("success", "Password changed successfully");
        navigate("/signin");
      }
    } catch (error) {
      // Display the error message from the backend
      showNotification(
        "error",
        error.message || "An unexpected error occurred. Please try again."
      );
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <>
      <div className="flex min-h-screen flex-1 flex-col justify-center py-12 px-6 sm:px-6 lg:px-8 bg-white dark:bg-gray-900">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {logoLoading ? (
            <div className="animate-pulse rounded-full bg-gray-200 dark:bg-white/10 h-10 w-10 mx-auto"></div>
          ) : (
            <img
              className="mx-auto h-10 w-auto"
              src={
                logoUrls
                  ? darkModeEnabled
                    ? logoUrls.dark
                    : logoUrls.light
                  : darkModeEnabled
                  ? logoDark
                  : logo
              }
              alt="Logo"
            />
          )}
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
            Choose a new password
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500 text-center">
            You're almost there! Create a new password below to complete the
            reset process and get back into your account.
          </p>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6">
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                New password
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  type="password"
                  name="password"
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  className={`block w-full rounded-md py-1.5 pr-10 ${
                    errors.password
                      ? "ring-1 ring-red-300 dark:ring-red-400 text-red-900 dark:text-red-400 dark:bg-red-400/10"
                      : "block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/10 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/5 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  }`} // Add conditional styling
                  placeholder="Enter your new password"
                />
                {errors.password && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {errors.password && (
                <p className="mt-2 text-sm text-red-600" id="password-error">
                  {errors.password}
                </p>
              )}

              <label
                htmlFor="confirmPassword"
                className="mt-4 block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Confirm Password
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className={`block w-full rounded-md py-1.5 pr-10 ${
                    errors.password
                      ? "ring-1 ring-red-300 dark:ring-red-400 text-red-900 dark:bg-red-400/10"
                      : "block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/10 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/5 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  }`} // Add conditional styling
                  placeholder="Confirm your new password"
                />
                {errors.password && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {errors.confirmPassword && (
                <p
                  className="mt-2 text-sm text-red-600"
                  id="confirmPassword-error"
                >
                  {errors.confirmPassword}
                </p>
              )}
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-primary-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                disabled={isLoading} // disable the button while loading
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <div className="flex items-center">
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Changing password...
                  </div>
                ) : (
                  "Change password"
                )}
              </button>
              <div className="relative mt-10">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-200 dark:border-white/10" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
