import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import countries from "i18n-iso-countries";
import english from "i18n-iso-countries/langs/en.json";
import { useNotification } from "../../contexts/NotificationContext.js";
import { httpsCallable, getFunctions } from "firebase/functions";

countries.registerLocale(english);

const functions = getFunctions();
const countryOptions = Object.entries(countries.getNames("en")).map(
  ([code, name]) => (
    <option key={code} value={name}>
      {name}
    </option>
  )
);

const getInputType = (field) => {
  switch (field) {
    case "email":
      return "email";
    case "full name":
      return "text";
    case "date of birth":
      return "date";
    default:
      return "text"; // Default to 'text' type
  }
};

export default function ClientDetailsUpdateForm({
  open,
  setOpen,
  fieldName,
  fieldValue,
  userId,
}) {
  const [inputValue, setInputValue] = useState(fieldValue || "");
  const cancelButtonRef = useRef(null);
  const inputType = getInputType(fieldName);
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const today = new Date();
  const maxDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  )
    .toISOString()
    .split("T")[0];

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);
    try {
      // Close the modal
      setOpen(false);

      // Get the Firestore reference
      const db = getFirestore();
      const docRef = doc(db, "users", userId);

      // Initialize an object to hold the updates
      const updates = {};

      switch (fieldName) {
        case "fullName": // Change this line
          const parts = inputValue.split(" ");
          const name = parts[0];
          const surname = parts.slice(1).join(" ");
          updates.name = name;
          updates.surname = surname;
          updates.fullName = inputValue;
          updates.fullNameLower = inputValue.toLowerCase();
          break;
        case "email":
          // Handle email update via cloud function
          const updateEmailFunction = httpsCallable(functions, "updateEmail");
          await updateEmailFunction({ newEmail: inputValue });
          updates["email"] = inputValue;
          break;

        case "date of birth":
          updates.dob = inputValue;
          break;

        case "street name":
          updates.address = inputValue;
          break;

        case "city":
          updates.city = inputValue;
          break;

        case "zip code":
          updates.zip_code = inputValue;
          break;

        case "country":
          updates.country = inputValue;
          break;
        default:
          // Handle the generic case
          updates[fieldName] = inputValue;
      }

      // Update the Firestore document with the updates object
      await updateDoc(docRef, updates);

      // Handle success here...
      console.log("Document successfully updated!");
      showNotification("success", "Updated information.");

      // Reload the page to reflect the changes
      window.location.reload();
    } catch (error) {
      // Handle errors here...
      console.error("Error updating document:", error);
      showNotification("error", "Error updating document!");
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 dark:bg-gray-900 dark:bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg">
                <div className="bg-white dark:bg-gray-800 px-4 pb-4 pt-5 sm:p-4 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:m-2 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
                      >
                        {`Update your ${fieldName
                          .replace(/([A-Z])/g, " $1")
                          .trim()}`}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          {`Change the ${fieldName
                            .replace(/([A-Z])/g, " $1")
                            .trim()} you want associated with your account.`}
                        </p>
                      </div>

                      <div className="mt-2">
                        {fieldName === "date of birth" ? (
                          <input
                            id="date-of-birth"
                            name="dob"
                            required
                            type="date"
                            autoComplete="bday"
                            max={maxDate}
                            value={inputValue}
                            onChange={handleChange}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                          />
                        ) : fieldName === "country" ? (
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="country"
                              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                            >
                              Country
                            </label>
                            <div className="mt-2">
                              <select
                                id="country"
                                name="country"
                                autoComplete="country-name"
                                value={inputValue}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-xs sm:text-sm sm:leading-6"
                              >
                                <option value="" disabled>
                                  Select a country
                                </option>
                                {countryOptions}
                              </select>
                            </div>
                          </div>
                        ) : (
                          <input
                            type={inputType}
                            name={fieldName}
                            id={fieldName}
                            value={inputValue}
                            onChange={handleChange}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 dark:placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 dark:bg-gray-900"
                            placeholder={fieldValue}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 dark:bg-white/5 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                    onClick={handleSaveChanges}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="flex items-center">
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Saving changes...
                      </div>
                    ) : (
                      "Save changes"
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white dark:bg-white/10 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:bg-gray-50 dark:hover:bg-white/5 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
