import { PaperClipIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import ClientDetailsUpdateForm from "../../Modals/ClientDetailsUpdateForm";
import { useNotification } from "../../../contexts/NotificationContext";
import { CheckCircleIcon, NoSymbolIcon } from "@heroicons/react/20/solid";

export default function KYCDetails() {
  const { clientId } = useParams();
  const [userData, setUserData] = useState(null);
  const [kycData, setKycData] = useState(null);
  const [isProfileUpdateOpen, setProfileUpdateOpen] = useState(false);
  const [currentFieldName, setCurrentFieldName] = useState("");
  const [currentFieldValue, setCurrentFieldValue] = useState("");
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const openProfileUpdateModalWithField = (fieldName, fieldValue) => {
    setCurrentFieldName(fieldName);
    setCurrentFieldValue(fieldValue);
    setProfileUpdateOpen(true);
  };

  const approveKYC = async () => {
    const db = getFirestore();
    const status = { kycStatus: "approved_kyc" };

    // Update the user's document in the "users" collection
    await setDoc(doc(db, "users", clientId), status, { merge: true });

    // Update the user's document in the "kyc" collection
    await setDoc(doc(db, "kyc", clientId), status, { merge: true });

    // After Firestore update
    showNotification("success", "KYC approved successfully!");

    // Navigate back to the KYC dashboard
    navigate("/broker/dashboard/kyc");
  };

  const rejectKYC = async () => {
    const db = getFirestore();
    const status = { kycStatus: null };

    // Update the user's document in the "users" collection
    await setDoc(doc(db, "users", clientId), status, { merge: true });

    // Update the user's document in the "kyc" collection
    await setDoc(doc(db, "kyc", clientId), status, { merge: true });

    // Optionally, show a notification for successful update
    showNotification("success", "KYC rejected successfully!");

    // Navigate back to the KYC dashboard
    navigate("/broker/dashboard/kyc");
  };

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();

      // Fetch user data
      const userRef = doc(db, "users", clientId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        setUserData(userDoc.data());
      }

      // Fetch kyc data
      const kycRef = doc(db, "kyc", clientId);
      const kycDoc = await getDoc(kycRef);

      if (kycDoc.exists()) {
        setKycData(kycDoc.data());
      }
    };

    fetchData();
  }, [clientId]);

  const attachmentMapping = {
    iDDocumentFront: "ID Document (Front)",
    iDDocumentBack: "ID Document (Back)",
    proofofAddress: "Proof of Address",
    "selfiewithID-document": "Selfie with ID Document",
  };

  return (
    <>
      <div className="bg-gray-50 dark:bg-gray-900">
        <div className="mx-auto">
          <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden px-4 sm:px-6 lg:px-8 pt-4">
            <div className="flex justify-between items-center px-4 sm:px-0">
              <div>
                <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
                  KYC Verification
                </h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">
                  Review and verify the provided documents to complete the
                  user's identity verification.
                </p>
              </div>
              <div>
                <button
                  type="button"
                  className="mr-2 inline-flex items-center gap-x-2 rounded-md bg-white dark:bg-white/5 px-3.5 py-2.5 text-sm font-semibold text-gray-900 dark:text-white shadow-sm hover:bg-gray-50 dark:hover:bg-white/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 dark:focus-visible:outline-gray-400"
                  onClick={rejectKYC}
                >
                  <NoSymbolIcon
                    className="-ml-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  Reject
                </button>
                <button
                  type="button"
                  className="inline-flex items-center gap-x-2 rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                  onClick={approveKYC}
                >
                  <CheckCircleIcon
                    className="-ml-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  Approve verification
                </button>
              </div>
            </div>

            <div className="mt-6 border-t border-gray-100 dark:border-white/10">
              <dl className="divide-y divide-gray-100 dark:divide-white/10">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">
                    Full name
                  </dt>
                  <dd className="mt-1 flex text-sm leading-6 text-gray-500 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">{userData?.fullName}</span>
                    <span className="ml-4 flex-shrink-0">
                      <button
                        type="button"
                        className="rounded-md bg-white dark:bg-gray-800 font-semibold text-primary-600 hover:text-primary-500"
                        onClick={() =>
                          openProfileUpdateModalWithField(
                            "fullName",
                            userData?.fullName
                          )
                        }
                      >
                        Update
                      </button>
                    </span>
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">
                    Date of birth
                  </dt>
                  <dd className="mt-1 flex text-sm leading-6 text-gray-500 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">{userData?.dob}</span>
                    <span className="ml-4 flex-shrink-0">
                      <button
                        type="button"
                        className="rounded-md bg-white dark:bg-gray-800 font-semibold text-primary-600 hover:text-primary-500"
                        onClick={() =>
                          openProfileUpdateModalWithField(
                            "date of birth",
                            userData?.dob
                          )
                        }
                      >
                        Update
                      </button>
                    </span>
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">
                    Street name
                  </dt>
                  <dd className="mt-1 flex text-sm leading-6 text-gray-500 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">{userData?.address}</span>
                    <span className="ml-4 flex-shrink-0">
                      <button
                        type="button"
                        className="rounded-md bg-white dark:bg-gray-800 font-semibold text-primary-600 hover:text-primary-500"
                        onClick={() =>
                          openProfileUpdateModalWithField(
                            "street name",
                            userData?.address
                          )
                        }
                      >
                        Update
                      </button>
                    </span>
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">
                    City
                  </dt>
                  <dd className="mt-1 flex text-sm leading-6 text-gray-500 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">{userData?.city}</span>

                    <span className="ml-4 flex-shrink-0">
                      <button
                        type="button"
                        className="rounded-md bg-white dark:bg-gray-800 font-semibold text-primary-600 hover:text-primary-500"
                        onClick={() =>
                          openProfileUpdateModalWithField(
                            "city",
                            userData?.city
                          )
                        }
                      >
                        Update
                      </button>
                    </span>
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">
                    State
                  </dt>
                  <dd className="mt-1 flex text-sm leading-6 text-gray-500 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">{userData?.state}</span>

                    <span className="ml-4 flex-shrink-0">
                      <button
                        type="button"
                        className="rounded-md bg-white dark:bg-gray-800 font-semibold text-primary-600 hover:text-primary-500"
                        onClick={() =>
                          openProfileUpdateModalWithField(
                            "state",
                            userData?.state
                          )
                        }
                      >
                        Update
                      </button>
                    </span>
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">
                    Zip code
                  </dt>
                  <dd className="mt-1 flex text-sm leading-6 text-gray-500 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">{userData?.zip_code}</span>

                    <span className="ml-4 flex-shrink-0">
                      <button
                        type="button"
                        className="rounded-md bg-white dark:bg-gray-800 font-semibold text-primary-600 hover:text-primary-500"
                        onClick={() =>
                          openProfileUpdateModalWithField(
                            "zip code",
                            userData?.zip_code
                          )
                        }
                      >
                        Update
                      </button>
                    </span>
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">
                    Country
                  </dt>
                  <dd className="mt-1 flex text-sm leading-6 text-gray-500 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">{userData?.country}</span>

                    <span className="ml-4 flex-shrink-0">
                      <button
                        type="button"
                        className="rounded-md bg-white dark:bg-gray-800 font-semibold text-primary-600 hover:text-primary-500"
                        onClick={() =>
                          openProfileUpdateModalWithField(
                            "country",
                            userData?.country
                          )
                        }
                      >
                        Update
                      </button>
                    </span>
                  </dd>
                </div>

                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">
                    Attachments
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-500 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                    <ul className="divide-y divide-gray-100 dark:divide-white/10 rounded-md border border-gray-200 dark:border-white/10">
                      {kycData &&
                        Object.keys(attachmentMapping).map(
                          (key, index) =>
                            kycData[key] && (
                              <li
                                key={key}
                                className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6"
                              >
                                <div className="flex w-0 flex-1 items-center">
                                  <PaperClipIcon
                                    className="h-5 w-5 flex-shrink-0 text-gray-400 dark:text-gray-100"
                                    aria-hidden="true"
                                  />
                                  <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                    <a
                                      href={kycData[key]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="truncate font-semibold"
                                    >
                                      {attachmentMapping[key]}
                                    </a>
                                  </div>
                                </div>
                                <div className="ml-4 flex flex-shrink-0 space-x-4">
                                  <span
                                    className="text-gray-200 dark:text-white/10"
                                    aria-hidden="true"
                                  >
                                    |
                                  </span>
                                  <a
                                    href={kycData[key]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="rounded-md bg-white dark:bg-gray-800 font-semibold text-gray-900 dark:text-white hover:text-gray-800 dark:hover:text-white/80"
                                  >
                                    View file
                                  </a>
                                </div>
                              </li>
                            )
                        )}
                    </ul>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <ClientDetailsUpdateForm
        open={isProfileUpdateOpen}
        setOpen={setProfileUpdateOpen}
        fieldName={currentFieldName}
        fieldValue={currentFieldValue}
        userId={clientId}
      />
    </>
  );
}
