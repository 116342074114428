import React, { useState, useEffect } from "react";
import { Dropdown } from "flowbite-react";
import countries from "i18n-iso-countries";
import ReactCountryFlag from "react-country-flag";
import { getCountryCallingCode } from "libphonenumber-js";

export default function PhoneInput({
  onPhoneInputChange,
  defaultPhoneNumber = "",
  defaultCountryCode = "",
}) {
  const countryList = countries.getNames("en", { select: "official" });

  // Use defaultCountryCode if provided, otherwise extract from browser's locale
  const initialCountryCode =
    defaultCountryCode ||
    (navigator.language.split("-")[1] || "US").toUpperCase();
  const initialCountryName = countries.getName(initialCountryCode, "en");
  const initialCallingCode = getCountryCallingCode(initialCountryCode);

  const [selectedCountry, setSelectedCountry] = useState({
    code: initialCountryCode,
    name: initialCountryName,
    callingCode: initialCallingCode,
  });
  const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber);

  useEffect(() => {
    // If default values are provided, don't override them with browser's locale
    if (!defaultPhoneNumber && !defaultCountryCode) {
      const browserLocale = navigator.language.split("-")[1] || "US"; // Default to "US" if not found
      const countryCode = browserLocale.toUpperCase();
      const countryName = countries.getName(countryCode, "en");
      const callingCode = getCountryCallingCode(countryCode);

      setSelectedCountry({
        code: countryCode,
        name: countryName,
        callingCode,
      });
    }
  }, [defaultPhoneNumber, defaultCountryCode]);

  const handleSelectCountry = (code, callingCode) => {
    setSelectedCountry({
      code,
      name: countries.getName(code, "en"),
      callingCode,
    });
    // Prepend "+" to the calling code
    onPhoneInputChange(`+${callingCode}${phoneNumber}`);
  };

  // Function to handle changes to the phone number input
  const handlePhoneNumberChange = (event) => {
    const newPhoneNumber = event.target.value;
    setPhoneNumber(newPhoneNumber);
    // Prepend "+" to the calling code
    onPhoneInputChange(`+${selectedCountry.callingCode}${newPhoneNumber}`);
  };

  return (
    <div className="mx-auto">
      <div className="flex items-center">
        <Dropdown
          label=""
          dismissOnClick={true}
          renderTrigger={() => (
            <button
              id="dropdown-phone-button"
              data-dropdown-toggle="dropdown-phone"
              className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-primary-200 focus:ring-4 focus:outline-none focus:ring-primary-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
              type="button"
            >
              <ReactCountryFlag
                countryCode={selectedCountry.code}
                svg
                style={{
                  width: "1em",
                  height: "1em",
                  marginRight: "10px",
                  borderRadius: "30%",
                }}
              />
              {selectedCountry.code} (+{selectedCountry.callingCode})
            </button>
          )}
        >
          <div className="max-h-60 max-w-sm overflow-auto">
            {Object.entries(countryList).map(([code, name]) => {
              let callingCode;
              try {
                callingCode = getCountryCallingCode(code);
              } catch (error) {
                callingCode = "N/A";
              }
              return (
                <Dropdown.Item
                  key={code}
                  onClick={() =>
                    handleSelectCountry(code, getCountryCallingCode(code))
                  }
                >
                  <ReactCountryFlag
                    countryCode={code}
                    svg
                    style={{
                      width: "1em",
                      height: "1em",
                      marginRight: "10px",
                      borderRadius: "30%",
                    }}
                  />
                  {name} (+{callingCode})
                </Dropdown.Item>
              );
            })}
          </div>
        </Dropdown>

        <label
          htmlFor="phone-input"
          className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
        >
          Phone number:
        </label>
        <div className="relative w-full">
          <input
            type="text"
            id="phone-input"
            className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-0 border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-primary-500"
            placeholder="123 456 7890"
            required
            value={phoneNumber} // Make the input a controlled component
            onChange={handlePhoneNumberChange} // Handle changes to the input
          />
        </div>
      </div>
    </div>
  );
}
