import { useState, useCallback, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import countries from "i18n-iso-countries";
import english from "i18n-iso-countries/langs/en.json";
import { httpsCallable, getFunctions } from "firebase/functions";
import { getCurrentUser } from "../../services/userService";
import { useNotification } from "../../contexts/NotificationContext";
import { UserContext } from "../../contexts/UserContext";
import CountryCombobox from "../Shared/CountryCombobox";
import TagManager from "react-gtm-module";
import PixelScript from "../Shared/PixelScript";
import Spinner from "../Shared/Spinner";
import OnboardingAccountSelection from "./OnboardingAccountSelection";
import {
  getFirestore,
  getDocs,
  collection,
  where,
  query,
} from "firebase/firestore";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../Shared/LanguageSelector";

import logoDark from "../../logoDark.svg";

import PhoneInput from "../Shared/PhoneInput";

countries.registerLocale(english);

const eighteenYearsAgo = new Date();
eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
const maxDate = eighteenYearsAgo.toISOString().split("T")[0];

export default function Onboarding() {
  const [formData, setFormData] = useState({}); // State to capture form data
  const navigate = useNavigate();
  const functions = getFunctions();
  const createXManagerAccount = httpsCallable(
    functions,
    "createXManagerAccount"
  );
  const [selectedAccountType, setSelectedAccountType] = useState("");
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const { fetchUserData, setUser } = useContext(UserContext);
  const [isPhoneValid, setIsPhoneValid] = useState(true); // New state to manage phone validity
  const { user } = useContext(UserContext);
  const useQueryParams = useQuery();
  const userBrokerUID = user?.userData?.brokerUID;
  const queryBrokerUID = useQueryParams.get("brokerUID");
  const queryCampaignId =
    useQueryParams.get("campaignId") || localStorage.getItem("campaignId");
  const ib = useQueryParams.get("ib") || localStorage.getItem("ib");
  const brokerUID = userBrokerUID || queryBrokerUID;
  const [countrySelected, setCountrySelected] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const pixelId = localStorage.getItem("pixelId");
  const { loading } = useContext(UserContext);
  const [shouldRenderOnboardingSelection, setShouldRenderOnboardingSelection] =
    useState(true);
  const [logoUrls, setLogoUrls] = useState(null);
  const [logoLoading, setLogoLoading] = useState(true);
  const { t } = useTranslation();

  const handleAccountChange = useCallback(
    (accountType) => {
      console.log("Selected Account Type:", accountType);
      setSelectedAccountType(accountType);
      setShouldRenderOnboardingSelection(false); // Add this line to update the state
    },
    [setShouldRenderOnboardingSelection]
  );

  useEffect(() => {
    const googleTagManagerId = localStorage.getItem("googleTagManagerId");

    if (googleTagManagerId) {
      // Initialize GTM
      TagManager.initialize({ gtmId: googleTagManagerId });

      // Log that GTM has been initialized
      console.log(
        `Google Tag Manager initialized with ID: ${googleTagManagerId}`
      );
    } else {
      console.log("Google Tag Manager ID not available");
    }
  }, []); // Empty dependency array means this effect runs once on mount

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const handleCountrySelect = (selectedCountry) => {
    setFormData({
      ...formData,
      country: selectedCountry,
    });
    setCountrySelected(true); // Set countrySelected to true when a country is selected
  };

  const handleInputChange = (e) => {
    const { name: inputName, value: inputValue } = e.target;

    // ... (other code for updating form data)
    setFormData({
      ...formData,
      [inputName]: inputValue,
    });

    // Check form validity
    const isFormNowValid = checkFormValidity({
      ...formData,
      [inputName]: inputValue,
    });
    setIsFormValid(isFormNowValid);
  };

  function checkFormValidity(formData) {
    const requiredFields = [
      "name",
      "surname",
      "city",
      "address",
      "country",
      "dob",
      "zip_code",
      "state",
      "phone",
    ];
    return requiredFields.every((field) => Boolean(formData[field]));
  }

  // Function to validate the phone number
  function validatePhoneNumber(phone) {
    const phonePattern = /^\+\d{8,15}$/; // Regex pattern to match the phone number
    return phonePattern.test(phone); // Test the phone number against the pattern
  }

  const handleOnboardingFormSubmit = async (e) => {
    e.preventDefault();

    // Fire the form submission attempt event
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "form_submission_attempt",
      });
    } else {
      console.warn("GTM has not been initialized. DataLayer is not available.");
    }

    // Check if a country has been selected
    if (!countrySelected) {
      showNotification("error", "Please select a country.");
      return;
    }

    // Validate the phone number before proceeding with form submission
    const isValidPhoneNumber = validatePhoneNumber(formData.phone);
    setIsPhoneValid(isValidPhoneNumber);

    // If the phone number is not valid, return early to prevent form submission
    if (!isValidPhoneNumber) {
      showNotification("error", "Please enter a valid phone number.");
      return;
    }

    setIsLoading(true);

    const user = getCurrentUser();
    if (!user) {
      console.error("No authenticated user found.");
      return;
    }

    const uid = user.uid;
    const email = user.email;

    // Data to send to createXManagerAccount function
    const dataToSubmit = {
      userData: {
        name: formData.name,
        surname: formData.surname,
        city: formData.city,
        address: formData.address,
        country: formData.country,
        dob: formData.dob,
        zip_code: formData.zip_code,
        state: formData.state,
        phone: formData.phone,
        email: email,
        uid: uid,
        accountType: selectedAccountType.name,
        ib: ib,
      },
      brokerUID: brokerUID,
      type: selectedAccountType.type,
    };

    if (queryCampaignId) {
      dataToSubmit.userData.campaignId = queryCampaignId;
    }

    console.log("Data to Submit:", dataToSubmit);

    try {
      // Call the Firebase Cloud Function
      const result = await createXManagerAccount(dataToSubmit);
      console.log("Response from Cloud Function:", result.data); // Access the response data

      // Check if the Cloud Function returned a successful response
      if (result.data && result.data.success) {
        // Call the sendEmail function
        sendEmail(email, result.data.accountId, result.data.password); // Removed 'await'

        // Manually fetch the latest user data from Firestore
        const updatedUserData = await fetchUserData();

        // Update the user context/state with the fetched user data
        setUser(updatedUserData);

        // Push the successful onboarding event to the dataLayer
        if (window.dataLayer) {
          window.dataLayer.push({
            event: "successful_onboarding",
            transactionId: result.data.accountId, // or user.uid
            user: {
              email: email,
              uid: uid,
              accountType: selectedAccountType.name,
            },
            email: email,
            phone: formData.phone,
            firstName: formData.name,
            lastName: formData.surname,
            street: formData.address,
            city: formData.city,
            region: formData.state,
            country: formData.country,
            postcode: formData.zip_code,
          });

          // Check if propellerAdsUrl and subId exist in local storage
          const propellerAdsUrl = localStorage.getItem("propellerAdsUrl");
          const subId = localStorage.getItem("subId");
          if (propellerAdsUrl && subId) {
            // Replace ${SUBID} in the propellerAdsUrl with the actual subId
            let url = propellerAdsUrl.replace(/\$\{SUBID\}/g, subId);
            url = url.replace("http://", "https://"); // Add this line

            // Replace ${PAYOUT} with "profit"
            url = url.replace(/\$\{PAYOUT\}/g, "profit");

            // Send a GET request to the PropellerAds postback URL
            fetch(url)
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Network response was not ok");
                }
                console.log("PropellerAds postback URL called successfully");
                console.log(url);
              })
              .catch((error) => {
                console.error(
                  "Failed to send request to PropellerAds postback URL:",
                  error
                );
              });
          }
        } else {
          console.warn(
            "GTM has not been initialized. DataLayer is not available."
          );
        }

        navigate("/dashboard", {
          state: {
            username: result.data.accountId,
            password: result.data.password,
          },
        });

        showNotification("success", "Account successfully created.");
      } else {
        // Handle error - show error message to the user
        showNotification(
          "error",
          "Failed to create account. Please try again."
        );
      }
    } catch (error) {
      console.error("Error calling Cloud Function: ", error);

      // Fire the form submission error event
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "form_submission_error",
          errorMessage: error.message,
          errorStack: error.stack,
        });
      } else {
        console.warn(
          "GTM has not been initialized. DataLayer is not available."
        );
      }
    } finally {
      setIsLoading(false); // Set loading back to false once the request is complete
    }
  };

  useEffect(() => {
    // If the hostname doesn't start with "platform", exit early
    if (!window.location.hostname.startsWith("platform.")) {
      setLogoLoading(false); // Ensure loading is set to false
      return;
    }

    const fetchLogoUrls = async () => {
      try {
        setLogoLoading(true);
        const db = getFirestore();
        const domain = window.location.hostname.split(".").slice(-2).join("."); // Get the domain

        // Query the "whitelabel" collection where the domain field matches the domain
        const whitelabelQuery = query(
          collection(db, "whitelabel"),
          where("domain", "==", domain)
        );
        const whitelabelSnapshot = await getDocs(whitelabelQuery);

        // Check if any documents match the query
        if (!whitelabelSnapshot.empty) {
          // Get the first document from the query results
          const whitelabelDoc = whitelabelSnapshot.docs[0];

          // Update the logo URLs
          setLogoUrls({
            dark: whitelabelDoc.data().logoUrlDark,
            light: whitelabelDoc.data().logoUrlLight,
          });
        }
      } catch (error) {
        console.error("An error occurred while fetching logo URLs:", error);
      } finally {
        setLogoLoading(false); // Ensure this gets called even if an error occurs
      }
    };

    // Fetch the logo URLs
    fetchLogoUrls();
  }, []);

  const sendEmail = async (recipientEmail, username, password) => {
    const functions = getFunctions();

    // Create a reference to the callable function
    const sendEmailFunction = httpsCallable(functions, "sendEmail");

    try {
      // Data to be sent to the cloud function
      const emailData = {
        emailType: "new-trading-account",
        recipientEmail: recipientEmail,
        username: username,
        password: password,
      };

      // Calling the cloud function and passing the data
      const result = await sendEmailFunction(emailData);
      console.log("Email sent successfully:", result.data);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  // If the user data is being loaded, render the Spinner component
  if (loading) {
    return <Spinner />;
  }

  const handlePhoneInputChange = (fullPhoneNumber) => {
    // Simulate an event object for handleInputChange
    const event = {
      target: {
        name: "phone",
        value: fullPhoneNumber,
      },
    };
    handleInputChange(event);
  };

  return (
    <div className="bg-gray-50 dark:bg-gray-900">
      {pixelId && <PixelScript pixelId={pixelId} />}
      {shouldRenderOnboardingSelection ? (
        <OnboardingAccountSelection onAccountChange={handleAccountChange} />
      ) : (
        <section className="py-8 bg-white dark:bg-gray-900 lg:py-0">
          <div className="lg:flex">
            <div className="hidden w-full max-w-md p-12 lg:h-screen lg:block bg-primary-600">
              <div className="flex items-center mb-8 space-x-4">
                {logoLoading ? (
                  <div className="animate-pulse rounded-full bg-gray-200 dark:bg-white/10 h-10 w-10 mx-auto"></div>
                ) : (
                  <img
                    className="h-10 w-auto"
                    src={logoUrls ? logoUrls.dark : logoDark}
                    alt="Logo"
                  />
                )}
              </div>
              <LanguageSelector />
            </div>
            <div className="flex items-center mx-auto md:w-[42rem] px-4 md:px-8 xl:px-0">
              <div className="w-full">
                <div className="flex items-center justify-center mb-8 space-x-4 lg:hidden">
                  {logoLoading ? (
                    <div className="animate-pulse rounded-full bg-gray-200 dark:bg-white/10 h-10 w-10 mx-auto"></div>
                  ) : (
                    <img
                      className="h-10 w-auto"
                      src={logoUrls ? logoUrls.dark : logoDark}
                      alt="Logo"
                    />
                  )}
                </div>
                <ol className="flex items-center mb-6 text-sm font-medium text-center text-gray-500 dark:text-gray-400 lg:mb-12 sm:text-base">
                  <li className="flex items-center text-primary-600 dark:text-primary-500 sm:after:content-[''] after:w-12 after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                    <div className="flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-gray-200 dark:after:text-gray-500">
                      <svg
                        className="w-4 h-4 mr-2 sm:mb-2 sm:w-6 sm:h-6 sm:mx-auto"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                      {t("onboarding.selection")}
                    </div>
                  </li>
                  <li className="flex items-center after:content-[''] after:w-12 after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700 mx-auto">
                    <div className="flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-gray-200 dark:after:text-gray-500">
                      <div className="mr-2 sm:mb-2 sm:mx-auto">2</div>

                      {t("onboarding.info")}
                    </div>
                  </li>
                  <li className="flex items-center sm:block">
                    <div className="mr-2 sm:mb-2 sm:mx-auto">3</div>
                    {t("onboarding.finished")}
                  </li>
                </ol>
                <h1 className="mb-4 text-2xl font-extrabold tracking-tight text-gray-900 sm:mb-6 leding-tight dark:text-white">
                  {t("onboarding.accountDetailsTitle")}
                </h1>
                <form onSubmit={handleOnboardingFormSubmit}>
                  <div className="grid gap-5 my-6 sm:grid-cols-2">
                    <div>
                      <label
                        htmlFor="full-name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {t("onboarding.firstName")}
                      </label>
                      <input
                        placeholder={t("onboarding.firstName")}
                        type="text"
                        name="name"
                        required
                        onChange={handleInputChange}
                        onInput={handleInputChange}
                        id="first-name"
                        autoComplete="given-name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="last-name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {t("onboarding.lastName")}
                      </label>
                      <input
                        placeholder={t("onboarding.lastName")}
                        type="text"
                        name="surname"
                        required
                        onChange={handleInputChange}
                        onInput={handleInputChange}
                        id="last-name"
                        autoComplete="family-name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="date-of-birth"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {t("onboarding.dateOfBirth")}
                      </label>
                      <input
                        id={t("onboarding.dateOfBirth")}
                        name="dob"
                        required
                        type="date"
                        autoComplete="bday"
                        max={maxDate}
                        onChange={handleInputChange}
                        onInput={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="country"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {t("onboarding.country")}
                      </label>
                      <CountryCombobox onCountrySelect={handleCountrySelect} />
                    </div>
                    <div>
                      <label
                        htmlFor="street-address"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {t("onboarding.streetName")}
                      </label>
                      <input
                        placeholder={t("onboarding.streetName")}
                        type="text"
                        name="address"
                        required
                        id="street-address"
                        autoComplete="street-address"
                        onChange={handleInputChange}
                        onInput={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="city"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {t("onboarding.city")}
                      </label>
                      <input
                        placeholder={t("onboarding.city")}
                        type="text"
                        name="city"
                        required
                        id="city"
                        autoComplete="address-level2"
                        onChange={handleInputChange}
                        onInput={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="region"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {t("onboarding.state")}
                      </label>
                      <input
                        placeholder={t("onboarding.state")}
                        type="text"
                        name="state"
                        id="region"
                        required
                        autoComplete="address-level1"
                        onChange={handleInputChange}
                        onInput={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="zip-input"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {t("onboarding.zipCode")}
                      </label>
                      <div className="relative">
                        <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                          <svg
                            className="w-4 h-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 16 20"
                          >
                            <path d="M8 0a7.992 7.992 0 0 0-6.583 12.535 1 1 0 0 0 .12.183l.12.146c.112.145.227.285.326.4l5.245 6.374a1 1 0 0 0 1.545-.003l5.092-6.205c.206-.222.4-.455.578-.7l.127-.155a.934.934 0 0 0 .122-.192A8.001 8.001 0 0 0 8 0Zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                          </svg>
                        </div>
                        <input
                          aria-describedby="helper-text-explanation"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder={t("onboarding.zipCode")}
                          type="text"
                          name="zip_code"
                          id="postal-code"
                          required
                          autoComplete="postal-code"
                          onChange={handleInputChange}
                          onInput={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="">
                      <label
                        htmlFor="phone-number"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {t("onboarding.phone")}
                      </label>
                      <PhoneInput onPhoneInputChange={handlePhoneInputChange} />
                      {!isPhoneValid && ( // Only render the error text if isPhoneValid is false
                        <div
                          className="flex p-4 mt-2 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                          role="alert"
                        >
                          <svg
                            className="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                          </svg>
                          <span className="sr-only">Danger</span>
                          <div>
                            <span className="font-medium">
                              {t("onboarding.phoneErrorMessage")}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex space-x-3">
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        setShouldRenderOnboardingSelection(true);
                      }}
                      className="text-center items-center w-full py-2.5 sm:py-3.5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    >
                      {t("onboarding.previousButton")}
                    </button>

                    <button
                      type="submit"
                      className={`flex w-full justify-center text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 sm:py-3.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 ${
                        !isFormValid || isLoading ? "cursor-not-allowed" : ""
                      }`}
                      disabled={isLoading} // disable the button while loading
                    >
                      {isLoading ? (
                        <div className="flex items-center">
                          <svg
                            aria-hidden="true"
                            className="w-4 h-4 -ml-1 mr-3 text-gray-200 animate-spin dark:text-gray-500 fill-primary-600 dark:fill-white"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          {t("onboarding.finishButtonLoading")}
                        </div>
                      ) : (
                        t("onboarding.finishButton")
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
