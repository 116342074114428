import { Navigate, useLocation } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { BrokerContext } from "../../contexts/BrokerContext";
import { getAuth } from "firebase/auth";
import Spinner from "../Shared/Spinner";
import { useNotification } from "../../contexts/NotificationContext";

export function ProtectedRoute({ userType, children }) {
  const { user, loading: userLoading } = useContext(UserContext);
  const { broker, loading: brokerLoading } = useContext(BrokerContext);
  const location = useLocation();
  const { showNotification } = useNotification();
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [firebaseLoading, setFirebaseLoading] = useState(true);

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setFirebaseUser(user);
      setFirebaseLoading(false);
    });
    return () => unsubscribe();
  }, [auth]);

  if (
    userLoading ||
    brokerLoading ||
    firebaseLoading ||
    !user?.userData?.isBrokerUIDFetched
  ) {
    return <Spinner />;
  }

  console.log("Current User:", user); // Logging the current user state
  console.log("Current Broker:", broker); // Logging the current broker state

  // First, handle the broker case explicitly
  if (broker || (firebaseUser && firebaseUser.displayName === "broker")) {
    if (userType === "broker") {
      if (!location.pathname.startsWith("/broker/")) {
        showNotification("error", "Unauthorized access.");
        return <Navigate to="/broker/dashboard/clients" replace />;
      }
      // If the broker has not completed onboarding, navigate to onboarding
      if (!broker?.userData?.hasCompletedOnboarding) {
        return <Navigate to={`/broker/onboarding`} replace />;
      }
      // Check if the broker has an active subscription
      if (
        broker.subscriptionData === null ||
        broker.subscriptionData.status !== "active" ||
        broker.subscriptionData.status === "not_started"
      ) {
        // If the subscription is cancelled, check if the expiry date has passed
        if (
          broker.subscriptionData &&
          broker.subscriptionData.status === "cancelled" &&
          broker.subscriptionData.expiryDate.toDate() > new Date()
        ) {
          // If the expiry date has not passed, let them proceed
        } else {
          // If not, redirect them to start a free trial
          // But first, check if they're not already on the trial page or subscription-success page to avoid a redirect loop
          if (
            !location.pathname.startsWith("/broker/dashboard/trial") &&
            !location.pathname.endsWith("/subscription-success")
          ) {
            return <Navigate to="/broker/dashboard/trial" replace />;
          }
        }
      }
      if (!broker.userData.hasCompletedOnboarding) {
        return <Navigate to={`/broker/dashboard/clients`} replace />;
      }
      return children;
    } else {
      // If a broker tries to access user routes, redirect them to broker dashboard
      return <Navigate to="/broker/dashboard/clients" replace />;
    }
  }

  // Then handle the user case
  if (userType === "user") {
    if (!user) {
      showNotification("error", "Please sign in to access this content.");
      return <Navigate to="/signin" replace />;
    }

    if (
      !user.userData.hasCompletedOnboarding &&
      !location.pathname.startsWith("/onboarding")
    ) {
      return <Navigate to="/onboarding" replace />;
    }

    if (
      user.userData.hasCompletedOnboarding &&
      location.pathname.startsWith("/onboarding")
    ) {
      return <Navigate to="/dashboard" replace />;
    }

    return children;
  }

  // If none of the above conditions are met, navigate to sign in page
  showNotification("error", "Unauthorized access. Please sign in.");
  return <Navigate to="/signin" replace />;
}
