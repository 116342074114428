import { Fragment, useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import ReactApexChart from "react-apexcharts";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useNotification } from "../../../../../contexts/NotificationContext";
import { useTranslation } from "react-i18next";

const VolumeDonut = ({ onNavigate }) => {
  const [chartOptions, setChartOptions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { showNotification } = useNotification();
  const [timePeriod, setTimePeriod] = useState("30");
  const [percentageChange, setPercentageChange] = useState(0);
  const [totalNominalLot, setTotalNominalLot] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchChartData = async () => {
      setIsLoading(true);
      const functions = getFunctions();
      const queryBigQueryIB = httpsCallable(functions, "queryBigQueryIB");

      try {
        const response = await queryBigQueryIB({
          type: "tradingVolumeInPeriod",
          timePeriod: timePeriod,
        });
        const data = response.data;
        const currentTotalVolume = data.totalVolumeCurrentPeriod;
        const previousTotalVolume = data.totalVolumePreviousPeriod;

        // Calculate percentage change
        const change =
          ((currentTotalVolume - previousTotalVolume) / previousTotalVolume) *
          100;
        setPercentageChange(isFinite(change) ? change.toFixed(2) : 0); // Check for Infinity or NaN
        setTotalNominalLot(currentTotalVolume);

        const series = data.currentPeriodData.map((item) =>
          parseFloat(item.nominalPercentage)
        );
        const labels = data.currentPeriodData.map((item) => item.instrument);
        console.log(series, labels); // Add this line to debug

        // Update chart options
        const options = getChartOptions(currentTotalVolume);
        options.series = series;
        options.labels = labels;
        setChartOptions(options);
      } catch (error) {
        console.error("Error fetching chart data:", error);
        showNotification(
          "error",
          "Unable to load chart data. Please try refreshing the page or check back later."
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchChartData();
  }, [timePeriod]);

  // Determine arrow direction and color based on percentage change
  const arrowDirection = percentageChange >= 0 ? "rotate-0" : "rotate-180";
  const textColor = percentageChange >= 0 ? "text-green-500" : "text-red-500";

  const getChartOptions = (currentTotalVolume) => {
    return {
      series: [],
      colors: ["#1C64F2", "#16BDCA", "#FDBA8C", "#E74694"],
      chart: {
        height: 291,
        width: "100%",
        type: "donut",
      },
      stroke: {
        colors: ["transparent"],
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontFamily: "Inter, sans-serif",
                offsetY: 20,
              },

              total: {
                showAlways: true,
                show: true,
                label: "Lots",
                fontFamily: "Inter, sans-serif",
                formatter: function () {
                  // Use currentTotalVolume directly
                  return `${currentTotalVolume}`;
                },
              },
              value: {
                show: true,
                fontFamily: "Inter, sans-serif",
                offsetY: -20,
                formatter: function (value) {
                  // Ensure value is a number before calling toFixed
                  if (typeof value === "number") {
                    return value.toFixed(2) + "%";
                  } else {
                    // Handle the case where value is not a number
                    console.error("Value is not a number:", value);
                    return "N/A"; // Or any other fallback value you prefer
                  }
                },
              },
            },
            size: "80%",
          },
        },
      },
      labels: [],
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "bottom",
        fontFamily: "Inter, sans-serif",
      },
    };
  };

  // Example onClick handler for navigating to reports
  const handleNavigateToReports = () => {
    onNavigate("Reports"); // Use the onNavigate function with the desired view
  };

  return (
    <div className="w-full max-w-sm rounded-lg bg-white p-4 shadow md:p-6 dark:bg-gray-800">
      <div className="flex justify-between">
        <div>
          <h5 className="pb-2 text-3xl font-bold leading-none text-gray-900 dark:text-white">
            {isLoading ? (
              <div className="h-6 w-10 animate-pulse rounded-full bg-gray-200 pb-2 dark:bg-gray-700"></div>
            ) : (
              `${totalNominalLot} lots`
            )}
          </h5>

          <p className="text-base font-normal text-gray-500 dark:text-gray-400">
            {t("ibVolumeDonut.titleDescription")} {timePeriod}{" "}
            {t("ibCommissionChart.days")}
          </p>
        </div>

        <div
          className={`flex items-center px-2.5 py-0.5 text-center text-base font-semibold ${textColor}`}
        >
          {isLoading ? (
            <div className="flex h-4 w-12 animate-pulse items-center rounded-full bg-gray-200 px-2.5 py-0.5 dark:bg-gray-700"></div>
          ) : (
            `${Math.abs(percentageChange)}%`
          )}
          <svg
            className={`ms-1 h-3 w-3 ${arrowDirection}`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13V1m0 0L1 5m4-4 4 4"
            />
          </svg>
        </div>
      </div>
      {isLoading ? (
        <div
          role="status"
          className="mt-2 max-w-sm animate-pulse rounded border border-gray-200 p-4 shadow md:p-6 dark:border-gray-700"
        >
          <div className="mt-4 flex items-baseline">
            <div className="h-56 w-full rounded-t-lg bg-gray-200 dark:bg-gray-700"></div>
            <div className="ms-6 h-56 w-full rounded-t-lg bg-gray-200 dark:bg-gray-700"></div>
            <div className="ms-6 h-52 w-full rounded-t-lg bg-gray-200 dark:bg-gray-700"></div>
            <div className="ms-6 h-48 w-full rounded-t-lg bg-gray-200 dark:bg-gray-700"></div>
            <div className="ms-6 h-56 w-full rounded-t-lg bg-gray-200 dark:bg-gray-700"></div>
            <div className="ms-6 h-52 w-full rounded-t-lg bg-gray-200 dark:bg-gray-700"></div>
            <div className="ms-6 h-56 w-full rounded-t-lg bg-gray-200 dark:bg-gray-700"></div>
          </div>
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div style={{ height: "291px" }}>
          <ReactApexChart
            options={chartOptions}
            series={chartOptions.series}
            type="donut"
            height="100%"
          />
        </div>
      )}
      <div className="grid grid-cols-1 items-center justify-between border-t border-gray-200 dark:border-gray-700">
        <div className="flex items-center justify-between pt-5">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex items-center text-center text-sm font-medium text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                {t("ibCommissionChart.last")} {timePeriod}{" "}
                {t("ibCommissionChart.days")}
                <ChevronDownIcon
                  className="-mr-1 ml-2 h-5 w-5"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-lg bg-white shadow dark:divide-gray-600 dark:bg-gray-700">
                <div className="px-1 py-1">
                  <Menu.Item>
                    <button
                      onClick={() => setTimePeriod("1")}
                      className="block w-full rounded-md px-4 py-2 text-left text-sm text-gray-500 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      {t("ibCommissionChart.today")}
                    </button>
                  </Menu.Item>
                </div>
                <div className="px-1 py-1">
                  <Menu.Item>
                    <button
                      onClick={() => setTimePeriod("7")}
                      className="block w-full rounded-md px-4 py-2 text-left text-sm text-gray-500 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      {t("ibCommissionChart.last7")}
                    </button>
                  </Menu.Item>
                  <Menu.Item>
                    <button
                      onClick={() => setTimePeriod("30")}
                      className="block w-full rounded-md px-4 py-2 text-left text-sm text-gray-500 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      {t("ibCommissionChart.last30")}
                    </button>
                  </Menu.Item>
                  <Menu.Item>
                    <button
                      onClick={() => setTimePeriod("90")}
                      className="block w-full rounded-md px-4 py-2 text-left text-sm text-gray-500 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      {t("ibCommissionChart.last90")}
                    </button>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <button
            onClick={handleNavigateToReports}
            className="inline-flex items-center rounded-lg px-3 py-2 text-sm font-semibold uppercase text-blue-600  hover:bg-gray-100 hover:text-blue-700 dark:border-gray-700 dark:hover:bg-gray-700 dark:hover:text-blue-500 dark:focus:ring-gray-700"
          >
            {t("ibVolumeDonut.buttonReport")}
            <svg
              className="ms-1.5 h-2.5 w-2.5 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1 9 4-4-4-4"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default VolumeDonut;
