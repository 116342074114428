import React, { createContext, useState, useEffect } from "react";
import { fetchBrokerData } from "../services/brokerService";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebase.js";

const BrokerContext = createContext(null);

const BrokerProvider = ({ children }) => {
  const [broker, setBroker] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("BrokerProvider useEffect triggered."); // Log when the useEffect is triggered

    const unsubscribe = onAuthStateChanged(auth, async (brokerAuth) => {
      setLoading(true);
      console.log("Auth state changed in BrokerProvider.", brokerAuth); // Log the brokerAuth object or null

      if (brokerAuth) {
        console.log("Broker authenticated. Fetching broker data."); // Log when a broker is authenticated

        try {
          const brokerData = await fetchBrokerData();
          console.log("Broker data fetched successfully:", brokerData); // Log the fetched broker data
          setBroker(brokerData);
        } catch (error) {
          console.error("Error fetching broker data:", error);
          setBroker(null);
        } finally {
          setLoading(false);
        }
      } else {
        console.log("No broker authenticated."); // Log when no broker is authenticated
        setBroker(null);
        setLoading(false);
      }
    });

    // Cleanup subscription on unmount
    return () => {
      console.log("BrokerProvider useEffect cleanup."); // Log when the useEffect is cleaning up
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (broker && broker.whiteLabelData) {
      const { favicon, displayName } = broker.whiteLabelData; // Adjust based on your actual property names

      // Setting the page title
      document.title = displayName || "XOH Sync";

      // Setting the favicon
      let link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = favicon || "/Favicon.ico";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
  }, [broker]);

  const logout = () => {
    signOut(auth)
      .then(() => {
        setBroker(null);
      })
      .catch((error) => {
        // Handle any errors
      });
  };

  return (
    <BrokerContext.Provider
      value={{ broker, fetchBrokerData, logout, loading }}
    >
      {children}
    </BrokerContext.Provider>
  );
};

export { BrokerContext, BrokerProvider };
