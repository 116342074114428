import React, { useContext, useEffect, useState } from "react";
import { BrokerContext } from "../../../contexts/BrokerContext.js";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { useNotification } from "../../../contexts/NotificationContext.js";

export default function KYCSettings() {
  const broker = useContext(BrokerContext)?.broker || {};
  const { showNotification } = useNotification();
  const [kycProviders, setKycProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("None");
  const [idenfyAPIKey, setIdenfyAPIKey] = useState("");
  const [idenfyAPISecret, setIdenfyAPISecret] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [allowDepositBeforeKYC, setAllowDepositBeforeKYC] = useState(false);
  const [allowWithdrawalBeforeKYC, setAllowWithdrawalBeforeKYC] =
    useState(false);

  const handleAllowDepositChange = (e) => {
    setAllowDepositBeforeKYC(e.target.checked);
  };

  const handleAllowWithdrawalChange = (e) => {
    setAllowWithdrawalBeforeKYC(e.target.checked);
  };

  const handleIdenfyAPIKeyChange = (e) => {
    setIdenfyAPIKey(e.target.value);
  };

  const handleIdenfyAPISecretChange = (e) => {
    setIdenfyAPISecret(e.target.value);
  };

  const handleSaveSettings = async () => {
    setIsSaving(true); // <-- Start saving

    const db = getFirestore();
    const brokerDoc = doc(db, "brokers", broker.brokerData.uid);
    const whitelabelDoc = doc(db, "whitelabel", broker.brokerData.uid); // Reference to whitelabel collection with uid
    const kycData = {
      kycCredentials: {
        provider: selectedProvider,
        idenfyAPIKey,
        idenfyAPISecret,
      },
      allowDepositBeforeKYC,
      allowWithdrawalBeforeKYC,
    };

    const whitelabelData = {
      kycProvider: selectedProvider,
      allowDepositBeforeKYC,
      allowWithdrawalBeforeKYC,
    };

    try {
      // Save to brokers collection
      await setDoc(brokerDoc, kycData, { merge: true });

      // Save provider selection and deposit/withdrawal settings to whitelabel collection
      await setDoc(whitelabelDoc, whitelabelData, { merge: true });

      showNotification("success", "Settings updated successfully!");
    } catch (error) {
      console.error("Firestore update error:", error);
      showNotification("error", "Failed to update settings. Please try again.");
    } finally {
      setIsSaving(false); // <-- Stop saving, whether success or fail
    }
  };

  useEffect(() => {
    const fetchKycProviders = async () => {
      const db = getFirestore();
      const kycProvidersDoc = doc(db, "providers", "available_kyc_providers");
      const docSnap = await getDoc(kycProvidersDoc);

      if (docSnap.exists()) {
        setKycProviders(docSnap.data().providers || []);
      }
    };

    fetchKycProviders();
  }, []);

  useEffect(() => {
    const fetchBrokerKycSettings = async () => {
      const db = getFirestore();
      const brokerDocRef = doc(db, "brokers", broker.brokerData.uid);
      const brokerDocSnap = await getDoc(brokerDocRef);

      if (brokerDocSnap.exists()) {
        const kycData = brokerDocSnap.data();
        if (kycData.kycCredentials) {
          setSelectedProvider(kycData.kycCredentials.provider || "None");
          setIdenfyAPIKey(kycData.kycCredentials.idenfyAPIKey || "");
          setIdenfyAPISecret(kycData.kycCredentials.idenfyAPISecret || "");
        }
        setAllowDepositBeforeKYC(kycData.allowDepositBeforeKYC || false);
        setAllowWithdrawalBeforeKYC(kycData.allowWithdrawalBeforeKYC || false);
      }
    };

    fetchBrokerKycSettings();
  }, [broker.brokerData.uid]);

  const handleProviderChange = (e) => {
    // <-- Step 2: Add this function
    setSelectedProvider(e.target.value);
  };

  // Check if user object or userData is null or undefined
  if (!broker || !broker.userData) {
    return (
      <div className="fixed inset-0 flex items-center justify-center dark:bg-gray-900">
        <svg
          className="animate-spin h-10 w-10 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white text-left">
          KYC configuration
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500 text-left">
          Set up your KYC provider and modify the KYC rules.
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 dark:divide-gray-700 border-t border-gray-200 dark:border-gray-700 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <fieldset>
              <div className="space-y-5">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="allowDeposit"
                      name="allowDeposit"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 dark:border-gray-700 dark:bg-gray-800 text-primary-600 focus:ring-primary-600"
                      checked={allowDepositBeforeKYC}
                      onChange={handleAllowDepositChange}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="comments"
                      className="font-medium text-gray-900 dark:text-white"
                    >
                      Allow deposit before KYC
                    </label>
                    <p id="comments-description" className="text-gray-500">
                      If enabled, users are allowed to deposit without a
                      verified KYC.
                    </p>
                  </div>
                </div>

                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="allowWithdrawal"
                      name="allowWithdrawal"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 dark:border-gray-700 dark:bg-gray-800 text-primary-600 focus:ring-primary-600"
                      onChange={handleAllowWithdrawalChange}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="comments"
                      className="font-medium text-gray-900 dark:text-white"
                    >
                      Allow withdrawal before KYC
                    </label>
                    <p id="comments-description" className="text-gray-500">
                      If enabled, users are allowed to request a withdrawal
                      without a verified KYC.
                    </p>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </dl>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 dark:divide-gray-700 border-t border-gray-200 dark:border-gray-700 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <div>
              <label
                htmlFor="provider"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Select a KYC provider from the list
              </label>
              <select
                id="provider"
                name="provider"
                onChange={handleProviderChange}
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus:ring-2 focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-gray-800"
                value={selectedProvider}
              >
                {kycProviders.map((provider, index) => (
                  <option key={index}>{provider}</option>
                ))}
              </select>
            </div>
          </div>
        </dl>

        {selectedProvider === "iDenfy" && (
          <dl className="mt-6 space-y-6 divide-y divide-gray-100 dark:divide-gray-700 border-t border-gray-200 dark:border-gray-700 text-sm leading-6">
            <div className="pt-6 sm:flex">
              <div>
                <label
                  htmlFor="idenfyAPIKey"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  iDenfy access key
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="idenfyAPIKey"
                    id="idenfyAPIKey"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-gray-800"
                    value={idenfyAPIKey} // Step 2
                    onChange={handleIdenfyAPIKeyChange}
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500" id="idenfyAPIKey">
                  API key found in the iDenfy Dashboard
                </p>
              </div>

              <div className="ml-6">
                <label
                  htmlFor="idenfyAPIKey"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  iDenfy API secret
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    name="idenfyAPIKey"
                    id="idenfyAPISecret"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-gray-800"
                    value={idenfyAPISecret} // Step 2
                    onChange={handleIdenfyAPISecretChange}
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500" id="idenfyAPIKey">
                  API secret found in the iDenfy Dashboard
                </p>
              </div>
            </div>
          </dl>
        )}

        <div className="mt-6">
          <button
            type="button"
            onClick={handleSaveSettings}
            className="rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            disabled={isSaving} // Disabling the button while saving
          >
            {isSaving ? (
              <div className="flex items-center">
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Saving settings...
              </div>
            ) : (
              "Save settings"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
