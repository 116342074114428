import React, { useContext, useState } from "react";
import { Routes, Route, useNavigate, Link } from "react-router-dom";

import SettingsBroker from "../../Settings/SettingsBroker";
import ClientsDashboard from "./ClientsDashboard";
import ClientDetails from "./ClientDetails";
import TransactionsDashboard from "./TransactionsDashboard";
import KYCDashboard from "./KYCDashboard";
import KYCDetails from "./KYCDetails";
import LogsDashboard from "./LogsDashboard";
import LogsDetails from "./LogsDetails";
import BillingSettings from "../../Settings/BrokerComponents/BillingSettings";
import SubscriptionSuccessPage from "../../Settings/BrokerComponents/SubscriptionSuccessPage";
import CampaignManagerDashboard from "./BrokerCampaignManager/CampaignManagerDashboard";
import AuthComponent from "./BrokerCampaignManager/AuthComponent";
import IBSettings from "./BrokerIBManager/IBSettings.js";
import { useLocation } from "react-router-dom";
import { BrokerContext } from "../../../contexts/BrokerContext.js";
import ActiveIBs from "../BrokerDashboard/BrokerIBManager/ActiveIBs.js";
import IBPendingPayouts from "../BrokerDashboard/BrokerIBManager/IBPendingPayouts.js";

import ProfileUpdateFormBroker from "../../Modals/ProfileUpdateFormBroker";
import ProfileSettings from "../../Settings/BrokerComponents/ProfileSettings";
import WhiteLabelSettings from "../../Settings/BrokerComponents/WhiteLabelSettings";
import AccountGroupSettings from "../../Settings/BrokerComponents/AccountGroupSettings.js";
import ConnectionSettingsXmanager from "../../Settings/BrokerComponents/ConnectionSettingsXmanager.js";
import KYCSettings from "../../Settings/BrokerComponents/KYCSettings.js";
import PSPSettings from "../../Settings/BrokerComponents/PSPSettings.js";
import DomainSettings from "../../Settings/BrokerComponents/DomainSettings.js";
import SignInSignUpSettings from "../../Settings/BrokerComponents/SignInSignUpSettings.js";
import BonusSettings from "../../Settings/BrokerComponents/BonusSettings.js";
import EmailConfiguration from "../../Settings/BrokerComponents/EmailConfiguration.js";
import UserChangePassword from "../../Settings/UserComponents/UserChangePassword.js";
import MigrationDashboard from "../../Settings/BrokerComponents/Migration/MigrationDashboard.js";
import BillingActionPanel from "../../Settings/BrokerComponents/BillingActionPanel.js";

import UserIcon from "../../Icons/UserIcon.js";
import SignOutIcon from "../../Icons/SignOutIcon.js";
import ReferralsIcon from "../../Icons/ReferralsIcon.js";
import CampaignsIcon from "../../Icons/CampaignsIcon.js";
import SearchIcon from "../../Icons/SearchIcon.js";

import useDarkMode from "../../../utils/useDarkMode";
import { Sidebar, Avatar, Badge, Button } from "flowbite-react";

import { Dropdown } from "flowbite-react";
import { useBrokerCommandPalette } from "../../../contexts/BrokerCommandPaletteProvider.js";
import SettingsCogIcon from "../../Icons/SettingsCogIcon.js";

export default function ApplicationShellBroker() {
  const { broker, logout } = useContext(BrokerContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { togglePaletteVisibility } = useBrokerCommandPalette();
  const [isProfileUpdateOpen, setProfileUpdateOpen] = useState(false);
  const [currentFieldName, setCurrentFieldName] = useState("");
  const [currentFieldValue, setCurrentFieldValue] = useState("");
  const [currentCollectionName, setCurrentCollectionName] = useState("users");
  const [showPendingKYCNotification, setShowPendingKYCNotification] =
    useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleCloseNotification = () => {
    setShowPendingKYCNotification(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSignOut = (e) => {
    if (e) e.preventDefault(); // Prevent default behavior only if event object exists
    logout(); // Clear user data
    navigate("/signin"); // Redirect to sign-in page using navigate
  };

  // Check if userData exists and has required properties, else provide defaults
  const name = `${broker?.userData?.name || "Loading"} ${
    broker?.userData?.surname || ""
  }`.trim();

  const darkModeEnabled = useDarkMode();

  // Loading check for broker.whiteLabelData
  if (!broker?.whiteLabelData) {
    return (
      <div className="flex justify-center items-center h-screen">
        {/* Spinner or any other loading indicator */}
        <div className="loader">Loading...</div>
      </div>
    );
  }

  const openProfileUpdateModalWithField = (
    fieldName,
    fieldValue,
    collectionName
  ) => {
    setCurrentFieldName(fieldName);
    setCurrentFieldValue(fieldValue);
    setProfileUpdateOpen(true);
    setCurrentCollectionName(collectionName);
  };

  const logoLight = broker.whiteLabelData?.logoUrlLight;
  const logoDark = broker.whiteLabelData?.logoUrlDark;

  return (
    <>
      <div className="antialiased bg-gray-50 dark:bg-gray-900">
        <nav className="bg-white border-b border-gray-200 px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 fixed left-0 right-0 top-0 z-40">
          <div className="flex flex-wrap justify-between items-center">
            <div className="flex justify-start items-center">
              <button
                aria-controls="drawer-navigation"
                className="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer md:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={toggleSidebar}
              >
                <svg
                  aria-hidden="true"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
                <svg
                  aria-hidden="true"
                  className="hidden w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Toggle sidebar</span>
              </button>
              <Link
                to="/broker/dashboard/clients"
                className="flex items-center justify-between mr-4"
              >
                <img
                  src={darkModeEnabled ? logoDark : logoLight}
                  className="mr-3 h-8"
                  alt="XOH Sync"
                />
              </Link>
              <div className="hidden md:block md:pl-2">
                <label htmlFor="topbar-search" className="sr-only">
                  Search
                </label>
                <div className="relative md:w-64">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      />
                    </svg>
                  </div>
                  <Button
                    color="light"
                    onClick={togglePaletteVisibility}
                    className="dark:bg-gray-800"
                    pill
                  >
                    <SearchIcon className="mr-2 h-5 w-5" />
                    {"Search"}
                    <span
                      className="text-gray-500 dark:text-gray-400"
                      style={{ marginLeft: "6px" }}
                    >
                      {"⌘K"}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex items-center lg:order-2">
              <button
                type="button"
                data-drawer-toggle="drawer-navigation"
                aria-controls="drawer-navigation"
                className="p-2 mr-1 text-gray-500 rounded-lg md:hidden hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                onClick={togglePaletteVisibility}
              >
                <span className="sr-only">Toggle search</span>
                <svg
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 21L15.0001 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>

              {/* Dropdown menu */}
              <Dropdown
                label=""
                dismissOnClick={false}
                renderTrigger={() => (
                  <button>
                    <Avatar
                      placeholderInitials={name
                        .split(" ")
                        .slice(0, 2) // Limit to the first two elements
                        .map((n) => n[0])
                        .join("")
                        .toUpperCase()}
                      rounded
                      bordered
                      size="sm"
                      status="online"
                    />
                  </button>
                )}
              >
                <Dropdown.Header>
                  <span className="block text-sm">
                    {broker?.userData?.name || "Loading"}
                  </span>
                  <span className="block truncate text-sm font-medium">
                    {broker?.userData?.email || "Loading"}
                  </span>
                </Dropdown.Header>

                <Dropdown.Item
                  icon={SignOutIcon}
                  onClick={(e) => handleSignOut(e)}
                >
                  Sign out
                </Dropdown.Item>
              </Dropdown>
            </div>
          </div>
        </nav>
        {/* Sidebar */}
        <aside
          className={`fixed top-0 left-0 z-30 w-64 h-screen pt-14 transition-transform duration-300 ease-in-out ${
            isSidebarOpen
              ? "translate-x-0"
              : "-translate-x-full md:translate-x-0"
          } bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700`}
          aria-label="Sidenav"
          id="drawer-navigation"
        >
          <div className="h-full bg-white dark:bg-gray-800">
            <Sidebar
              aria-label="Sidebar with multi-level dropdown"
              className="border-r border-gray-300 dark:border-gray-700"
            >
              <Sidebar.Items>
                <Sidebar.ItemGroup>
                  <Sidebar.Collapse icon={UserIcon} label="Users">
                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/clients"
                      className={
                        location.pathname === "/broker/dashboard/clients"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                      onClick={toggleSidebar}
                    >
                      All users
                    </Sidebar.Item>

                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/transactions"
                      className={
                        location.pathname === "/broker/dashboard/transactions"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                      onClick={toggleSidebar}
                    >
                      Transactions
                    </Sidebar.Item>
                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/kyc"
                      className={
                        location.pathname === "/broker/dashboard/kyc"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                      label={
                        broker.hasPendingKYCNotification
                          ? `Pending: ${broker.pendingKYCCount}`
                          : undefined
                      }
                      labelColor={
                        broker.hasPendingKYCNotification ? "purple" : undefined
                      }
                      onClick={toggleSidebar}
                    >
                      KYC
                    </Sidebar.Item>
                  </Sidebar.Collapse>
                  <Sidebar.Collapse
                    icon={ReferralsIcon}
                    label="Introducing Brokers"
                  >
                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/ib-pending-payouts"
                      className={
                        location.pathname ===
                        "/broker/dashboard/ib-pending-payouts"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                      onClick={toggleSidebar}
                    >
                      Payouts
                    </Sidebar.Item>
                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/active-ib"
                      className={
                        location.pathname === "/broker/dashboard/active-ib"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                      onClick={toggleSidebar}
                    >
                      Active IBs
                    </Sidebar.Item>
                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/ib-settings"
                      className={
                        location.pathname === "/broker/dashboard/ib-settings"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                      onClick={toggleSidebar}
                    >
                      IB Settings
                    </Sidebar.Item>
                  </Sidebar.Collapse>
                  <Sidebar.Collapse icon={CampaignsIcon} label="Campaigns">
                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/campaigns"
                      className={
                        location.pathname === "/broker/dashboard/campaigns"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                      onClick={toggleSidebar}
                    >
                      All campaigns
                    </Sidebar.Item>
                  </Sidebar.Collapse>
                  <Sidebar.Collapse icon={SettingsCogIcon} label="Settings">
                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/settings/profile"
                      className={
                        location.pathname ===
                        "/broker/dashboard/settings/profile"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                      onClick={toggleSidebar}
                    >
                      Profile
                    </Sidebar.Item>
                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/settings/white-label"
                      className={
                        location.pathname ===
                        "/broker/dashboard/settings/white-label"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                      onClick={toggleSidebar}
                    >
                      White-label
                    </Sidebar.Item>
                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/settings/account-groups"
                      className={
                        location.pathname ===
                        "/broker/dashboard/settings/account-groups"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                      onClick={toggleSidebar}
                    >
                      Account groups
                    </Sidebar.Item>
                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/settings/billing"
                      className={
                        location.pathname ===
                        "/broker/dashboard/settings/billing"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                      onClick={toggleSidebar}
                    >
                      Billing
                    </Sidebar.Item>
                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/settings/xmanager-connection"
                      className={
                        location.pathname ===
                        "/broker/dashboard/settings/xmanager-connection"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                      onClick={toggleSidebar}
                    >
                      xManager
                    </Sidebar.Item>
                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/settings/kyc"
                      className={
                        location.pathname === "/broker/dashboard/settings/kyc"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                      onClick={toggleSidebar}
                    >
                      KYC
                    </Sidebar.Item>
                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/settings/psp"
                      className={
                        location.pathname === "/broker/dashboard/settings/psp"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                      onClick={toggleSidebar}
                    >
                      PSP
                    </Sidebar.Item>
                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/settings/domain"
                      className={
                        location.pathname ===
                        "/broker/dashboard/settings/domain"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                      onClick={toggleSidebar}
                    >
                      Domain
                    </Sidebar.Item>
                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/settings/links"
                      className={
                        location.pathname === "/broker/dashboard/settings/links"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                      onClick={toggleSidebar}
                    >
                      Links
                    </Sidebar.Item>
                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/settings/bonus"
                      className={
                        location.pathname === "/broker/dashboard/settings/bonus"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                    >
                      Bonus
                    </Sidebar.Item>
                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/settings/email"
                      className={
                        location.pathname === "/broker/dashboard/settings/email"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                      onClick={toggleSidebar}
                    >
                      Email
                    </Sidebar.Item>
                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/settings/password"
                      className={
                        location.pathname ===
                        "/broker/dashboard/settings/password"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                      onClick={toggleSidebar}
                    >
                      Change password
                    </Sidebar.Item>
                    <Sidebar.Item
                      as={Link}
                      to="/broker/dashboard/settings/migration"
                      className={
                        location.pathname ===
                        "/broker/dashboard/settings/migration"
                          ? "bg-gray-100 dark:bg-gray-700"
                          : ""
                      }
                      onClick={toggleSidebar}
                    >
                      Migration
                    </Sidebar.Item>
                  </Sidebar.Collapse>
                  {broker.hasPendingKYCNotification &&
                    showPendingKYCNotification && (
                      <Sidebar.CTA>
                        <div className="mb-3 flex items-center">
                          <Badge color="purple" className="animate-pulse">
                            Pending KYC submissions
                          </Badge>
                          <button
                            aria-label="Close"
                            className="-m-1.5 ml-auto inline-flex h-6 w-6 rounded-lg bg-gray-100 p-1 text-cyan-900 hover:bg-gray-200 focus:ring-2 focus:ring-gray-400 dark:bg-gray-700 dark:text-gray-400 dark:hover:bg-gray-600"
                            type="button"
                            onClick={handleCloseNotification}
                          >
                            <svg
                              aria-hidden
                              className="h-4 w-4"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </div>
                        <div className="mb-3 text-sm text-cyan-900 dark:text-gray-400">
                          Pending KYC submissions require your attention in the
                          Users section.
                        </div>
                        <Link
                          className="text-sm text-cyan-900 underline hover:text-cyan-800 dark:text-gray-400 dark:hover:text-gray-300"
                          to="/broker/dashboard/kyc"
                          onClick={handleCloseNotification}
                        >
                          Go to pending KYCs
                        </Link>
                      </Sidebar.CTA>
                    )}
                  <Sidebar.ItemGroup>
                    <Sidebar.Item
                      onClick={(e) => handleSignOut(e)}
                      icon={SignOutIcon}
                      as="button"
                      className="w-full text-left"
                    >
                      Sign out
                    </Sidebar.Item>
                  </Sidebar.ItemGroup>
                </Sidebar.ItemGroup>
              </Sidebar.Items>
            </Sidebar>
          </div>
        </aside>
        <main className="p-4 md:ml-64 h-screen pt-20">
          <Routes>
            <Route path="client/:clientId" element={<ClientDetails />} />
            <Route path="kyc/:clientId" element={<KYCDetails />} />
            <Route path="logs/:docId" element={<LogsDetails />} />
            <Route path="clients" element={<ClientsDashboard />} />
            <Route path="transactions" element={<TransactionsDashboard />} />
            <Route path="settings" element={<SettingsBroker />} />
            <Route path="kyc" element={<KYCDashboard />} />
            <Route path="logs" element={<LogsDashboard />} />
            <Route path="trial" element={<BillingSettings />} />
            <Route path="campaigns" element={<CampaignManagerDashboard />} />
            <Route path="zapier-oauth" element={<AuthComponent />} />
            <Route
              path="subscription-success"
              element={<SubscriptionSuccessPage />}
            />
            <Route path="ib-settings" element={<IBSettings />} />
            <Route path="active-ib" element={<ActiveIBs />} />
            <Route path="ib-pending-payouts" element={<IBPendingPayouts />} />

            <Route
              path="/settings/profile"
              element={
                <ProfileSettings
                  openProfileUpdateModalWithField={
                    openProfileUpdateModalWithField
                  }
                />
              }
            />
            <Route
              path="/settings/white-label"
              element={<WhiteLabelSettings />}
            />
            <Route
              path="/settings/account-groups"
              element={<AccountGroupSettings />}
            />
            <Route
              path="/settings/xmanager-connection"
              element={<ConnectionSettingsXmanager />}
            />
            <Route path="/settings/kyc" element={<KYCSettings />} />
            <Route path="/settings/psp" element={<PSPSettings />} />
            <Route path="/settings/domain" element={<DomainSettings />} />
            <Route path="/settings/links" element={<SignInSignUpSettings />} />
            <Route path="/settings/bonus" element={<BonusSettings />} />
            <Route path="/settings/email" element={<EmailConfiguration />} />
            <Route path="/settings/password" element={<UserChangePassword />} />
            <Route
              path="/settings/migration"
              element={<MigrationDashboard />}
            />
            <Route path="/settings/billing" element={<BillingActionPanel />} />
            <Route
              path="/settings/migration"
              element={<MigrationDashboard />}
            />
          </Routes>
        </main>
      </div>
      {isProfileUpdateOpen && (
        <ProfileUpdateFormBroker
          open={isProfileUpdateOpen}
          setOpen={setProfileUpdateOpen}
          fieldName={currentFieldName}
          fieldValue={currentFieldValue}
          collectionName={currentCollectionName}
        />
      )}
    </>
  );
}
