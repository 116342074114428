import { useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";

const colors = [
  { id: 1, name: "Red", class: "bg-red-500" },
  { id: 2, name: "Orange", class: "bg-orange-500" },
  { id: 3, name: "Amber", class: "bg-amber-500" },
  { id: 4, name: "Yellow", class: "bg-yellow-500" },
  { id: 5, name: "Lime", class: "bg-lime-500" },
  { id: 6, name: "Green", class: "bg-green-500" },
  { id: 7, name: "Emerald", class: "bg-emerald-500" },
  { id: 8, name: "Teal", class: "bg-teal-500" },
  { id: 9, name: "Cyan", class: "bg-cyan-500" },
  { id: 10, name: "Sky", class: "bg-sky-500" },
  { id: 11, name: "Blue", class: "bg-blue-500" },
  { id: 12, name: "Indigo", class: "bg-indigo-500" },
  { id: 13, name: "Violet", class: "bg-violet-500" },
  { id: 14, name: "Purple", class: "bg-purple-500" },
  { id: 15, name: "Pink", class: "bg-pink-500" },
  { id: 16, name: "Rose", class: "bg-rose-500" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ColorPicker({ selectedColor, onColorChange }) {
  const [query, setQuery] = useState("");
  const initialSelectedColor =
    colors.find((color) => color.name === selectedColor) || null;

  const handleColorSelection = (selected) => {
    onColorChange(selected.name);
  };

  const filteredColors =
    query === ""
      ? colors
      : colors.filter((color) => {
          return color.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox
      as="div"
      value={initialSelectedColor}
      onChange={handleColorSelection}
    >
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">
        Choose an accent color
      </Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white dark:bg-gray-800 py-1.5 pl-3 pr-12 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(color) => color?.name}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredColors.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-gray-800 py-1 text-base shadow-lg ring-1 ring-black dark:ring-gray-700 ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredColors.map((color) => (
              <Combobox.Option
                key={color.id}
                value={color}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active
                      ? "bg-primary-600 text-white"
                      : "text-gray-900 dark:text-gray-400"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      <span
                        className={classNames(
                          "inline-block h-5 w-5 rounded-full mr-3",
                          color.class
                        )}
                      />
                      <span className={selected ? "font-semibold" : undefined}>
                        {color.name}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-primary-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}

export default ColorPicker;
