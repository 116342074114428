import {
  useLayoutEffect,
  useRef,
  useState,
  useContext,
  useEffect,
} from "react";
import { BrokerContext } from "../../../contexts/BrokerContext.js";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { useNotification } from "../../../contexts/NotificationContext.js";
import GroupNameUpdateModal from "../../Modals/GroupNameUpdateModal.js";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useCallback } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function GroupSelection({ setGroup }) {
  const broker = useContext(BrokerContext)?.broker || {};
  const uid = broker?.userData?.uid;
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [enabledGroups] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const { showNotification } = useNotification();
  const [isGroupNameModalOpen, setGroupNameModalOpen] = useState(false);
  const [groupBeingEdited, setGroupBeingEdited] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const fetchAccountGroupInfo = useCallback(async () => {
    setIsFetching(true);
    try {
      const functions = getFunctions();
      const getAccountGroupInfoFunction = httpsCallable(
        functions,
        "getAccountGroupInfo"
      );
      const getAccountGroupInfoDemoFunction = httpsCallable(
        functions,
        "getAccountGroupInfoDemo"
      );

      await getAccountGroupInfoFunction({ uid });
      await getAccountGroupInfoDemoFunction({ uid });

      // If both functions succeed, show a success notification
      showNotification("success", "Account group info fetched successfully!");

      // Reload the page
      window.location.reload();
    } catch (error) {
      console.error("Error fetching account group info:", error);
      // If either function fails, show an error notification
      showNotification("error", "Failed to fetch account group info.");
    } finally {
      setIsFetching(false);
    }
  }, [showNotification, uid]);

  const groupsWithoutIdGroup = groups.filter(
    (group) => !group.hasOwnProperty("id_group")
  );
  if (groupsWithoutIdGroup.length) {
    console.error("Groups without id_group:", groupsWithoutIdGroup);
  }

  const idGroupCounts = groups.reduce((acc, group) => {
    acc[group.id_group] = (acc[group.id_group] || 0) + 1;
    return acc;
  }, {});

  const duplicateIdGroups = Object.entries(idGroupCounts).filter(
    ([id, count]) => count > 1
  );
  if (duplicateIdGroups.length) {
    console.error("Duplicate id_group values detected:", duplicateIdGroups);
  }

  const db = getFirestore(); // Ensure you have initialized Firebase somewhere in your app

  useEffect(() => {
    async function fetchData() {
      if (uid) {
        // Fetch xmanager_groups data
        const groupRef = doc(db, "xmanager_groups", uid);
        const docSnap = await getDoc(groupRef);

        if (docSnap.exists() && docSnap.id === uid) {
          const data = docSnap.data();
          const demoGroups = (data.accountGroupsDemo?.account_groups || []).map(
            (group) => ({ ...group, type: "Demo" })
          );
          const liveGroups = (data.accountGroupsLive?.account_groups || []).map(
            (group) => ({ ...group, type: "Live" })
          );

          // Fetch display names from whitelabel collection
          const whitelabelRef = doc(db, "whitelabel", uid);
          const whitelabelSnap = await getDoc(whitelabelRef);

          if (whitelabelSnap.exists() && whitelabelSnap.id === uid) {
            const whiteLabelGroups = whitelabelSnap.data().groups || [];
            const displayNameMap = whiteLabelGroups.reduce((acc, group) => {
              acc[group.id_group] = group.displayName;
              return acc;
            }, {});

            const allGroups = [...demoGroups, ...liveGroups].map((group) => ({
              ...group,
              displayName: displayNameMap.hasOwnProperty(group.id_group)
                ? displayNameMap[group.id_group]
                : "",
            }));

            // Filter out the groups that are enabled (i.e., exist in broker.whiteLabelData.groups)
            const enabledGroupNames =
              broker.whiteLabelData?.groups?.map((group) => group.name) || [];
            const enabledGroups = allGroups.filter((group) =>
              enabledGroupNames.includes(group.name)
            );

            setGroups(allGroups);
            setSelectedGroups(enabledGroups);
          } else {
            console.log("No such document in xmanager_groups collection!");
            if (groups.length === 0) {
              fetchAccountGroupInfo();
            }
          }
        } else {
          console.log("No such document in xmanager_groups collection!");
        }
      }
    }
    fetchData();
  }, [uid, broker.whiteLabelData, db, fetchAccountGroupInfo, groups.length]);

  async function saveSelection(action) {
    setIsSaving(true); // start saving
    try {
      let updatedGroups;
      if (action === "enable") {
        updatedGroups = [...new Set([...selectedGroups, ...enabledGroups])];
      } else if (action === "disable") {
        updatedGroups = enabledGroups.filter(
          (group) => !selectedGroups.includes(group)
        );
      } else {
        updatedGroups = selectedGroups; // default behavior
      }

      // Save the updated groups to the whitelabel collection
      const whitelabelRef = doc(db, "whitelabel", uid);
      await setDoc(whitelabelRef, { groups: updatedGroups }, { merge: true });

      showNotification("success", "Groups updated successfully!"); // <-- Show success notification
      setSelectedGroups(updatedGroups);
    } catch (error) {
      console.error("Entered catch block");
      console.error("Error updating groups:", error);
      showNotification("error", "Failed to update groups.");
    } finally {
      setIsSaving(false); // end saving
    }
  }

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedGroups.length > 0 && selectedGroups.length < groups.length;
    setChecked(selectedGroups.length === groups.length);
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedGroups, groups.length]);

  function toggleAll() {
    setSelectedGroups(checked || indeterminate ? [] : groups);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  function updateDisplayNameForGroup(updatedGroupId, newDisplayName) {
    setGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.id_group === updatedGroupId
          ? { ...group, displayName: newDisplayName }
          : group
      )
    );
  }

  return (
    <div className="min-w-full">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
            Account groups
          </h1>
          <p className="mt-2 text-sm text-gray-700 dark:text-gray-400">
            Enable the account groups and edit their display names for account
            creation in the sign up process and for existing users.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <span className="isolate inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              onClick={fetchAccountGroupInfo}
            >
              {isFetching ? (
                <div className="flex items-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Fetching...
                </div>
              ) : (
                "Fetch groups"
              )}
            </button>

            <button
              type="button"
              onClick={saveSelection}
              className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            >
              {isSaving ? (
                <div className="flex items-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Saving...
                </div>
              ) : (
                "Save selection"
              )}
            </button>
          </span>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="relative">
              {selectedGroups.length > 0 && (
                <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white dark:bg-gray-900 sm:left-12">
                  <button
                    type="button"
                    onClick={() => saveSelection("enable")}
                    className="inline-flex items-center rounded bg-white dark:bg-gray-900 px-2 py-1 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white dark:disabled:hover:bg-gray-600"
                  >
                    Enable groups
                  </button>
                  <button
                    type="button"
                    onClick={() => saveSelection("disable")}
                    className="inline-flex items-center rounded bg-white dark:bg-gray-900 px-2 py-1 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white dark:disabled:hover:bg-gray-600"
                  >
                    Disable groups
                  </button>
                </div>
              )}
              <table className="min-w-full table-fixed divide-y divide-gray-300 dark:divide-gray-700">
                <thead>
                  <tr>
                    <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 dark:border-gray-700 text-primary-600 focus:ring-primary-600 dark:bg-gray-700"
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th
                      scope="col"
                      className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white"
                    >
                      Group name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                    >
                      Currency
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                    >
                      Live/Demo
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-900">
                  {groups.map((group) => (
                    <tr
                      key={group.id_group}
                      className={
                        selectedGroups.includes(group)
                          ? "bg-gray-50 dark:bg-gray-800"
                          : undefined
                      }
                    >
                      <td className="relative px-7 sm:w-12 sm:px-6">
                        {selectedGroups.includes(group) && (
                          <div className="absolute inset-y-0 left-0 w-0.5 bg-primary-600" />
                        )}
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 dark:border-gray-700 text-primary-600 focus:ring-primary-600 dark:bg-gray-700"
                          value={group.email}
                          checked={selectedGroups.includes(group)}
                          onChange={(e) =>
                            setSelectedGroups(
                              e.target.checked
                                ? [...selectedGroups, group]
                                : selectedGroups.filter((p) => p !== group)
                            )
                          }
                        />
                      </td>
                      <td
                        className={classNames(
                          "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                          selectedGroups.includes(group)
                            ? "text-primary-600"
                            : "text-gray-900 dark:text-gray-200"
                        )}
                      >
                        {group.name}{" "}
                        {group.displayName ? `(${group.displayName})` : ""}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">
                        {group.currency}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">
                        {group.type}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">
                        {selectedGroups.some(
                          (selectedGroup) =>
                            selectedGroup.id_group === group.id_group
                        )
                          ? "Enabled"
                          : "Disabled"}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                        <button
                          className="text-primary-600 hover:text-primary-900 bg-transparent border-none p-0"
                          onClick={() => {
                            setGroupBeingEdited({
                              ...group,
                              nameToEdit: group.displayName || group.name,
                            });
                            setGroupNameModalOpen(true);
                          }}
                        >
                          Edit display name
                          <span className="sr-only">, {group.name}</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {isGroupNameModalOpen && (
                <GroupNameUpdateModal
                  groupId={groupBeingEdited.id_group}
                  groupName={groupBeingEdited.nameToEdit}
                  open={isGroupNameModalOpen}
                  setOpen={setGroupNameModalOpen}
                  onDisplayNameUpdate={updateDisplayNameForGroup}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
