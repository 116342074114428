import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { BrokerContext } from "../../../../contexts/BrokerContext";
import { useNotification } from "../../../../contexts/NotificationContext";
import Spinner from "../../../Shared/Spinner";

const AuthComponent = () => {
  const { broker } = useContext(BrokerContext);
  const brokerUID = broker?.brokerData?.uid;
  const [user, setUser] = useState(null);
  const [isAuthorizing, setIsAuthorizing] = useState(false); // Add this state
  const auth = getAuth();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const stateFromZapier = queryParams.get("state");
  const codeFromZapier = queryParams.get("code"); // Add this to handle the redirect from Zapier

  const callOauthAuthorize = useCallback(
    async (userId) => {
      console.log("Calling OAuth Authorize function for user:", userId);

      const redirectUri =
        queryParams.get("redirect_uri") ||
        "https://zapier.com/dashboard/auth/oauth/return/App194777CLIAPI"; // Remove trailing slash here
      const clientId = queryParams.get("client_id"); // Extract client_id if needed
      const state = stateFromZapier;

      console.log(`Redirect URI: ${redirectUri}, Client ID: ${clientId}`);

      const idToken = await auth.currentUser.getIdToken();
      const oauthAuthorizeUrl = `https://us-central1-xohsync.cloudfunctions.net/oauthAuthorize?userId=${userId}&idToken=${idToken}&redirect_uri=${encodeURIComponent(
        redirectUri
      )}&state=${state}&client_id=${clientId}`;
      window.location.href = oauthAuthorizeUrl;
      console.log("Redirecting to:", oauthAuthorizeUrl);

      showNotification("success", "Redirecting to authenticate with Zapier...");
    },
    [showNotification, auth.currentUser, stateFromZapier, queryParams] // Add stateFromZapier to dependency array
  );

  useEffect(() => {
    const initiateOAuthProcess = async (loggedInUser) => {
      if (brokerUID === loggedInUser.uid && !codeFromZapier && !isAuthorizing) {
        console.log(
          "User is signed in and is a broker. Starting OAuth process."
        );
        setIsAuthorizing(true);
        await callOauthAuthorize(loggedInUser.uid);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (loggedInUser) => {
      if (loggedInUser) {
        console.log("Auth state changed. Logged in user:", loggedInUser);
        setUser(loggedInUser);
        initiateOAuthProcess(loggedInUser);
      } else {
        console.log("User is not signed in.");
        showNotification("warning", "Please sign in to continue.");
        navigate("/signin");
      }
    });

    return () => {
      unsubscribe();
      // Only reset isAuthorizing if the user is logged out or the OAuth process is completed
      // This prevents resetting the flag unnecessarily
    };
  }, [
    auth,
    brokerUID,
    navigate,
    showNotification,
    callOauthAuthorize,
    codeFromZapier,
    isAuthorizing,
  ]);

  return (
    <div>
      <Spinner />
    </div>
  );
};

export default AuthComponent;
