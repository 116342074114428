import { useState } from "react";
import UsersReportIB from "./Reports/UsersReportIB";
import CommissionReportIB from "./Reports/CommissionReportIB";
import VolumeReportIB from "./Reports/VolumeReportIB";
import { useTranslation } from "react-i18next";

const ClientIBReports = () => {
  const [currentView, setCurrentView] = useState("usersReport");
  const { t } = useTranslation();

  return (
    <div className="md:flex mt-4">
      <ul className="flex-column space-y space-y-4 text-sm font-medium text-gray-500 dark:text-gray-400 md:me-4 mb-4 md:mb-0">
        <li>
          <button
            className={`inline-flex items-center px-4 py-3 rounded-lg ${
              currentView === "usersReport"
                ? "text-white bg-primary-700 active w-full dark:bg-primary-600"
                : "bg-gray-50 dark:bg-gray-800 hover:text-gray-900 hover:bg-gray-100 w-full dark:hover:bg-gray-700 dark:hover:text-white"
            }`}
            onClick={() => setCurrentView("usersReport")}
            aria-current={currentView === "usersReport" ? "page" : undefined}
          >
            <svg
              className={`w-4 h-4 me-2 ${
                currentView === "usersReport"
                  ? "text-white"
                  : "text-gray-500 dark:text-gray-400"
              }`}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.1064 15.388C17.3543 14.8945 17.9553 14.6954 18.4489 14.9433C20.0833 15.7644 21.4649 17.0715 22.4648 18.6822C22.6163 18.9262 22.8368 19.2521 22.9052 19.6937C22.9785 20.1665 22.8502 20.6186 22.659 20.9624C22.4678 21.3061 22.1513 21.6536 21.711 21.8407C21.2923 22.0186 20.8369 22 20.5 22C19.9477 22 19.5 21.5523 19.5 21C19.5 20.4477 19.9477 20 20.5 20C20.7152 20 20.8283 19.9994 20.9091 19.9939L20.9112 19.9902C20.9303 19.9558 20.785 19.7683 20.7656 19.737C19.9433 18.4124 18.8284 17.3722 17.5511 16.7305C17.0576 16.4826 16.8585 15.8815 17.1064 15.388Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.1045 3.02273C15.3502 2.52815 15.9504 2.32646 16.445 2.57225C18.2536 3.47103 19.5 5.33904 19.5 7.5C19.5 9.66096 18.2536 11.529 16.445 12.4278C15.9504 12.6735 15.3502 12.4719 15.1045 11.9773C14.8587 11.4827 15.0604 10.8825 15.5549 10.6367C16.7098 10.0628 17.5 8.87276 17.5 7.5C17.5 6.12724 16.7098 4.9372 15.5549 4.36327C15.0604 4.11749 14.8587 3.51731 15.1045 3.02273Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.99997 7.5C3.99997 4.46243 6.4624 2 9.49997 2C12.5375 2 15 4.46243 15 7.5C15 10.5376 12.5375 13 9.49997 13C6.4624 13 3.99997 10.5376 3.99997 7.5Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.49997 14C12.6962 14 15.5535 15.8041 17.2971 18.4201C17.4507 18.6506 17.6145 18.8964 17.729 19.13C17.8669 19.4111 17.9659 19.7341 17.9427 20.1198C17.9243 20.4275 17.8222 20.7129 17.7009 20.9395C17.5795 21.1661 17.3986 21.4093 17.1527 21.5952C16.8234 21.8442 16.4685 21.9315 16.1548 21.968C15.8786 22.0001 15.5514 22.0001 15.2219 22C11.4088 21.9993 7.59266 21.9993 3.77804 22C3.4485 22.0001 3.12135 22.0001 2.84511 21.968C2.53141 21.9315 2.17652 21.8442 1.84721 21.5952C1.60138 21.4093 1.4204 21.1661 1.29906 20.9395C1.17772 20.7129 1.07567 20.4275 1.0572 20.1198C1.03405 19.7341 1.13305 19.4111 1.27089 19.13C1.38542 18.8964 1.54923 18.6506 1.70284 18.4201C3.44643 15.8041 6.30373 14 9.49997 14Z"
                fill="currentColor"
              />
            </svg>
            {t("ibBalanceTabs.users")}
          </button>
        </li>
        <li>
          <button
            className={`inline-flex items-center px-4 py-3 rounded-lg ${
              currentView === "commissionReport"
                ? "text-white bg-primary-700 active w-full dark:bg-primary-600"
                : "bg-gray-50 dark:bg-gray-800 hover:text-gray-900 hover:bg-gray-100 w-full dark:hover:bg-gray-700 dark:hover:text-white"
            }`}
            onClick={() => setCurrentView("commissionReport")}
            aria-current={
              currentView === "commissionReport" ? "page" : undefined
            }
          >
            <svg
              className={`w-4 h-4 me-2 ${
                currentView === "commissionReport"
                  ? "text-white"
                  : "text-gray-500 dark:text-gray-400"
              }`}
              aria-hidden="true"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.7979 21.1334C11.3081 21.0089 11.7988 20.8486 12.2356 20.6475C13.3323 22.0778 15.0584 23 17 23C20.3137 23 23 20.3137 23 17C23 13.6863 20.3137 11 17 11C15.9071 11 14.8825 11.2922 14 11.8027V10.6974C14 10.5884 13.9116 10.5 13.8026 10.5C13.7356 10.5 13.6737 10.5343 13.6347 10.5887C13.5109 10.761 13.3666 10.9105 13.2197 11.0368C12.8167 11.3832 12.2921 11.6499 11.7308 11.854C10.6 12.2652 9.10403 12.5 7.5 12.5C5.89597 12.5 4.4 12.2652 3.26917 11.854C2.70787 11.6499 2.18326 11.3832 1.78029 11.0368C1.63341 10.9105 1.48911 10.761 1.36533 10.5887C1.32625 10.5343 1.26436 10.5 1.1974 10.5C1.08838 10.5 1 10.5884 1 10.6974V13C1 13.6772 1.38057 14.1931 1.78029 14.5368C2.18326 14.8832 2.70787 15.1499 3.26917 15.354C4.4 15.7652 5.89597 16 7.5 16C9.10403 16 10.6 15.7652 11.7308 15.354C12.2921 15.1499 12.8167 14.8832 13.2197 14.5368C13.3666 14.4105 13.5109 14.261 13.6347 14.0887C13.6737 14.0343 13.7356 14 13.8026 14C13.9116 14 14 14.0884 14 14.1974V15C14 15.6772 13.6194 16.1931 13.2197 16.5368C12.8167 16.8832 12.2921 17.1499 11.7308 17.354C10.6 17.7652 9.10403 18 7.5 18C5.89597 18 4.4 17.7652 3.26917 17.354C2.70787 17.1499 2.18326 16.8832 1.78029 16.5368C1.62895 16.4067 1.47826 16.2416 1.36042 16.0839C1.32174 16.0322 1.26168 16 1.19706 16C1.08761 16 0.999483 16.0898 1.00092 16.1992C1.01099 16.9659 1 17.7334 1 18.5C1 19.1772 1.38057 19.6931 1.78029 20.0368C2.18326 20.3832 2.70787 20.6499 3.26917 20.854C4.4 21.2652 5.89597 21.5 7.5 21.5C8.70215 21.5 9.8356 21.3682 10.7979 21.1334Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 7.5V5C1 4.32275 1.38057 3.80688 1.78029 3.46322C2.15808 3.13843 2.64277 2.88375 3.16438 2.685C3.19915 2.67175 3.23409 2.65875 3.26917 2.64599C4.4 2.23478 5.89597 2 7.5 2C9.10403 2 10.6 2.23478 11.7308 2.64599C12.2921 2.8501 12.8167 3.11678 13.2197 3.46322C13.6194 3.80688 14 4.32275 14 5V7.5C14 8.17725 13.6194 8.69312 13.2197 9.03678C12.8167 9.38322 12.2921 9.6499 11.7308 9.85401C10.6 10.2652 9.10403 10.5 7.5 10.5C5.89597 10.5 4.4 10.2652 3.26917 9.85401C2.70787 9.6499 2.18326 9.38322 1.78029 9.03678C1.38057 8.69312 1 8.17725 1 7.5Z"
                fill="currentColor"
              />
            </svg>
            {t("ibBalanceTabs.commission")}
          </button>
        </li>
        <li>
          <button
            className={`inline-flex items-center px-4 py-3 rounded-lg ${
              currentView === "volumeReport"
                ? "text-white bg-primary-700 active w-full dark:bg-primary-600"
                : "bg-gray-50 dark:bg-gray-800 hover:text-gray-900 hover:bg-gray-100 w-full dark:hover:bg-gray-700 dark:hover:text-white"
            }`}
            onClick={() => setCurrentView("volumeReport")}
            aria-current="page"
          >
            <svg
              className={`w-4 h-4 me-2 ${
                currentView === "volumeReport"
                  ? "text-white"
                  : "text-gray-500 dark:text-gray-400"
              }`}
              aria-hidden="true"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.56811 10C3.57873 10 3.58936 10 3.60001 10H4.4C4.41065 10 4.42128 10 4.4319 10C4.68428 9.99997 4.93008 9.99995 5.13824 10.017C5.36683 10.0356 5.63656 10.0797 5.90799 10.218C6.28431 10.4097 6.59027 10.7157 6.78202 11.092C6.92032 11.3634 6.96438 11.6332 6.98306 11.8618C7.00007 12.0699 7.00004 12.3157 7.00001 12.5681C7.00001 12.5787 7.00001 12.5894 7.00001 12.6L7.00001 19.4319C7.00004 19.6843 7.00007 19.9301 6.98306 20.1382C6.96438 20.3668 6.92032 20.6366 6.78202 20.908C6.59027 21.2843 6.28431 21.5903 5.90799 21.782C5.63656 21.9203 5.36683 21.9644 5.13823 21.9831C4.93007 22.0001 4.68427 22 4.43189 22H3.56812C3.31574 22 3.06994 22.0001 2.86178 21.9831C2.63318 21.9644 2.36345 21.9203 2.09202 21.782C1.7157 21.5903 1.40974 21.2843 1.21799 20.908C1.07969 20.6366 1.03563 20.3668 1.01695 20.1382C0.999943 19.9301 0.999973 19.6843 1 19.4319L1.00001 12.6C1.00001 12.5894 1 12.5787 1 12.5681C0.999973 12.3157 0.999943 12.0699 1.01695 11.8618C1.03563 11.6332 1.07969 11.3634 1.21799 11.092C1.40974 10.7157 1.7157 10.4097 2.09202 10.218C2.36345 10.0797 2.63318 10.0356 2.86178 10.017C3.06993 9.99995 3.31572 9.99997 3.56811 10Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5681 6C11.5787 6.00001 11.5894 6.00001 11.6 6.00001H12.4C12.4107 6.00001 12.4213 6.00001 12.4319 6C12.6843 5.99997 12.9301 5.99994 13.1382 6.01695C13.3668 6.03563 13.6366 6.0797 13.908 6.21799C14.2843 6.40974 14.5903 6.7157 14.782 7.09203C14.9203 7.36345 14.9644 7.63318 14.9831 7.86178C15.0001 8.06994 15 8.31573 15 8.56811C15 8.57873 15 8.58937 15 8.60001L15 19.4319C15 19.6843 15.0001 19.9301 14.9831 20.1382C14.9644 20.3668 14.9203 20.6366 14.782 20.908C14.5903 21.2843 14.2843 21.5903 13.908 21.782C13.6366 21.9203 13.3668 21.9644 13.1382 21.9831C12.9301 22.0001 12.6843 22 12.4319 22H11.5681C11.3157 22 11.0699 22.0001 10.8618 21.9831C10.6332 21.9644 10.3635 21.9203 10.092 21.782C9.71571 21.5903 9.40975 21.2843 9.218 20.908C9.07971 20.6366 9.03564 20.3668 9.01696 20.1382C8.99995 19.9301 8.99998 19.6843 9.00001 19.4319L9.00002 8.60001C9.00002 8.58937 9.00001 8.57873 9.00001 8.56811C8.99998 8.31573 8.99995 8.06994 9.01696 7.86178C9.03564 7.63318 9.07971 7.36345 9.218 7.09203C9.40975 6.7157 9.71571 6.40974 10.092 6.21799C10.3635 6.0797 10.6332 6.03563 10.8618 6.01695C11.0699 5.99995 11.3157 5.99997 11.5681 6Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.5681 2C19.5788 2 19.5894 2.00001 19.6 2.00001L20.4 2.00001C20.4107 2.00001 20.4213 2 20.4319 2C20.6843 1.99997 20.9301 1.99994 21.1383 2.01695C21.3668 2.03563 21.6366 2.07969 21.908 2.21799C22.2843 2.40974 22.5903 2.7157 22.782 3.09202C22.9203 3.36345 22.9644 3.63318 22.9831 3.86178C23.0001 4.06993 23.0001 4.31573 23 4.56811C23 4.57873 23 4.58936 23 4.60001L23 19.4319C23.0001 19.6843 23.0001 19.9301 22.9831 20.1382C22.9644 20.3668 22.9203 20.6366 22.782 20.908C22.5903 21.2843 22.2843 21.5903 21.908 21.782C21.6366 21.9203 21.3668 21.9644 21.1383 21.9831C20.9301 22.0001 20.6843 22 20.4319 22H19.5681C19.3157 22 19.07 22.0001 18.8618 21.9831C18.6332 21.9644 18.3635 21.9203 18.092 21.782C17.7157 21.5903 17.4098 21.2843 17.218 20.908C17.0797 20.6366 17.0356 20.3668 17.017 20.1382C17 19.9301 17 19.6843 17 19.4319L17 4.60001C17 4.58936 17 4.57873 17 4.56811C17 4.31572 17 4.06993 17.017 3.86178C17.0356 3.63318 17.0797 3.36345 17.218 3.09202C17.4098 2.7157 17.7157 2.40974 18.092 2.21799C18.3635 2.07969 18.6332 2.03563 18.8618 2.01695C19.07 1.99994 19.3157 1.99997 19.5681 2Z"
                fill="currentColor"
              />
            </svg>
            {t("ibBalanceTabs.volume")}
          </button>
        </li>
      </ul>
      {currentView === "usersReport" && <UsersReportIB />}
      {currentView === "commissionReport" && <CommissionReportIB />}
      {currentView === "volumeReport" && <VolumeReportIB />}
    </div>
  );
};

export default ClientIBReports;
