import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import countries from "i18n-iso-countries";
import english from "i18n-iso-countries/langs/en.json";
import { getFirestore } from "firebase/firestore";
import { setDoc, doc, onSnapshot } from "firebase/firestore";
import { getCurrentUser } from "../../services/userService";
import { useNotification } from "../../contexts/NotificationContext.js";
import { UserContext } from "../../contexts/UserContext";
import axios from "axios";
import CountryCombobox from "../Shared/CountryCombobox";

countries.registerLocale(english);

const firestore = getFirestore();

const eighteenYearsAgo = new Date();
eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
const maxDate = eighteenYearsAgo.toISOString().split("T")[0];

export default function OnboardingFormBroker() {
  const { user } = useContext(UserContext);
  const [formData, setFormData] = useState({
    demoPort: "443",
    livePort: "443",
    demoHost: "xm-demo.tradingplatform.pro",
    liveHost: "xm-real.tradingplatform.pro",
  });
  const navigate = useNavigate();
  const [isDemoLoading, setIsDemoLoading] = useState(false);
  const [isLiveLoading, setIsLiveLoading] = useState(false);
  const [isDemoVerified, setIsDemoVerified] = useState(false);
  const [isLiveVerified, setIsLiveVerified] = useState(false);
  const { showNotification } = useNotification();
  const [isPersonalPhoneValid, setIsPersonalPhoneValid] = useState(true);
  const [isSupportPhoneValid, setIsSupportPhoneValid] = useState(true);
  const [phoneValidationTimeoutId, setPhoneValidationTimeoutId] =
    useState(null); // New state to manage the timeout ID
  const [countrySelected, setCountrySelected] = useState(null);
  const [incorporationCountry, setIncorporationCountry] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (
      user?.userData?.status === "broker" &&
      user?.userData?.hasCompletedOnboarding
    ) {
      showNotification(
        "warning",
        "Unauthorized access. Redirecting to dashboard..."
      );
      navigate("/broker/dashboard/clients");
    }
  }, [user, navigate, showNotification]);

  // Function to validate the phone number
  function validatePhoneNumber(phone) {
    const phonePattern = /^\+\d{8,15}$/; // Regex pattern to match the phone number
    return phonePattern.test(phone); // Test the phone number against the pattern
  }

  const handleCountrySelect = (selectedCountry) => {
    setFormData({
      ...formData,
      country: selectedCountry,
    });
    setCountrySelected(true); // Set countrySelected to true when a country is selected
  };

  function validateSupportPhoneNumber(phone) {
    const phonePattern = /^\+\d{8,15}$/; // Regex pattern to match the phone number
    return phonePattern.test(phone); // Test the phone number against the pattern
  }

  const handleIncorporationCountrySelect = (selectedCountry) => {
    setFormData({
      ...formData,
      countryCompany: selectedCountry,
    });
    setIncorporationCountry(selectedCountry);
  };

  const handleInputChange = (e) => {
    const { name: inputName, value: inputValue } = e.target;

    let transformedValue = inputValue;

    if (inputName === "phone") {
      // Clear the existing timeout
      if (phoneValidationTimeoutId) {
        clearTimeout(phoneValidationTimeoutId);
      }

      // Set a new timeout for phone number validation
      const timeoutId = setTimeout(() => {
        // Validate the phone number after a delay of 1000ms (1 second)
        const isValidPhoneNumber = validatePhoneNumber(inputValue);
        setIsPersonalPhoneValid(isValidPhoneNumber);
      }, 1000); // 1000ms delay

      // Save the timeout ID in the state
      setPhoneValidationTimeoutId(timeoutId);
    }

    // Update these handlers to directly update the formData state
    if (inputName === "supportPhoneNumber") {
      const isValidPhoneNumber = validateSupportPhoneNumber(inputValue);
      setIsSupportPhoneValid(isValidPhoneNumber);
      setFormData((prevState) => ({
        ...prevState,
        supportPhoneNumber: inputValue,
      }));
    }

    if (inputName === "incorporationCountry") {
      // handleIncorporationCountrySelect(inputValue);
      setFormData((prevState) => ({
        ...prevState,
        incorporationCountry: inputValue,
      }));
    }

    setFormData({
      ...formData,
      [inputName]: transformedValue,
    });
  };

  const handleVerifyDemoConnectionSettings = async () => {
    const requestData = {
      xmanagerUrl: formData.demoHost,
      username: formData.xmanagerDemoLogin,
      password: formData.xmanagerDemoPassword,
    };

    setIsDemoLoading(true);

    try {
      const response = await axios.post(
        "https://us-central1-xohsync.cloudfunctions.net/testXManagerConnection",
        requestData
      );
      if (response.status === 200) {
        setIsDemoVerified(true);
        showNotification("success", "Credentials saved successfully!");
      } else {
        setIsDemoVerified(false);
        // Handle other response scenarios here if needed
      }
    } catch (error) {
      setIsDemoVerified(false);

      // If the error response is available and contains a message, use it. Otherwise, use a generic error message.
      const errorMessage =
        error.response?.data?.message ||
        "Invalid credentials! Please update your credentials and try again.";
      showNotification("error", errorMessage);
    } finally {
      setIsDemoLoading(false);
    }
  };

  const handleVerifyLiveConnectionSettings = async () => {
    const requestData = {
      xmanagerUrl: formData.liveHost,
      username: formData.xmanagerLiveLogin,
      password: formData.xmanagerLivePassword,
    };

    setIsLiveLoading(true);

    try {
      const response = await axios.post(
        "https://us-central1-xohsync.cloudfunctions.net/testXManagerConnection",
        requestData
      );
      if (response.status === 200) {
        setIsLiveVerified(true);
        showNotification("success", "Credentials saved successfully!");
      } else {
        setIsLiveVerified(false);
        // Handle other response scenarios here if needed
      }
    } catch (error) {
      setIsLiveVerified(false);

      // If the error response is available and contains a message, use it. Otherwise, use a generic error message.
      const errorMessage =
        error.response?.data?.message ||
        "Invalid credentials! Please update your credentials and try again.";
      showNotification("error", errorMessage);
    } finally {
      setIsLiveLoading(false);
    }
  };

  const handleOnboardingFormSubmit = async (e) => {
    e.preventDefault();

    setIsSaving(true);

    const user = getCurrentUser();
    if (!user) {
      console.error("No authenticated user found.");
      return;
    }

    // Check if a country has been selected
    if (!countrySelected) {
      showNotification("error", "Please select a country.");
      return;
    }

    const uid = user.uid;
    const email = user.email;

    // Data for users collection
    const userSpecificData = {
      name: formData.name,
      surname: formData.surname,
      dob: formData.dob,
      phone: formData.phone,
      country: formData.country,
      address: formData.address,
      city: formData.city,
      state: formData.state,
      zip_code: formData.zip_code,
      email: email,
      uid: uid,
      status: "broker",
      hasCompletedOnboarding: true,
      brokerUID: uid,
    };

    // Data for brokers collection
    const brokerData = {
      companyData: {
        displayName: formData.displayName,
        companyName: formData.companyName,
        supportPhoneNumber: formData.supportPhoneNumber,
        countryCompany: formData.countryCompany,
        addressCompany: formData.addressCompany,
        cityCompany: formData.cityCompany,
        stateCompany: formData.stateCompany,
        zipCodeCompany: formData.zipCodeCompany,
      },
      xmanagerDemoCredentials: {
        xmanagerDemoLogin: formData.xmanagerDemoLogin,
        xmanagerDemoPassword: formData.xmanagerDemoPassword,
        demoPort: formData.demoPort,
        demoHost: formData.demoHost,
      },
      xmanagerLiveCredentials: {
        xmanagerLiveLogin: formData.xmanagerLiveLogin,
        xmanagerLivePassword: formData.xmanagerLivePassword,
        livePort: formData.livePort,
        liveHost: formData.liveHost,
      },
      uid: uid,
      email: email,
      status: "trial",
    };

    // Data for whitelabel collection
    const whitelabelData = {
      themeColor: "Green",
      logoUrlLight:
        "https://firebasestorage.googleapis.com/v0/b/xohsync.appspot.com/o/brokers%2FzZNKIqjPWzQjhXlE9xfS1xlAZIF2%2Flight?alt=media&token=62d5d35e-bae6-455f-8291-d7f45758951b",
      logoUrlDark:
        "https://firebasestorage.googleapis.com/v0/b/xohsync.appspot.com/o/brokers%2FzZNKIqjPWzQjhXlE9xfS1xlAZIF2%2Fdark?alt=media&token=96766c4e-28ae-4e6c-8f7b-a08f14f4baca",
      iframeUrlDark: "https://xs5.xopenhub.pro/xohsync",
      iframeUrlLight: "https://xs5.xopenhub.pro/xohsync",
      allowDepositBeforeKYC: false, // Default KYC rule
      allowWithdrawalBeforeKYC: false, // Default KYC rule
      displayName: formData.displayName,
      companyName: formData.companyName,
      supportPhoneNumber: formData.supportPhoneNumber,
      countryCompany: formData.countryCompany,
      addressCompany: formData.addressCompany,
      cityCompany: formData.cityCompany,
      stateCompany: formData.stateCompany,
      zipCodeCompany: formData.zipCodeCompany,
    };

    try {
      // Define document references
      const userDocRef = doc(firestore, "users", uid);
      const brokerDocRef = doc(firestore, "brokers", uid);
      const whitelabelDocRef = doc(firestore, "whitelabel", uid);

      // Save to users collection
      await setDoc(userDocRef, userSpecificData);
      console.log("Document successfully written to users collection!");

      // Save to brokers collection
      await setDoc(brokerDocRef, brokerData);
      console.log("Document successfully written to brokers collection!");

      // Save to whitelabel collection
      await setDoc(whitelabelDocRef, whitelabelData);
      console.log("Document successfully written to whitelabel collection!");

      // Variables to track if documents have been updated
      let isUserUpdated = false;
      let isBrokerUpdated = false;
      let isWhitelabelUpdated = false;

      // Listen for updates to the documents
      onSnapshot(userDocRef, (doc) => {
        if (doc.exists()) {
          isUserUpdated = true;
        }
      });

      onSnapshot(brokerDocRef, (doc) => {
        if (doc.exists()) {
          isBrokerUpdated = true;
        }
      });

      onSnapshot(whitelabelDocRef, (doc) => {
        if (doc.exists()) {
          isWhitelabelUpdated = true;
        }
      });

      // Check if all documents exist before navigating
      const checkDocuments = setInterval(() => {
        if (isUserUpdated && isBrokerUpdated && isWhitelabelUpdated) {
          clearInterval(checkDocuments);
          window.location.href = "/broker/dashboard/trial";
        }
      }, 1000); // Check every second
    } catch (error) {
      console.error("Error writing document: ", error);
      showNotification("error", `${error.message}`);
    } finally {
      setIsSaving(false); // Set isSaving back to false once the form has been submitted
    }
  };

  const [phonePlaceholder] = useState("+1");

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-10 min-h-screen dark:bg-gray-900">
      <form onSubmit={handleOnboardingFormSubmit}>
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 dark:border-gray-700 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
                Personal Information
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Your information is used to create an administrator account
                connected to your brokerage.
              </p>
            </div>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  First name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="name"
                    required
                    onChange={handleInputChange}
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Last name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="surname"
                    required
                    onChange={handleInputChange}
                    id="last-name"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="date-of-birth"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Date of Birth
                </label>
                <div className="mt-2">
                  <input
                    id="date-of-birth"
                    name="dob"
                    required
                    type="date"
                    autoComplete="bday"
                    max={maxDate}
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Phone Number
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="text"
                    name="phone"
                    id="phone-number"
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    placeholder={`${phonePlaceholder} 555 987 654`}
                  />
                  {!isPersonalPhoneValid && ( // Only render the error text if isPhoneValid is false
                    <p
                      className="mt-2 text-sm text-red-600 dark:text-red-400"
                      id="phone-error"
                    >
                      Oops! It seems like the phone number might be missing a
                      country code or might not be correct. Make sure to include
                      the country code and enter a valid number, like +1
                      123-456-7890.
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Country
                </label>
                <CountryCombobox onCountrySelect={handleCountrySelect} />
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Street address
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="address"
                    required
                    id="street-address"
                    autoComplete="street-address"
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-2 sm:col-start-1">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  City
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="city"
                    required
                    id="city"
                    autoComplete="address-level2"
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="region"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  State / Province
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="state"
                    id="region"
                    required
                    autoComplete="address-level1"
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="postal-code"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  ZIP / Postal code
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="zip_code"
                    id="postal-code"
                    required
                    autoComplete="postal-code"
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 dark:border-gray-700 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
                Company information
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Set up your brokerage information for white label and
                customizations.
              </p>
            </div>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Display name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="displayName"
                    onChange={handleInputChange}
                    id="displayName"
                    placeholder="Display name"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Company name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="companyName"
                    onChange={handleInputChange}
                    id="companyName"
                    placeholder="Company name"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Support Phone Number
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="text"
                    name="supportPhoneNumber"
                    id="support-phone-number"
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    placeholder={`${phonePlaceholder} 555 987 654`}
                  />
                  {!isSupportPhoneValid && ( // Only render the error text if isPhoneValid is false
                    <p
                      className="mt-2 text-sm text-red-600 dark:text-red-400"
                      id="support-phone-error"
                    >
                      Oops! It seems like the phone number might be missing a
                      country code or might not be correct. Make sure to include
                      the country code and enter a valid number, like +1
                      123-456-7890.
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Country of Incorporation
                </label>
                <CountryCombobox
                  onCountrySelect={handleIncorporationCountrySelect}
                />
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Company street address
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="addressCompany"
                    id="addressCompany"
                    onChange={handleInputChange}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-2 sm:col-start-1">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  City
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="cityCompany"
                    id="cityCompany"
                    onChange={handleInputChange}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="region"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  State / Province
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="stateCompany"
                    id="stateCompany"
                    onChange={handleInputChange}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="postal-code"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  ZIP / Postal code
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="zipCodeCompany"
                    id="zipCodeCompany"
                    onChange={handleInputChange}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 dark:border-gray-700 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
                xManager configuration
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Configure your xManager API connection for both the demo and
                live environment.
              </p>
            </div>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Demo xManager login
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="xmanagerDemoLogin"
                    onChange={handleInputChange}
                    id="demo-login"
                    required
                    disabled={isDemoVerified}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Demo xManager password
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    name="xmanagerDemoPassword"
                    onChange={handleInputChange}
                    id="demo-password"
                    required
                    disabled={isDemoVerified}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Demo host port
                </label>
                <div className="mt-2">
                  <input
                    type="number"
                    name="demoPort"
                    required
                    onChange={handleInputChange}
                    id="demo-port"
                    placeholder="443 (default)"
                    disabled={isDemoVerified}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Demo host address
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="demoHost"
                    required
                    onChange={handleInputChange}
                    id="demo-host"
                    placeholder="xm-demo.tradingplatform.pro (default)"
                    disabled={isDemoVerified}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <button
                  type="button"
                  onClick={handleVerifyDemoConnectionSettings}
                  className={`rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 hover:bg-gray-50 dark:hover:bg-white/10 ${
                    isDemoVerified
                      ? "bg-green-50 dark:bg-green-500/10 text-green-600 dark:text-green-400"
                      : "bg-white dark:bg-white/5 text-gray-900 dark:text-white"
                  }`}
                  disabled={isDemoLoading || isDemoVerified} // Disable the button during loading or after verification
                >
                  {isDemoLoading ? (
                    <div className="flex items-center">
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-900 dark:text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Verifying...
                    </div>
                  ) : isDemoVerified ? (
                    "Success! Credentials verified."
                  ) : (
                    "Save live xManager credentials"
                  )}
                </button>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Live xManager login
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="xmanagerLiveLogin"
                    onChange={handleInputChange}
                    id="live-login"
                    required
                    disabled={isLiveVerified}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Live xManager password
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    name="xmanagerLivePassword"
                    onChange={handleInputChange}
                    id="live-password"
                    required
                    disabled={isLiveVerified}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Live host port
                </label>
                <div className="mt-2">
                  <input
                    type="number"
                    name="livePort"
                    required
                    onChange={handleInputChange}
                    id="live-port"
                    placeholder="443 (default)"
                    disabled={isLiveVerified}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Live host address
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="liveHost"
                    required
                    onChange={handleInputChange}
                    id="live-host"
                    disabled={isLiveVerified}
                    placeholder="xm-real.tradingplatform.pro (default)"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-white/5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <button
                  type="button"
                  onClick={handleVerifyLiveConnectionSettings}
                  className={`rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 hover:bg-gray-50 dark:hover:bg-white/10 ${
                    isLiveVerified
                      ? "bg-green-50 dark:bg-green-500/10 text-green-600 dark:text-green-400"
                      : "bg-white dark:bg-white/5 text-gray-900 dark:text-white"
                  }`}
                  disabled={isLiveLoading || isLiveVerified} // Disable the button during loading or after verification
                >
                  {isLiveLoading ? (
                    <div className="flex items-center">
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-900 dark:text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Verifying...
                    </div>
                  ) : isLiveVerified ? (
                    "Success! Credentials verified."
                  ) : (
                    "Save live xManager credentials"
                  )}
                </button>
              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6 sm:col-span-6">
                <button
                  type="submit"
                  className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  disabled={isSaving} // Disable the button when the form is being submitted
                >
                  {isSaving ? (
                    <div className="flex items-center">
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Saving...
                    </div>
                  ) : (
                    "Save information and continue"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
