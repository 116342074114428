import { getAuth } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

export const getCurrentBroker = () => {
  const auth = getAuth();
  return auth.currentUser;
};

export const fetchBrokerData = async () => {
  const auth = getAuth();
  const brokerAuth = auth.currentUser;

  if (brokerAuth != null) {
    const uid = brokerAuth.uid;
    const db = getFirestore();

    // Fetch data from the users collection first
    const userDocRef = doc(db, "users", uid);
    const userDocSnap = await getDoc(userDocRef);

    if (!userDocSnap.exists() || userDocSnap.data().status !== "broker") {
      console.log("User is not a broker or no such user!");
      return null;
    }

    // Now that we know it's a broker, fetch data from the brokers collection
    const brokerDocRef = doc(db, "brokers", uid);
    const brokerDocSnap = await getDoc(brokerDocRef);

    // Check if there's a whitelabel document with the UID
    const whiteLabelDocRef = doc(db, "whitelabel", uid);
    const whiteLabelDocSnap = await getDoc(whiteLabelDocRef);

    // Set default values
    let whiteLabelData = {
      themeColor: "Green",
      logoUrlLight:
        "https://firebasestorage.googleapis.com/v0/b/xohsync.appspot.com/o/brokers%2FzZNKIqjPWzQjhXlE9xfS1xlAZIF2%2Flight?alt=media&token=62d5d35e-bae6-455f-8291-d7f45758951b",
      logoUrlDark:
        "https://firebasestorage.googleapis.com/v0/b/xohsync.appspot.com/o/brokers%2FzZNKIqjPWzQjhXlE9xfS1xlAZIF2%2Fdark?alt=media&token=96766c4e-28ae-4e6c-8f7b-a08f14f4baca",
      iframeUrlDark: "https://xs5.xopenhub.pro/xohsync",
      iframeUrlLight: "https://xs5.xopenhub.pro/xohsync",
      allowDepositBeforeKYC: false, // Default KYC rule
      allowWithdrawalBeforeKYC: false, // Default KYC rule
    };

    if (whiteLabelDocSnap.exists()) {
      // Overwrite the default values with the data from whiteLabelDocSnap
      whiteLabelData = whiteLabelDocSnap.data();
    }

    // Fetch data from the subscriptions collection
    const stripeCustomerId = brokerDocSnap.data().stripeCustomerId;
    // Set default subscriptionData
    let subscriptionData = {
      status: "not_started",
      // Add any other default fields you need
    };

    if (stripeCustomerId) {
      try {
        const subscriptionDocRef = doc(db, "subscriptions", stripeCustomerId);
        const subscriptionDocSnap = await getDoc(subscriptionDocRef);

        if (subscriptionDocSnap.exists()) {
          const subscriptionDocData = subscriptionDocSnap.data();
          if (subscriptionDocData.brokerUID === uid) {
            subscriptionData = subscriptionDocData;
          } else {
            console.log("brokerUID does not match!");
          }
        } else {
          console.log("No matching subscription document!");
          // Update subscriptionData to reflect that the subscription document does not exist
          subscriptionData = {
            status: "not_started",
            // Add any other fields you need
          };
        }
      } catch (error) {
        console.log("Error fetching subscription document:", error);
        // Update subscriptionData to reflect that there was an error fetching the document
        subscriptionData = {
          status: "fetch_error",
          // Add any other fields you need
        };
      }
    }

    if (brokerDocSnap.exists()) {
      const userData = userDocSnap.data();
      const brokerData = brokerDocSnap.data();

      // Initialize the notification state and count
      let hasPendingKYCNotification = false;
      let pendingKYCCount = 0;

      if (userData && userData.uid) {
        const db = getFirestore();
        const kycQuery = query(
          collection(db, "kyc"),
          where("brokerUID", "==", userData.uid),
          where("kycStatus", "==", "pending")
        );

        const kycSnapshot = await getDocs(kycQuery);
        pendingKYCCount = kycSnapshot.size; // Count of pending KYC documents
        hasPendingKYCNotification = pendingKYCCount > 0; // true if there's at least one pending KYC
      }

      return {
        userData: userData,
        brokerData: brokerData,
        whiteLabelData: whiteLabelData,
        subscriptionData: subscriptionData,
        hasPendingKYCNotification: hasPendingKYCNotification, // This is the boolean flag
        pendingKYCCount: pendingKYCCount, // This is the count of pending KYCs
      };
    } else {
      console.log("No such broker!");
      return null;
    }
  }
  return null;
};
