import { Fragment, useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import ReactApexChart from "react-apexcharts";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useNotification } from "../../../../../contexts/NotificationContext";
import { useTranslation } from "react-i18next";

const UsersChart = ({ onNavigate }) => {
  const [seriesData, setSeriesData] = useState([0]);
  const [categories, setCategories] = useState([]);
  const [percentageChange, setPercentageChange] = useState(0);
  const [timePeriod, setTimePeriod] = useState("30");
  const [isLoading, setIsLoading] = useState(false);
  const [actualTotalUsers, setActualTotalUsers] = useState(0);
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchChartData = async () => {
      setIsLoading(true);
      const functions = getFunctions();
      const queryBigQueryIB = httpsCallable(functions, "queryBigQueryIB");

      try {
        const response = await queryBigQueryIB({
          type: "referredInPeriod",
          timePeriod: timePeriod,
        });
        const data = response.data;
        let counts = data.dateGroupedData.counts;
        let dates = data.dateGroupedData.dates;

        // Calculate the actual total users for display before potentially modifying the data for the chart
        const totalUsers = counts.reduce(
          (acc, currentValue) => acc + currentValue,
          0
        );

        // Ensure there are at least two data points for the chart
        if (counts.length < 2) {
          if (counts.length === 1) {
            // Duplicate the existing data point for visual purposes
            counts = [counts[0], counts[0]];
            dates = [dates[0], `${dates[0]} (replicated)`];
          } else {
            // Provide default data points if none exist, for visual purposes
            counts = [0, 0];
            dates = ["Start Period", "End Period"];
          }
        }

        // Use the modified counts and dates for setting the state
        setSeriesData(counts);
        setCategories(dates);
        setPercentageChange(data.percentageChange);
        setActualTotalUsers(totalUsers);
      } catch (error) {
        console.error("Error calling queryBigQueryIB function:", error);
        showNotification(
          "error",
          "Unable to load chart data. Please try refreshing the page or check back later."
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchChartData();
  }, [timePeriod]);

  const formattedTotalUsers = actualTotalUsers.toLocaleString();

  // Determine arrow direction and color based on percentage change
  const arrowDirection = percentageChange >= 0 ? "" : "rotate-180";
  const textColor = percentageChange >= 0 ? "text-green-500" : "text-red-500";

  const options = {
    chart: {
      height: 291,
      maxWidth: "100%",
      type: "area",
      fontFamily: "Inter, sans-serif",
      dropShadow: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.55,
        opacityTo: 0,
        shade: "#1C64F2",
        gradientToColors: ["#1C64F2"],
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 6,
    },
    grid: {
      show: false,
      strokeDashArray: 4,
      padding: {
        left: 2,
        right: 2,
        top: 0,
      },
    },
    series: [
      {
        name: t("ibUsersChart.totalUsers"),
        data: seriesData,
        color: "#1A56DB",
      },
    ],
    xaxis: {
      categories: categories,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
  };

  // Example onClick handler for navigating to reports
  const handleNavigateToReports = () => {
    onNavigate("Reports"); // Use the onNavigate function with the desired view
  };

  return (
    <div className="w-full max-w-sm rounded-lg bg-white p-4 shadow md:p-6 dark:bg-gray-800">
      <div className="flex justify-between">
        <div>
          <h5 className="pb-2 text-3xl font-bold leading-none text-gray-900 dark:text-white">
            {isLoading ? (
              <div className="h-6 w-10 animate-pulse rounded-full bg-gray-200 pb-2 dark:bg-gray-700"></div>
            ) : (
              `${formattedTotalUsers}`
            )}
          </h5>

          <p className="text-base font-normal text-gray-500 dark:text-gray-400">
            {t("ibUsersChart.titleDescription")} {timePeriod}{" "}
            {t("ibCommissionChart.days")}
          </p>
        </div>

        <div
          className={`flex items-center px-2.5 py-0.5 text-center text-base font-semibold ${textColor}`}
        >
          {isLoading ? (
            <div className="flex h-4 w-12 animate-pulse items-center rounded-full bg-gray-200 px-2.5 py-0.5 dark:bg-gray-700"></div>
          ) : (
            `${Math.abs(percentageChange)}%`
          )}
          <svg
            className={`ms-1 h-3 w-3 ${arrowDirection}`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13V1m0 0L1 5m4-4 4 4"
            />
          </svg>
        </div>
      </div>
      {isLoading ? (
        <div
          role="status"
          className="mt-2 max-w-sm animate-pulse rounded border border-gray-200 p-4 shadow md:p-6 dark:border-gray-700"
        >
          <div className="mt-4 flex items-baseline">
            <div className="h-56 w-full rounded-t-lg bg-gray-200 dark:bg-gray-700"></div>
            <div className="ms-6 h-56 w-full rounded-t-lg bg-gray-200 dark:bg-gray-700"></div>
            <div className="ms-6 h-52 w-full rounded-t-lg bg-gray-200 dark:bg-gray-700"></div>
            <div className="ms-6 h-48 w-full rounded-t-lg bg-gray-200 dark:bg-gray-700"></div>
            <div className="ms-6 h-56 w-full rounded-t-lg bg-gray-200 dark:bg-gray-700"></div>
            <div className="ms-6 h-52 w-full rounded-t-lg bg-gray-200 dark:bg-gray-700"></div>
            <div className="ms-6 h-56 w-full rounded-t-lg bg-gray-200 dark:bg-gray-700"></div>
          </div>
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div style={{ height: "291px" }}>
          <ReactApexChart
            options={options}
            series={options.series}
            type="area"
            height="100%"
          />
        </div>
      )}
      <div className="grid grid-cols-1 items-center justify-between border-t border-gray-200 dark:border-gray-700">
        <div className="flex items-center justify-between pt-5">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex items-center text-center text-sm font-medium text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                {t("ibCommissionChart.last")} {timePeriod}{" "}
                {t("ibCommissionChart.days")}
                <ChevronDownIcon
                  className="-mr-1 ml-2 h-5 w-5"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-lg bg-white shadow dark:divide-gray-600 dark:bg-gray-700">
                <div className="px-1 py-1">
                  <Menu.Item>
                    <button
                      onClick={() => setTimePeriod("1")}
                      className="block w-full rounded-md px-4 py-2 text-left text-sm text-gray-500 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      {t("ibCommissionChart.today")}
                    </button>
                  </Menu.Item>
                </div>
                <div className="px-1 py-1">
                  <Menu.Item>
                    <button
                      onClick={() => setTimePeriod("7")}
                      className="block w-full rounded-md px-4 py-2 text-left text-sm text-gray-500 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      {t("ibCommissionChart.last7")}
                    </button>
                  </Menu.Item>
                  <Menu.Item>
                    <button
                      onClick={() => setTimePeriod("30")}
                      className="block w-full rounded-md px-4 py-2 text-left text-sm text-gray-500 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      {t("ibCommissionChart.last30")}
                    </button>
                  </Menu.Item>
                  <Menu.Item>
                    <button
                      onClick={() => setTimePeriod("90")}
                      className="block w-full rounded-md px-4 py-2 text-left text-sm text-gray-500 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      {t("ibCommissionChart.last90")}
                    </button>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <button
            onClick={handleNavigateToReports}
            className="inline-flex items-center rounded-lg px-3 py-2 text-sm font-semibold uppercase text-blue-600  hover:bg-gray-100 hover:text-blue-700 dark:border-gray-700 dark:hover:bg-gray-700 dark:hover:text-blue-500 dark:focus:ring-gray-700"
          >
            {t("ibUsersChart.buttonReport")}
            <svg
              className="ms-1.5 h-2.5 w-2.5 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1 9 4-4-4-4"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UsersChart;
