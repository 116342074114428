import { useState, useEffect } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import countries from "i18n-iso-countries";
import english from "i18n-iso-countries/langs/en.json";
import ReactCountryFlag from "react-country-flag";

countries.registerLocale(english);

// Getting the country names and codes
const countryList = Object.entries(countries.getNames("en")).map(
  ([code, name]) => ({
    code,
    name,
  })
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CountryCombobox({ onCountrySelect, defaultCountry }) {
  const [query, setQuery] = useState("");

  const [selectedCountry, setSelectedCountry] = useState(() => {
    if (defaultCountry) {
      return (
        countryList.find(
          (c) => c.name.toUpperCase() === defaultCountry.toUpperCase()
        ) || null
      );
    } else {
      const browserLocale = navigator.language.split("-")[1] || "US";
      const countryCode = browserLocale.toUpperCase();
      return (
        countryList.find((country) => country.code === countryCode) || null
      );
    }
  });

  useEffect(() => {
    if (selectedCountry) {
      let countryName = selectedCountry.name.toUpperCase();
      if (countryName === "UNITED STATES OF AMERICA") {
        countryName = "UNITED STATES";
      }
      onCountrySelect(countryName);
    }
  }, [selectedCountry]); // This ensures onCountrySelect is called when selectedCountry is first set.

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    let countryName = country.name.toUpperCase();
    if (countryName === "UNITED STATES OF AMERICA") {
      countryName = "UNITED STATES";
    }
    onCountrySelect(countryName); // Sending the full country name in uppercase
  };

  const filteredCountries = query
    ? countryList.filter((country) =>
        country.name.toLowerCase().includes(query.toLowerCase())
      )
    : countryList;

  return (
    <Combobox as="div" value={selectedCountry} onChange={handleCountryChange}>
      <div className="relative mt-2">
        <Combobox.Input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          onChange={(event) => setQuery(event.target.value)}
          onBlur={(event) => {
            const autofilledCountry = countryList.find(
              (country) =>
                country.name.toLowerCase() === event.target.value.toLowerCase()
            );
            if (autofilledCountry) {
              handleCountryChange(autofilledCountry);
            }
          }}
          displayValue={(country) => country?.name}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredCountries.length > 0 && (
          <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-gray-700 py-1 text-base shadow-lg ring-1 ring-black dark:ring-white ring-opacity-5 dark:ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredCountries.map((country) => (
              <Combobox.Option
                key={country.code}
                value={country}
                className={({ active }) =>
                  classNames(
                    "relative cursor-pointer select-none py-2 pl-3 pr-9",
                    active
                      ? "hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-white"
                      : "text-gray-900 dark:text-white"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      <ReactCountryFlag
                        countryCode={country.code.toLowerCase()}
                        svg
                        style={{
                          width: "1em",
                          height: "1em",
                          marginRight: "10px",
                          borderRadius: "30%",
                        }}
                      />
                      {country.name}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-primary-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
